import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  question: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '20px 12px',
    position: 'relative',
    background: theme.palette.background.paper,

    '& .ql-toolbar': {
      opacity: 0,
      margin: '0 0 0 4px',
    },

    '& .multiplyOption, & .questionTitle, & .textAnswer, & .locationAnswer': {
      '& .copyButton, & .ql-toolbar': {
        opacity: 0,
      },

      '&:hover': {
        '& .copyButton, & .ql-toolbar': {
          opacity: 1,
        },
      },
    },

    '&.textQuestion': {
      '& .textQuestionAnswer': {
        minHeight: '27px',
        display: 'block',
      },
    },

    '& .textQuestionAnswer': {
      borderBottom: `1px solid transparent`,
    },

    '& .textQuestionAnswer, & .viboTextarea': {
      fontSize: '13px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
      },
    },

    '&.headerQuestion': {
      '& .questionHeader': {
        margin: '0',
      },

      '& .questionMediaPreview ': {
        margin: '16px 0 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '20px 0 0 0',
        },
      },

      '& .questionTitle': {
        transform: 'none',
      },
    },

    '&:hover': {
      '& .tools': {
        opacity: 1,
      },

      '& .dndIcon': {
        display: 'block!important',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '24px 16px',
    },
  },

  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // TODO: STYLES FOR RTE check if used
    // '& .quillEditor': {
    //   flexDirection: 'row',
    //   width: '100%',
    //   alignItems: 'start',

    //   '& .ql-blank::before': {
    //     left: 0,
    //   },

    //   '& *': {
    //     fontSize: '11px',
    //     lineHeight: '16.55px',

    //     '@media screen and (min-width: 1441px)': {
    //       fontSize: '14px',
    //       lineHeight: '21.07px',
    //     },
    //   },

    //   '& .quill': {
    //     width: '100%',
    //     paddingLeft: '0',
    //     paddingRight: '0',
    //   },

    //   '& .ql-editor': {
    //     padding: '0',
    //   },

    //   '& .ql-toolbar': {
    //     width: 'fit-content',
    //     zIndex: 1,
    //     backgroundColor: 'transparent',
    //     padding: '0',

    //     '& button': {
    //       height: 'auto',
    //     },

    //     '& .ql-link': {
    //       padding: '4px',
    //       borderRadius: '2px',
    //       color: theme.palette.primary.main,

    //       '& svg': {
    //         width: '16px',
    //         height: '16px',
    //       },

    //       '&:hover, &.hoverable-action:hover': {
    //         color: isDark ? colors.dodgerBlue : colors.toryBlue,
    //         backgroundColor: isDark ? colors.balticSeaDark : colors.tropicalBlue,
    //       },
    //     },
    //   },
    // },

    '& .textAnswer': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .viboTextarea': {
        lineHeight: '28px',

        '@media screen and (min-width: 1441px)': {
          lineHeight: '34px',
        },
      },

      '& .limit': {
        bottom: '-2px',
      },
    },
  },
}));

export default useStyles;
