export const getProgressBarColor = (progress: number): string => {
  if (progress <= 35) {
    return '#DB6234';
  }

  if (progress <= 55) {
    return '#D4AF37';
  }

  return '#66DC64';
};

export const getHslColor = (code: number[], lightness?: number) => {
  // code is a array from 4 numbers

  return `hsl(${code[0]}, ${code[1]}%, ${lightness || code[2]}%, ${code[3] || 1})`;
};
