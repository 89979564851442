import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation, useParams } from 'react-router';

import PlaylistSongs from './PlaylistSongs';
import PlaylistHeader from './PlaylistHeader';
import Container from 'components/common/Container';
import SongIdeaPageHeader from './SongIdeaPageHeader';
import ContentArea from 'components/common/ContentArea';
import LoadingOverlay from 'components/common/LoadingOverlay';
import SongIdeasDetailsCard from 'components/songIdeas/SongIdeasDetailsCard';
import SongIdeasPlaylistHeader from 'components/headers/SongIdeasPlaylistHeader';

import { ReactComponent as AppleMusicIcon } from 'resources/img/svg/appleMusicOriginal.svg';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { SONG_IDEAS_KEYS } from 'services/constants';
import { useFormikAutoSave } from 'graphql/hooks/form';
import { updateSongIdeasCache } from 'graphql/cache/songIdeas';
import { updateSongIdeasValidationSchema } from './constants';

import { GET_SONG_IDEAS_BY_ID } from 'graphql/queries/songIdeas';
import { IMPORT_PLAYLIST_TO_SONG_IDEAS } from 'graphql/mutations/songs';
import {
  ADD_SONG_TO_SONG_IDEAS,
  REMOVE_SONG_FROM_SONG_IDEAS,
  UPDATE_SONG_IDEAS,
} from 'graphql/mutations/songIdeas';

import { IconmoonFont } from 'vibo-ui/Icon';
import { Modals, MusicImportSource } from 'types/enums';
import { AddSongFromLinkModalProps } from 'components/modals/AddSongFromLinkModal';
import { AddSongsFromMusicSourceModalProps } from 'components/modals/AddSongsFromMusicSourceModal';
import { AddSongsToPlaylistFromSearchModalProps } from 'components/modals/AddSongsToPlaylistFromSearchModal';
import { SongDropdownOption, SongIdeasDetailsInnerProps } from './interfaces';

import useStyles from './style';

const SongIdeasDetailsInner: FC<SongIdeasDetailsInnerProps> = ({ songIdeas, isMySongIdeas }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [songsCount, setSongsCount] = useState(0);

  const location = useLocation<Nullable<SongIdeasLocationState>>();
  const { id: songIdeasId } = useParams<{ id: string }>();

  const songIdeasType = useMemo(() => location.state?.type || SONG_IDEAS_KEYS.me, [
    location.state?.type,
  ]);

  const [updateSongIdeas] = useMutation<UpdateSongIdeasData>(UPDATE_SONG_IDEAS, {
    onCompleted: data => {
      const { updateSongIdeas } = data;

      updateSongIdeasCache(songIdeasId, {
        title: updateSongIdeas.title,
        description: updateSongIdeas.description || '',
        coverPhoto: updateSongIdeas.coverPhoto,
      });

      formik.setSubmitting(false);
      toastNotify({ text: t('changesSavedSuccessfully') });
    },
    onError: error => {
      formik.setSubmitting(false);
      onError(error);
    },
  });

  const { openModal } = useModal();

  const openAddSongToPlaylistFromSearchModal = useCallback(
    () =>
      !!songIdeas &&
      openModal<AddSongsToPlaylistFromSearchModalProps>({
        key: Modals.addSongsToPlaylistFromSearch,
        props: {
          playlist: songIdeas,
          variables: {
            songIdeasId,
            //TODO DELETE AFTER BE REFACTOR PAGINATION
            limit: 50,
          },
          mutations: {
            add: ADD_SONG_TO_SONG_IDEAS,
            delete: REMOVE_SONG_FROM_SONG_IDEAS,
          },
          refetchQueries: ['getSongIdeasSongs'],
        },
      }),
    [songIdeasId, songIdeas?.title, songIdeas?.updatedAt, songIdeasId]
  );
  const openAddSongsFromMusicSourceModal = useCallback(
    (source: MusicImportSource) =>
      openModal<AddSongsFromMusicSourceModalProps>({
        key: Modals.addSongsFromMusicSource,
        props: {
          source,
          variables: {
            songIdeasId,
          },
          importMutation: IMPORT_PLAYLIST_TO_SONG_IDEAS,
          refetchQueries: ['getSongIdeasSongs'],
        },
      }),
    []
  );
  const openAddSongFromLinkModal = useCallback(
    () =>
      openModal<AddSongFromLinkModalProps>({
        key: Modals.addSongFromLink,
        props: {
          mutation: ADD_SONG_TO_SONG_IDEAS,
          refetchQueries: ['getSongIdeasSongs'],
          variables: {
            songIdeasId,
          },
        },
      }),
    [songIdeasId]
  );

  const handleUpdatePlaylist = async (payload: UpdateSongIdeasPayload) => {
    isMySongIdeas &&
      updateSongIdeas({
        variables: {
          songIdeasId: songIdeas?._id,
          payload,
        },
      });
  };

  const { formik } = useFormikAutoSave<UpdateSongIdeasForm>(
    {
      initialValues: {
        title: songIdeas?.title || '',
        description: songIdeas?.description || '',
      },
      validationSchema: updateSongIdeasValidationSchema,
      onSubmit: values => {
        handleUpdatePlaylist(values);
      },
    },
    700
  );

  const addSongsDropdownOptions: SongDropdownOption[] = [
    {
      icon: IconmoonFont['search-16'],
      text: t('search'),
      onClick: openAddSongToPlaylistFromSearchModal,
    },
    {
      icon: IconmoonFont['link-16'],
      text: t('pasteLink'),
      onClick: openAddSongFromLinkModal,
    },
    {
      icon: IconmoonFont['spotifyColorful-16'],
      text: t('spotify'),
      onClick: () => openAddSongsFromMusicSourceModal(MusicImportSource.spotify),
    },
    {
      icon: AppleMusicIcon,
      text: t('appleMusic'),
      onClick: () => openAddSongsFromMusicSourceModal(MusicImportSource.appleMusic),
    },
  ];

  return (
    <div className={classes.songIdeasDetails}>
      <SongIdeasPlaylistHeader currentTab={songIdeasType} />
      <FormikProvider value={formik}>
        <Container>
          {!!songIdeas ? <SongIdeaPageHeader songIdeas={songIdeas} /> : null}
          <div className={classes.songIdeasContent}>
            {!!songIdeas ? (
              <SongIdeasDetailsCard
                currentTab={songIdeasType}
                songIdeas={songIdeas}
                canEdit={isMySongIdeas}
              />
            ) : null}
            <div className={classes.playlistContent}>
              {!!songIdeas ? (
                <ContentArea className={classes.playlistContentArea} shadowed>
                  <PlaylistHeader
                    addSongsDropdownOptions={addSongsDropdownOptions}
                    songsCount={songsCount}
                    isMySongIdeas={isMySongIdeas}
                  />
                  <PlaylistSongs
                    songIdeasId={songIdeasId}
                    isMySongIdeas={isMySongIdeas}
                    onSongsCountUpdate={setSongsCount}
                  />
                </ContentArea>
              ) : null}
            </div>
          </div>
        </Container>
      </FormikProvider>
    </div>
  );
};

const SongIdeasDetails: FC = () => {
  const history = useHistory();
  const location = useLocation<Nullable<SongIdeasLocationState>>();
  const { id: songIdeasId } = useParams<{ id: string }>();

  const songIdeasType = location.state?.type || SONG_IDEAS_KEYS.me;

  const { data, loading } = useQuery<SongIdeasByIdResponse>(GET_SONG_IDEAS_BY_ID, {
    variables: {
      songIdeasId,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      onError(error);
      history.push(`/songIdeas/${songIdeasType}`, location.state);
    },
  });

  const songIdeas: Maybe<SongIdeas> = data?.getSongIdeasById;
  const isMySongIdeas: boolean = data?.getSongIdeasById.isOwner || false;

  return loading || !data ? (
    <LoadingOverlay />
  ) : (
    <SongIdeasDetailsInner songIdeas={songIdeas} isMySongIdeas={isMySongIdeas} />
  );
};

export default SongIdeasDetails;
