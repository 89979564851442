import React, { FC, createContext, useState, useEffect } from 'react';
import { ThemeProvider } from 'react-jss';

import themeSettings from 'resources/styles/theme';
import { VIBO_THEME_NAME, VIBO_THEMES, DEFAULT_VIBO_COLOR } from './constants';

import { TViboThemeContext, ViboThemeEnum } from './interfaces';

export const ViboThemeContext = createContext<TViboThemeContext>(undefined!);

export const ViboThemeProvider: FC = ({ children }) => {
  const localTheme = localStorage.getItem(VIBO_THEME_NAME) as ViboThemeEnum;

  const [theme, setTheme] = useState<ViboThemeEnum>(localTheme || ViboThemeEnum.light);

  const isDark = theme === ViboThemeEnum.dark;

  useEffect(() => {
    if (!!localTheme && VIBO_THEMES.includes(localTheme)) {
      return;
    }

    if (!localTheme) {
      setTheme(ViboThemeEnum.light);
    }
  }, [localTheme]);

  useEffect(() => {
    localStorage.setItem(VIBO_THEME_NAME, `${theme}`);
  }, [theme]);

  return (
    <ViboThemeContext.Provider
      value={{
        isDark,
        setTheme,
      }}
    >
      {/* // TODO CHANGE HARDCODED COLOR TO THE STATE VARIABLE */}
      {/* @ts-ignore */}
      <ThemeProvider theme={themeSettings[theme](DEFAULT_VIBO_COLOR)}>{children}</ThemeProvider>
    </ViboThemeContext.Provider>
  );
};
