import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from 'vibo-ui/Button';

import { ReactComponent as BeatsourceLogoShortIcon } from 'resources/img/svg/beatsourceLogoShort.svg';

const BeatsourceButton: FC<ButtonProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  const { id } = useParams<EventPageRouteParams>();

  return (
    <Button
      to={`/event/${id}/beatsource`}
      prefixIcon={BeatsourceLogoShortIcon}
      shape="round"
      size="lg"
      {...rest}
      shadowed
    >
      {t('exportToBeatsource')}
    </Button>
  );
};

export default BeatsourceButton;
