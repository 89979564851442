import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import pick from 'lodash/pick';
import classNames from 'classnames';
import Tooltip from 'vibo-ui/Tooltip';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';

import Button from 'vibo-ui/Button';
import EventLayout from '../EventLayout';
import Sections from 'components/sections/Sections';
import M3UButton from 'components/buttons/M3UButton';
import WithEventRole from 'components/user/WithEventRole';
import TimelineLayout from 'components/layouts/TimelineLayout';
import EventTitle from 'components/events/Timeline/EventTitle';
import PrepModeSection from 'components/sections/PrepModeSection';
import BeatsourceButton from 'components/buttons/BeatsourceButton';
import PageContentHeader from 'components/common/PageContentHeader';
import ExportToSpotifyButton from 'components/buttons/ExportToSpotifyButton';
import TimelineSectionsHeader from 'components/sections/TimelineSectionsHeader';
import PrepModeSectionSongs from 'components/events/PrepMode/PrepModeSectionSongs';
import PrepModeSectionBlocksHeader from 'components/sections/PrepModeSectionBlocksHeader';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { EditableContext } from 'components/common/EditableContext';
import { SelectListProvider } from 'components/context/SelectListContext';
import { SortContext, SortContextProvider } from 'components/context/SortContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';
import { PrepModeSongsFilterableContext } from 'components/events/PrepMode/PrepModeSongsContext';

import client from 'graphql/client';
import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { EVENT_PAGE_TAB_KEYS } from 'services/constants';
import { updateSectionIdFromRoute } from 'services/sections/helpers';

import {
  GET_PREP_MODE_SECTIONS,
  GET_PREP_MODE_SONGS,
  GET_SCANNER_COMPUTER,
} from 'graphql/queries/prepMode';
import { UPDATE_EVENT_SECTION } from 'graphql/mutations/events';
import { PREP_MODE_SECTION_IS_DONE } from 'graphql/fragments/prepMode';
import { GET_EVENT } from 'graphql/queries/events';
import { UPDATE_PREP_MODE_SONGS } from 'graphql/mutations/songs';

import { FilteredSortedPaginated } from 'types/graphql';
import { EventUserType, Modals, Page } from 'types/enums';
import { ChooseComputerModalProps } from 'components/modals/ChooseComputerModal';

import useStyles from './style';

const PrepModePage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<Nullable<SectionsFilter>>(null);
  const [selectedSection, setSelectedSection] = useState<Nullable<PrepModeSection>>(null);

  const [songsFilter, setSongsFilter] = useState<PrepModeSectionSongsFilter>({ q: '' });
  const [sectionsFilter, setSectionsFilter] = useState<Nullable<SectionsFilter>>({
    songsEnabled: true,
  });

  const history = useHistory();
  const { id: eventId, sectionId, page } = useParams<EventPageRouteParams>();
  const [songsQ, setSongsQuery] = useQueryParam('songsQ');

  const { openModal } = useModal();

  const { data: eventData } = useQuery<EventResponse>(GET_EVENT, {
    variables: { eventId },
    fetchPolicy: 'cache-and-network',
  });

  const event: Nullable<EventItem> = eventData?.event || null;

  const { sort } = useContext(SortContext);

  const { data: scannerComputerData } = useQuery<ScannerComputerResponse>(GET_SCANNER_COMPUTER);
  const scannerComputer: Maybe<ScannerComputer> = scannerComputerData?.scannerComputer;

  const { data, refetch, loading } = useQuery<PrepModeSectionsResponse, PrepModeSectionsVariables>(
    GET_PREP_MODE_SECTIONS,
    {
      variables: { eventId, computerId: scannerComputer?.id, filter: sectionsFilter },
      skip: !scannerComputer?.id,
      fetchPolicy: 'cache-and-network',
    }
  );
  const sections: PrepModeSection[] = data?.getPrepModeSections || [];

  const updateSongsVariables = {
    eventId,
    sectionId: selectedSection?._id || '',
    computerId: scannerComputer?.id || '',
  };

  const songsQueryVariables: FilteredSortedPaginated<
    PrepModeSectionSongsFilter,
    SongsSortFields,
    PrepModeSongsVariables
  > = {
    ...updateSongsVariables,
    filter: songsFilter,
    sort,
    pagination: {
      skip: 0,
      limit: 50,
    },
  };

  const [updateSection, { loading: updateSectionLoading }] = useMutation<
    UpdateSectionResponse,
    EventSectionCommonVariables & { payload: UpdateEventSectionForm }
  >(UPDATE_EVENT_SECTION, {
    onError: e => {
      onError(e);
      return refetch();
    },
  });

  const [updatePrepModeSongs, { loading: isUpdatingSongs }] = useMutation<
    UpdatePrepModeSongsResponse,
    UpdatePrepModeSongsVariables
  >(UPDATE_PREP_MODE_SONGS, {
    onCompleted: () => refetch(),
    onError,
    refetchQueries: [
      {
        query: GET_PREP_MODE_SECTIONS,
        variables: pick(updateSongsVariables, ['eventId', 'computerId']),
      },
      {
        query: GET_PREP_MODE_SONGS,
        variables: songsQueryVariables,
      },
    ],
  });

  const handleUpdateSongs = (
    payload: UpdatePrepModeSongsInput,
    filter: PrepModeSectionSongsFilter
  ) => {
    updatePrepModeSongs({
      variables: {
        ...updateSongsVariables,
        filter,
        payload,
      },
    });
  };

  const openChooseCompModal = useCallback(
    () =>
      openModal<ChooseComputerModalProps>({
        key: Modals.chooseComputer,
        props: {
          eventId,
        },
      }),
    [eventId]
  );

  const handleDoneClick = useCallback(
    (section: PrepModeSection) => {
      const cachedSection = client.readFragment<Nullable<PrepModeSectionIsDoneFragment>>({
        id: `PrepModeSection:${section._id}`,
        fragment: PREP_MODE_SECTION_IS_DONE,
      });

      if (cachedSection) {
        client.writeFragment<PrepModeSectionIsDoneFragment>({
          id: `PrepModeSection:${section._id}`,
          fragment: PREP_MODE_SECTION_IS_DONE,
          data: {
            isDone: !section.isDone,
            __typename: cachedSection.__typename,
          },
        });
      }

      return updateSection({
        variables: {
          eventId,
          sectionId: section._id,
          payload: { isDone: !section.isDone },
        },
      });
    },
    [eventId]
  );

  const handleDoneClickDebounced = debounce(handleDoneClick, 200, {
    leading: true,
    trailing: false,
  });

  const handleClearSongsSearch = useCallback(() => {
    if (!!songsFilter.q || !!songsQ) {
      setSongsQuery(undefined);
      setSongsFilter({
        q: '',
      });
    }
  }, [songsFilter.q]);

  useEffect(() => {
    updateSectionIdFromRoute(selectedSection, setSelectedSection, sectionId, sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, sectionId]);

  useEffect(() => {
    if (selectedSection && selectedSection._id !== sectionId) {
      history.push(
        `/event/${eventId}/${EVENT_PAGE_TAB_KEYS.prepMode}/${selectedSection._id}/${history.location.search}`
      );
      handleClearSongsSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection, page]);

  useEffect(() => {
    if (!scannerComputer) {
      openChooseCompModal();
    }
  }, [scannerComputer, page]);

  useEffect(() => {
    handleClearSongsSearch();
  }, []);

  return (
    <EventLayout page={Page.prepMode}>
      <PageContentHeader
        extra={
          <>
            <Button
              onClick={openChooseCompModal}
              prefixIcon={IconmoonFont['personalComputer-16']}
              className={classes.selectComputerBtn}
              shape="round"
              size="lg"
              key="select-computer-button"
              shadowed
            >
              <div className="content">
                <span className="label">{t('device')}:</span>
                <span className="name">{scannerComputer?.name || t('chooseDevice')}</span>
              </div>
            </Button>
            <WithEventRole roles={[EventUserType.dj]}>
              <>
                <ExportToSpotifyButton />
                <BeatsourceButton />
              </>
            </WithEventRole>
            <M3UButton key="m3u-button" />
          </>
        }
      >
        <EventTitle name={event?.title} />
      </PageContentHeader>
      <TimelineLayout
        className={classes.prepModePage}
        left={
          <SectionsFilterableContext.Provider value={{ filter, setFilter }}>
            <EditableContext.Provider value={{ canEdit: false }}>
              <Sections<PrepModeSection>
                timelineClassName={classes.sectionsTimeline}
                sections={sections}
                renderTimelineItem={(content, section, isDontPlay) => {
                  return (
                    <div
                      className={classNames('timelineSection', {
                        dontPlaySection: isDontPlay,
                      })}
                      key={section._id}
                    >
                      {content}
                    </div>
                  );
                }}
                renderHeader={() => (
                  <TimelineSectionsHeader
                    numSections={sections.length}
                    filter={sectionsFilter}
                    onSearch={q =>
                      setSectionsFilter((prev: Nullable<SectionsFilter>) => ({ ...prev, q }))
                    }
                    loading={loading}
                  />
                )}
                renderSection={({ section, isDragging }): JSX.Element => (
                  <PrepModeSection
                    section={section}
                    isSelected={!!selectedSection && section._id === selectedSection._id}
                    isDragging={isDragging}
                    onDoneClick={handleDoneClickDebounced}
                    onClick={() => setSelectedSection(section)}
                  >
                    <div className={classNames('sectionExtra withContent')}>
                      <Tooltip
                        placement="bottom"
                        overlay={section.isDone ? t('markAsUndone') : t('markAsDone')}
                        type={section.isDone ? 'primary' : 'success'}
                        className="fillOverlay"
                      >
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            !updateSectionLoading && handleDoneClickDebounced(section);
                          }}
                          className={classNames('progressBtn', classes.progressBtn, {
                            loading: updateSectionLoading,
                            done: section.isDone,
                          })}
                        >
                          <Icon
                            className={classNames(classes.isDoneIcon, {
                              isDone: section.isDone,
                            })}
                            icon={
                              IconmoonFont[
                                section.isDone ? 'checkedCircleFilled-16' : 'radioButtonOff-16'
                              ]
                            }
                          />
                          {section.isDone ? t('done') : t('markAsDone')}
                        </div>
                      </Tooltip>
                    </div>
                  </PrepModeSection>
                )}
              />
            </EditableContext.Provider>
          </SectionsFilterableContext.Provider>
        }
        right={
          selectedSection ? (
            <PrepModeSongsFilterableContext.Provider
              value={{ filter: songsFilter, setFilter: setSongsFilter }}
            >
              <SelectListProvider>
                <PrepModeSectionBlocksHeader section={selectedSection} />
                <PrepModeSectionSongs
                  variables={{
                    sectionId: selectedSection._id,
                    eventId,
                    computerId: scannerComputer?.id || '',
                  }}
                  handleUpdateSongs={handleUpdateSongs}
                  isUpdatingSongs={isUpdatingSongs}
                />
              </SelectListProvider>
            </PrepModeSongsFilterableContext.Provider>
          ) : null
        }
      />
    </EventLayout>
  );
};

const PrepModePageWrapper: FC = () => (
  <SortContextProvider>
    <PrepModePage />
  </SortContextProvider>
);

export default PrepModePageWrapper;
