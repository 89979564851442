import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  imageDefaultPlaceholder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '100%',

    '& svg': {
      margin: 'auto',
    },

    '& span': {
      position: 'absolute',
      bottom: '16px',
      fontSize: '16px',
      fontWeight: 500,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default useStyles;
