import React, { FC } from 'react';
import classNames from 'classnames';

import { ColorfulSectionWrapperProps, SectionColorsKeys } from './interfaces';

import useStyles from './style';

const ColorfulSectionWrapper: FC<ColorfulSectionWrapperProps> = ({
  children,
  color = SectionColorsKeys.empty,
}) => {
  const classes = useStyles({ color });

  return (
    <div className={classNames('colorfulSectionWrapper', classes.colorfulSectionWrapper, color)}>
      {children}
    </div>
  );
};

export default ColorfulSectionWrapper;
