import client from '../client';
import {
  SECTION_NOTE,
  SECTION_DETAILS,
  EVENT_SECTION,
  TEMPLATE_SECTION,
  TEMPLATE_SECTION_NOTE,
  TEMPLATE_SECTION_NOTES_VISIBLE_HOSTS,
  TEMPLATE_SECTION_VISIBILITY,
  EVENT_SECTION_VISIBILITY,
  FAVORITE_SECTION,
} from '../fragments/sections';

import { SectionVisibilityRoles } from '../../types/enums';

export const updateSectionNote = (sectionId: string, note: string): void => {
  client.writeFragment({
    fragment: SECTION_NOTE,
    id: sectionId,
    data: {
      note,
      __typename: 'Section',
    },
  });
};

export const updateEventSectionDetails = (sectionId: string, payload: SectionDetails): void => {
  client.writeFragment({
    fragment: SECTION_DETAILS,
    id: sectionId,
    data: {
      ...payload,
      __typename: 'Section',
    },
  });
};

export const updateTemplateSectionNote = (sectionId: string, note: string): void => {
  client.writeFragment({
    fragment: TEMPLATE_SECTION_NOTE,
    id: sectionId,
    data: {
      note,
      __typename: 'TemplateSection',
    },
  });
};

export const updateTemplateNotesVisibleHosts = (
  sectionId: string,
  notesVisibleForHosts: boolean
): void => {
  client.writeFragment({
    fragment: TEMPLATE_SECTION_NOTES_VISIBLE_HOSTS,
    id: sectionId,
    data: {
      settings: {
        notesVisibleForHosts,
        __typename: 'SectionSettings',
      },
      __typename: 'TemplateSection',
    },
  });
};

export const updateTemplateSectionVisibility = (
  sectionId: string,
  visibility: SectionVisibilityRoles
): void => {
  client.writeFragment({
    fragment: TEMPLATE_SECTION_VISIBILITY,
    id: sectionId,
    data: {
      visibility,
      __typename: 'TemplateSection',
    },
  });
};

export const updateEventSectionVisibility = (
  sectionId: string,
  visibility: SectionVisibilityRoles
): void => {
  client.writeFragment({
    fragment: EVENT_SECTION_VISIBILITY,
    id: sectionId,
    data: {
      visibility,
      __typename: 'Section',
    },
  });
};

export const getEventSection = (sectionId: string): Nullable<EventSection> => {
  return client.readFragment<EventSection>({
    id: sectionId,
    fragment: EVENT_SECTION,
    fragmentName: 'EventSection',
  });
};

export const getTemplateSection = (sectionId: string): Nullable<TemplateSection> => {
  return client.readFragment<TemplateSection>({
    id: sectionId,
    fragment: TEMPLATE_SECTION,
    fragmentName: 'TemplateSection',
  });
};

export const getFavoriteSection = (sectionId: string): Nullable<FavoriteSectionOption> => {
  return client.readFragment<FavoriteSectionOption>({
    id: sectionId,
    fragment: FAVORITE_SECTION,
    fragmentName: 'FavoriteSection',
  });
};

export const getSection = (
  sectionId: string,
  isEventSection: boolean,
  isFavorite?: boolean
): Nullable<EventSection | TemplateSection | FavoriteSectionOption> => {
  return isFavorite
    ? getFavoriteSection(sectionId)
    : isEventSection
    ? getEventSection(sectionId)
    : getTemplateSection(sectionId);
};
