import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import SongsToggler from './SongsToggler';
import QuestionsToggler from './QuestionsToggler';
import NotesToggler from './NotesToggler';
import SelectCheckbox from '../../../../components/context/SelectListContext/SelectCheckbox';
import { SelectListContext } from '../../../../components/context/SelectListContext';
import { EventContext } from '../../../../components/context/EventContext';

import { PdfSectionsHeaderProps } from '../interfaces';

import useStyles from './style';

const PdfSectionsHeader: FC<PdfSectionsHeaderProps> = ({ layout, sectionsControl }) => {
  const classes = useStyles();

  const { event } = useContext(EventContext);
  const { ids, selectedIds } = useContext(SelectListContext);

  const allSongsCount = event?.playlistSize || 0;

  return (
    <div className={classNames('pdfSectionsHeader', classes.pdfSectionsHeader)}>
      <div className="headerMain">
        <div className={classNames('togleAllCheckbox', classes.togleAllCheckbox)}>
          <SelectCheckbox />
          <div className={classes.sectionsOptionInfo}>{`${selectedIds.length}/${ids.length}`}</div>
        </div>
        <SongsToggler
          onClick={sectionsControl.handleCheckAllSongs}
          selected={sectionsControl.allSongsChecked}
          songsCount={allSongsCount}
          someSongsEnabled={sectionsControl.someSongsEnabled}
          layout={layout}
        />
        <QuestionsToggler
          onClick={sectionsControl.handleCheckAllQuestions}
          selected={sectionsControl.someQuestionsEnabled}
          someQuestionsEnabled={sectionsControl.someQuestionsEnabled}
          layout={layout}
        />
        <NotesToggler
          onClick={sectionsControl.handleCheckAllNotes}
          selected={sectionsControl.allNotesChecked}
          someNotesEnabled={sectionsControl.someNotesEnabled}
          layout={layout}
        />
      </div>
    </div>
  );
};

export default PdfSectionsHeader;
