import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  KeyboardEvent,
  FocusEventHandler,
  ChangeEventHandler,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { Key } from 'ts-key-enum';

import MaxCharsLimit from 'vibo-ui/common/MaxCharsLimit';
import InputErrors from 'vibo-ui/common/InputErrors';

import { EXTRA_INPUT_SPACE } from 'vibo-ui/utils/constants';

import { TextareaProps } from './interfaces';

import useInputStyles from 'resources/styles/inputs/style';
import useStyles from './style';

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      withUnderline,
      maxLength,
      className,
      wrapperClassName,
      autoWidth,
      errors,
      showMaxLength,
      onChange,
      onPressEnter,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
      selectOnFocus = false,
      autoFocus = false,
      value = '',
      ...rest
    },
    ref
  ) => {
    const inputClasses = useInputStyles();
    const classes = useStyles();

    const [height, setHeight] = useState<number>(0);
    const [currentValue, setCurrentValue] = useState<string>('');
    const [highlighted, setHighlighted] = useState<boolean>(false);

    const autoSizerRef = useRef<Nullable<HTMLDivElement>>(null);
    const textareaRef = useRef<Nullable<HTMLTextAreaElement>>(
      (ref as unknown) as HTMLTextAreaElement
    );

    const handlePressKey: (e: KeyboardEvent) => void = e => {
      switch (e.key) {
        case Key.Enter:
          onPressEnter?.();
          return;
        default:
          return;
      }
    };

    const handleKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void = e => onKeyDown?.(e);

    const handleKeyUp: (e: KeyboardEvent<HTMLTextAreaElement>) => void = e => onKeyUp?.(e);

    const handleFocus: FocusEventHandler<HTMLTextAreaElement> = e => onFocus?.(e);

    const handleBlur: FocusEventHandler<HTMLTextAreaElement> = e => {
      onBlur?.(e);
      setHighlighted(false);
    };

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
      const newValue = e.currentTarget.value;

      if (autoWidth && autoSizerRef.current) {
        e.currentTarget.style.width = `${
          newValue ? autoSizerRef.current.clientWidth + EXTRA_INPUT_SPACE : 0
        }px`;
      }

      setCurrentValue(newValue);
      onChange?.(e, newValue);
    };

    useEffect(() => {
      const newValue = !!value ? value : rest.defaultValue || '';

      if (autoFocus) {
        textareaRef.current?.focus();
      }

      if (!!newValue) {
        setCurrentValue(newValue);
      }
    }, [value]);

    return (
      <div
        className={classNames(
          'viboTextareaWrapper',
          classes.viboTextareaWrapper,
          inputClasses.viboUnderlineWrapper,
          wrapperClassName,
          {
            [`${inputClasses.underline} underline`]: withUnderline,
            [`${inputClasses.autoWidth} autoWidth`]: autoWidth,
            [`${inputClasses.withAutoWidth} withAutoWidth`]: maxLength,
            errorsPresent: !!errors?.filter(Boolean).length,
          }
        )}
      >
        <div className={classNames('closeWrapper')}>
          <TextareaAutosize
            value={currentValue}
            onChange={handleChange}
            maxLength={maxLength}
            className={classNames(
              'viboTextarea',
              withUnderline
                ? `withUnderline ${inputClasses.withUnderline}`
                : `withBorder ${inputClasses.withBorder}`,
              classes.viboTextarea,
              className,
              height,
              {
                [`disabled ${inputClasses.disabled}`]: rest.disabled,
              }
            )}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onKeyPress={handlePressKey}
            ref={textareaRef}
            onClick={e => {
              if (selectOnFocus && !highlighted) {
                setHighlighted(true);
                e.currentTarget.select();
              }
            }}
            {...rest}
            onHeightChange={height => setHeight(height)}
          />
        </div>
        {errors?.filter(Boolean)?.length ? (
          <InputErrors errors={errors} />
        ) : showMaxLength ? (
          <MaxCharsLimit maxLength={maxLength} valueLength={currentValue.length} />
        ) : null}
        {autoWidth ? (
          <div
            ref={autoSizerRef}
            className={classNames('autoWidthSizer', inputClasses.autoWidthSizer)}
          >
            {currentValue.replace(/ /g, '\u00a0')}
          </div>
        ) : null}
      </div>
    );
  }
);

export default Textarea;
