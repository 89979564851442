import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  timelineSection: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '&.headline': {
      '& .section': {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
        border: '1px solid transparent',
        padding: '3px 0',

        '& .sectionTop': {
          width: '100%',
          margin: '0 0 2px 0',

          '& .viboInputWrapper': {
            margin: '0 50px 0 0',
          },

          '& .textPreview.lg, .viboInputWrapper input': {
            maxWidth: '260px',
            lineHeight: '24px!important',

            '@media screen and (min-width: 1441px)': {
              lineHeight: '28px!important',
              maxWidth: '360px',
            },
          },

          '& .editableSectionName': {
            color: theme.palette.text.secondary,
          },
        },

        '& .sectionBottom': {
          display: 'none',
        },

        '& .sectionExtra': {
          margin: 0,

          '& .sectionTools': {
            top: '-4px',
            transform: 'translateY(-100%)',
            bottom: 'auto',
          },
        },

        '&.selected': {
          '& .sectionTools': {
            '& .iconButton': {
              color: theme.palette.primary.main,

              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.action.hover,
              },
            },
          },
        },

        '&:hover': {
          border: `1px solid ${theme.palette.primary.light}`,
          backgroundColor: theme.palette.selection.hover,

          '& .sectionTop': {
            '& .textPreview.lg, .viboInputWrapper input': {
              maxWidth: '220px',

              '@media screen and (min-width: 1441px)': {
                maxWidth: '310px',
              },
            },
          },
        },
      },
    },

    '&:not(.headline)': {
      '& .section': {
        '& .sectionTop .editableSectionName': {
          '&.textPreview.lg': {
            lineHeight: '23px',

            '@media screen and (min-width: 1441px)': {
              lineHeight: '28px',
            },
          },
        },
      },
    },

    '& .clickableSectionTime': {
      cursor: 'pointer',
    },
  },

  addInTheMiddleBtn: {
    width: '100%',
    position: 'relative',
    padding: '0',
    opacity: 0,

    '& .prefixIcon': {
      zIndex: 3,
      pointerEvents: 'none',

      '& circle:first-child, & path': {
        fill: theme.palette.background.paper,
      },
    },

    '&:hover, &:active, &:focus': {
      opacity: 1,

      '& .prefixIcon': {
        color: theme.palette.primary.main,
      },
    },

    '&.defaultDisplay': {
      border: 'none',
      backgroundColor: 'transparent',

      '&:hover': {
        border: 'none',
        backgroundColor: 'transparent',
      },
    },

    '&.smSize': {
      height: '12px',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      borderRadius: '2px',
      backgroundColor: theme.palette.primary.main,
    },
  },

  iconWithContent: {
    marginRight: '4px',
  },

  blockInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.56px',
    height: '100%',
    color: 'inherit',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },

    '& svg': {
      color: 'inherit',
    },
  },

  sectionTools: {
    position: 'absolute',
    right: '6px',
    bottom: '-2px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 0 auto',

    '& .iconButton': {
      '& svg': {
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      right: '8px',
      bottom: '-4px',
    },
  },

  iconsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '20px',
    position: 'relative',

    '& .blockInfo': {
      '&:not(:last-child)': {
        marginRight: '26px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '24px',
    },
  },

  section: {
    '& .hidden-section-icon': {
      display: 'none',
    },

    '&:hover .hidden-section-icon': {
      display: 'flex',
    },
  },

  sectionMiddle: {
    justifyContent: 'space-between',

    '& .songArtistNames': {
      maxWidth: '100%',
      width: '100%',
    },

    '& .songName': {
      ...theme.mixins.longText,
      maxWidth: '240px',
      flexGrow: 'initial',
      whiteSpace: 'nowrap',

      '@media screen and (min-width: 1441px)': {
        maxWidth: '310px',
      },
    },

    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  editableSectionName: {
    margin: '0 4px 0 -4px',

    '&': {
      ...theme.mixins.longText,
      whiteSpace: 'nowrap!important',
    },

    '&.isDontPlay': {
      '&:hover': {
        backgroundColor: 'transparent!important',
      },
    },

    '&.withBorder': {
      height: '22px!important',

      '@media screen and (min-width: 1441px)': {
        height: '26px!important',
      },
    },

    '&.textPreview, &.viboInput': {
      fontWeight: 500,
      padding: '0 4px',
      backgroundColor: 'transparent',

      '&, &:hover': {
        border: 'none!important',
      },
    },
  },
}));

export default useStyles;
