import React, { FC, useContext, MouseEvent } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useFormikContext } from 'formik';

import Radio from 'vibo-ui/Radio';
import CopyQuestionAnswer from '../../CopyQuestionAnswer';
import MultiplyOptionContent from '../MultiOption/MultiplyOptionContent';
import { QuestionContext } from '../../../events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { MultiplyOptionContentProps } from '../MultiOption/interfaces';

import useStyles from '../../common/style';

const Option: FC<MultiplyOptionContentProps> = ({ index, value }) => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext<UpdateQuestionForm>();

  const { question, canAnswer } = useContext(QuestionContext);

  const selected = question.answer?.selectedOptions.includes(`${index}`);

  return (
    <div
      className={classNames('multiplyOption', classes.multiplyOption, {
        withCopyIconSpace: value.isOther && !value.title,
        withAnswer: selected,
      })}
      onClick={(e: MouseEvent) => e.stopPropagation()}
    >
      <Radio
        className={classNames(classes.multiplyOptionContent)}
        value={`${index}`}
        onClick={e => {
          const value = get(e, 'target.value');

          isEqual(values.answer?.selectedOptions[0], value) &&
            setFieldValue('answer.selectedOptions', []);
        }}
        disabled={!canAnswer || !value.title}
      />
      <MultiplyOptionContent value={value} index={index} />
      <CopyQuestionAnswer value={value.title} />
    </div>
  );
};

export default Option;
