import { Dispatch } from 'react';

export type TViboThemeContext = {
  setTheme?: Dispatch<ViboThemeEnum>;
  isDark: boolean;
};

export enum ViboThemeEnum {
  'light' = 'light',
  'dark' = 'dark',
}
