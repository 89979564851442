import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Button, { ButtonDecor } from 'vibo-ui/Button';

import { useModal } from 'vibo-ui/Modal';
import { openErrorNotification, toastNotify } from 'graphql/hooks/common';

import { UPDATE_EVENT, DELETE_EVENT } from 'graphql/mutations/events';

import { IconmoonFont } from 'vibo-ui/Icon';
import { ButtonIconType, Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { DeleteEventButtonProps } from './interfaces';

const DeleteEventButton: FC<DeleteEventButtonProps> = ({ event, className }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const isHistory = event.isPast || event.isDeleted;
  const eventId = event._id;

  const { openModal } = useModal();

  const openConfirmDeleteEventModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('deleteEvent'),
          children: t('sureYouWantDeleteEvent'),
          submit: {
            text: t('delete'),
            onClick: handleDeleteEvent,
          },
        },
      }),
    []
  );

  const [updateEvent, { loading: updateEventLoading }] = useMutation(UPDATE_EVENT, {
    onError: () => openErrorNotification(t('somethingWentWrong')),
    onCompleted: data => {
      toastNotify({ text: t('changesSavedSuccessfully') });

      if (data.updateEvent.isDeleted === true) {
        history.push(`/events${isHistory ? '/history' : '/upcoming'}`);
      }
    },
  });

  const [deleteEvent, { loading: deleteEventLoading }] = useMutation(DELETE_EVENT, {
    onError: () => openErrorNotification(t('somethingWentWrong')),
    onCompleted: () => {
      toastNotify({ text: t('eventHasDeleted'), iconType: ButtonIconType.delete });
      history.push(`/events/history`);
    },
    variables: {
      eventId,
    },
  });

  const handleDeleteEvent = useCallback(
    () =>
      isHistory
        ? deleteEvent()
        : updateEvent({
            variables: {
              eventId: event._id,
              payload: {
                isDeleted: true,
              },
            },
          }),
    [isHistory, event._id]
  );

  return (
    <Button
      onClick={openConfirmDeleteEventModal}
      className={classNames('centrate', ButtonDecor.danger, className)}
      loading={deleteEventLoading || updateEventLoading}
      prefixIcon={IconmoonFont['delete-16']}
      displayType="link"
      shape="round"
      size="lg"
      shadowed
    >
      {isHistory ? t('deleteForever') : t('deleteEvent')}
    </Button>
  );
};

export default DeleteEventButton;
