import React, { FC, useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import some from 'lodash/some';
import every from 'lodash/every';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import ReviewLinkInput from './ReviewLinkInput';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { UserPolicyFields } from 'components/context/PolicyContext';

import { useFieldPolicy } from 'graphql/hooks/policy';
import { MAX_LINKS_COUNT, getErrorIndex, reviewsValidator, urlValidator } from './constants';

import { ReviewsLinksProps } from './interfaces';

import useStyles from './style';

const ReviewLinks: FC<ReviewsLinksProps> = ({ handleUpdateProfile, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [reviews, setReviews] = useState<ReviewLink[]>(cloneDeep(user?.reviews || []));
  const [errors, setErrors] = useState<string[]>([]);
  const [isAddButtonVisible, setAddButtonVisible] = useState(reviews.length <= MAX_LINKS_COUNT);
  const [isButtonEnabled, setButtonEnabled] = useState(true);

  const { isEditable } = useFieldPolicy(UserPolicyFields.reviews);

  useEffect(() => {
    setReviews(cloneDeep(user?.reviews || []));
  }, [user]);

  useEffect(() => {
    const hasNotEmptyLinks = every(reviews, ({ url }: any) => url);
    const hasNotErrors = every(errors, (e: any) => !e);

    setButtonEnabled(hasNotEmptyLinks && hasNotErrors);

    if (reviews.length >= MAX_LINKS_COUNT) {
      setAddButtonVisible(false);
    } else {
      setAddButtonVisible(true);
    }
  }, [reviews, errors]);

  const addLink = () => {
    if (reviews.length < MAX_LINKS_COUNT) {
      setReviews([...reviews, { url: '' }]);
    }
  };

  const changeLink = (i: number, url: string) => {
    reviews[i].url = url;
    setReviews([...reviews]);

    urlValidator
      .validate(url)
      .then(() => {
        delete errors[i];
        setErrors([...errors]);

        return updateReviewsLinks(reviews, errors);
      })
      .catch((e: any) => {
        errors[i] = e.message;
        setErrors([...errors]);
      });
  };

  const updateReviewsLinks = (reviews: ReviewLink[], errors: string[]) => {
    const hasErrors = some(errors, v => v);

    if (!hasErrors) {
      return updateProfileDebounced(reviews);
    }
  };

  const updateProfileDebounced = useRef(
    debounce(async (reviews: ReviewLink[]) => {
      return handleUpdateProfile({ reviews })
        .then(() => setErrors([]))
        .catch((e: any) => {
          const message = get(e, 'graphQLErrors.0.message', null);
          const errorIndex = getErrorIndex(get(e, 'graphQLErrors.0.path'));

          if (errorIndex !== false) {
            for (let i = 0; i < errorIndex; i++) {
              delete errors[i];
            }

            errors[errorIndex] = message;
          }

          setErrors([...errors]);
        });
    }, 500)
  ).current;

  const removeLink = (index: number) => {
    reviews.splice(index, 1);
    errors.splice(index, 1);

    setReviews([...reviews]);
    setErrors([...errors]);

    return reviewsValidator.validate(reviews).then(() => handleUpdateProfile({ reviews }));
  };

  return (
    <div className={classes.reviewLinks}>
      <>
        {reviews.map((link: any, index: number) => {
          const error = errors[index];

          return (
            <div
              className={classNames('linksContainer', classes.linksContainer)}
              key={`${link}-${index}`}
            >
              <div className={classes.linkLabel}>
                <Icon icon={IconmoonFont['link-16']} />
                <span className={classes.linkTitle}>
                  {t('link')} {`${index + 1}`}
                </span>
                {isEditable ? (
                  <Icon
                    className={classNames('removeIcon', classes.removeIcon)}
                    icon={IconmoonFont['close-16']}
                    onClick={() => removeLink(index)}
                  />
                ) : null}
              </div>
              <ReviewLinkInput
                onChange={(url: string) => {
                  changeLink(index, url);
                }}
                initialValue={link.url}
                disabled={!isEditable}
                errors={[error]}
                withUnderline
              />
            </div>
          );
        })}
        {isAddButtonVisible ? (
          <Button
            onClick={addLink}
            disabled={!isButtonEnabled || !isEditable}
            prefixIcon={IconmoonFont['plus-16']}
            className={classes.addLink}
            displayType="link"
          >
            {t('addLink')}
          </Button>
        ) : null}
      </>
    </div>
  );
};

export default ReviewLinks;
