import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  contentBottomArea: {
    marginTop: '8px',
  },

  eventContentScroll: {
    height: 'calc(100% - 84px)',
  },

  content: {
    display: 'flex',
    paddingBottom: '20px',
  },

  contentLeft: {
    margin: '0 55px 0 0',

    '& .viboImage': {
      boxShadow: theme.shadows[3],
    },

    '& .contentArea': {
      padding: '12px',
    },

    '& .viboButton': {
      width: '100%',
      padding: '10px',
      backgroundColor: theme.palette.background.paper,

      '&.lgSize ': {
        height: '36px',
      },

      '& svg': {
        width: '20px',
        height: '20px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },

      '&:not(:last-child)': {
        margin: '0 0 8px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 12px 0',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 72px 0 0',
    },
  },

  contentRight: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '19.5px',

    '& p': {
      marginBottom: 0,
    },

    '& .contentArea': {
      padding: '20px',

      '&:not(:last-child)': {
        margin: '0 0 20px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 24px 0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        padding: '24px',
      },
    },

    '& .settingsRow': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .switchWithTooltip': {
        width: '100%',
      },

      '& .viboSwitchLabel, & .viboInputWrapper': {
        margin: '0 0 0 auto',
      },

      '&:not(.withInput):not(:last-child)': {
        margin: '0 0 22px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 24px 0',
        },
      },

      '&.withInput:not(:last-child)': {
        margin: '0 0 14px 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 0 16px 0',
        },
      },
    },

    '& .row': {
      '&:last-child': {
        margin: '0',
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}));

export default useStyles;
