import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import Card from 'vibo-ui/Card';

import { ToggleComponentProps } from './interfaces';

import useStyles from './style';

const Toggle: FC<PropsWithChildren<ToggleComponentProps>> = ({
  disableToggleClass,
  toggleClassName,
  className,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      {...rest}
      shadowed={!disableToggleClass}
      className={classNames(
        'viboToggleDropdown',
        className,
        classes.viboToggleDropdownDefault,
        {
          [classes.viboToggleDropdown]: !disableToggleClass,
        },
        toggleClassName
      )}
    >
      {children}
    </Card>
  );
};

export default Toggle;
