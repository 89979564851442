import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import WithEventRole from 'components/user/WithEventRole';
import VisibilityDropdown from 'components/dropdowns/VisibilityDropdown';
import SectionBlockInfo from 'components/sections/blocks/SectionBlock/SectionBlockInfo';
import Button, { ButtonDecor } from 'vibo-ui/Button';
import { EditableContext } from 'components/common/EditableContext';
import {
  TimelineNotesContext,
  EditingNotesContext,
  VisibilityNotesContext,
} from '../TimelineNotesContext';

import { strippString } from 'services/common/stringHelpers';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType, SectionVisibilityRoles } from 'types/enums';

import useStyles from './style';

const NotesBlockHeader: FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { canEdit } = useContext(EditableContext);
  const { handleNotesVisiblity } = useContext(VisibilityNotesContext);
  const { section, updateSection, variables } = useContext(TimelineNotesContext);
  const { isEditing, setIsEditing, notesText } = useContext(EditingNotesContext);

  const isDjOnly = !section.settings?.visibleForGuests && !section.settings?.visibleForHosts;

  const strippedNoteText = useMemo(() => strippString(section?.note), [section?.note]);

  const handleFinishEditing = () => {
    updateSection({
      variables: {
        ...variables,
        payload: {
          note: notesText,
        },
      },
    });
    setIsEditing(false);
  };

  return (
    <div className={classNames('notesBlockHeader', classes.notesBlockHeader)}>
      <div className={classes.info}>
        <SectionBlockInfo title={t('notes')} />
        {strippedNoteText?.length ? <span className={classes.notesPresentIndicator} /> : null}
      </div>
      {canEdit ? (
        <>
          {isEditing ? (
            <Button
              onClick={e => {
                e.stopPropagation();
                handleFinishEditing();
              }}
              prefixIcon={IconmoonFont['editPencil-16']}
              className={classNames('saveNotes', ButtonDecor.success)}
              displayType="primary"
              shape="round"
            >
              {t('saveChanges')}
            </Button>
          ) : null}
          <WithEventRole roles={[EventUserType.dj]}>
            <VisibilityDropdown
              isDisabled={isDjOnly}
              allowedTypes={[SectionVisibilityRoles.dj, SectionVisibilityRoles.host]}
              handleChange={handleNotesVisiblity}
              value={
                section.settings.notesVisibleForHosts
                  ? SectionVisibilityRoles.host
                  : SectionVisibilityRoles.dj
              }
            />
          </WithEventRole>
        </>
      ) : null}
      {children}
    </div>
  );
};

export default NotesBlockHeader;
