import React, { FC } from 'react';
import classNames from 'classnames';

import Section from '../Section';
import VisibilityLabel from '../../labels/VisibilityLabel';
import PrepModeSectionSongsInfo from '../PrepModeSectionSongsInfo';
import TextPreviewField from 'vibo-ui/TextPreviewField/TextPreview';
import PrepModeSectionSongsMatched from '../../events/PrepMode/PrepModeSectionSongsMatched';

import { SectionVisibilityRoles } from 'types/enums';
import { PrepModeSectionProps } from './interfaces';

import useTimelineSectionStyles from '../TimelineSection/style';

const PrepModeSection: FC<PrepModeSectionProps> = ({
  section,
  isSelected,
  isDragging,
  onClick,
  children,
}) => {
  const timelineSectionClasses = useTimelineSectionStyles();

  return (
    <div className={timelineSectionClasses.timelineSection}>
      <Section
        content={{
          top: (
            <>
              <TextPreviewField
                className={classNames(
                  'editableSectionName',
                  timelineSectionClasses.editableSectionName
                )}
                text={section.name}
              />
              {section.settings.visibleForGuests || !section.settings.visibleForHosts ? (
                <VisibilityLabel
                  tooltipPlacement="right"
                  type={
                    section.settings.visibleForGuests
                      ? SectionVisibilityRoles.public
                      : SectionVisibilityRoles.dj
                  }
                />
              ) : null}
            </>
          ),
          middle: section.songsCount === 1 ? <PrepModeSectionSongsInfo section={section} /> : null,
          bottom: <PrepModeSectionSongsMatched section={section} />,
        }}
        isSelected={isSelected}
        isDragging={isDragging}
        onClick={onClick}
        classes={{
          root: 'prepModeSection',
          middle: timelineSectionClasses.sectionMiddle,
        }}
      >
        {children}
      </Section>
    </div>
  );
};

export default PrepModeSection;
