import React, { FC, useCallback, useContext } from 'react';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';
import { EventContext } from 'components/context/EventContext';

import { useModal } from 'vibo-ui/Modal';
import { toastNotify } from 'graphql/hooks/common';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType, Modals } from 'types/enums';
import { AddEventContactModalProps } from 'components/modals/AddEventContactModal';
import { SendEventInviteModalProps } from 'components/modals/SendEventInviteModal';

const AddPeopleButton: FC = () => {
  const { t } = useTranslation();

  const { event, canAddGuest, isEventHost, isEventDj, isHistory } = useContext(EventContext);

  const { id } = useParams<EventPageRouteParams>();

  const { openModal } = useModal();

  const openAddEventContactModal = useCallback(
    () =>
      openModal<AddEventContactModalProps>({
        key: Modals.addEventContact,
        props: {
          values: {
            role: EventUserType.host,
            emailMessage: event?.emailMessage,
          },
        },
      }),
    [event?.emailMessage]
  );
  const openSendEventInviteModal = useCallback(
    () =>
      openModal<SendEventInviteModalProps>({
        key: Modals.sendEventInvite,
        props: {
          eventId: id,
          values: {
            emailMessage: event?.emailMessage,
          },
        },
      }),
    [id, event?.emailMessage]
  );

  const copyLink = (userType: EventUserType) => {
    const isHost = userType === EventUserType.host;
    const userLink = (isHost ? event?.hostDeepLink : event?.guestDeepLink) || '';

    copy(userLink, { format: 'text/plain' });
    toastNotify({
      text: t(`${userType}LinkCopiedSuccessfully`),
    });
  };

  const menuItems = [
    isEventDj ? (
      <Menu.Item
        onClick={openAddEventContactModal}
        icon={IconmoonFont['userV2-16']}
        key="add-contact"
      >
        {t('addContact')}
      </Menu.Item>
    ) : null,
    isEventDj ? (
      <Menu.Item
        onClick={openSendEventInviteModal}
        icon={IconmoonFont['email-16']}
        key="invite-email"
      >
        {t('inviteByEmail')}
      </Menu.Item>
    ) : null,
    isEventHost || isEventDj ? (
      <Menu.Item
        onClick={() => copyLink(EventUserType.host)}
        icon={IconmoonFont['link-16']}
        key="copy-host"
      >
        {t('copyHostLink')}
      </Menu.Item>
    ) : null,
    canAddGuest ? (
      <Menu.Item
        onClick={() => copyLink(EventUserType.guest)}
        icon={IconmoonFont['link-16']}
        key="copy-guest"
      >
        {t('copyGuestLink')}
      </Menu.Item>
    ) : null,
  ].filter(Boolean);

  return isHistory || !menuItems.length ? null : (
    <Dropdown
      overlay={<Menu selectable={false}>{menuItems}</Menu>}
      trigger="click"
      disableToggleClass
    >
      <Button prefixIcon={IconmoonFont['plus-16']} displayType="primary" shape="round" size="lg">
        {t('addPeople')}
      </Button>
    </Dropdown>
  );
};

export default AddPeopleButton;
