import React from 'react';
import { Redirect } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

import { ModalWindowContextProvider } from 'vibo-ui/Modal/ModalWindowContext';
import { BeatsourceContextProvider } from 'components/context/BeatsourceContext';

import { isLoggedIn } from 'graphql/queries/auth';

const AuthRequired = (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { loading, data } = useQuery(isLoggedIn);

  if (loading) return <div>loading...</div>;

  return data?.isLoggedIn ? (
    <ModalWindowContextProvider>
      <BeatsourceContextProvider>{props.children}</BeatsourceContextProvider>
    </ModalWindowContextProvider>
  ) : (
    <Redirect to="/login" />
  );
};

export default AuthRequired;
