import { gql } from 'graphql.macro';

export const SECTION_SONGS_SORT = gql`
  fragment SectionSongsSort on SectionSongsSort {
    field
    direction
    songsCount
    __typename
  }
`;

export const TEMPLATE_SECTION_NOTE = gql`
  fragment TemplateSectionNote on TemplateSection {
    note
  }
`;

export const SECTION_NOTE = gql`
  fragment SectionNote on Section {
    note
  }
`;

export const SECTION_DETAILS = gql`
  fragment SectionDetails on Section {
    name
    description
  }
`;

export const EVENT_SECTION = gql`
  fragment EventSection on Section {
    _id
    name
    time
    note
    description
    type
    wasSongsReordered
    hasNote
    hasComments
    hasSongs
    hasQuestions
    songsCount
    songIdeasCount
    settings {
      songsLimit
      mustPlayLimit
      canHostsOrderSongs
      canHostDeleteSection
      visibleForGuests
      songsEnabled
      timeEnabled
    }
  }
`;

export const TEMPLATE_SECTION = gql`
  fragment TemplateSection on TemplateSection {
    _id
    name
    time
    note
    description
    type
    songIdeasCount
    settings {
      songsLimit
      mustPlayLimit
      canHostsOrderSongs
      canHostDeleteSection
      visibleForGuests
      songsEnabled
      timeEnabled
    }
  }
`;

export const FAVORITE_SECTION = gql`
  fragment FavoriteSection on FavoriteSection {
    _id
    name
    time
    note
    description
    type
    songIdeasCount
    settings {
      songsLimit
      mustPlayLimit
      canHostsOrderSongs
      canHostDeleteSection
      visibleForGuests
      songsEnabled
      timeEnabled
    }
  }
`;

export const SECTION_BLOCKS = gql`
  fragment SectionBlocks on Section {
    blocks {
      name
      isExpanded
    }
    settings {
      songsEnabled
      notesEnabled
    }
    __typename
  }
`;

export const TEMPLATE_SECTION_BLOCKS = gql`
  fragment TemplateSectionBlocks on TemplateSection {
    blocks {
      name
      isExpanded
    }
    settings {
      songsEnabled
    }
    __typename
  }
`;

export const TEMPLATE_SECTION_NOTES_VISIBLE_HOSTS = gql`
  fragment TemplateSectionNotesVisibleHosts on TemplateSection {
    settings {
      notesVisibleForHosts
      __typename
    }
    __typename
  }
`;

export const TEMPLATE_SECTION_VISIBILITY = gql`
  fragment TemplateSectionVisibility on TemplateSection {
    visibility
    __typename
  }
`;

export const EVENT_SECTION_VISIBILITY = gql`
  fragment EventSectionVisibility on EventSection {
    visibility
    __typename
  }
`;
