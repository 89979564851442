import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo';

import Icon from 'vibo-ui/Icon';
import Image from 'components/image/Image';
import NotificationIcon from './NotificationIcon';

import { ReactComponent as MenuEventsIcon } from 'resources/img/svg/Dashboard/MenuIcons/menu_events.svg';
import { ReactComponent as UserRoleLgIcon } from 'resources/img/svg/userRoleLg.svg';

import { momentShort, notificationRedirectsByType } from './contstants';
import { readNotification, downgradeNotificationsCount } from 'graphql/cache/notifications';
import { onError } from 'graphql/helpers';

import { MARK_AS_READ } from 'graphql/mutations/notifications';

import { NotificationProps, NotificationType } from './interface';

import useStyles from './style';

const Notification: FC<NotificationProps> = ({
  _id,
  imageUrl,
  header,
  body,
  isRead,
  metadata,
  notificationType,
  createdAt,
}) => {
  const classes = useStyles();

  const eventDate = useMemo(() => momentShort(createdAt).fromNow(true), [createdAt]);

  const redirectLink = useMemo(
    () => !!notificationType && notificationRedirectsByType[notificationType](metadata.eventId),
    [notificationType, metadata.eventId, _id]
  );

  const placeholderIcon = useMemo(() => {
    if (
      notificationType === NotificationType.lockoutDate ||
      notificationType === NotificationType.eventLocked
    ) {
      return MenuEventsIcon;
    }

    return UserRoleLgIcon;
  }, [notificationType]);

  const [markAsRead] = useMutation(MARK_AS_READ, {
    onCompleted: () => {
      readNotification(_id);
      downgradeNotificationsCount();
    },
    onError,
  });

  const handleRead = useCallback(() => {
    if (!isRead) {
      markAsRead({
        variables: {
          notificationIds: [_id],
        },
      });
    }
  }, [_id, isRead]);

  return (
    // @ts-ignore
    <Link
      className={classNames('notification', classes.notification, {
        isNew: !isRead,
      })}
      onClick={handleRead}
      to={redirectLink}
      target="_blank"
    >
      <div className={classes.notificationImage} id={_id}>
        <Image token={_id} src={imageUrl} alt="notification-image">
          <Icon icon={placeholderIcon} disableAutoFontSize />
        </Image>
        {!!notificationType ? <NotificationIcon notificationType={notificationType} /> : null}
      </div>
      <div className={classes.notificationDescription}>
        <div className="eventName">{header}</div>
        <div className="message">{body}</div>
      </div>
      <div className={classes.indicators}>
        {!isRead ? <span className="newCircle" /> : null}
        <span className="time">{eventDate}</span>
      </div>
    </Link>
  );
};

export default Notification;
