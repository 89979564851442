import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AppleConnect from './AppleConnect';
import LanguageSelect from './LanguageSelect';
import SpotifyConnect from './SpotifyConnect';
import WithDjType from 'components/user/WithDjType';
import BeatsourceConnect from './BeatsourceConnect';
import ContentArea from 'components/common/ContentArea';
import ConditionsPrivacyPolicy from './ConditionsPrivacyPolicy';

import useStyles from './style';

const GeneralSettings: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ContentArea shadowed>
      <div className={classes.title}>{t('generalSettings')}</div>
      <SpotifyConnect />
      <AppleConnect />
      <WithDjType types={['child', 'company', 'single']}>
        <BeatsourceConnect />
      </WithDjType>
      <LanguageSelect />
      <ConditionsPrivacyPolicy />
    </ContentArea>
  );
};

export default GeneralSettings;
