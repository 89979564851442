import { getHslColor } from 'services/common/colorsHelper';

export const colors = {
  curiousBlue: (accentColor: number) => getHslColor([accentColor, 70, 50]),
  toryBlue: (accentColor: number) => getHslColor([accentColor, 79, 32]),
  tropicalBlue: (accentColor: number) => getHslColor([accentColor, 79, 85]),
  hawkesBlue: (accentColor: number) => getHslColor([accentColor, 83, 95]),
  dodgerBlue: (accentColor: number) => getHslColor([accentColor, 87, 70]),
  dodgerBlueLight: (accentColor: number) => getHslColor([accentColor, 100, 65]),
  selago: (accentColor: number) => getHslColor([accentColor, 83, 98]),
  overlayCuriousBlue: (accentColor: number) => getHslColor([accentColor, 70, 50, 0.3]),
  malachite: '#1ed760',
  malachiteLight: '#1BD317',
  malachiteLighty: '#BBF2BA',
  fetaLight: '#EFFCEF',
  yellow: '#FCF403',
  cornflowerBlueLight: '#6195ED',
  mountainMeadow: '#1DB954',
  cornflowerBlue: '#4285F4',
  indigo: '#5662C4',
  royalBlue: '#6370E3',
  frenchPass: '#BADCFD',
  danube: '#5b87CB',
  malibu: '#72AAFF',
  easternBlue: '#15A2B5',
  java: '#1ACBE3',
  anakiwa: '#9FCEFC',
  white: '#FFFFFF',
  endeavour: '#075CB2',
  black: '#000000',
  emperor: '#505050',
  dustyGray: '#999999',
  scarpaFlow: '#56535E',
  gray: '#808080',
  pomegranate: '#F03A2E',
  carnation: '#F36158',
  oldGold: '#D4AF37',
  apple: '#39CD36',
  corn: '#E3DA02',
  sahara: '#BAA90F',
  polar: '#F4F9FD',
  balticSea: '#2A292D',
  balticSeaDark: '#19181F',
  shipGray: '#3E3B43',
  stTropaz: '#2D5C8F',
  silver: '#CACACA',
  lisbonBrown: '#3F3D1E',
  costaDelSol: '#5F5C25',
  shalimar: '#FFFBB9',
  texas: '#FBF59A',
  electricViolet: '#9B35EC',
  razzleDazzleRose: '#F03CDE',
  indochine: '#D27200',
  gold: '#B9A702',
  buddhaGold: '#01A486',
  malibuLight: '#6FB6FF',
  heliotrope: '#B982FF',
  persianPink: '#F56DAE',
  westSide: '#FF891D',
  gorse: '#FFEE53',
  aquamarine: '#3EFFD1',
  feta: '#E9FBE8',
  kashmirBlue: '#475993',
  texasRose: '#FFC250',
  sunsetOrange: '#FD5341',
  mediumRedViolet: '#CB39A4',
  california: '#F39604',
};
