import { gql } from 'graphql.macro';
import { QUESTION_IMAGE, QUESTION_V2 } from 'graphql/fragments/questions';

export const CREATE_TEMPLATE_SECTION_QUESTION_V2 = gql`
  mutation createTemplateSectionQuestionV2(
    $templateId: ID!
    $sectionId: ID!
    $payload: CreateQuestionV2Input!
    $questionIdAfter: ID
  ) {
    createTemplateSectionQuestionV2(
      templateId: $templateId
      sectionId: $sectionId
      payload: $payload
      questionIdAfter: $questionIdAfter
    ) {
      question {
        _id
      }
    }
  }
`;

export const DELETE_TEMPLATE_SECTION_QUESTION = gql`
  mutation deleteTemplateSectionQuestion($templateId: ID!, $sectionId: ID!, $questionId: ID!) {
    deleteTemplateSectionQuestion(
      templateId: $templateId
      sectionId: $sectionId
      questionId: $questionId
    ) {
      deleted
    }
  }
`;

export const DELETE_TEMPLATE_QUESTION = gql`
  mutation deleteTemplateQuestion($templateId: ID!, $questionId: ID!) {
    deleteTemplateQuestion(templateId: $templateId, questionId: $questionId) {
      deleted
    }
  }
`;

export const CLONE_TEMPLATE_SECTION_QUESTION = gql`
  mutation cloneTemplateSectionQuestion($templateId: ID!, $sectionId: ID!, $questionId: ID!) {
    cloneTemplateSectionQuestion(
      templateId: $templateId
      sectionId: $sectionId
      questionId: $questionId
    ) {
      question {
        _id
      }
    }
  }
`;

export const REORDER_TEMPLATE_SECTION_QUESTION = gql`
  mutation reorderTemplateSectionQuestions(
    $templateId: ID!
    $sectionId: ID!
    $sourceQuestionId: ID!
    $targetQuestionId: ID
  ) {
    reorderTemplateSectionQuestions(
      templateId: $templateId
      sectionId: $sectionId
      sourceQuestionId: $sourceQuestionId
      targetQuestionId: $targetQuestionId
    )
  }
`;

export const UPDATE_TEMPLATE_SECTION_QUESTION_V2 = gql`
  mutation updateTemplateSectionQuestionV2(
    $templateId: ID!
    $questionId: ID!
    $sectionId: ID!
    $payload: UpdateQuestionV2Input!
  ) {
    updateTemplateSectionQuestionV2(
      templateId: $templateId
      sectionId: $sectionId
      questionId: $questionId
      payload: $payload
    ) {
      question {
        ...QuestionV2
      }
    }
  }
  ${QUESTION_V2}
`;

export const REORDER_EVENT_QUESTION = gql`
  mutation reorderEventQuestions($eventId: ID!, $sourceQuestionId: ID!, $targetQuestionId: ID) {
    reorderEventQuestions(
      eventId: $eventId
      sourceQuestionId: $sourceQuestionId
      targetQuestionId: $targetQuestionId
    )
  }
`;

export const DELETE_EVENT_QUESTION = gql`
  mutation deleteEventQuestion($eventId: ID!, $questionId: ID!) {
    deleteEventQuestion(eventId: $eventId, questionId: $questionId) {
      deleted
    }
  }
`;

export const CREATE_EVENT_SECTION_QUESTION_IN_MIDDLE = gql`
  mutation createEventSectionQuestionInTheMiddle(
    $eventId: ID!
    $sectionId: ID!
    $payload: CreateQuestionInput!
    $questionIdAfter: ID
  ) {
    createEventSectionQuestionInTheMiddle(
      eventId: $eventId
      sectionId: $sectionId
      payload: $payload
      questionIdAfter: $questionIdAfter
    ) {
      question {
        _id
      }
    }
  }
`;

export const CREATE_EVENT_SECTION_QUESTION_V2 = gql`
  mutation createEventSectionQuestionV2(
    $eventId: ID!
    $sectionId: ID!
    $payload: CreateQuestionV2Input!
    $questionIdAfter: ID
  ) {
    createEventSectionQuestionV2(
      eventId: $eventId
      sectionId: $sectionId
      payload: $payload
      questionIdAfter: $questionIdAfter
    ) {
      question {
        _id
      }
    }
  }
`;

export const CLONE_EVENT_SECTION_QUESTION = gql`
  mutation cloneEventSectionQuestion($eventId: ID!, $sectionId: ID!, $questionId: ID!) {
    cloneEventSectionQuestion(eventId: $eventId, sectionId: $sectionId, questionId: $questionId) {
      question {
        _id
      }
    }
  }
`;

export const UPDATE_EVENT_SECTION_QUESTION_V2 = gql`
  mutation updateEventSectionQuestionV2(
    $eventId: ID!
    $questionId: ID!
    $sectionId: ID!
    $payload: UpdateQuestionV2Input!
  ) {
    updateEventSectionQuestionV2(
      eventId: $eventId
      sectionId: $sectionId
      questionId: $questionId
      payload: $payload
    ) {
      question {
        ...QuestionV2
      }
    }
  }
  ${QUESTION_V2}
`;

export const ANSWER_EVENT_SECTION_QUESTION_V2 = gql`
  mutation answerEventSectionQuestionV2(
    $eventId: ID!
    $questionId: ID!
    $sectionId: ID!
    $payload: AnswerQuestionV2Input!
  ) {
    answerEventSectionQuestionV2(
      eventId: $eventId
      sectionId: $sectionId
      questionId: $questionId
      payload: $payload
    ) {
      question {
        _id
        isAnswered
        question {
          title
          options {
            title
            images {
              ...QuestionImageFragment
            }
          }
        }
        settings {
          type
          hasOther
        }
        answer {
          text
          selectedOptions
        }
      }
    }
  }
  ${QUESTION_IMAGE}
`;

export const REORDER_EVENT_SECTION_QUESTION = gql`
  mutation reorderEventSectionQuestions(
    $eventId: ID!
    $sourceQuestionId: ID!
    $targetQuestionId: ID
    $sectionId: ID!
  ) {
    reorderEventSectionQuestions(
      eventId: $eventId
      sectionId: $sectionId
      sourceQuestionId: $sourceQuestionId
      targetQuestionId: $targetQuestionId
    )
  }
`;

export const DELETE_EVENT_SECTION_QUESTION = gql`
  mutation deleteEventSectionQuestion($eventId: ID!, $sectionId: ID!, $questionId: ID!) {
    deleteEventSectionQuestion(eventId: $eventId, sectionId: $sectionId, questionId: $questionId) {
      deleted
    }
  }
`;

export const CREATE_FAVORITE_SECTION_QUESTION_IN_MIDDLE_V2 = gql`
  mutation createFavoriteSectionQuestionV2(
    $favoriteSectionId: ID!
    $payload: CreateQuestionV2Input!
    $questionIdAfter: ID
  ) {
    createFavoriteSectionQuestionV2(
      favoriteSectionId: $favoriteSectionId
      payload: $payload
      questionIdAfter: $questionIdAfter
    ) {
      question {
        _id
      }
    }
  }
`;

export const DELETE_FAVORITE_SECTION_QUESTION = gql`
  mutation deleteFavoriteSectionQuestion($favoriteSectionId: ID!, $questionId: ID!) {
    deleteFavoriteSectionQuestion(favoriteSectionId: $favoriteSectionId, questionId: $questionId) {
      deleted
    }
  }
`;

export const CLONE_FAVORITE_SECTION_QUESTION = gql`
  mutation cloneFavoriteSectionQuestion($favoriteSectionId: ID!, $questionId: ID!) {
    cloneFavoriteSectionQuestion(favoriteSectionId: $favoriteSectionId, questionId: $questionId) {
      question {
        _id
      }
    }
  }
`;

export const UPDATE_FAVORITE_SECTION_QUESTION_V2 = gql`
  mutation updateFavoriteSectionQuestionV2(
    $favoriteSectionId: ID!
    $questionId: ID!
    $payload: UpdateQuestionV2Input!
  ) {
    updateFavoriteSectionQuestionV2(
      favoriteSectionId: $favoriteSectionId
      questionId: $questionId
      payload: $payload
    ) {
      question {
        ...QuestionV2
      }
    }
  }
  ${QUESTION_V2}
`;

export const REORDER_FAVORITE_SECTION_QUESTION = gql`
  mutation reorderFavoriteSectionQuestions(
    $favoriteSectionId: ID!
    $sourceQuestionId: ID!
    $targetQuestionId: ID
  ) {
    reorderFavoriteSectionQuestions(
      favoriteSectionId: $favoriteSectionId
      sourceQuestionId: $sourceQuestionId
      targetQuestionId: $targetQuestionId
    )
  }
`;
