import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  brick: {
    borderRadius: '4px',
    background: theme.palette.overlay.skeleton,
  },
}));

export default useStyles;
