import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';
import Modal, { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { ConfirmActionModalProps } from './interfaces';

import useStyles from './style';

const ConfirmActionModal: FC<ConfirmActionModalProps> = ({
  cancel,
  discard,
  submit,
  children,
  bodyStyle,
  noScroll = false,
  closeOnSubmit = true,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const closeConfirmModal = useCallback(() => closeModal(rest.modalName || Modals.confirmAction), [
    rest.modalName,
  ]);

  const handleClick = (action?: Function) => {
    const actionTimer = setTimeout(() => {
      action?.();
      clearTimeout(actionTimer);
    });

    closeOnSubmit && closeConfirmModal();
  };

  return (
    <StopPropagationWrapper className={classes.stopPropagation}>
      <Modal
        modalName={Modals.confirmAction}
        {...rest}
        className={classNames(classes.confirmActionModal, rest.className)}
      >
        <Modal.Body style={bodyStyle} noScroll={noScroll}>
          <div className="content">{children}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleClick(cancel?.onClick)}
            displayType="bordered"
            shape="round"
            size="lg"
            key="confirm-cancel-button"
          >
            {cancel?.text || t('cancel')}
          </Button>
          {!!discard?.onClick ? (
            <Button
              onClick={() => handleClick(discard?.onClick)}
              displayType="bordered"
              shape="round"
              size="lg"
              key="confirm-discard-button"
            >
              {discard.text || t('dontSave')}
            </Button>
          ) : null}
          {!!submit?.onClick ? (
            <Button
              onClick={() => handleClick(submit?.onClick)}
              className={submit.className}
              loading={submit.loading}
              displayType="primary"
              shape="round"
              size="lg"
              key="confirm-submit-button"
            >
              {submit.text}
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </StopPropagationWrapper>
  );
};

export default ConfirmActionModal;
