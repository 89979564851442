import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  playlistInfoBlock: {
    padding: '12px',
    margin: '0 0 8px 0',
    position: 'relative',
    width: 'fit-content',

    '& .viboTextarea.disabled': {
      color: `${theme.palette.text.primary}!important`,
    },

    '& .publicLabel': {
      position: 'absolute',
      top: '20px',
      left: '20px',

      '@media screen and (min-width: 1441px)': {
        top: '24px',
        left: '24px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '16px',
      margin: '0 0 12px 0',
    },
  },

  coverPhoto: {
    width: '264px',
    height: '264px',

    '& .gradient': {
      height: '100%',
      width: '100%',
      background: theme.palette.background.photo,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },

    '& .viboImage': {
      cursor: 'pointer',
    },

    '@media screen and (min-width: 1441px)': {
      width: '352px',
      height: '352px',
    },
  },

  scrollbar: {
    width: 'fit-content!important',

    '&.shiftThumb': {
      '& > .scrollView': {
        padding: '4px 4px 20px 4px',
      },
    },

    '& .viboButton': {
      width: '100%',
      margin: '8px 0 0 0',
      backgroundColor: theme.palette.background.paper,

      '@media screen and (min-width: 1441px)': {
        margin: '12px 0 0 0',
      },
    },

    '& .detailsRow': {
      '&:not(:last-child)': {
        marginBottom: '13px',

        '@media screen and (min-width: 1441px)': {
          marginBottom: '19px',
        },
      },
    },

    '& .errorInput': {
      borderBottom: `1px solid ${theme.palette.error.main} !important`,
    },
  },

  songIdeasTitle: {
    minHeight: '27px',
    width: '100%',

    '& input': {
      ...theme.mixins.longText,
      maxWidth: 'none',
      width: '100%',
      fontWeight: 500,
      fontSize: '19px!important',
      lineHeight: '26.92px!important',

      '@media screen and (min-width: 1441px)': {
        fontSize: '24px!important',
        lineHeight: '34px!important',
      },
    },

    '& .limit, & .error': {
      position: 'relative',
      whiteSpace: 'nowrap',
      padding: '0 0 0 5px!important',
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '27px',
    },
  },

  fieldLabel: {
    fontSize: '11px',
    lineHeight: '15.59px',
    color: theme.palette.text.secondary,

    '@media screen and (min-width: 1441px)': {
      fontSize: '14px',
      lineHeight: '19.84px',
    },
  },

  fieldText: {
    fontSize: '13px',
    lineHeight: '18.42px',
    color: theme.palette.text.primary,
    marginTop: '4px',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '22.67px',
    },
  },

  customInput: {
    border: '0',
    color: theme.palette.text.primary,
    fontSize: '18px',
    lineHeight: '1.4',
    padding: '3px 0!important',
    marginBottom: '0',
    width: '100%',
    maxWidth: '470px',

    '&:focus + div.limit': {
      display: 'block !important',
    },
  },

  customInputWrap: {
    position: 'relative',

    '& .limit': {
      paddingRight: '8px',
      color: theme.palette.primary.main,
      display: 'none',
    },
  },
}));

export default useStyles;
