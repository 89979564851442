import React, { FC, useRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

import Scrollbar from 'vibo-ui/Scrollbar';

import { ModalBodyProps } from './interfaces';

import useStyles from './style';

const ModalBody: FC<PropsWithChildren<ModalBodyProps>> = ({
  style,
  autoHeight,
  autoStaticHeight,
  id,
  noScroll = false,
  children,
}) => {
  const classes = useStyles();

  const bodyRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={bodyRef}
      className={classNames('modalBody', classes.modalBody, {
        noScroll,
        autoHeight,
        autoStaticHeight,
      })}
      style={style}
    >
      {noScroll ? (
        children
      ) : (
        <Scrollbar className="modalBodyScroll" id={id} shiftThumb>
          <>{children}</>
        </Scrollbar>
      )}
    </div>
  );
};

export default ModalBody;
