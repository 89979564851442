import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songExtra: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .like': {
      margin: '0 0 0 5px',
    },

    '& .songMoreTools': {
      margin: '0 5px 0 0',
    },
  },

  creatorImage: {
    height: '24px',
    width: '24px',

    '&:not(img)': {
      position: 'relative',
      overflow: 'visible',

      '&:hover': {
        '&:before': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          content: '""',
          backgroundColor: theme.palette.action.hover,
          width: 'calc(100% + 3px)',
          height: 'calc(100% + 3px)',
          borderRadius: '50%',
        },

        '& img': {
          zIndex: 5,
        },

        '& svg': {
          color: theme.palette.background.paper,
        },
      },
    },

    //for gtm tracking propper target
    '& .imageDefaultPlaceholder': {
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
