import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  questions: {
    '& .draggableItemClassName, .question': {
      '&.smooth-dnd-draggable-wrapper': {
        overflow: 'visible',
      },

      '&:not(:last-child)': {
        margin: ' 0 0 12px 0',
      },
    },
  },

  dropQuestionPlaceholder: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '4px',
  },

  dragQuestion: {
    '& .content > *': {
      '&:not(.questionHeader)': {
        display: 'none',
      },
    },

    '& .questionHeader': {
      margin: 0,
    },
  },

  hideDndIcon: {
    '& .dndIcon': {
      display: 'none!important',
    },
  },

  savingQuestions: {
    '& .dndIcon': {
      display: 'none!important',
    },
  },
}));

export default useStyles;
