import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import Card from 'vibo-ui/Card';

import { OverlayWrapperComponentProps } from './interfaces';

import useStyles from './style';

const OverlayWrapper: FC<OverlayWrapperComponentProps> = ({
  disableOverlayClass,
  overlayWrapperClassName,
  overlay,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={classNames(
        'overlayWrapper',
        {
          [classes.viboOverlayWrapper]: !disableOverlayClass,
        },
        overlayWrapperClassName
      )}
      shadowed={!disableOverlayClass}
    >
      {overlay as ReactElement}
    </Card>
  );
};

export default OverlayWrapper;
