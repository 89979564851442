import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import DjInfo from './DjInfo';
import UserInfo from './UserInfo';
import WithRole from '../../WithRole';
import Scrollbar from 'vibo-ui/Scrollbar';
import { UserPolicyContextProvider } from 'components/context/PolicyContext/UserPolicyContext';

import client from 'graphql/client';
import { useModal } from 'vibo-ui/Modal';
import { toastNotify } from 'graphql/hooks/common';

import { Modals, UserRole } from 'types/enums';
import { ChangeUserImageModalProps } from 'components/modals/ChangeUserImageModal';
import { UserInfoProps } from './interfaces';

const MyInfo: FC<UserInfoProps> = ({
  user,
  userQuery,
  userQueryKey,
  mutations,
  variables = {},
}) => {
  const { t } = useTranslation();

  const { openModal } = useModal();

  const openChangeProfilePhotoModal = useCallback(() => {
    openModal<ChangeUserImageModalProps>({
      key: Modals.changeUserImage,
      props: {
        currentImg: user?.imageUrl,
        updateImageMutation: mutations.updateProfile.mutation,
        updateImageMutationKey: mutations.updateProfile.key,
        variables,
        userQuery,
        userQueryKey,
        title: t('profilePicture'),
        placeholder: t('addProfilePicture'),
        fieldKey: 'imageUrl',
      },
    });
  }, [
    variables,
    userQuery,
    userQueryKey,
    user?.imageUrl,
    mutations.updateProfile.mutation,
    mutations.updateProfile.key,
  ]);
  const openChangeLogoModal = useCallback(() => {
    openModal<ChangeUserImageModalProps>({
      key: Modals.changeUserImage,
      props: {
        currentImg: user?.logoUrl,
        updateImageMutation: mutations.updateProfile.mutation,
        updateImageMutationKey: mutations.updateProfileLogo.key,
        variables,
        userQuery,
        userQueryKey,
        title: t('logo'),
        placeholder: t('addLogo'),
        fieldKey: 'logoUrl',
      },
    });
  }, [
    variables,
    userQuery,
    userQueryKey,
    user?.logoUrl,
    mutations.updateProfile.mutation,
    mutations.updateProfileLogo.key,
  ]);

  const [updateProfile] = useMutation(mutations.updateProfile.mutation, {
    onCompleted: (data: any) => {
      client.writeQuery({
        query: userQuery,
        variables,
        data: {
          [userQueryKey]: data[mutations.updateProfile.key],
        },
      });
      toastNotify({ text: t('changesSavedSuccessfully') });
    },
  });

  const handleUpdateProfile = (payload: Partial<User>) =>
    updateProfile({
      variables: {
        ...variables,
        payload,
      },
    });

  const infoDefaultProps = {
    user,
    setModalLogoOpen: openChangeLogoModal,
    setModalProfileOpen: openChangeProfilePhotoModal,
    handleUpdateProfile,
  };

  return (
    <Scrollbar shiftThumb>
      <UserPolicyContextProvider>
        <WithRole roles={[UserRole.dj]} key={`user-${user?._id}`}>
          <DjInfo {...infoDefaultProps} />
        </WithRole>
        <WithRole roles={[UserRole.user]}>
          <UserInfo {...infoDefaultProps} />
        </WithRole>
      </UserPolicyContextProvider>
    </Scrollbar>
  );
};

export default MyInfo;
