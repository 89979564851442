import React, { FC, useCallback, useContext, useEffect, memo } from 'react';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Sections from 'components/sections/Sections';
import WithEventRole from 'components/user/WithEventRole';
import TimelineSection from 'components/sections/TimelineSection';
import TimelineSectionsHeader from 'components/sections/TimelineSectionsHeader';
import ColorfulSectionWrapper from '../../ColorfulSectionWrapper/ColorfulSectionWrapper';
import AddTimelineSectionButton from 'components/buttons/AddTimelineSectionButton';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from '../SectionsFilterContext';

import { useModal } from 'vibo-ui/Modal';
import { useColorfulSections } from 'graphql/hooks/sections';
import { hasSectionsFilterValue } from 'services/sections/helpers';
import { compareSearchStrings } from 'services/common/stringHelpers';

import { SECTIONS } from 'graphql/queries/events';
import {
  CREATE_EVENT_SECTION,
  DELETE_EVENT_SECTION,
  REORDER_EVENT_SECTIONS,
} from 'graphql/mutations/events';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType, Modals } from 'types/enums';
import { DeleteSectionModalProps } from 'components/modals/DeleteSectionModal';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { EventSectionsProps } from './interfaces';

const EventSections: FC<EventSectionsProps> = ({
  event,
  sections,
  selectedSection,
  refetch,
  updateSection,
  onSectionClick,
  onEditClick,
}) => {
  const { t } = useTranslation();

  const eventId: string = event._id;

  const { filter, setFilter } = useContext(SectionsFilterableContext);
  const { canAddSection = false } = useContext(EventContext);

  const { openModal } = useModal();

  const openDeleteSectonModal = useCallback(
    section =>
      openModal<DeleteSectionModalProps>({
        key: Modals.deleteSection,
        props: {
          delteSectionMutation: DELETE_EVENT_SECTION,
          refetchQueries: ['sections'],
          variables: {
            eventId,
            sectionId: section._id,
          },
        },
      }),
    [eventId]
  );
  const openConfirmReorderingModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('clearSearch'),
          children: t('clearSearchReorderSections'),
          submit: {
            text: t('clear'),
            onClick: handleConfirmReordering,
          },
        },
      }),
    []
  );

  const { sectionColors } = useColorfulSections({ sections });

  const [reorderSections] = useMutation<boolean, ReorderSectionsVariables>(REORDER_EVENT_SECTIONS);

  const filteredSections = sections.filter(section =>
    compareSearchStrings(section.name, filter?.q)
  );

  const handleConfirmReordering = useCallback(() => {
    setFilter(null);
    refetch({ eventId, filter: omit(filter, 'q') });
  }, [eventId, filter?.q]);

  useEffect(() => {
    if (!selectedSection || (!sections.includes(selectedSection) && sections.length)) {
      onSectionClick(sections[0]);
    }
  }, [sections, selectedSection, onSectionClick]);

  return (
    <Sections<EventSection, EventSectionsVariables>
      sections={filteredSections}
      reorderProps={{
        onReorder: reorderSections,
        variables: { eventId, filter: omit(filter, 'q') },
        listQuery: SECTIONS,
        listCacheKey: 'sections',
        onBeforeCapture: () => {
          if (hasSectionsFilterValue(filter)) {
            return openConfirmReorderingModal();
          }
        },
      }}
      renderSection={({ section, isDragging }): JSX.Element => (
        <ColorfulSectionWrapper
          color={sectionColors.find(option => option.sectionId === section._id)?.color}
        >
          <TimelineSection
            section={section}
            isSelected={section._id === selectedSection?._id}
            isDragging={isDragging}
            onClick={onSectionClick}
            onEditClick={onEditClick}
            onDeleteClick={openDeleteSectonModal}
            onTimeChange={debounce(
              time =>
                updateSection({
                  variables: {
                    eventId,
                    sectionId: section._id,
                    payload: {
                      time,
                    },
                  },
                }),
              500
            )}
            onNameChange={debounce(
              name =>
                updateSection({
                  variables: {
                    eventId,
                    sectionId: section._id,
                    payload: { name },
                  },
                }),
              1000
            )}
          />
        </ColorfulSectionWrapper>
      )}
      renderHeader={() => (
        <TimelineSectionsHeader
          numSections={sections.length}
          filter={filter}
          onSearch={q => {
            setFilter(prev => ({ ...prev, q }));
          }}
          buttons={[
            <WithEventRole
              roles={[EventUserType.dj, EventUserType.host]}
              key="add-section-button"
              extraCondition={canAddSection}
            >
              <AddTimelineSectionButton
                refetchQueries={['sections']}
                createSectionMutation={CREATE_EVENT_SECTION}
                variables={{ eventId, filter: omit(filter, 'q') }}
                eventSettings={event.settings as EventSettings}
                prefixIcon={IconmoonFont['plus-16']}
                displayType="primary"
                shape="round"
                size="lg"
                hideTooltip
                shadowed
              >
                {t('addSection')}
              </AddTimelineSectionButton>
            </WithEventRole>,
          ]}
        />
      )}
    />
  );
};

const shouldUpdate: (prevProps: EventSectionsProps, nextProps: EventSectionsProps) => boolean = (
  prevProps: EventSectionsProps,
  nextProps: EventSectionsProps
) => {
  return (
    prevProps.event.note === nextProps.event.note &&
    prevProps.selectedSection === nextProps.selectedSection &&
    prevProps.sections === nextProps.sections
  );
};

export default memo(EventSections, shouldUpdate);
