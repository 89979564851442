import { colors } from 'resources/styles/colors';
import { DEFAULT_VIBO_COLOR } from 'components/context/ViboThemeContext/constants';

export const WAVE_CONFIG = {
  responsive: true,
  cursorWidth: 0,
  height: 40,
  barGap: 1,
  barWidth: 2,
};

export const VOLUME_STEP = 0.05;

export const HALF_VOLUME = 0.5;

export const MIN_VOLUME = 0;

export const MAX_VOLUME = 1;

export const handleVolumeUi = (target: Nullable<HTMLInputElement>, progress: number) => {
  if (!!target) {
    const percentProgress = progress * 100;

    target.style.background = `linear-gradient(to right, ${colors.curiousBlue(
      210
    )} ${percentProgress}%, ${colors.tropicalBlue(210)} ${percentProgress}%)`;
  }
};

export const normilizeVolume = (volume: number) => {
  if (volume > MAX_VOLUME) {
    return MAX_VOLUME;
  }

  if (volume < MIN_VOLUME) {
    return MIN_VOLUME;
  }

  return volume;
};

export const getProgressColor = (isDark: boolean) =>
  isDark ? colors.dodgerBlue(DEFAULT_VIBO_COLOR) : colors.curiousBlue(DEFAULT_VIBO_COLOR);

export const getWaveColor = (isDark: boolean) =>
  isDark ? colors.toryBlue(DEFAULT_VIBO_COLOR) : colors.hawkesBlue(DEFAULT_VIBO_COLOR);
