import React, { FC, useContext, memo, useState, useEffect, useMemo, useCallback } from 'react';
import last from 'lodash/last';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import usePrevious from 'react-use-previous-hook';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import QuestionComponent from '../Question';
import AddQuestionInMiddle from '../AddQuestionInMiddle';
import List, { DragResponse } from 'vibo-ui/List';
import { EventContext } from '../../context/EventContext';
import {
  TimelineQuestionsContext,
  QuestionActionsContext,
} from '../../events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { useNewQuestion } from 'graphql/hooks/questions';
import { getDestination } from 'services/common/reorderHelper';

import { Modals } from 'types/enums';
import { QuestionsProps } from './interfaces';

import useStyles from './style';

const Questions: FC<QuestionsProps> = ({ onAddQuestion }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentQuestions, setCurrentQuestions] = useState<Question[]>([]);

  const { isEventDj, canEditSection } = useContext(EventContext);
  const timelineQuestionsContext = useContext(TimelineQuestionsContext);
  const {
    questions,
    mutations,
    variables,
    refetchQueries,
    listCacheKey,
  } = timelineQuestionsContext;

  const { closeModal } = useModal();

  const closeCreateQuestionModal = useCallback(() => closeModal(Modals.createQuestion), []);

  const { sectionId } = useParams<EventPageRouteParams>();

  const prevQuestions = usePrevious(usePrevious(questions));
  const prevSectionId = usePrevious(sectionId);

  const { clearAddingNew } = useNewQuestion();

  const [reorderQuestions] = useMutation(mutations.reorder, {
    onError,
    refetchQueries: [listCacheKey],
  });

  const [answerQuestion, { loading: answerLoading }] = useMutation<Question>(mutations.answer, {
    onCompleted: () => {
      toastNotify({ text: t('changesSavedSuccessfully') });
      closeCreateQuestionModal();
    },
    refetchQueries,
  });
  const [updateQuestion] = useMutation<Question>(mutations.update, {
    onCompleted: () => {
      toastNotify({ text: t('changesSavedSuccessfully') });
      closeCreateQuestionModal();
    },
    refetchQueries,
  });
  const [cloneQuestion] = useMutation<Question>(mutations.clone, {
    onError,
  });
  const [deleteQuestion, { loading: deleteQuestionLoading }] = useMutation(mutations.delete, {
    onError,
  });

  const handleAnswerQuestion = (questionId: string) => (payload: object) => {
    answerQuestion({
      variables: {
        ...variables,
        questionId,
        payload,
      },
      refetchQueries,
    });
  };
  const handleUpdateQuestion = (questionId: string) => (payload: object) => {
    updateQuestion({
      variables: {
        ...variables,
        questionId,
        payload,
      },
      refetchQueries,
    });
  };

  const handleCloneQuestion = (questionId: string) =>
    cloneQuestion({
      variables: {
        ...variables,
        questionId,
      },
      refetchQueries,
    });

  const handleDeleteQuestion = (questionId: string) => {
    setCurrentQuestions(currentQuestions.filter(({ _id }) => _id !== questionId));
    deleteQuestion({
      variables: {
        ...variables,
        questionId,
      },
      refetchQueries,
    });
  };

  const handleDragChange = (newData: Question[], { removedIndex, addedIndex }: DragResponse) => {
    if (!isEqual(currentQuestions, newData)) {
      const destinationIndex = getDestination(removedIndex, addedIndex);

      setCurrentQuestions(newData);
      reorderQuestions({
        variables: {
          ...variables,
          sourceQuestionId: currentQuestions[removedIndex]._id,
          targetQuestionId: destinationIndex < 0 ? null : currentQuestions[destinationIndex]._id,
        },
      });
    }
  };

  const isOnlyOneQuestion = useMemo(() => questions.length <= 1, [questions.length]);

  const enableReorderQuestions = useMemo(() => isEventDj && canEditSection, [
    canEditSection,
    isEventDj,
  ]);

  useEffect(() => {
    if (prevSectionId && prevSectionId !== sectionId) {
      setCurrentQuestions([]);
    }
  }, [sectionId]);

  useEffect(() => {
    closeCreateQuestionModal();
  }, []);

  useEffect(() => {
    setCurrentQuestions(questions);
  }, [questions]);

  useEffect(() => {
    if (
      prevQuestions &&
      prevQuestions?.length > 0 &&
      questions.length > prevQuestions?.length &&
      prevSectionId === sectionId
    ) {
      const lastQuestion = last(questions);
      if (lastQuestion) {
        const callbackTimer = setTimeout(() => {
          onAddQuestion?.();
          clearTimeout(callbackTimer);
        });
      }
    }
  }, [questions.length, prevQuestions]);

  useEffect(() => {
    return () => clearAddingNew();
  }, []);

  return (
    <QuestionActionsContext.Provider
      value={{
        deleteQuestion: handleDeleteQuestion,
        cloneQuestion: handleCloneQuestion,
        answerQuestion: handleAnswerQuestion,
        updateQuestion: handleUpdateQuestion,
        deleteQuestionLoading,
      }}
    >
      <AddQuestionInMiddle questionId={null} />
      <List<Question>
        lockAxis="y"
        data={currentQuestions}
        onDropCallback={handleDragChange}
        className={classNames(classes.questions, {
          [classes.hideDndIcon]: isOnlyOneQuestion || !enableReorderQuestions,
          [classes.savingQuestions]: answerLoading,
        })}
        renderItem={question => (
          <QuestionComponent question={question} key={`question-${sectionId}-${question._id}`} />
        )}
        dragHandleSelector=".dndIcon"
        enableDragCursor={false}
        draggable={enableReorderQuestions}
        dropPlaceholder={{
          animationDuration: 150,
          showOnTop: true,
          className: classes.dropQuestionPlaceholder,
        }}
        dragClass={classes.dragQuestion}
      />
    </QuestionActionsContext.Provider>
  );
};

export default memo(Questions);
