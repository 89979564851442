import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';

import client from '../client';
import { isHeadlineSection } from 'services/sections/helpers';
import { colorNames } from 'components/events/ColorfulSectionWrapper/constants';

import { SECTION_SONGS_SORT } from '../fragments/sections';

import {
  ColorfulSectionExtend,
  SectionColorsKeys,
} from 'components/events/ColorfulSectionWrapper/interfaces';

type SongsSort = Nullable<TSort<SongsSortFields>>;

interface UseSectionsSongsSortProps {
  sectionId: string;
}

interface UseSectionsSongsSortResponse {
  sort: SongsSort;
  updateSongsSort: (sort: SongsSort, songsCount?: Nullable<number>) => void;
  songsCount: number;
}

export const useSectionsSongsSort: (
  props: UseSectionsSongsSortProps
) => UseSectionsSongsSortResponse = ({ sectionId }) => {
  const fragmentId = `SectionSongsSort:${sectionId}`;

  const cachedSectionSort = client.readFragment({
    id: fragmentId,
    fragment: SECTION_SONGS_SORT,
    fragmentName: 'SectionSongsSort',
  });

  const updateSongsSort = (
    sort: Nullable<TSort<SongsSortFields>>,
    songsCount?: Nullable<number>
  ) => {
    if (!!sectionId && !isEqual(sort, cachedSectionSort)) {
      const isCustomOrder = !sort;

      client.writeFragment({
        id: fragmentId,
        fragment: SECTION_SONGS_SORT,
        data: isCustomOrder
          ? {
              field: null,
            }
          : { ...sort, songsCount, __typename: 'SectionSongsSort' },
      });
    }
  };

  return {
    songsCount: cachedSectionSort?.songsCount,
    sort: (cachedSectionSort?.field ? omit(cachedSectionSort, 'songsCount') : null) as Nullable<
      TSort<SongsSortFields>
    >,
    updateSongsSort,
  };
};

export const useOpenSection = () => {
  const history = useHistory();

  const openSection = (id: string) => {
    const { pathname } = history.location;

    history.push({
      search: history.location.search,
      pathname: pathname.replace(/timeline(\/\w*)?/, `timeline/${id}`),
    });
  };

  return {
    openSection,
  };
};

export const useColorfulSections = ({
  sections,
  skip,
}: {
  sections: TemplateSection[] | EventSection[] | FavoriteSectionOption[];
  skip?: boolean;
}) => {
  const [sectionColors, setSectionColors] = useState<ColorfulSectionExtend[]>([]);

  useEffect(() => {
    if (!skip) {
      const colorsPalette = getSectionsPalette(sections);

      setSectionColors(colorsPalette);
    }
  }, [sections, skip]);

  return {
    sectionColors,
  };
};

export const getSectionsPalette = (
  sections: EventSection[] | TemplateSection[] | FavoriteSectionOption[]
) => {
  let colorIndex = -1;
  const colorsMap: ColorfulSectionExtend[] = [];

  sections.forEach((section, idx) => {
    const prevSections = sections.slice(0, ++idx);
    const underHeadline = prevSections.some(sectionOption => isHeadlineSection(sectionOption));

    if (isHeadlineSection(section)) {
      colorIndex = colorIndex === colorNames.length - 1 ? 0 : colorIndex + 1;
    }

    colorsMap.push({
      sectionId: section._id,
      color: underHeadline ? colorNames[colorIndex] : SectionColorsKeys.empty,
    });
  });

  return colorsMap;
};
