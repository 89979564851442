import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  highlighted: {
    padding: '4px',
    borderRadius: '2px',
    color: theme.palette.primary.main,
    minWidth: '24px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.danger': {
      color: theme.palette.error.main,
    },

    '&:hover, &.hoverable-action:hover': {
      backgroundColor: isDark ? theme.palette.selection.menuHover : theme.palette.primary.light,

      '&, & svg': {
        color: isDark ? colors.white : theme.palette.button.active,
      },
    },
  },
}));

export default useStyles;
