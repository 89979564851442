import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  youtubePlayerPlaceholder: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  player: {
    borderRadius: '4px',
    width: '100%',
    height: '340px',

    '@media screen and (min-width: 1441px)': {
      height: '390px',
    },
  },

  links: {
    margin: '10px 0 0 0',
    borderRadius: '4px',
    height: '20px',
    width: '20px',

    '@media screen and (min-width: 1441px)': {
      height: '24px',
      width: '24px',
    },
  },
}));

export default useStyles;
