import React, { useContext, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Section from '../Section';
import SectionTime from '../SectionTime';
import IconButton from '../../buttons/IconButton';
import SectionBlocksInfo from './SectionBlocksInfo';
import WithEventRole from '../../user/WithEventRole';
import AddSectionInMiddle from './AddSectionInMiddle';
import TextPreviewField from 'vibo-ui/TextPreviewField';
import WithTimelineSection from '../WithTimelineSection';
import VisibilityLabel from '../../labels/VisibilityLabel';
import TimelineSectionSongsInfo from './TimelineSectionSongsInfo';
import StopPropagationWrapper from '../../common/StopPropagationWrapper';
import { EventContext } from '../../context/EventContext';
import { TimelineSectionContext } from './TimelineSectionContext';

import { useModal } from 'vibo-ui/Modal';
import { onError } from 'graphql/helpers';
import { MAX_TITLE_LENGTH } from 'services/constants';
import { strippString } from 'services/common/stringHelpers';
import { toastNotify, openErrorNotification } from 'graphql/hooks/common';
import { isHeadlineSection, isDontPlaySection } from 'services/sections/helpers';

import { CHECK_FAVORITE_SECTION_EXISTS } from 'graphql/mutations/sections';

import { CloneModalProps } from 'components/modals/CloneModal/interfaces';
import {
  ButtonIconType,
  EventUserType,
  Modals,
  SectionType,
  SectionVisibilityRoles,
} from 'types/enums';
import { TimelineSectionProps } from './interfaces';

import useIconButtonDecorStyle from '../../buttons/IconButton/decorStyle';
import useStyles from './style';

const TimelineSection = <TSection extends TimelineSectionBase>({
  section,
  isSelected,
  isDragging,
  onClick,
  onDeleteClick,
  onEditClick,
  onNameChange,
  onTimeChange,
}: TimelineSectionProps<TSection>): React.ReactElement => {
  const iconButtonDecorClasses = useIconButtonDecorStyle();
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const {
    event,
    isEventDj,
    isEventGuest,
    isEventHost,
    isTemplate,
    showSectionInfo = false,
    canAddSection = false,
    canEditSection = false,
    mutations,
    timelineRoute,
    isFavoriteSections,
  } = useContext(EventContext);

  const isHeadline = useMemo(() => isHeadlineSection(section), [section.type, section._id]);
  const isDontPlay = useMemo(() => isDontPlaySection(section), [section.type, section._id]);

  const isNameDisabledForHost = useMemo(
    () => isEventHost && !section.settings.canHostChangeSectionName,
    [isEventHost, section.settings.canHostChangeSectionName]
  );
  const isTimeHiddenForHost = useMemo(
    () => isEventHost && !section.settings.canHostChangeSectionTime,
    [isEventDj, section.settings.canHostChangeSectionTime]
  );

  const hasTimeRow: boolean = useMemo(() => !isDontPlay && section.settings.timeEnabled, [
    isDontPlay,
    section.settings.timeEnabled,
  ]);
  const hasDeleteIcon: boolean = useMemo(
    () =>
      !isDontPlay &&
      canEditSection &&
      (isEventHost ? section.settings.canHostDeleteSection : showSectionInfo),
    [
      isDontPlay,
      canEditSection,
      isEventHost,
      showSectionInfo,
      section.settings.canHostDeleteSection,
    ]
  );

  const notesContent = useMemo(() => !!strippString(section.note), [section.note]);

  const showSongsInfo = useMemo(() => section.settings.songsEnabled, [
    section.settings.songsEnabled,
  ]);
  const showQuestionsInfo = useMemo(() => section.settings.questionsEnabled && showSectionInfo, [
    section.settings.questionsEnabled,
    showSectionInfo,
  ]);
  const showNotesInfo = useMemo(
    () =>
      section.settings.notesEnabled &&
      notesContent &&
      (isEventDj || (isEventHost && !!section.settings.notesVisibleForHosts)),
    [
      section.settings.notesEnabled,
      notesContent,
      isEventDj,
      isEventHost,
      section.settings.notesVisibleForHosts,
    ]
  );

  const [cloneSection, { loading: isCloning }] = useMutation(mutations.cloneSection, {
    onCompleted: cloneResponse => {
      const newSection = Object.values(cloneResponse)[0] as Maybe<TCloneEventSection>;

      toastNotify({
        text: t('sectionClonedSuccessfully'),
      });

      if (!!newSection) {
        history.push(`${timelineRoute}/${newSection._id}`);
      }
    },
    refetchQueries: [
      isFavoriteSections ? 'getFavoriteSections' : isTemplate ? 'templateSections' : 'sections',
    ],
    onError,
  });

  const [addSectionToFavorite, { loading: isAddingFavSectionLoading }] = useMutation(
    mutations.addSectionToFavorite,
    {
      onCompleted: () => {
        toastNotify({
          text: t('sectionAddedToFavorites'),
        });
      },
      onError,
    }
  );

  const [checkFavoriteSectionNameAlreadyExists, { loading: isCheckFavNameLoading }] = useMutation<
    TCheckFavSectionExistsResponse
  >(CHECK_FAVORITE_SECTION_EXISTS, {
    onCompleted: ({ checkFavoriteSectionNameAlreadyExists }) => {
      const { isExists, name } = checkFavoriteSectionNameAlreadyExists;

      if (isExists) {
        openErrorNotification(t('favoriteSectionNameAlreadyExists'));
      } else {
        addSectionToFavorite({
          variables: {
            [isTemplate ? 'templateId' : 'eventId']: event?._id,
            sectionId: section._id,
            name,
          },
        });
      }
    },
    onError,
  });

  const { openModal } = useModal();

  const openCloneSectionModal = useCallback(
    () =>
      openModal<CloneModalProps>({
        key: Modals.clone,
        props: {
          values: {
            title: section.name,
          },
          onSubmit: payload =>
            cloneSection({
              variables: isFavoriteSections
                ? {
                    favoriteSectionId: section._id,
                    name: payload.title,
                  }
                : {
                    [isTemplate ? 'templateId' : 'eventId']: event?._id,
                    sectionId: section._id,
                    name: payload.title,
                  },
            }),
          title: t('cloneSection'),
          label: t('sectionTitle'),
          closeOnSubmit: true,
        },
      }),
    [section._id, section.name, isTemplate, event?._id, isFavoriteSections]
  );
  const openCloneFavoriteModal = useCallback(
    () =>
      openModal<CloneModalProps>({
        key: Modals.clone,
        props: {
          values: {
            title: section.name,
          },
          onSubmit: payload =>
            checkFavoriteSectionNameAlreadyExists({
              variables: {
                name: payload.title,
              },
            }),
          title: t('addToFavorites'),
          label: t('addToFavorites'),
          closeOnSubmit: true,
        },
      }),
    []
  );

  return (
    <TimelineSectionContext.Provider
      value={{
        section,
        isDontPlay: false,
        isHeadLine: section.type === SectionType.headline,
      }}
    >
      <div
        className={classNames(classes.timelineSection, {
          headline: isHeadline,
        })}
      >
        {canAddSection && !isFavoriteSections ? (
          <AddSectionInMiddle sectionId={section._id} />
        ) : null}
        <Section
          content={{
            top: (
              <>
                <TextPreviewField.Input
                  className={classNames('editableSectionName', classes.editableSectionName, {
                    isDontPlay,
                  })}
                  onChange={(_, value) => onNameChange?.(value)}
                  defaultValue={section.name}
                  maxLength={MAX_TITLE_LENGTH}
                  disabled={isEventGuest || isDontPlay || isNameDisabledForHost}
                  data-gtm-target-id="timeline-edit-section-name"
                />
                <WithTimelineSection exludedTypes={[SectionType.headline]} section={section}>
                  <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
                    {section.visibility === SectionVisibilityRoles.host ? null : (
                      <VisibilityLabel tooltipPlacement="right" type={section.visibility} />
                    )}
                  </WithEventRole>
                </WithTimelineSection>
              </>
            ),
            middle:
              section.songsCount && section.settings.songsEnabled && section.songsCount === 1 ? (
                <WithTimelineSection exludedTypes={[SectionType.headline]} section={section}>
                  <TimelineSectionSongsInfo section={section} />
                </WithTimelineSection>
              ) : null,
            bottom: (
              <WithTimelineSection exludedTypes={[SectionType.headline]} section={section}>
                <SectionBlocksInfo
                  showNotesInfo={showNotesInfo}
                  showSongsInfo={showSongsInfo}
                  showQuestionsInfo={showQuestionsInfo}
                />
              </WithTimelineSection>
            ),
          }}
          isSelected={isSelected}
          isDragging={isDragging}
          onClick={() => onClick(section)}
          classes={{
            root: classNames(classes.section, {
              headline: isHeadline,
              dontPlay: isDontPlay,
            }),
            middle: classes.sectionMiddle,
          }}
        >
          <div
            className={classNames('sectionExtra', {
              withContent: !!hasTimeRow,
            })}
          >
            {hasTimeRow ? (
              <StopPropagationWrapper>
                <SectionTime
                  time={section.time}
                  onTimeChange={onTimeChange}
                  disabled={isTimeHiddenForHost}
                />
              </StopPropagationWrapper>
            ) : null}
            <WithEventRole
              roles={[EventUserType.dj, EventUserType.host]}
              extraCondition={(isEventHost && !isDontPlay) || isEventDj}
            >
              <div className={classNames('sectionTools', classes.sectionTools)}>
                <WithEventRole
                  roles={[EventUserType.dj]}
                  extraCondition={!isHeadline && !isDontPlay && canAddSection}
                >
                  <>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        openCloneSectionModal();
                      }}
                      disabled={isCloning}
                      title={t('cloneSection')}
                      type={ButtonIconType.clone}
                      className={classNames(
                        'clone',
                        'hidden-section-icon',
                        iconButtonDecorClasses.highlighted
                      )}
                      tooltipAlign={{
                        offset: [0, 12],
                      }}
                      placement="bottom"
                      data-gtm-target-id="timeline-clone-section"
                    />
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        openCloneFavoriteModal();
                      }}
                      disabled={isAddingFavSectionLoading || isCheckFavNameLoading}
                      title={t('addToFavorites')}
                      type={ButtonIconType.starOutlined}
                      className={classNames(
                        'addToFavorite',
                        'hidden-section-icon',
                        iconButtonDecorClasses.highlighted
                      )}
                      tooltipAlign={{
                        offset: [0, 12],
                      }}
                      placement="bottom"
                      data-gtm-target-id="timeline-add-section-to-favorite"
                    />
                  </>
                </WithEventRole>
                {!!onEditClick && canEditSection && showSectionInfo ? (
                  <IconButton
                    title={t(isHeadline ? 'editHeadline' : 'sectionSettings')}
                    type={ButtonIconType.settings}
                    className={classNames(
                      'hidden-section-icon',
                      iconButtonDecorClasses.highlighted
                    )}
                    onClick={e => {
                      e.stopPropagation();
                      onEditClick(section);
                    }}
                    tooltipAlign={{
                      offset: [0, 12],
                    }}
                    placement="bottom"
                    data-gtm-target-id="timeline-section-settings"
                  />
                ) : null}
                {hasDeleteIcon ? (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      onDeleteClick(section);
                    }}
                    title={t(isHeadline ? 'deleteHeadline' : 'deleteSection')}
                    type={ButtonIconType.delete}
                    className={classNames(
                      'hidden-section-icon',
                      iconButtonDecorClasses.highlighted
                    )}
                    tooltipAlign={{
                      offset: [0, 12],
                    }}
                    placement="bottom"
                  />
                ) : null}
              </div>
            </WithEventRole>
          </div>
        </Section>
      </div>
    </TimelineSectionContext.Provider>
  );
};

export default TimelineSection;
