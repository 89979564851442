import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  songsInfo: {
    minWidth: 'fit-content',
    width: '100%',

    '& .songPreview , & .songArtistNames': {
      color: 'inherit',
    },
  },

  songPreview: {
    height: 'fit-content',
    width: '100%',
    margin: '0',
    justifyContent: 'flex-start',

    '& .imageContainer': {
      minWidth: '14px',
      minHeight: '14px',
      width: '14px',
      height: '14px',
      margin: '0 6px 0 0',

      '@media screen and (min-width: 1441px)': {
        minWidth: '16px',
        minHeight: '16px',
        width: '16px',
        height: '16px',
      },
    },

    '& .songArtistNames': {
      color: 'inherit',
      flexDirection: 'row',

      '& .songName, & .artistName': {
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '19.56px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },

      '& .songName': {
        position: 'relative',
        display: 'block',
      },
    },
  },
}));

export default useStyles;
