import React, { createContext, useState } from 'react';

type TLogoContext = {
  url: Nullable<string>;
  setUrl: Function;
  alt: Nullable<string>;
  setAlt: Function;
  id: Nullable<string>;
  setId: Function;
};

export const LogoContext = createContext<TLogoContext>(undefined!);

export const LogoContextProvider: React.FC<any> = ({ children }) => {
  const [url, setUrl] = useState<Nullable<string>>(null);
  const [alt, setAlt] = useState<Nullable<string>>(null);
  const [id, setId] = useState<Nullable<string>>(null);

  return (
    <LogoContext.Provider value={{ url, setUrl, alt, setAlt, id, setId }}>
      {children}
    </LogoContext.Provider>
  );
};
