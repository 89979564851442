import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import PageContentHeader from '../../../components/common/PageContentHeader';
import AddPeopleButton from '../../../components/events/buttons/AddPeopleButton';
import EventContacts from '../../../components/events/EventContacts';
import EventLayout from '../EventLayout';
import EventTitle from '../../../components/events/Timeline/EventTitle';

import { openErrorNotification } from 'graphql/hooks/common';

import { GET_EVENT } from '../../../graphql/queries/events';

import { Page } from '../../../types/enums';

import useStyles from './style';

const EventUsers: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id: eventId } = useParams<EventPageRouteParams>();

  const { push } = useHistory();

  const { data: eventData } = useQuery<EventResponse>(GET_EVENT, {
    variables: { eventId },
    fetchPolicy: 'cache-and-network',
    onError: e => {
      // @ts-ignore
      if (e.graphQLErrors.some(error => error.code === 'EVENT_NOT_FOUND')) {
        openErrorNotification(t('eventNotFoundMessage'));
        push('/not-found');
      }
    },
  });

  const event = eventData?.event as EventItem;

  return (
    <EventLayout page={Page.people}>
      <div className={classes.eventUsers}>
        {!!event ? (
          <PageContentHeader extra={!!event ? <AddPeopleButton /> : null}>
            <EventTitle name={event.title} />
          </PageContentHeader>
        ) : null}
        <EventContacts key="eventContacts" />
      </div>
    </EventLayout>
  );
};

export default EventUsers;
