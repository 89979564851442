import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  searchInputWrapper: {
    position: 'relative',

    '& .viboInput': {
      boxShadow: theme.shadows[3],

      '&.withBorder': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));

export default useStyles;
