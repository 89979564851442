import React, { FC, forwardRef, PropsWithChildren, Ref } from 'react';
import classNames from 'classnames';

import { CardProps } from './interfaces';

import useStyles from './style';

const Card: FC<PropsWithChildren<CardProps>> = forwardRef(
  ({ className, children, shadowed, ...rest }, ref: Ref<HTMLDivElement>) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        className={classNames(
          'viboCard',
          classes.viboCard,
          {
            [`shadowed ${classes.shadowed}`]: shadowed,
          },
          className
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default Card;
