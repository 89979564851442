import { gql } from 'graphql.macro';

export const VIBO_USER = gql`
  fragment ViboUser on ViboUser {
    _id
    firstName
    lastName
    email
    imageUrl
    logoUrl
    image {
      cropped {
        small
        medium
      }
    }
    role
    djSettings {
      type
      financeStatus
      parentId
      allowUpdateProfileForChild
      isAdmin
      stripeId
    }
    createdAt
    isMe
    parent {
      _id
      firstName
      lastName
      email
      role
      djSettings {
        type
        financeStatus
        parentId
        allowUpdateProfileForChild
        isAdmin
        stripeId
      }
      createdAt
      isMe
    }
    children {
      _id
      firstName
      lastName
      email
      role
      djSettings {
        type
        financeStatus
        parentId
        allowUpdateProfileForChild
        isAdmin
        stripeId
      }
      createdAt
      isMe
    }
  }
`;
