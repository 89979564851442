import React, { FC, memo } from 'react';
import classNames from 'classnames';

import SongsBlockInfo from './SongsBlockInfo';
import QuestionsBlockInfo from './QuestionsBlockInfo';
import { SectionBlocksInfoProps } from './interfaces';
import NotesBlockInfo from './NotesBlockInfo';

import useStyles from '../style';

const SectionBlocksInfo: FC<SectionBlocksInfoProps> = ({
  showSongsInfo,
  showQuestionsInfo,
  showNotesInfo,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames('iconsWrapper', classes.iconsWrapper)}>
      {showSongsInfo ? <SongsBlockInfo /> : null}
      {showQuestionsInfo ? <QuestionsBlockInfo /> : null}
      {showNotesInfo ? <NotesBlockInfo /> : null}
    </div>
  );
};

export default memo(SectionBlocksInfo);
