import React, { FC, useState, useCallback, FocusEvent, useRef } from 'react';
import classNames from 'classnames';

import ViboInput, { InputSize } from 'vibo-ui/Input';
import TextPreview from './TextPreview';

import { TextPreviewInputProps } from './interfaces';

import useInputStyles from 'resources/styles/inputs/style';

const Input: FC<TextPreviewInputProps> = ({ previewClassName, ...rest }) => {
  const inputClasses = useInputStyles();

  const [showInput, setShowInput] = useState<boolean>(false);

  const handleShowInput = () => setShowInput(true);
  const handleHideInput = () => setShowInput(false);
  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      handleHideInput();
      rest.onBlur?.(e);
    },
    [rest.onBlur]
  );

  const inputSize: InputSize = rest.size || 'lg';
  const textContent = rest.value || rest.defaultValue || '';

  const inputRef = useRef(null);
  const isFocused = document.activeElement === inputRef.current;
  const isPreviewEnabled = Boolean((!showInput && textContent && !isFocused) || rest.disabled);

  return isPreviewEnabled ? (
    <TextPreview
      className={classNames(
        previewClassName,
        rest.className,
        inputSize,
        rest.withUnderline
          ? `withUnderline ${inputClasses.withUnderline}`
          : `withBorder ${inputClasses.withBorder}`
      )}
      onMouseDown={handleShowInput}
      text={textContent as string}
    />
  ) : (
    <ViboInput ref={inputRef} autoFocus={isPreviewEnabled} {...rest} onBlur={handleBlur} />
  );
};

export default Input;
