import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sectionName: {
    '&': {
      ...theme.mixins.longText,
    },

    maxWidth: '180px',
  },

  scrollbar: {
    width: '225px',
    maxHeight: '200px',

    '& .scrollView': {
      position: 'relative!important',
      maxHeight: 'inherit',
    },
  },
}));

export default useStyles;
