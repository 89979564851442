import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Spinner from 'vibo-ui/Spinner';

import { useApolloLoadingIndicator } from '../../../graphql/hooks/apollo';

import useStyles from './style';

const SavingLoadingDataIndicator: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isLoading } = useApolloLoadingIndicator();

  return isLoading ? (
    <div className={classNames('savingLoadingDataIndicator', classes.savingLoadingDataIndicator)}>
      <Spinner size={12} />
      <span className="text">{t('loading')}</span>
    </div>
  ) : null;
};

export default SavingLoadingDataIndicator;
