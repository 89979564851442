import { gql } from 'graphql.macro';

export const USER_DATA = gql`
  fragment UserData on User {
    _id
    firstName
    lastName
    email
    role
    nick
    phoneCode
    phoneNumber
    country
    lang
    imageUrl
    loginType
    logoUrl
    site
    scannerComputers {
      id
      name
      lastScanDate
      version
      djSoftPlatforms
      drives {
        id
        name
        folders
      }
    }
    location {
      name
      lat
      lng
    }
    about
    showName
    subscriptions {
      newsAndUpdates
      eventsReminding
    }
    socialLinks {
      facebook
      youtube
      instagram
      spotify
      soundcloud
      mixcloud
      tiktok
    }
    reviews {
      url
    }
    spotifyConnected
    appleMusicConnected
    djSettings {
      type
      financeStatus
      parentId
      allowUpdateProfileForChild
      isAdmin
      stripeId
    }
    policy {
      editableFields
    }
  }
`;

export const USER_IMAGES = gql`
  fragment UserLogo on User {
    _id
    firstName
    lastName
    imageUrl
    logoUrl
  }
`;

export const USER_PREVIEW_DATA = gql`
  fragment UserPreviewData on UserPreview {
    _id
    firstName
    lastName
    nick
    showName
    imageUrl
    logoUrl
    isMe
    image {
      cropped {
        small
        medium
      }
    }
  }
`;
