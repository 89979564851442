import { gql } from 'graphql.macro';

export const CREATE_SONG_IDEAS = gql`
  mutation createSongIdeas($payload: CreateSongIdeasPayload!) {
    createSongIdeas(payload: $payload) {
      _id
    }
  }
`;

export const CLONE_SONG_IDEAS = gql`
  mutation cloneSongIdeas($songIdeasId: ID!, $payload: CloneSongIdeasPayload!) {
    cloneSongIdeas(songIdeasId: $songIdeasId, payload: $payload) {
      _id
      creatorId
      title
      songsCount
      coverPhoto {
        url
        cropped {
          medium
          small
        }
      }
    }
  }
`;

export const UPDATE_SONG_IDEAS = gql`
  mutation updateSongIdeas($songIdeasId: ID!, $payload: UpdateSongIdeasInput!) {
    updateSongIdeas(songIdeasId: $songIdeasId, payload: $payload) {
      title
      description
      updatedAt
      coverPhoto {
        url
        cropped {
          medium
        }
      }
    }
  }
`;

export const DELETE_SONG_IDEAS = gql`
  mutation deleteSongIdeas($songIdeasId: ID!) {
    deleteSongIdeas(songIdeasId: $songIdeasId) {
      ok
      deletedCount
    }
  }
`;

export const REMOVE_SONG_FROM_SONG_IDEAS = gql`
  mutation removeSongFromSongIdeas($songIdeasId: ID!, $viboSongId: ID!) {
    removeSongFromSongIdeas(songIdeasId: $songIdeasId, viboSongId: $viboSongId)
  }
`;

export const REORDER_SONG_IDEAS_SONGS = gql`
  mutation reorderSongIdeasSongs($songIdeasId: ID!, $sourceSongId: ID!, $targetSongId: ID) {
    reorderSongIdeasSongs(
      songIdeasId: $songIdeasId
      sourceSongId: $sourceSongId
      targetSongId: $targetSongId
    )
  }
`;

export const ADD_SONG_TO_SONG_IDEAS = gql`
  mutation addSongToSongIdeas($songIdeasId: ID!, $payload: AddSongIdeasSongInput!) {
    addSongToSongIdeas(songIdeasId: $songIdeasId, payload: $payload)
  }
`;

export const EXPORT_SONG_IDEAS_TO_SPOTIFY = gql`
  mutation exportSongIdeasToSpotify($songIdeasId: ID!) {
    exportSongIdeasToSpotify(songIdeasId: $songIdeasId) {
      playlistUrl
      exportedCount
      failedToExport
    }
  }
`;

export const TOGGLE_PUBLIC_SONG_IDEAS = gql`
  mutation togglePublicSongIdeas($songIdeasId: ID!) {
    togglePublicSongIdeas(songIdeasId: $songIdeasId) {
      isPublic
    }
  }
`;

export const DELETE_SONG_IDEAS_FROM_FAVORITE_SECTION = gql`
  mutation deleteSongIdeasFromFavoriteSection(
    $favoriteSectionId: ID!
    $payload: DeleteSongIdeasFromSectionInput!
  ) {
    deleteSongIdeasFromFavoriteSection(favoriteSectionId: $favoriteSectionId, payload: $payload)
  }
`;

export const ADD_SONG_IDEAS_TO_FAVORITE_SECTION = gql`
  mutation addSongIdeasToFavoriteSection(
    $favoriteSectionId: ID!
    $payload: AddSongIdeasToSectionInput!
  ) {
    addSongIdeasToFavoriteSection(favoriteSectionId: $favoriteSectionId, payload: $payload) {
      addedCount
      totalCount
    }
  }
`;
