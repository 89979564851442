import React, { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import IconButton from 'components/buttons/IconButton';
import {
  QuestionActionsContext,
  QuestionContext,
  TimelineQuestionsContext,
} from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';

import { ButtonDecor } from 'vibo-ui/Button';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { CreateQuestionModalProps } from 'components/modals/CreateQuestionModal';
import { ButtonIconType, Modals } from 'types/enums';

import useIconButtonStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const Tools: FC = () => {
  const iconButtonDecClasses = useIconButtonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { deleteQuestion, cloneQuestion } = useContext(QuestionActionsContext);
  const { values } = useFormikContext<UpdateQuestionForm>();
  const { question } = useContext(QuestionContext);
  const timelineQuestionsContext = useContext(TimelineQuestionsContext);

  const { openModal } = useModal();

  const openCreateQuestionModal = () => {
    openModal<CreateQuestionModalProps>({
      key: Modals.createQuestion,
      props: {
        questionId: question._id,
        data: values,
        timelineQuestionsContext,
      },
    });
  };
  const openDeleteQuestionModal = () =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('removeThisQuestion'),
        children: t('sureYouWantDelete'),
        submit: {
          text: t('remove'),
          onClick: handleRemoveQuestion,
        },
      },
    });

  const handleRemoveQuestion = useCallback(() => {
    deleteQuestion(question._id);
  }, [question._id]);

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      className={classNames('tools', classes.tools)}
    >
      <IconButton
        type={ButtonIconType.edit}
        placement="bottom"
        title={t('editQuestion')}
        className={classNames(iconButtonDecClasses.highlighted)}
        onClick={e => {
          e.stopPropagation();

          openCreateQuestionModal();
        }}
      />
      <IconButton
        type={ButtonIconType.clone}
        title={t('clone')}
        onClick={e => {
          e.stopPropagation();
          cloneQuestion(question._id);
        }}
        className={classNames(iconButtonDecClasses.highlighted)}
      />
      <IconButton
        type={ButtonIconType.delete}
        title={t('remove')}
        onClick={e => {
          e.stopPropagation();
          openDeleteQuestionModal();
        }}
        className={classNames(iconButtonDecClasses.highlighted, ButtonDecor.danger)}
      />
    </div>
  );
};

export default Tools;
