import React from 'react';
import classNames from 'classnames';

import SectionSongPreview from '../../SectionSongPreview';

import { isEventSection } from '../../../../services/sections/helpers';

import useStyles from './style';

interface TimelineSectionSongsInfoProps {
  section: TimelineSection;
}

const TimelineSectionSongsInfo: React.FC<TimelineSectionSongsInfoProps> = ({ section }) => {
  const classes = useStyles();

  return isEventSection(section) ? (
    <div className={classNames('songsInfo', classes.songsInfo)}>
      <SectionSongPreview section={section} className={classes.songPreview} />
    </div>
  ) : null;
};

export default TimelineSectionSongsInfo;
