import React, { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

import Spinner from 'vibo-ui/Spinner';
import Icon from 'vibo-ui/Icon';
import Link from './Link';

import { ButtonProps } from './interfaces';

import useStyles from './style';

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      children,
      to,
      href,
      className,
      prefixIcon,
      sufixIcon,
      loading,
      shadowed = false,
      disabled = false,
      target = '_blank',
      shape = 'default',
      displayType = 'default',
      size = 'sm',
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const isDisabled = loading || disabled;

    const baseClassName = classNames(
      'viboButton',
      {
        [classes.alignedContent]: !!prefixIcon || !!sufixIcon,
        [classes.withPrefixIcon]: !!prefixIcon,
        [classes.withSufixIcon]: !!sufixIcon,
        shadowed,
      },
      classes.viboButton,
      `${size}Size`,
      `${displayType}Display`,
      `${shape}Shape`,
      className
    );

    const content = (
      <>
        {loading ? (
          <Spinner
            size="sm"
            className={classNames('prefixIcon', classes.prefixIcon, {
              withChildren: !!children,
            })}
          />
        ) : null}
        {prefixIcon && !loading ? (
          <Icon
            icon={prefixIcon}
            className={classNames('prefixIcon', classes.prefixIcon, {
              withChildren: !!children,
            })}
            disableAutoFontSize
          />
        ) : null}
        {children}
        {sufixIcon ? (
          <Icon
            icon={sufixIcon}
            className={classNames('sufixIcon', classes.sufixIcon)}
            disableAutoFontSize
          />
        ) : null}
      </>
    );

    if (to || href) {
      return (
        <Link
          className={baseClassName}
          target={target}
          to={to}
          href={href}
          disabled={isDisabled}
          {...rest}
        >
          {content}
        </Link>
      );
    }

    return (
      <button className={baseClassName} disabled={isDisabled} ref={ref} {...rest}>
        {content}
      </button>
    );
  }
);

export default Button;
