import React from 'react';
import classNames from 'classnames';

import HeadlineLabel from 'components/labels/HeadlineLabel';
import SelectCheckbox from '../../../../components/context/SelectListContext/SelectCheckbox';
import VisibilityLabel from '../../../../components/labels/VisibilityLabel';
import SongsToggler from './SongsToggler';
import QuestionsToggler from './QuestionsToggler';
import NotesToggler from './NotesToggler';

import { SectionVisibilityRoles } from '../../../../types/enums';
import { PdfSectionRowProps, PdfSectionTypes } from '../interfaces';

import useStyles from './style';

const PdfSectionRow = <T extends PdfSectionTypes>({
  sectionsControl,
  section,
  layout,
}: PdfSectionRowProps<T>) => {
  const classes = useStyles();

  return (
    <div
      className={classNames('pdfSectionRow', classes.pdfSectionRow, {
        disabled: !section.selected,
      })}
    >
      <SelectCheckbox id={section._id} />
      <div className="sectionName" title={section.name}>
        {section.name}
      </div>
      {section.visibility === SectionVisibilityRoles.host ? null : (
        <VisibilityLabel type={section.visibility} useShortNames />
      )}
      {section.isHeadline ? <HeadlineLabel /> : null}
      <div
        onClick={e => e.stopPropagation()}
        className={classNames('toggleControls', classes.toggleControls)}
      >
        <SongsToggler
          onClick={() => sectionsControl.handleSongCheck(section._id)}
          selected={section.showSongs}
          songsCount={section.songsCount}
          section={section}
          layout={layout}
        />
        <QuestionsToggler
          onClick={() => sectionsControl.handleQuestionCheck(section._id)}
          selected={section.showQuestions}
          section={section}
          layout={layout}
        />
        <NotesToggler
          onClick={() => sectionsControl.handleNoteCheck(section._id)}
          selected={section.showNotes}
          section={section}
          layout={layout}
        />
      </div>
    </div>
  );
};

export default PdfSectionRow;
