import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Location from 'components/common/Location';
import CopyQuestionAnswer from 'components/questions/CopyQuestionAnswer';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import useStyles from '../../common/style';

const LocationAnswer: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const formik = useFormikContext<UpdateQuestionForm>();

  const { question, canAnswer } = useContext(QuestionContext);

  const location = useMemo(() => formik.values.answer?.location, [
    formik.values.answer?.location?.name,
  ]);

  return (
    <div className={classNames('locationAnswer', classes.infoAnswer)}>
      <Icon icon={IconmoonFont['location-16']} />
      <Location
        locationValue={location}
        onLocationChange={newLocation => {
          formik.setFieldValue('answer.location', newLocation);
        }}
        disabled={!canAnswer}
        placeholder={`${t('addLocation')}...`}
        key={`location-answer-${question._id}-${location?.name}`}
      />
      {!!location?.name ? <CopyQuestionAnswer value={location.name} /> : null}
    </div>
  );
};

export default LocationAnswer;
