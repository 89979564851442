import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  virtualizedList: {
    '& .ReactVirtualized__List, & .ReactVirtualized__Grid__innerScrollContainer, & .ReactVirtualized__Table__Grid, & .ReactVirtualized__Grid': {
      overflow: 'visible!important',
    },
  },
}));

export default useStyles;
