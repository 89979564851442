import React, { FC } from 'react';

import Scrollbar from 'vibo-ui/Scrollbar';

import { EventGeneralInfoLayoutProps } from './interfaces';

import useStyles from './style';

const EventGeneralInfoLayout: FC<EventGeneralInfoLayoutProps> = props => {
  const classes = useStyles();

  return (
    <>
      <div className="titleInput">{props.titleInput}</div>
      <Scrollbar className={classes.eventContentScroll} shiftThumb>
        <div className={classes.content}>
          <div className={classes.contentLeft}>{props.contentLeft}</div>
          <div className={classes.contentRight}>{props.contentRight}</div>
        </div>
      </Scrollbar>
    </>
  );
};

export default EventGeneralInfoLayout;
