import React, { FC, MouseEvent } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Button from 'vibo-ui/Button';

import { useModal } from 'vibo-ui/Modal';
import { toastNotify } from 'graphql/hooks/common';
import { MAX_EVENT_TITLE_LENGTH } from 'services/constants';

import { CLONE_TEMPLATE } from 'graphql/mutations/template';

import { IconmoonFont } from 'vibo-ui/Icon';
import { ButtonIconType, Modals } from 'types/enums';
import { CloneModalProps } from 'components/modals/CloneModal';
import { CloneTemplateButtonProps } from './interfaces';

const CloneTemplateButton: FC<CloneTemplateButtonProps> = ({
  template,
  className,
  renderButton,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { openModal } = useModal();

  const openCloneModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal<CloneModalProps>({
      key: Modals.clone,
      props: {
        values: {
          title: template.title,
        },
        onSubmit: payload =>
          cloneTemplate?.({
            variables: {
              templateId: template._id,
              payload,
            },
          }),
        maxLength: MAX_EVENT_TITLE_LENGTH,
        title: t('cloneTemplate'),
        label: t('templateName'),
        closeOnSubmit: true,
      },
    });
  };

  const [cloneTemplate] = useMutation(CLONE_TEMPLATE, {
    onCompleted: data => {
      toastNotify({ text: t('templateClonedSuccessfully'), iconType: ButtonIconType.clone });
      history.push(`/template/${data.cloneTemplate._id}/general`);
    },
  });

  return !!renderButton ? (
    renderButton(openCloneModal)
  ) : (
    <Button
      onClick={openCloneModal}
      className={classNames('centrate', className)}
      prefixIcon={IconmoonFont['clone-24']}
      shape="round"
      size="lg"
      shadowed
    >
      {t('cloneTemplate')}
    </Button>
  );
};

export default CloneTemplateButton;
