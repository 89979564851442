import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  timelineSongsBlock: {
    '& .sectionBlockHeader': {
      flexDirection: 'column',

      '&:before': {
        top: '-4px',
      },
    },

    '&.isFilterApplied': {
      '& .timelineSongsHeader': {
        display: 'flex',
      },

      '&.noSongsFound': {
        '& .timelineSongsHeader': {
          top: 0,
        },
      },
    },

    '& .songsList': {
      '& .song': {
        backgroundColor: theme.palette.background.paper,

        '&:hover': {
          backgroundColor: theme.palette.selection.hover,
        },
      },
    },
  },

  onlyOneSong: {
    '& .sortFilter': {
      display: 'none',
    },
  },

  draggableWrapper: {
    width: '100%',
    position: 'relative',
  },

  space: {
    height: '70px',
  },

  loadMoreButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  actionIcons: {
    display: 'flex',

    '& > span': {
      padding: '0',
    },
  },

  checkbox: {
    width: '16px',
    height: '16px',
    marginRight: '6px',
  },

  dropdown: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  shownContent: {
    '& .timelineSongsHeader': {
      display: 'flex',
    },

    '& .sectionBlockContent': {
      overflow: 'visible',
    },

    '& .loadSongsTrigger': {
      display: 'none',
    },
  },

  loadSongsWrapper: {
    '& .loadSongsTrigger': {
      zIndex: 100,
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },

  searchInput: {
    right: '0',
    position: 'absolute',
    boxShadow: theme.shadows[4],
    borderRadius: '4px',
    background: theme.palette.background.paper,
    height: '28px',
    margin: 'auto 4px',
    width: '92%',

    '& svg': {
      width: '16px',
      height: '16px',
    },

    '@media screen and (min-width: 1441px)': {
      width: '93%',
    },
  },

  searchShorter: {
    width: '66.5%',

    '@media screen and (min-width: 1080px)': {
      width: '72%',
    },

    '@media screen and (min-width: 1242px)': {
      width: '75%',
    },
  },

  searchIcon: {
    marginLeft: 'auto',
  },

  iconButton: {
    cursor: 'pointer',
    padding: '6px',

    '@media screen and (min-width: 1441px)': {
      padding: '8px',
    },

    '& svg': {
      color: theme.palette.primary.main,
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },
  },

  hidden: {
    opacity: '0',
    pointerEvents: 'none',
  },

  popover: {
    paddingTop: '0',
    margin: '-30px 0 0 0',
    paddingBottom: '30px',
  },
}));

export default useStyles;
