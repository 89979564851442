import React, { forwardRef, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import RcSwitch, { SwitchChangeEventHandler } from 'rc-switch';
import isString from 'lodash/isString';
import classNames from 'classnames';

import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import StateIcon from './StateIcon';
import Spinner from 'vibo-ui/Spinner';

import { SwitchProps } from './interfaces';

import useStyles from './style';

const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  (
    {
      onChange,
      onClick,
      loading,
      disabled,
      className,
      wrapperClassName,
      hideStateIcons,
      checkedIcon,
      uncheckedIcon,
      labelText,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const handleChange: SwitchChangeEventHandler = (checked, e) =>
      onChange?.(
        checked,
        e as KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement, MouseEvent>
      );

    const handleClick: SwitchChangeEventHandler = (checked, e) =>
      onClick?.(
        checked,
        e as KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement, MouseEvent>
      );

    const loadingIcon = loading ? <Spinner /> : null;

    const isCheckedIconString = isString(checkedIcon);
    const isUncheckedIconIconString = isString(uncheckedIcon);

    return (
      <label
        className={classNames('viboSwitchLabel', classes.viboSwitchLabel, wrapperClassName, {
          withLabel: !!labelText,
        })}
      >
        <RcSwitch
          className={classNames(
            'viboSwitch',
            {
              loading,
            },
            classes.viboSwitch,
            className
          )}
          onChange={handleChange}
          onClick={handleClick}
          loadingIcon={loadingIcon}
          checkedChildren={
            hideStateIcons ? null : isCheckedIconString ? (
              <Icon icon={checkedIcon as IconmoonFont} />
            ) : (
              ((checkedIcon || <StateIcon.CheckedIcon />) as ReactNode)
            )
          }
          unCheckedChildren={
            (hideStateIcons
              ? null
              : (isUncheckedIconIconString ? (
                  <Icon icon={uncheckedIcon as IconmoonFont} />
                ) : (
                  uncheckedIcon
                )) || <StateIcon.UncheckedIcon />) as ReactNode
          }
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        {!!labelText ? labelText : null}
      </label>
    );
  }
);

export default Switch;
