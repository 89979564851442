import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';

import { TimelineSongsContext } from '../../TimelineSongsContext';
import Action from '../Action';

import { ReactComponent as DontPlayIcon } from '../../../../../../resources/img/svg/dontPlay.svg';

import { onError } from '../../../../../../graphql/helpers';
import { MOVE_SECTION_SONGS } from '../../../../../../graphql/mutations/songs';

import { SectionType } from '../../../../../../types/enums';
import { DontPlayProps } from './interfaces';

const DontPlay: FC<DontPlayProps> = ({ onCompleted, songIds, ...rest }) => {
  const { t } = useTranslation();

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const { songsOptions, sections, refetchSongs } = useContext(TimelineSongsContext);

  const [moveSongs, { loading: loadingMoveSong }] = useMutation(MOVE_SECTION_SONGS, {
    onCompleted: () => {
      onCompleted?.();
      songsOptions.removeSongsById(songIds);
      refetchSongs();
    },
    onError: e => {
      onError(e);
    },
    refetchQueries: ['sections', 'getSectionSongsStats'],
  });

  const handleMoveSong = (targetSection: TimelineSection) => {
    if (targetSection) {
      moveSongs({
        variables: {
          eventId,
          sourceSectionId: sectionId,
          targetSectionId: targetSection._id,
          songIds,
        },
      });
    }
  };

  const handleClick = () => {
    if (!loadingMoveSong) {
      const dontPlaySection = sections.find(
        section => section.type === SectionType.dontPlay
      ) as TimelineSection;

      handleMoveSong(dontPlaySection);
    }
  };

  return (
    <Action
      onClick={handleClick}
      icon={DontPlayIcon}
      text={t('dontPlay')}
      songIds={songIds}
      {...rest}
    />
  );
};

export default DontPlay;
