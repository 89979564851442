import React, { FC, useEffect } from 'react';
import { Query } from 'react-apollo';

import { useApolloLoadingIndicator } from '../../../graphql/hooks/apollo';
import { GET_LOADING_STATUS } from '../../../graphql/queries/common';

import { LoadingQueryProps } from './interfaces';

const UpdateLoading: FC<{ loading: boolean }> = ({ loading }) => {
  const { setIsLoading } = useApolloLoadingIndicator();

  useEffect(() => {
    setIsLoading(loading);

    return () => {
      setIsLoading(false);
    };
  }, [loading]);

  return null;
};

const ApolloLoadingIndicator: FC = () => (
  <Query query={GET_LOADING_STATUS}>
    {(loadingQuery: LoadingQueryProps) => (
      <UpdateLoading loading={loadingQuery?.data?.loading || false} />
    )}
  </Query>
);
export default ApolloLoadingIndicator;
