import React, { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router';
import { NetworkStatus } from 'apollo-client';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import Button from 'vibo-ui/Button';
import Spinner from 'vibo-ui/Spinner';
import Scrollbar from 'vibo-ui/Scrollbar';
import PrepModeSong from '../PrepModeSong';
import ContentArea from 'components/common/ContentArea';
import PrepModeSongsHeader from '../PrepModeSongsHeader';
import PrepModeSongsSubHeader from '../PrepModeSongsSubHeader';
import { SortContext } from 'components/context/SortContext';
import { PrepModeSongsFilterableContext } from '../PrepModeSongsContext';
import { SelectListContext } from 'components/context/SelectListContext';

import { GET_PREP_MODE_SONGS } from 'graphql/queries/prepMode';

import { getPrepModeSongsData } from 'services/songs/helpers';
import { getIds } from 'services/common/arrHelpers';

import { IconmoonFont } from 'vibo-ui/Icon';
import { PrepModeSongExpansion } from 'types/enums';
import { FilteredSortedPaginated } from 'types/graphql';
import { PrepModeSectionSongsProps } from './interfaces';

import useStyles from './style';

const PrepModeSectionSongs: FC<PrepModeSectionSongsProps> = ({
  variables,
  isUpdatingSongs,
  handleUpdateSongs,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { sectionId } = useParams<EventPageRouteParams>();

  const [openedSongs, setOpenedSongs] = useState<string[]>([]);

  const { sort } = useContext(SortContext);
  const { filter } = useContext(PrepModeSongsFilterableContext);
  const { selectedIds, setSelectedIds, setIds } = useContext(SelectListContext);

  const songsQueryVariables: FilteredSortedPaginated<
    PrepModeSectionSongsFilter,
    SongsSortFields,
    PrepModeSongsVariables
  > = {
    ...variables,
    filter,
    sort,
    pagination: {
      skip: 0,
      limit: 50,
    },
  };

  const { data, fetchMore, refetch, loading, networkStatus } = useQuery<
    PrepModeSongsResponse,
    FilteredSortedPaginated<PrepModeSectionSongsFilter, SongsSortFields, PrepModeSongsVariables>
  >(GET_PREP_MODE_SONGS, {
    variables: songsQueryVariables,
    skip: !variables.computerId,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const prepModeSongsData: PrepModeSongsData = getPrepModeSongsData(data);

  const loadMoreSongs = () => {
    !loading &&
      prepModeSongsData.next &&
      fetchMore({
        variables: {
          ...variables,
          filter,
          sort,
          pagination: prepModeSongsData.next,
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;

          return {
            getPrepModeSongs: {
              ...prev.getPrepModeSongs,
              songs: [...prev.getPrepModeSongs.songs, ...fetchMoreResult.getPrepModeSongs.songs],
              next: fetchMoreResult.getPrepModeSongs.next,
            },
          };
        },
      });
  };

  useEffect(() => {
    refetch();
  }, [variables.computerId]);

  useEffect(() => {
    const selectedIdList = prepModeSongsData.songs
      .map(({ files, _id }) => (files.some(file => file.isSelected) ? _id : ''))
      .filter(Boolean);
    const allSongIds = prepModeSongsData.songs.map(({ _id }) => _id);

    if (!isEqual(allSongIds, selectedIds)) {
      setIds(allSongIds);
    }

    if (!isEqual(selectedIdList, selectedIds)) {
      setSelectedIds(selectedIdList);
    }
  }, [prepModeSongsData.songs]);

  const isExpandedSongs = openedSongs.length;

  return (
    <>
      <ContentArea className={classes.songsHeaderCard} shadowed>
        <PrepModeSongsHeader onUpdateSongs={handleUpdateSongs} />
        <PrepModeSongsSubHeader
          onUpdateSongs={handleUpdateSongs}
          renderExpandCollapseIcon={() => (
            <Button
              onClick={() => {
                if (isExpandedSongs) {
                  setOpenedSongs([]);
                } else {
                  setOpenedSongs(
                    getIds(
                      prepModeSongsData.songs.filter(songOption => songOption.files.length > 1)
                    )
                  );
                }
              }}
              prefixIcon={IconmoonFont[isExpandedSongs ? 'colapse-16' : 'expand-16']}
              className={classes.expandBtn}
              displayType="link"
            >
              {t(isExpandedSongs ? 'collapseAll' : 'expandAll')}
            </Button>
          )}
          key={`section-songs-sub-header-${sectionId}`}
        />
      </ContentArea>
      {(loading && networkStatus !== NetworkStatus.fetchMore) || isUpdatingSongs ? (
        <div>
          <Spinner className="withSpace" />
        </div>
      ) : (
        <Scrollbar className={classes.scrollbar} shiftThumb>
          <InfiniteScroll
            useWindow={false}
            initialLoad={false}
            threshold={250}
            hasMore={!!prepModeSongsData.next}
            loader={<Spinner key={0} />}
            loadMore={loadMoreSongs}
          >
            {prepModeSongsData.songs.map((song, index) => (
              <div
                key={song._id}
                className={classNames('listItemWrapper', classes.listItemWrapper)}
              >
                <PrepModeSong
                  index={index}
                  song={song}
                  variables={songsQueryVariables}
                  refetch={refetch}
                  initialExpansion={
                    openedSongs.some(songOption => songOption === song._id)
                      ? PrepModeSongExpansion.more
                      : PrepModeSongExpansion.less
                  }
                  openedSongs={openedSongs}
                />
              </div>
            ))}
          </InfiniteScroll>
        </Scrollbar>
      )}
    </>
  );
};

export default PrepModeSectionSongs;
