import React, { FC, createContext, useContext } from 'react';

import { UserContext } from '../UserContext';
import { WizardContext } from 'vibo-ui/Wizard/WizardStepContext';

import { DeepLinkWizardSteps } from 'components/common/DeepLinkWizard/interfaces';
import { HeadingWithImageData } from 'components/common/HeadingWithImage/interfaces';

type TDeepLinkContext = {
  action?: Nullable<Function>;
  goLogin?: Function;
  previewData?: HeadingWithImageData;
};

export const DeepLinkContext = createContext<TDeepLinkContext>(undefined!);

export const DeepLinkContextProvider: FC<TDeepLinkContext> = ({
  action,
  children,
  previewData,
}) => {
  const wizard = useContext(WizardContext);
  const { isLoggedIn } = useContext(UserContext);

  const goLogin = () => {
    wizard?.wizardRefProps?.goToNamedStep(DeepLinkWizardSteps.login);
  };

  return (
    <DeepLinkContext.Provider
      value={{
        goLogin,
        previewData,
        action: !!action ? (isLoggedIn ? action : goLogin) : () => null,
      }}
    >
      {children}
    </DeepLinkContext.Provider>
  );
};
