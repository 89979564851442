import React, { FC, useCallback } from 'react';
import get from 'lodash/get';
import join from 'lodash/join';
import slice from 'lodash/slice';
import classNames from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import SettingsItem from './SettingsItem';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useModal } from 'vibo-ui/Modal';
import { useMe } from 'graphql/hooks/user';

import { GET_CARD } from 'graphql/queries/user';

import { Modals } from 'types/enums';

import useStyles from './style';

const ChangeCard: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isChildDj } = useMe();

  const { openModal } = useModal();

  const openChangeUserCardModal = useCallback(
    () =>
      openModal({
        key: Modals.changeUserCard,
      }),
    []
  );

  const { data } = useQuery<BankCard>(GET_CARD, {
    fetchPolicy: 'cache-and-network',
    skip: isChildDj,
  });

  const last4 = get(data, 'getCard.last4');
  const month = get(data, 'getCard.month');
  const year = join(slice(get(data, 'getCard.year'), 2, 5), '');

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['creditCard-24']} className={classes.generalIcon} />
        <div className={classes.boldText}>{`XXXX-XXXX-XXXX-${last4 || 'XXXX'}`}</div>
        <div className={classNames(classes.boldText, classes.expiredDate)}>{`${month ||
          'MM'}/${year || 'YY'}`}</div>
      </div>
      <Button
        onClick={openChangeUserCardModal}
        prefixIcon={IconmoonFont['arrowLeftRight-16']}
        shape="round"
        shadowed
      >
        {t('changeCard')}
      </Button>
    </SettingsItem>
  );
};

export default ChangeCard;
