import React from 'react';
import { Redirect } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

import { ModalWindowContextProvider } from 'vibo-ui/Modal/ModalWindowContext';

import { isLoggedIn } from 'graphql/queries/auth';

import { LoginSources } from 'types/enums';

const AuthNotRequired = (props: any) => {
  const source = new URLSearchParams(window.location.search).get('source');

  if (source === LoginSources.zapier) {
    return props.children;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, loading } = useQuery(isLoggedIn);

  // eslint-disable-next-line react-hooks/rules-of-hooks

  if (loading) return <div>loading...</div>;

  return !data || !data.isLoggedIn ? (
    <ModalWindowContextProvider>{props.children}</ModalWindowContextProvider>
  ) : (
    <Redirect to="/events/upcoming" />
  );
};

export default AuthNotRequired;
