import React, { FC } from 'react';
import classNames from 'classnames';

import { CustomLabelProps, CustomLabelTypes } from './interfaces';

import useStyles from './style';

const CustomLabel: FC<CustomLabelProps> = ({
  className,
  type = CustomLabelTypes.default,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames('customLabel', classes.customLabel, className, type)}>
      {children}
    </div>
  );
};

export default CustomLabel;
