import React from 'react';
import classNames from 'classnames';

import SongArtistNames from '../../songs/SongArtistNames';
import Image from '../../image/Image';

import { getSmallestAvailableThumbnail } from '../../../services/songs/helpers';
import { getSectionSongsInfo } from '../../../services/sections/helpers';

import useStyles from './style';

interface SectionSongPreviewProps<TSection> {
  section: TSection;
  className?: string;
}

const SectionSongPreview = <TSection extends SectionBase & SectionWithSongsInfoBase>({
  section,
  className,
}: SectionSongPreviewProps<TSection>): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames('songPreview', classes.sectionSongPreview, className)}>
      {section.coverSong ? (
        <>
          <Image
            src={getSmallestAvailableThumbnail(section.coverSong.thumbnails)}
            alt={getSectionSongsInfo(section)}
            token={getSmallestAvailableThumbnail(section.coverSong.thumbnails)}
            className={classNames('imageContainer', classes.imageContainer)}
          />
          <SongArtistNames song={section.coverSong} divideWithHyphen />
        </>
      ) : null}
    </div>
  );
};

export default SectionSongPreview;
