import { ApolloError } from 'apollo-client';

import { openErrorNotification } from 'graphql/hooks/common';

export function getGraphQLError(e: ApolloError): ClientGraphQLError {
  if (e.networkError) {
    return {
      message: 'Something went wrong',
    };
  }

  // todo: get error message based on error code

  return e.graphQLErrors[0] as ClientGraphQLError;
}

export function onError(e: ApolloError) {
  const error = getGraphQLError(e);
  openErrorNotification(error.message);
}

// todo: remove it
export function canAddSongs(max: any, count: any) {
  if (max && max === 1) {
    if (max === count) {
      return 'change_song';
    }
    return 'add_song';
  }
  return 'add_songs';
}
