import { gql } from 'graphql.macro';

import { USER_DATA } from '../fragments/user';

export const GET_ME = gql`
  query getMe {
    me {
      ...UserData
    }
  }
  ${USER_DATA}
`;

export const GET_SCANNER_COMPUTERS = gql`
  query getMe {
    me {
      _id
      scannerComputers {
        id
        name
        lastScanDate
        version
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($userId: ID!) {
    user(userId: $userId) {
      ...UserData
      policy {
        editableFields
      }
    }
  }
  ${USER_DATA}
`;

export const GET_COUNT_EVENT_CHILD_DJ = gql`
  query getChildDj($userId: ID!) {
    getChildDj(userId: $userId) {
      eventsStats {
        upcomingEventsCount
      }
    }
  }
`;

export const GET_CHILD_DJS = gql`
  query getChildDj {
    getChildDjs {
      djs {
        _id
        firstName
        lastName
        imageUrl
        logoUrl
        eventsStats {
          upcomingEventsCount
          historyEventsCount
        }
      }
    }
  }
`;

export const GET_CARD = gql`
  query getCard {
    getCard {
      last4
      month
      year
    }
  }
`;
