import React, { useState, useCallback, forwardRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Content from './Content';
import Tooltip from 'vibo-ui/Tooltip';
import Dropdown from 'vibo-ui/Dropdown';
import IconButton from 'components/buttons/IconButton';

import { SongCommentProps } from './interfaces';
import { ButtonIconType } from 'types/enums';

import useIconButtonDecStyles from 'components/buttons/IconButton/decorStyle';
import useSongStyles from '../style';
import useStyles from './style';

const SongComment = forwardRef<HTMLDivElement, SongCommentProps<SectionSong>>(
  ({ song, className }, ref) => {
    const songClasses = useSongStyles();
    const iconButtonDecClasses = useIconButtonDecStyles();
    const classes = useStyles();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(e => {
      e.target.focus();
    }, []);

    const handleClose = useCallback(() => {
      setIsOpen(false);
    }, [isOpen]);

    return (
      <div
        className={classNames(
          'songComment',
          {
            [songClasses.hidden]: !song?.comment && !isOpen,
          },
          classes.songComment,
          className
        )}
        onClick={handleClick}
        ref={ref}
      >
        <Dropdown
          overlay={<Content song={song} handleClose={handleClose} />}
          onVisibleChange={setIsOpen}
          visible={isOpen}
          trigger="click"
        >
          <Tooltip
            className={classNames(classes.commentText, {
              [classes.addCommentText]: !song?.comment,
            })}
            overlay={song?.comment || t('addComment')}
            destroyTooltipOnHide
          >
            <IconButton
              type={ButtonIconType.comment}
              className={classNames(songClasses.iconButton, iconButtonDecClasses.highlighted)}
              data-gtm-target-id="timeline-section-details-songs-song-add-comment"
            />
          </Tooltip>
        </Dropdown>
      </div>
    );
  }
);

export default SongComment;
