import { ReactComponent as USA } from '../resources/img/svg/Dashboard/SettingsIcons/USA.svg';
import { ReactComponent as Danish } from '../resources/img/svg/Dashboard/SettingsIcons/danish.svg';
import { ReactComponent as Hebrew } from '../resources/img/svg/Dashboard/SettingsIcons/hebrew.svg';
import { ReactComponent as German } from '../resources/img/svg/Dashboard/SettingsIcons/german.svg';
import { ReactComponent as Canada } from '../resources/img/svg/Dashboard/SettingsIcons/canada.svg';
import { ReactComponent as Spanish } from '../resources/img/svg/Dashboard/SettingsIcons/spanish.svg';

import { ReactComponent as SeratoIcon } from '../resources/img/svg/prepMode/djSoftwares/serato.svg';
import { ReactComponent as VirtualDjIcon } from '../resources/img/svg/prepMode/djSoftwares/virtualDj.svg';
import { ReactComponent as TraktorIcon } from '../resources/img/svg/prepMode/djSoftwares/traktor.svg';
import { ReactComponent as DjayProIcon } from '../resources/img/svg/prepMode/djSoftwares/djayPro.svg';
import { ReactComponent as RekordboxIcon } from '../resources/img/svg/prepMode/djSoftwares/rekordbox.svg';
import { ReactComponent as EnginePrimeIcon } from '../resources/img/svg/prepMode/djSoftwares/enginePrime.svg';

import { ReactComponent as InstagramIcon } from '../resources/img/svg/Dashboard/SocialIcons/icon_instagram.svg';
import { ReactComponent as FacebookIcon } from '../resources/img/svg/Dashboard/SocialIcons/icon_facebook.svg';
import { ReactComponent as YoutubeIcon } from '../resources/img/svg/Dashboard/SocialIcons/icon_youtube.svg';
import { ReactComponent as MixCloudIcon } from '../resources/img/svg/Dashboard/SocialIcons/icon_mixcloud.svg';
import { ReactComponent as TikTokIcon } from '../resources/img/svg/Dashboard/SocialIcons/icon_tiktok.svg';
import { ReactComponent as Facebook } from '../resources/img/svg/support/facebook.svg';
import { ReactComponent as GoogleDrive } from '../resources/img/svg/support/googleDrive.svg';
import { ReactComponent as TuneMyMusic } from '../resources/img/svg/support/tuneMyMusic.svg';
import { ReactComponent as Soundiiz } from '../resources/img/svg/support/soundiiz.svg';
import { ReactComponent as Zapier } from '../resources/img/svg/support/zapier.svg';
import { ReactComponent as Bitly } from '../resources/img/svg/support/bitly.svg';
import { ReactComponent as CodeMonkey } from '../resources/img/svg/support/codeMonkey.svg';
import { ReactComponent as Emojipedia } from '../resources/img/svg/support/emojipedia.svg';

import { ReactComponent as YouTubeIcon } from '../resources/img/svg/songs/youtube.svg';
import { ReactComponent as DeezerIcon } from '../resources/img/svg/songs/deezer.svg';
import { ReactComponent as SoundCloudIcon } from '../resources/img/svg/Dashboard/SocialIcons/icon_soundcloud.svg';
import { ReactComponent as AppleMusicOriginalIcon2 } from '../resources/img/svg/appleMusicOriginal2.svg';
import { ReactComponent as TidalIcon } from '../resources/img/svg/songs/tidal.svg';
import { ReactComponent as AmazonStoreIcon } from '../resources/img/svg/songs/amazonStore.svg';
import { ReactComponent as SpotifyIcon } from '../resources/img/svg/Dashboard/SocialIcons/icon_spotify.svg';

import { LinkData } from '../components/user/Profile/MyInfo/SocialLinks/interfaces';
import {
  SongsSortOptionKeyEnum,
  ButtonIconType,
  PdfSettingOptions,
  AccountPlatform,
  EventUserType,
  EventTimeType,
} from '../types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';

export const INITIAL_QUESTIONS_LIMIT = window?.innerHeight >= 780 ? 3 : 2;

export const PREDEFINED_SONG_PLACEHOLDERS_COUNT = 2;

export const INITIAL_SONGS_LIMIT = window?.innerHeight >= 780 ? 4 : 2;

export const INITIAL_LOAD_SONGS_COUNT = 50;

export const LOAD_MORE_SONGS_COUNT = 100;

export const EVENT_STATUSES = {
  pending: 1,
  active: 2,
  locked: 3,
};

export const ERROR_MESSAGES = {
  emptyCardNumber: 'enterCardNumber',
  invalidCardNumber: 'yourCardNumberIncomplete',
  emptyExpiryDate: 'enterExpiryDate',
  monthOutOfRange: 'monthOutOfRange',
  yearOutOfRange: 'yearOutOfRange',
  dateOutOfRange: 'dateOutOfRange',
  invalidExpiryDate: 'yourCardExpireDateNumberIncorect',
  emptyCVC: 'enterCvv',
  invalidCVC: 'invalidCvc',
};

export const STATUS = {
  pending: 'pending',
  active: 'active',
  locked: 'locked',
  delete: 'delete',
  deleted: 'deleted',
  past: 'past',
};

export const LANG_LIST: { value: string; title: string; LangIcon: Icon; locale: string }[] = [
  { value: 'en', title: 'English (English)', LangIcon: USA, locale: 'en' },
  { value: 'es', title: 'Spanish (Española)', LangIcon: Spanish, locale: 'es' },
  {
    value: 'fr',
    title: 'French Canadian (Français Canadien)',
    LangIcon: Canada,
    locale: 'fr',
  },
  { value: 'de', title: 'German (Deutsche)', LangIcon: German, locale: 'de' },
  { value: 'iw', title: 'Hebrew (עברית)', LangIcon: Hebrew, locale: 'he' },
  { value: 'da', title: 'Danish (Dansk)', LangIcon: Danish, locale: 'da' },
];

export const MUSIC_PLATFORM_LINKS: Partial<SongLinks> = {
  youtube: 'https://www.youtube.com',
  spotify: 'https://open.spotify.com',
  appleMusic: 'https://geo.music.apple.com',
  tidal: 'https://listen.tidal.com',
  soundcloud: 'https://soundcloud.com',
  deezer: 'https://www.deezer.com',
};

export const ADDITIONAL_SONG_LINK_SOURCES: string[] = [
  'YouTube Music',
  'Amazon Store',
  'Pandora',
  'Napster',
  'Yandex',
];

export const SORTED_SONG_LINK_KEYS: (keyof SongLinks)[] = [
  'youtube',
  'spotify',
  'appleMusic',
  'tidal',
  'soundcloud',
  'deezer',
  'amazonStore',
];

export const SONG_SOURCE_METADATA: { [key in keyof Partial<SongLinks>]: SongSourceMetadata } = {
  youtube: {
    Icon: YouTubeIcon,
    text: 'YouTube',
  },
  deezer: {
    Icon: DeezerIcon,
    text: 'Deezer',
  },
  soundcloud: {
    Icon: SoundCloudIcon,
    text: 'SoundCloud',
  },
  appleMusic: {
    Icon: AppleMusicOriginalIcon2,
    text: 'Apple Music',
  },
  tidal: {
    Icon: TidalIcon,
    text: 'Tidal',
  },
  amazonStore: {
    Icon: AmazonStoreIcon,
    text: 'Amazon Store',
  },
  spotify: {
    Icon: SpotifyIcon,
    text: 'Spotify',
  },
};

export const SONG_LINK_PLACEHOLDER = 'https://songLink';

export const TEMPLATES_PAGE_KEYS = {
  user: 'user',
  vibo: 'vibo',
  company: 'company',
};

export const SONG_IDEAS_KEYS = {
  vibo: 'vibo',
  company: 'company',
  me: 'me',
};

export const ACCOUNT_PAGE_KEYS = {
  account: 'account',
  me: 'me',
};

export const TEMPLATE_PAGE_TAB_KEYS = {
  general: 'general',
  timeline: 'timeline',
  invite: 'invite',
  prepMode: 'prepMode',
};

export const EVENT_PAGE_TAB_KEYS = {
  general: 'general',
  users: 'users',
  timeline: 'timeline',
  prepMode: 'prepMode',
  beatsource: 'beatsource',
};

export const SUPPORT_CONTACTS = {
  mail: 'support@vibodj.com',
  phone: '+1 (646) 593-1460',
};

export const USEFUL_LINKS: UsefulLink[] = [
  {
    Icon: Facebook,
    label: 'viboDjsGroup',
    link: 'https://www.facebook.com/groups/viboofficial/',
  },
  {
    Icon: GoogleDrive,
    label: 'viboSalesTrainingPack',
    link: 'https://drive.google.com/drive/folders/10GdqmVuzVgvL2xavB0i_GtQ-fduOco0l?usp=sharing',
  },
  {
    Icon: GoogleDrive,
    label: 'viboMarketingAssets',
    link: 'https://drive.google.com/drive/folders/1aNm3M8nbfaCVQy3OtAJsuT5Ccm0wR72c?usp=sharing',
  },
  {
    Icon: GoogleDrive,
    label: 'viboWebinars',
    link: 'https://drive.google.com/drive/folders/1K1mw-gm5v0KaGoyLJFkIUWNIhsEGDTdC?usp=sharing',
  },
  {
    Icon: YouTubeIcon,
    label: 'viboUoutubeChannel',
    link: 'https://www.youtube.com/channel/UC7SRTi7-Uu293OibPBuLyRw',
  },
  {
    Icon: TuneMyMusic,
    label: 'tuneMyMusic',
    link: 'https://www.tunemymusic.com/',
  },
  {
    Icon: Soundiiz,
    label: 'soundiiz',
    link: 'https://soundiiz.com/',
  },
  {
    Icon: Zapier,
    label: 'zapier',
    link: 'https://zapier.com/developer/public-invite/13439/3f86647be38ac1092fe89eba6559e838/',
  },
  {
    Icon: Emojipedia,
    label: 'emojipedia',
    link: 'https://emojipedia.org/',
  },
  {
    Icon: Bitly,
    label: 'supportLinksBitly',
    link: 'https://bitly.com/',
  },
  {
    Icon: CodeMonkey,
    label: 'qrcodeMonkey',
    link: 'https://www.qrcode-monkey.com/',
  },
];

export const PREP_MODE_SONG_SHOW_MORE_AMOUNT = 5;

export const SCANNER_PROTOCOL = 'viboscanner';

export const MIN_CROP_SIZE = 120;

export const MAX_TITLE_LENGTH = 45;

export const MAX_EVENT_TITLE_LENGTH = 60;

export const MAX_PROFILE_NAMES_LENGTH = 60;

export const MAX_SONG_COMMENT_LENGTH = 45;

export const MAX_DESCRIPTION_LENGTH = 500;

export const MAX_SONG_LIMIT = 999;

export const MAX_DAYS_BEFORE_LOCKING = 99;

export const MAX_SONGS_IN_SPECIAL_SECTION = 10;

export const EXTRA_INPUT_SPACE = 30;

export const MAX_LIMIT_DIGITS = 3;

export const PREP_MODE_ACCORDION = {
  FILE_ITEM_HEIGHT: 21,
  FILE_ITEM_MARGIN_TOP: 4,
  NO_MATCHES_TEXT_HEIGHT: 20,
  NO_MATCHES_TEXT_MARGIN_TOP: 4,
  FOOTER_HEIGHT: 32,
};

export const TIMELINE_SONGS_FILTER_OPTIONS: WithIcon<TimelineSongsFilterOption>[] = [
  {
    key: ButtonIconType.totalCount,
    filter: {},
    Icon: IconmoonFont['musicNoteDoubleOutlined-16'],
  },
  {
    key: ButtonIconType.mustPlayCount,
    filter: { isMustPlay: true },
    Icon: IconmoonFont['crown-16'],
  },
  {
    key: ButtonIconType.hostLikedCount,
    filter: { hostLiked: true },
    Icon: IconmoonFont['heartOutlined-16'],
  },
  {
    key: ButtonIconType.withCommentsCount,
    filter: { hasComments: true },
    Icon: IconmoonFont['comment-16'],
  },
  {
    key: ButtonIconType.flaggedCount,
    filter: { isFlagged: true },
    Icon: IconmoonFont['flagOutlined-16'],
  },
];

export const PREP_MODE_SONGS_FILTER_OPTIONS: WithIcon<PrepModeSongsFilterOption>[] = [
  {
    key: 'all',
    value: {},
    iconKey: ButtonIconType.totalCount,
    Icon: IconmoonFont['musicNoteDoubleOutlined-16'],
  },
  {
    key: 'selected',
    value: { hasSelected: true },
    iconKey: ButtonIconType.mark,
    Icon: IconmoonFont['checkedFilled-16'],
  },
  {
    key: 'unselected',
    value: { hasSelected: false },
    iconKey: ButtonIconType.unchecked,
    Icon: IconmoonFont['uncheckedOutlined-16'],
  },
  {
    key: 'not_matched',
    value: { hasMatched: false },
    iconKey: ButtonIconType.close,
    Icon: IconmoonFont['close-16'],
  },
  {
    key: 'flagged',
    value: { isFlagged: true },
    iconKey: ButtonIconType.flag,
    Icon: IconmoonFont['flagFilled-16'],
  },
];

export const SONGS_SORT_OPTIONS: SongsSortOption[] = [
  {
    key: SongsSortOptionKeyEnum.topVoted,
    value: { field: 'likesCount', direction: 'desc' },
    Icon: IconmoonFont['heartOutlined-16'],
    type: ButtonIconType.heartOutlined,
  },
  {
    key: SongsSortOptionKeyEnum.newest,
    value: { field: 'createdAt', direction: 'desc' },
    Icon: IconmoonFont['arrowUp-16'],
    type: ButtonIconType.arrowUp,
    isDefault: true,
  },
  {
    key: SongsSortOptionKeyEnum.oldest,
    value: { field: 'createdAt', direction: 'asc' },
    Icon: IconmoonFont['arrowDown-16'],
    type: ButtonIconType.arrowDown,
  },
  {
    key: SongsSortOptionKeyEnum.custom,
    value: null,
    Icon: IconmoonFont['userV2-16'],
    type: ButtonIconType.user,
  },
];

export const FILE_DETAIL_FIELDS: (keyof FileDetails)[] = [
  'fileName',
  'directory',
  'title',
  'artist',
  'fileSize',
  'duration',
  'bitrate',
  'album',
  'genre',
  'comment',
];

export const FILE_PLAY_STATS: {
  Icon: Icon;
  field: keyof FilePlayStats;
}[] = [
  { Icon: SeratoIcon, field: 'seratoPlayCount' },
  { Icon: VirtualDjIcon, field: 'virtualDJPlayCount' },
  { Icon: TraktorIcon, field: 'traktorPlayCount' },
  { Icon: DjayProIcon, field: 'djayProPlayCount' },
  { Icon: RekordboxIcon, field: 'rekordboxPlayCount' },
  { Icon: EnginePrimeIcon, field: 'enginePrimePlayCount' },
];

export const DEBOUNCE_WAIT_MS = {
  SEARCH: 500,
};

export const DEFAULT_PAGINATION: Cursor = {
  skip: 0,
  limit: 50,
};

export const MAX_IMPORT_SPOTIFY_PLAYLIST_SIZE: number = 1000;

export const MAX_QUESTION_LENGTH: number = 1000;

export const VIBO_PROD_LINK: string = 'https://web.vibodj.com';

export const PRIVACY_POLICY_LINK: string =
  'https://web.vibodj.com/privacy-and-legal/privacy-notice-website';

export const EVENT_TIME_FIELDS: {
  key: EventTimeType;
  translationKey: string;
  icon: IconmoonFont | SvgElement;
}[] = [
  {
    key: EventTimeType.arrivalTime,
    translationKey: 'djArrivalTime',
    icon: IconmoonFont['djArrivalTime-24'],
  },
  {
    key: EventTimeType.startTime,
    translationKey: 'startTime',
    icon: IconmoonFont['sandClockStart-24'],
  },
  {
    key: EventTimeType.endTime,
    translationKey: 'endTime',
    icon: IconmoonFont['sandClockEnd-24'],
  },
];

export const MIN_WINDOW_WIDTH = 1260;

export const EVENT_PAGE_MENU_ITEMS = [
  {
    name: 'home',
    route: 'general',
    roles: [EventUserType.dj, EventUserType.host, EventUserType.guest],
  },
  {
    name: 'people',
    route: 'users',
    roles: [EventUserType.dj, EventUserType.host, EventUserType.guest],
  },
  {
    name: 'timelineMusic',
    route: `timeline`,
    roles: [EventUserType.dj, EventUserType.host, EventUserType.guest],
  },
  {
    name: 'prepMode',
    route: 'prepMode',
    roles: [EventUserType.dj],
  },
  {
    name: 'pdf',
    route: 'pdf',
    roles: [EventUserType.dj, EventUserType.host],
  },
];

export const TEMPLATE_PAGE_MENU_ITEMS = [
  {
    name: 'home',
    route: 'general',
  },
  {
    name: 'people',
    route: 'users',
  },
  {
    name: 'timelineMusic',
    route: `timeline`,
  },
];

export const EVENTS_PAGE_MENU_ITEMS = [
  {
    name: 'upcoming',
    route: '/events/upcoming',
  },
  {
    name: 'history',
    route: '/events/history',
  },
];

export const SONG_ITEAS_PAGE_MENU_ITEMS = [
  {
    name: 'myPlaylists',
    route: SONG_IDEAS_KEYS.me,
  },
  {
    name: 'publicPlaylists',
    route: SONG_IDEAS_KEYS.vibo,
  },
];

export const TEMPLATES_PAGE_MENU_ITEMS = [
  {
    name: 'myTemplates',
    route: TEMPLATES_PAGE_KEYS.user,
    type: 'user',
  },
  {
    name: 'publicTemplates',
    route: TEMPLATES_PAGE_KEYS.vibo,
    type: 'vibo',
  },
  {
    name: 'companyTemplates',
    route: TEMPLATES_PAGE_KEYS.company,
    type: 'company',
  },
];

export const ACCOUNT_PAGE_MENU_ITEMS = [
  {
    name: 'profileInfo',
    route: ACCOUNT_PAGE_KEYS.me,
  },
  {
    name: 'myDjs',
    route: ACCOUNT_PAGE_KEYS.account,
  },
];

export const TABLE_SELECT_ID = 'SELECTION';

export const MAX_SONG_LIKES_DISPLAY = 99;

export const MAX_NOTIFICATIONS_DISPLAY_COUNT = 99;

export const SOCIAL_LINKS_STRUCTURE: LinkData[] = [
  {
    icon: InstagramIcon,
    name: AccountPlatform.instagram,
    link: '@your_handle',
  },
  {
    icon: FacebookIcon,
    name: AccountPlatform.facebook,
    link: 'https://www.facebook.com/your_page',
  },
  {
    icon: YoutubeIcon,
    name: AccountPlatform.youtube,
    link: 'https://www.youtube.com/channel/your_channel',
  },
  {
    icon: SpotifyIcon,
    name: AccountPlatform.spotify,
    link: 'https://open.cpotify.com/user/your_profile',
  },
  {
    icon: SoundCloudIcon,
    name: AccountPlatform.soundCloud,
    link: 'https://soundcloud.com/your_page',
  },
  {
    icon: MixCloudIcon,
    name: AccountPlatform.mixcloud,
    link: 'https://www.mixcloud.com/your_page/',
  },
  {
    icon: TikTokIcon,
    name: AccountPlatform.tikTok,
    link: 'https://www.tiktok.com/@your_profile',
  },
];

export const PDF_OPTIONS_TEXT = {
  [PdfSettingOptions.eventInfo]: 'eventInfo',
  [PdfSettingOptions.djNotes]: 'djNotesPrivate',
  [PdfSettingOptions.timelineLayout]: 'timelineLayout',
  [PdfSettingOptions.sectionDetails]: 'sectionDetailsLayout',
  [PdfSettingOptions.playlists]: 'playlists',
};

export const PREP_MODE_SONG_FILES_COUNT = 4;

export const SCREEN_SIZE = {
  lg: 1440,
};

export const VIBO_MARKET_LINKS = {
  ios: 'https://apps.apple.com/ua/app/vibo-2-0/id1523045467?l=uk',
  android: 'https://play.google.com/store/apps/details?id=com.vibodj',
};

export const FORBIDDEN_RESPONSE_OPERATION_NAMES = [
  'getTemplate',
  'getUserSongIdeas',
  'getEvent',
  'cloneTemplate',
  'joinEvent',
  'joinEventViaDeepLink',
  'getMyTemplates',
  'getCompanyTemplates',
  'getViboTemplates',
  'getScannerInfo',
];

export const DJS_LIMIT_USER_EVENT_VIEW = 3;

export const VIBO_FACEBOOK_GROUP_LINK = 'https://www.facebook.com/groups/vibodjs/';
export const VIBO_INSTAGRAM_LINK = 'https://www.instagram.com/vibodj/';

export const CURRENCY_SYMBOLS = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

export const INTERCOM_APP_ID = 'n9xpmj95';

export const PDF_DONT_SHOW_NOTIFICATION_NAME = 'dontNotifyPdfAgain';

export const SPACE_CHAR = ' ';

export const HELP_CENTER_LINK = 'https://help.vibodj.com/en/';

export const BEATSOURCE_AUTH_LINK = `https://api.beatport.com/v4/auth/o/authorize/?client_id=${process.env.REACT_APP_BEATSOURCE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_BEATSOURCE_AUTH_REDIRECT}`;

export const EMOJI_REJEX = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

/* eslint-disable no-useless-escape */
export const YOUTUBE_LINK_REGEX = /[?&]?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
export const LOOM_LINK_REGEX = /[a-f0-9]{32}/;
