import moment from 'moment';

import { getDateWithTimezone } from '../common/dateHelpers';

export const getEventWithTime = (date: string, value: Nullable<string> = '00:00 AM'): string =>
  moment(`${date}, ${value}`).format();

export const getGoogleEventDate = (event: EventItem) => {
  const eventData = getDateWithTimezone(event.date, event.timezone);
  const dateShort = moment(eventData || event.currentTime).format('LL');
  const start = moment(`${dateShort}, ${event.startTime || ''}`).format('YYYYMMDDTHHmmss');
  const end = moment(`${dateShort}, ${event.endTime || event.startTime || ''}`).format(
    'YYYYMMDDTHHmmss'
  );

  return {
    start,
    end,
  };
};

export const createGoogleEvent = (event: EventItem) => {
  const time = getGoogleEventDate(event);
  const eventLink = `https://www.google.com/calendar/render?action=TEMPLATE&sf=true&output=xml&text=${
    event.title
  }&location=${event.location.name || ''}&details=&dates=${time.start}/${time.end}`;

  window.open(eventLink);
};

const calendarUtils = {
  create: createGoogleEvent,
};

export default calendarUtils;
