export enum SectionColorsKeys {
  'empty' = 'empty',
  'blue' = 'blue',
  'violet' = 'violet',
  'rose' = 'rose',
  'indochine' = 'indochine',
  'gold' = 'gold',
  'green' = 'green',
}

export interface ColorfulSectionExtend {
  sectionId: string;
  color: SectionColorsKeys;
}

export interface ColorfulSectionWrapperProps {
  color?: SectionColorsKeys;
}

export interface StyleProps {
  color: SectionColorsKeys;
}
