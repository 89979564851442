import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  section: {
    padding: '8px 0',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxShadow: theme.shadows[3],
    fontWeight: 500,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    cursor: 'pointer',
    width: '100%',

    '& .editableSectionName': {
      ...theme.mixins.longText,
      display: 'block',
    },

    '&:not(.selected), &.headline': {
      '& .sectionTime, & .progressBtn:not(.done), & .sectionTools': {
        color: theme.palette.primary.main,
      },
    },

    '& .sectionExtra': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      color: 'inherit',

      '& .sectionTools, & .iconButton': {
        color: 'inherit',
      },

      '&.withContent': {
        margin: '8px 0 0 0',
        padding: '0 8px 0 10px',

        '@media screen and (min-width: 1441px)': {
          margin: '10px 0 0 0',
          padding: '0 12px 0 14px',
        },
      },
    },

    '&.headline': {
      '& .editableSectionName': {
        fontSize: '17px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '20px',
        },
      },
    },

    '&.dontPlay': {
      '&:not(.selected)': {
        '&:before': {
          backgroundColor: colors.carnation,
        },
      },
    },

    '&:hover': {
      backgroundColor: theme.palette.selection.hover,
    },

    '@media screen and (min-width: 1441px)': {
      padding: '12px 0',
    },
  },

  selected: {
    color: colors.white,
    backgroundColor: theme.palette.primary.main,

    '& .editableSectionName': {
      '&.textPreview, &.viboInput': {
        color: colors.white,

        '&:focus': {
          color: theme.palette.text.primary,
          background: theme.palette.background.paper,
        },
      },
    },

    '&:hover': {
      background: theme.palette.button.active,

      '& .sectionTools': {
        '& .iconButton:hover': {
          backgroundColor: isDark
            ? theme.palette.selection.menuItemSelectedBg
            : theme.palette.primary.light,
        },
      },

      '&:after': {
        borderLeftColor: theme.palette.button.active,
      },

      '& .text': {
        color: theme.palette.background.paper,
      },

      '& .editableSectionName': {
        '& > input': {
          background: theme.palette.background.paper,
        },
      },
    },

    '&.headline': {
      '&.section': {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.selection.hover,
      },
    },

    '&:not(.headline)': {
      '&:not(.prepModeSection):not(.beatsourceSection)': {
        '&:hover': {
          '& .editableSectionName:hover:not(:focus)': {
            background: theme.palette.primary.main,
          },
        },
      },

      '&:after': {
        ...theme.mixins.selectedSectionCaret,
        borderLeftColor: theme.palette.primary.main,
      },
    },

    '&:not(.headline):not(.prepModeSection):not(.dontPlay)': {
      '& .placeholder': {
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },

  isDragging: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.light} !important`,
  },

  sectionTop: {
    padding: '0 8px 0 10px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '18.2px',

    '& svg': {
      width: '16px',
      height: '16px',
    },

    '@media screen and (min-width: 1441px)': {
      padding: '0 12px 0 14px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  middle: {
    margin: '8px 0 0 0',
    padding: '0 8px 0 10px',
    display: 'flex',
    alignItems: 'center',

    '@media screen and (min-width: 1441px)': {
      margin: '10px 0 0 0',
      padding: '0 12px 0 14px',
    },
  },

  bottom: {
    margin: '8px 0 0 0',
    padding: '0 8px 0 10px',
    display: 'flex',
    alignItems: 'center',

    '& > div:first-child': {
      minWidth: '20px',
      display: 'flex',
    },

    '@media screen and (min-width: 1441px)': {
      margin: '10px 0 0 0',
      padding: '0 12px 0 14px',
    },
  },
}));

export default useStyles;
