import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import Container from '../../../components/common/Container';
import LoadingOverlay from 'components/common/LoadingOverlay';
import TemplateInvite from '../../../components/template/TemplateInvite';
import TemplateGeneral from '../../../components/template/TemplateGeneral';
import TemplateTimeline from '../../../components/template/TemplateTimeline';
import TemplatePageHeader from '../../../components/headers/TemplatePageHeader';
import { EditableContext } from '../../../components/common/EditableContext';
import { EventContextProvider } from '../../../components/context/EventContext';

import { GET_TEMPLATE } from '../../../graphql/queries/template';

import { EVENT_PAGE_TAB_KEYS } from '../../../services/constants';

import { Page } from '../../../types/enums';

import useStyles from './style';

const Template: FC = () => {
  const classes = useStyles();

  const { params } = useRouteMatch<{ sectionId: string; id: string; page: string }>();

  const { data, loading } = useQuery<GetTemplateResponse>(GET_TEMPLATE, {
    variables: { templateId: params.id },
    fetchPolicy: 'cache-and-network',
  });
  const template: Maybe<Template> = data?.getTemplate;

  const pageStructure = useMemo(
    () => ({
      [EVENT_PAGE_TAB_KEYS.general]: template ? <TemplateGeneral template={template} /> : null,
      [EVENT_PAGE_TAB_KEYS.users]: template ? <TemplateInvite template={template} /> : null,
      [EVENT_PAGE_TAB_KEYS.timeline]: template ? <TemplateTimeline template={template} /> : null,
    }),
    [template, params]
  );

  return (
    <EventContextProvider
      value={{ isLoading: loading, event: (template as unknown) as EventItem, page: Page.template }}
    >
      <EditableContext.Provider value={{ canEdit: template?.canEdit || false }}>
        <div className={classes.templatePage}>
          <TemplatePageHeader />
          <Container key={template?._id}>
            {loading && !template ? <LoadingOverlay /> : pageStructure[params.page]}
          </Container>
        </div>
      </EditableContext.Provider>
    </EventContextProvider>
  );
};

export default Template;
