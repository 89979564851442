import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sectionBlocksHeader: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 14px 0',
    padding: '3px 12px',
    backgroundColor: theme.palette.background.contentArea,

    '@media screen and (min-width: 1441px)': {
      padding: '3px 16px',
    },
  },

  settingsIcon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },
  },

  blocksHeaderTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    '& .viboButton': {
      padding: '0',
    },
  },

  sectionName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.text.primary,
    margin: '0',
    fontSize: '17px',
    lineHeight: '24px',
    height: '24px',

    '@media screen and (min-width: 1080px)': {
      maxWidth: '500px',
    },

    '@media screen and (min-width: 1280px)': {
      maxWidth: '580px',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '20px',
      height: '30px',
      maxWidth: '750px',
    },

    '@media screen and (min-width: 1600px)': {
      maxWidth: '800px',
    },
  },
}));

export default useStyles;
