import React, { forwardRef, MutableRefObject, PropsWithChildren } from 'react';
import RcDropdown from 'rc-dropdown';
import { useEnsuredForwardedRef } from 'react-use';
import { DropdownProps as RcDropdownProps } from 'rc-dropdown/lib/Dropdown';

import Toggle from './Toggle';
import OverlayWrapper from './OverlayWrapper';

import { DropdownProps } from './interfaces';

const Dropdown = forwardRef<RcDropdownProps, PropsWithChildren<DropdownProps>>(
  (
    {
      overlay,
      disableToggleClass,
      toggleClassName,
      overlayWrapperClassName,
      disableOverlayClass,
      children,
      ...rest
    },
    ref
  ) => {
    const inputRef = useEnsuredForwardedRef(ref as MutableRefObject<RcDropdownProps>);

    const overlayWrapperProps = {
      overlay,
      overlayWrapperClassName,
      disableOverlayClass,
    };

    const toggleProps = {
      children,
      toggleClassName,
      disableToggleClass,
    };

    return (
      <RcDropdown overlay={<OverlayWrapper {...overlayWrapperProps} />} ref={inputRef} {...rest}>
        <Toggle {...toggleProps} />
      </RcDropdown>
    );
  }
);

export default Dropdown;
