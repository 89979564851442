import React, { FC, createContext } from 'react';
import { useQuery } from 'react-apollo';

import { GET_ME } from 'graphql/queries/user';
import { isLoggedIn } from 'graphql/queries/auth';

type TUserContext = {
  user: Maybe<User>;
  refetchUser: Function;
  isLoggedIn: boolean;
};

export const UserContext = createContext<TUserContext>(undefined!);

export const UserContextProvider: FC = ({ children }) => {
  const { data: loginData, refetch } = useQuery(isLoggedIn, {});
  const { data } = useQuery<UserResponse>(GET_ME, {
    skip: !loginData?.isLoggedIn,
    fetchPolicy: 'cache-and-network',
  });

  const user: Maybe<User> = data?.me;

  return (
    <UserContext.Provider
      value={{
        user,
        refetchUser: refetch,
        isLoggedIn: loginData?.isLoggedIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
