import React, { FC, useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import Input from 'vibo-ui/Input';
import { UserPolicyFields } from '../../../../context/PolicyContext';
import { useFieldPolicy } from '../../../../../graphql/hooks/policy';

import { NickNameProps } from './interfaces';

const NickName: FC<NickNameProps> = ({ handleUpdateProfile, user }) => {
  const { t } = useTranslation();

  const [nick, setNick] = useState(user?.nick);

  const { isEditable } = useFieldPolicy(UserPolicyFields.nick);

  const updateDebounced = useRef(
    debounce((value: string) => {
      handleUpdateProfile({
        nick: value,
        showName: (value?.length ? user?.showName : false) ?? false,
      });
    }, 1000)
  ).current;

  const updateNick = (value: string) => {
    setNick(value);
    updateDebounced(value);
  };

  useEffect(() => {
    user?.nick && setNick(user.nick);
  }, [user?.nick]);

  return (
    <Input
      onChange={(_, value) => updateNick(value)}
      defaultValue={nick}
      placeholder={t('addyourDjNickname')}
      disabled={!isEditable}
      className="bold"
      size="xlg"
      withUnderline
    />
  );
};

export default NickName;
