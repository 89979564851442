import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  eventImages: {
    display: 'flex',
    position: 'relative',
  },

  imgSquare: {
    width: '288px',
    height: '288px',
    cursor: 'pointer',

    '@media screen and (min-width: 1441px) ': {
      width: '384px',
      height: '384px',
    },
  },

  placeholder: {
    '& svg': {
      width: '56px',
      height: '56px',

      '& path': {
        fill: theme.palette.primary.contrastText,
      },
    },
  },
}));

export default useStyles;
