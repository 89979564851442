import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  timelineLayout: {
    background: theme.palette.background.default,
    height: 'calc(100% - 60px)',

    '& .container': {
      height: '100%',

      '& .containerContent': {
        height: '100%',
      },
    },

    '@media screen and (min-width: 1441px)': {
      height: 'calc(100% - 90px)',
    },
  },

  content: {
    padding: '0 0 10px 0',
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: '100%',
  },

  left: {
    position: 'relative',
    height: '100%',
    marginRight: '56px',
    width: '100%',
    maxWidth: '288px',
    minWidth: '288px',

    '@media screen and (min-width: 1441px) ': {
      marginRight: '72px',
      maxWidth: '384px',
      minWidth: '384px',
    },

    '@media screen and (min-width: 1441px) and (max-width: 1536px)': {
      marginRight: '30px',
    },
  },

  right: {
    height: '100%',
    width: '100%',
    position: 'relative',
    maxWidth: '537px',
    display: 'flex',
    flexDirection: 'column',

    '& .sectionBlocksHeader': {
      margin: '0 0 24px 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 28px 0',
      },
    },

    '& .sectionBlock': {
      '&:not(:last-child)': {
        margin: '0 0 16px 0',
      },
    },

    '& > .viboScrollbar': {
      height: 'calc(100% - 60px)',

      '& .scrollView': {
        marginLeft: '-20px',
        paddingLeft: '20px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      maxWidth: 'none',
    },
  },
}));

export default useStyles;
