import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  whiteSpace: {
    whiteSpace: 'nowrap',
  },

  dot: {
    width: '8px',
    height: '8px',
    margin: '0 4px 0 0',
    borderRadius: '50%',
  },

  titleColumn: {
    fontSize: '13px',
    lineHeight: '15.23px',
    color: theme.palette.primary.main,

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '18.75px',
    },
  },

  djsDropdown: {
    '& .checkMarkWrapper': {
      padding: '3px 6px 3px 4px',

      '& .iconWrapper': {
        padding: '0 0 0 4px',
      },
    },

    '& .viboImage': {
      width: '16px',
      height: '16px',
      maxHeight: '16px',
    },

    '& .content': {
      margin: '0 0 0 4px',
    },

    '& .djName, & .checkMarkWrapper': {
      color: theme.palette.text.secondary,
      fontWeight: 500,
    },
  },

  newStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 8px 8px 12px',

    '& .info': {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 0 8px',

      '& .djsPreview .djPreview': {
        margin: '0 6px 0 0',

        '&:not(:last-child)': {
          '& .content': {
            display: 'none',
          },
        },

        '& .djName': {
          maxWidth: '150px',
          fontSize: '13px',

          '@media screen and (min-width: 1441px)': {
            fontSize: '16px',
          },
        },
      },

      '& .djPreview': {
        '& .djName': {
          lineHeight: '18px',
          margin: '-2px 0',
          padding: '2px 0',
        },
      },

      '& .eventName, & .location,': {
        '& > span': {
          ...theme.mixins.longText,
          maxWidth: '240px',
          lineHeight: '18px',
          margin: '-4px 0',
          padding: '2px 0',

          '@media screen and (min-width: 1441px)': {
            maxWidth: '400px',
          },
        },
      },

      '& .location': {
        '& > span': {
          fontSize: '13px',

          '@media screen and (min-width: 1441px)': {
            fontSize: '16px',
          },
        },
      },

      '& .viboIcon': {
        color: theme.palette.text.primary,
        minWidth: '14px',
        width: '14px',
        height: '14px',
        margin: '0 8px 0 0',

        '@media screen and (min-width: 1441px)': {
          minWidth: '16px',
          width: '16px',
          height: '16px',
          margin: '0 6px 0 0',
        },
      },

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 12px',
      },
    },

    '& > div:not(.viboImage):not(.status)': {
      height: 'auto',
    },

    '& .gradientCover': {
      position: 'relative',
      width: '56px',
      height: '56px',

      '@media screen and (min-width: 1441px)': {
        width: '80px',
        height: '80px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '8px 8px 8px 16px',
    },
  },

  djLogo: {
    width: '16px!important',
    height: '16px!important',
    borderRadius: '50%!important',

    '& .gradientCover, & .gradient': {
      width: '100%',
      height: '100%',

      '& svg': {
        color: theme.palette.background.paper,
        width: '8px',
        height: '8px',
        margin: 'auto',
        transform: 'translateY(-.5px)',

        '@media screen and (min-width: 1441px)': {
          width: '10px',
          height: '10px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '16px',
      height: '16px',
      maxHeight: '16px',
    },

    '& div, img': {
      borderRadius: '50%',
    },
  },

  users: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',

    '& .djPreview': {
      margin: '0',

      '& svg': {
        color: theme.palette.background.paper,
        minWidth: '10px',
        width: '10px',
        height: '10px',
      },

      '& .viboImage': {
        width: '14px',
        height: '14px',
        minHeight: '14px',
        minWidth: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
          minHeight: '16px',
          minWidth: '16px',
        },
      },
    },

    '&.hideNames': {
      '& .content': {
        display: 'none',
      },
    },

    '& .content ': {
      ...theme.mixins.longText,
      maxWidth: '150px',
      margin: '0 0 0 6px',

      '& .djName': {
        color: theme.palette.text.secondary,
      },

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 8px',
      },
    },
  },

  progressBars: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '144px',

    '& .eventProgress': {
      width: '100%',

      '&:not(:last-child)': {
        margin: '0 0 10px 0',
      },

      '& .progressBarLabel': {
        fontWeight: 500,
        color: theme.palette.text.primary,
      },

      '& .progressBarInfo': {
        color: theme.palette.text.primary,
      },

      '& .progressBarTop': {
        fontSize: '11px',
        lineHeight: '15.4px',
        margin: '0 0 2px 0',

        '@media screen and (min-width: 1441px)': {
          fontSize: '14px',
          lineHeight: '19.6px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      maxWidth: '190px',
    },
  },

  dj: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .djName': {
      ...theme.mixins.longText,
      maxWidth: '150px',
      margin: '0 0 0 6px',
      color: theme.palette.text.secondary,

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 8px',
      },
    },
  },

  date: {
    fontSize: '13px',
    lineHeight: '15.23px',

    '& .date': {
      fontWeight: 500,
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '18.75px',
    },
  },

  eventName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0 0 0',
    fontWeight: 500,
    fontSize: '15px',
    color: theme.palette.text.primary,

    '& svg path': {
      fill: theme.palette.text.primary,
    },

    '& *': {
      color: theme.palette.text.primary,
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '18px',
      maxWidth: '540px',
    },
  },

  selectIcon: {
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },

  eventImage: {
    width: '72px!important',
    height: '72px!important',

    '@media screen and (min-width: 1441px)': {
      width: '92px!important',
      height: '92px!important',
    },
  },

  eventTitleIcon: {
    '& svg path': {
      fill: theme.palette.primary.contrastText,
    },
  },

  sortable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',

    '& .viboIcon': {
      margin: '0 0 0 2px',
    },

    '&.activeSort': {
      fontWeight: 500,
      width: 'fit-content',
      position: 'relative',

      '& > *': {
        zIndex: 2,
      },

      '&:before': {
        padding: '3px 6px',
        borderRadius: '4px',
        zIndex: 1,
        content: "''",
        position: 'absolute',
        backgroundColor: theme.palette.background.photo,
        width: '100%',
        height: '100%',
        left: '-6px',
        top: '-4px',
      },
    },
  },

  statusCircle: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    border: `1px solid ${isDark ? colors.white : 'transparent'}`,
  },

  eventStatusBg: {
    '&.active': {
      backgroundColor: theme.palette.success.main,
    },

    '&.pending': {
      backgroundColor: theme.palette.info.main,
    },

    '&.locked': {
      backgroundColor: theme.palette.error.main,
    },

    '&.past': {
      backgroundColor: colors.black,
    },

    '&.deleted': {
      backgroundColor: theme.palette.text.hint,
    },
  },

  status: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 12px 0 0',
    width: '14px',
    height: '14px',

    '@media screen and (min-width: 1441px)': {
      height: '16px',
      width: '16px',
      margin: '0 16px 0 0',
    },
  },

  statusName: {
    margin: '0 20px 0 0',
  },

  nameWithLocation: {
    width: 'auto',
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
    paddingBottom: '3px',
  },

  location: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    margin: '12px 0 0',

    '& *': {
      color: theme.palette.text.secondary,
    },
  },

  eventTypeIcon: {
    display: 'block',
    width: '16px',
    height: '16px',
    marginRight: '4px',

    '@media screen and (min-width: 1441px)': {
      marginRight: '8px',
    },
  },

  eventType: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& div, & svg': {
      color: `${theme.palette.text.secondary}!important`,
    },

    '& > div': {
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '25px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '14px',
        lineHeight: '25px',
      },
    },
  },
}));

export default useStyles;
