import React, { FC, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Tooltip from 'vibo-ui/Tooltip';
import Dropdown from 'vibo-ui/Dropdown';
import TimePicker from 'vibo-ui/TimePicker';
import TimePreviewGuest from './TimePreviewGuest';
import WithEventRole from 'components/user/WithEventRole';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { EventUserType } from 'types/enums';
import { SectionTimeProps } from './interfaces';

import useStyles from './style';

const SectionTime: FC<SectionTimeProps> = ({ onTimeChange, disabled = false, time = '' }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [opened, setOpened] = useState<boolean>(false);

  const timePreview = time
    ?.split(' ')
    .map(sectionTimePart => <div key={sectionTimePart}>{sectionTimePart}</div>);

  const handleToggleOpen = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const handleTimeChange = (time: string) => {
    onTimeChange?.(time);

    if (time) {
      setOpened(false);
    }
  };

  return (
    <>
      {!!time ? (
        <WithEventRole roles={[EventUserType.guest]}>
          <TimePreviewGuest time={time} />
        </WithEventRole>
      ) : null}
      <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
        <Tooltip
          overlay={opened ? '' : t(time ? 'changeTime' : 'addTime')}
          type="primary"
          key={`select-section-time-tooltip-${time}-${opened}`}
          destroyTooltipOnHide
        >
          <div className={classNames('sectionTime', classes.sectionTime)}>
            <Icon icon={IconmoonFont['clock-16']} className={classes.timeIcon} />
            <Dropdown
              overlayClassName={classes.selectSectionTimeDropdown}
              overlay={
                <TimePicker
                  value={time as string}
                  onChangeTime={time => {
                    handleTimeChange(time);
                    setOpened(false);
                  }}
                  autoFocus
                />
              }
              onVisibleChange={isVisible => {
                if (!isVisible) {
                  setOpened(false);
                }
              }}
              align={{
                offset: [0, -28],
              }}
              visible={opened}
              trigger="click"
              key={`select-section-time-dropdown-${time}-${opened}`}
              disableToggleClass
            >
              <Button
                onClick={handleToggleOpen}
                disabled={disabled}
                displayType="link"
                data-gtm-target-id="timeline-section-time"
              >
                {!!time ? timePreview : t('addTime')}
              </Button>
            </Dropdown>
          </div>
        </Tooltip>
      </WithEventRole>
    </>
  );
};

export default SectionTime;
