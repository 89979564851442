import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PdfSectionToggler from './PdfSectionToggler';

import { PdfSettingsLayout, SectionBlockName } from '../../../../types/enums';
import { SongsTogglerProps } from '../interfaces';

import useStyles from './style';
import classNames from 'classnames';

const SongsToggler: FC<SongsTogglerProps> = ({ section, songsCount, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return rest.layout === PdfSettingsLayout.playlists ? (
    !section || !songsCount ? null : (
      <div className={classNames('songsCount', classes.songsCount)}>{`${songsCount} ${t('song', {
        count: songsCount,
      })}`}</div>
    )
  ) : (
    <PdfSectionToggler
      name={SectionBlockName.songs}
      content={
        !section || section?.songsEnabled ? (
          <div className="toggle-content">{!section ? t('songs') : songsCount}</div>
        ) : null
      }
      {...rest}
    />
  );
};

export default SongsToggler;
