import React, { FC, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Tooltip from 'vibo-ui/Tooltip';
import WithEventRole from 'components/user/WithEventRole';
import { EditableContext } from 'components/common/EditableContext';
import { TimelineQuestionsContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { useModal } from 'vibo-ui/Modal';

import { EventUserType, Modals } from 'types/enums';
import { CreateQuestionModalProps } from 'components/modals/CreateQuestionModal';
import { AddTimelineSectionButtonProps } from './interfaces';

const AddTimelineQuestionButton: FC<AddTimelineSectionButtonProps> = ({
  questionIdAfter,
  children,
  hideTooltip,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();

  const timelineQuestionsContext = useContext(TimelineQuestionsContext);
  const { canEdit } = useContext(EditableContext);

  const { openModal } = useModal();

  const openCreateQuestionModal = useCallback(
    () =>
      openModal<CreateQuestionModalProps>({
        key: Modals.createQuestion,
        props: {
          timelineQuestionsContext,
          questionIdAfter,
        },
      }),
    [timelineQuestionsContext, questionIdAfter]
  );

  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      openCreateQuestionModal();
    },
    [questionIdAfter]
  );

  return canEdit ? (
    <WithEventRole roles={[EventUserType.dj]}>
      <Tooltip
        overlay={hideTooltip ? null : t('addQuestion')}
        align={{
          offset: [-6, 0],
        }}
        placement="left"
        type="primary"
        fillOverlay
      >
        <Button onClick={handleClick} {...rest}>
          {children}
        </Button>
      </Tooltip>
    </WithEventRole>
  ) : null;
};

export default AddTimelineQuestionButton;
