import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Textarea from 'vibo-ui/Textarea';
import Scrollbar from 'vibo-ui/Scrollbar';
import ContentArea from 'components/common/ContentArea';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { useFormikAutoSave } from 'graphql/hooks/form';
import { GET_TEMPLATE } from 'graphql/queries/template';
import { UPDATE_TEMPLATE } from 'graphql/mutations/template';

import { onError } from 'graphql/helpers';
import { toastNotify } from 'graphql/hooks/common';
import { getInitialUpdateTemplateValues } from 'services/template/helpers';

import { updateTemplateValidationSchema } from './constants';

import { TemplateInviteProps } from './interfaces';

import useStyles from './style';

const TemplateInvite: FC<TemplateInviteProps> = ({ template }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { title } = template;

  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onCompleted: () => {
      toastNotify({ text: t('changesSavedSuccessfully') });
    },
    onError,
    refetchQueries: [
      {
        query: GET_TEMPLATE,
        variables: {
          templateId: template?._id,
        },
      },
    ],
  });

  const handleUpdateTemplate = (payload: UpdateTemplateForm | UpdateTemplateImage) =>
    updateTemplate({
      variables: { templateId: template?._id, payload },
    });

  const { formik } = useFormikAutoSave<UpdateTemplateForm>(
    {
      initialValues: getInitialUpdateTemplateValues(template as Template),
      onSubmit: values => handleUpdateTemplate(values),
      validationSchema: updateTemplateValidationSchema,
    },
    1000
  );

  return (
    <div className={classes.templateInvite}>
      <PageContentHeader>
        <EventTitle name={title} />
      </PageContentHeader>
      <Scrollbar shiftThumb>
        <ContentArea className={classes.invitation} shadowed>
          <label>
            <Icon icon={IconmoonFont['personalComputer-16']} />
            <span>{t('emailInvitationToHosts')}</span>
          </label>
          <Textarea
            onChange={(_, value) => {
              formik.setFieldValue('emailMessage.host', value);
            }}
            value={formik.values?.emailMessage?.host}
            disabled={!template.canEdit}
            placeholder={t('personalizeEmailMessageToHosts')}
            maxRows={4}
            withUnderline
          />
        </ContentArea>
        <ContentArea className={classes.invitation} shadowed>
          <label>
            <Icon icon={IconmoonFont['personalComputer-16']} />
            <span>{t('emailInvitationToGuests')}</span>
          </label>
          <Textarea
            onChange={(_, value) => {
              formik.setFieldValue('emailMessage.guest', value);
            }}
            value={formik.values?.emailMessage?.guest}
            disabled={!template.canEdit}
            placeholder={t('personalizeEmailMessageToGuests')}
            maxRows={4}
            withUnderline
          />
        </ContentArea>
        <ContentArea className={classes.invitation} shadowed>
          <label>
            <Icon icon={IconmoonFont['mobile-16']} />
            <span>{t('textInvitationToHosts')}</span>
          </label>
          <Textarea
            onChange={(_, value) => {
              formik.setFieldValue('textMessage.host', value);
            }}
            value={formik.values.textMessage?.host}
            disabled={!template.canEdit}
            placeholder={t('personalizeTextMessageToHosts')}
            maxRows={4}
            withUnderline
          />
        </ContentArea>
        <ContentArea className={classes.invitation} shadowed>
          <label>
            <Icon icon={IconmoonFont['mobile-16']} />
            <span>{t('textInvitationToGuests')}</span>
          </label>
          <Textarea
            onChange={e => {
              e.persist();
              formik.setFieldValue('textMessage.guest', e.target.value);
            }}
            value={formik.values?.textMessage?.guest}
            disabled={!template.canEdit}
            placeholder={t('personalizeTextMessageToGuests')}
            maxRows={4}
            withUnderline
          />
        </ContentArea>
      </Scrollbar>
    </div>
  );
};

export default TemplateInvite;
