import { gql } from 'graphql.macro';

import {
  EVENT_DETAILS,
  EVENT_TITLE_AND_NOTES,
  EVENTS_LIST_ITEM,
  GUEST_DEEPLINK,
  HOST_DEEPLINK,
  EVENT_FILE_OPTIONS,
  EVENT_CONTACT,
  AVAILABLE_EVENT_DJ,
} from '../fragments/events';
import { SONG_IDEAS_RESPONSE } from '../fragments/songIdeas';
import { THUMBNAILS } from '../fragments/songs';

export const GET_USER_EVENT_TYPE = gql`
  query getEventTypes {
    eventTypes {
      _id
      title
    }
  }
`;

export const GET_EVENTS_TEMPLATES = gql`
  query getTemplates($typeId: ID) {
    templates(filter: { typeId: $typeId }) {
      _id
      title
      type {
        _id
        title
      }
    }
  }
`;

export const GET_EVENT = gql`
  query getEvent($eventId: ID!) {
    event(eventId: $eventId) {
      ...EventDetailsFragment
    }
  }
  ${EVENT_DETAILS}
`;

export const GET_TITLE_AND_NOTES = gql`
  query getEvent($eventId: ID!) {
    event(eventId: $eventId) {
      ...GetTitleAndNotesFromEvent
    }
  }
  ${EVENT_TITLE_AND_NOTES}
`;

export const HOST_DEEP_LINK = gql`
  query getHost($eventId: ID!) {
    event(eventId: $eventId) {
      ...HostDeepLinkFragment
    }
  }
  ${HOST_DEEPLINK}
`;

export const GUEST_DEEP_LINK = gql`
  query getGuest($eventId: ID!) {
    event(eventId: $eventId) {
      ...GuestDeepLinkFragment
    }
  }
  ${GUEST_DEEPLINK}
`;

export const CREATE_EVENT = gql`
  mutation createEvent($payload: CreateEventInput!) {
    createEvent(payload: $payload) {
      _id
    }
  }
`;

export const GET_EVENT_USERS = gql`
  query eventContacts($eventId: ID!, $usersType: EventUserType, $pagination: PaginationInput) {
    eventUsers(eventId: $eventId, usersType: $usersType, pagination: $pagination) {
      users {
        _id
        firstName
        lastName
        email
        phoneCode
        phoneNumber
        imageUrl
        logoUrl
        image {
          cropped {
            small
            medium
          }
        }
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
`;

export const GET_UPCOMING_EVENTS = gql`
  query getUpcomingEvents(
    $filter: EventsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    upcomingEvents(filter: $filter, pagination: $pagination, sort: $sort) {
      events {
        ...EventItemFragment
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
  ${EVENTS_LIST_ITEM}
`;

export const CALEDAR_VIEW_EVENTS = gql`
  query calendarViewEvents($filter: CalendarViewEventsFilterInput) {
    calendarViewEvents(filter: $filter) {
      ...EventItemFragment
    }
  }
  ${EVENTS_LIST_ITEM}
`;

export const GET_UPCOMING_EVENTS_ASSIGN_PLAYLIST = gql`
  query upcomingEventsWhereDj(
    $filter: EventsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    upcomingEventsWhereDj(filter: $filter, pagination: $pagination, sort: $sort) {
      events {
        _id
        image {
          squareUrl
          rectUrl
          cropped {
            small
            medium
          }
        }
        title
        status
        date
        type
        timezone
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
`;

export const GET_HISTORY_EVENTS = gql`
  query getHistoryEvents(
    $filter: EventsFilterInput
    $pagination: PaginationInput
    $sort: SortInput
  ) {
    historyEvents(filter: $filter, pagination: $pagination, sort: $sort) {
      events {
        ...EventItemFragment
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
  ${EVENTS_LIST_ITEM}
`;

export const GET_HISTORY_COUNT_EVENTS = gql`
  query getCountHistoryEvents {
    historyEvents(
      filter: { q: null, isDeleted: null, past: null }
      pagination: { limit: 50, skip: 0 }
    ) {
      totalCount
    }
  }
`;

export const GET_EVENTS_STATS = gql`
  query getEventsStats($filter: EventsFilterInput) {
    upcomingEvents(filter: $filter, pagination: { limit: 0, skip: 0 }) {
      totalCount
    }

    historyEvents(filter: $filter, pagination: { limit: 0, skip: 0 }) {
      totalCount
    }
  }
`;

export const SECTIONS = gql`
  query sections($eventId: ID!, $filter: SectionsFilterInput) {
    sections(eventId: $eventId, filter: $filter) {
      _id
      name
      time
      note
      description
      type
      wasSongsReordered
      hasNote
      hasComments
      hasSongs
      hasQuestions
      questionsCount
      answeredCount
      songsInfo
      songsCount
      blocks {
        name
        isExpanded
      }
      visibility
      settings {
        songsLimit
        mustPlayLimit
        canHostsOrderSongs
        canHostDeleteSection
        canHostChangeSectionName
        canHostChangeSectionTime
        visibleForGuests
        visibleForHosts
        songsEnabled
        questionsEnabled
        notesEnabled
        timeEnabled
        notesVisibleForHosts
      }
      songIdeasCount
      coverSong {
        artist
        title
        thumbnails {
          ...Thumbnails
        }
      }
    }
  }
  ${THUMBNAILS}
`;

export const SECTIONS_ASSIGN_PLAYLIST = gql`
  query sections($eventId: ID!, $filter: SectionsFilterInput) {
    sections(eventId: $eventId, filter: $filter) {
      _id
      name
      type
      visibility
      songIdeas
    }
  }
`;

export const GET_EVENT_SECTION_USER_SONG_IDEAS = gql`
  query getEventSectionUserSongIdeas(
    $eventId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getEventSectionUserSongIdeas(
      eventId: $eventId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_EVENT_SECTION_VIBO_SONG_IDEAS = gql`
  query getEventSectionViboSongIdeas(
    $eventId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getEventSectionViboSongIdeas(
      eventId: $eventId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_EVENT_SECTION_COMPANY_SONG_IDEAS = gql`
  query getEventSectionCompanySongIdeas(
    $eventId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getEventSectionCompanySongIdeas(
      eventId: $eventId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_EVENT_SECTION_SONG_IDEAS = gql`
  query getEventSectionSongIdeas(
    $eventId: ID!
    $sectionId: ID!
    $pagination: PaginationInput
    $filter: SectionSongIdeasFilter
  ) {
    getEventSectionSongIdeas(
      eventId: $eventId
      sectionId: $sectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_EVENT_SECTION_QUESTIONS_STATS = gql`
  query getEventSectionQuestionsStats($eventId: ID!, $sectionId: ID!) {
    getEventSectionQuestionsStats(eventId: $eventId, sectionId: $sectionId) {
      totalCount
      answeredCount
    }
  }
`;

export const GET_EVENT_DEEP_LINK_PREVIEW = gql`
  query getEventDeepLinkPreview($eventId: ID!) {
    getEventDeepLinkPreview(eventId: $eventId) {
      title
      image {
        squareUrl
        cropped {
          medium
          small
        }
      }
      djs {
        logoUrl
        imageUrl
      }
    }
  }
`;

export const GET_CREATE_EVENT_FILE_OPTIONS_WEB = gql`
  query getCreateEventFileOptionsWeb($eventId: ID!) {
    getCreateEventFileOptionsWeb(eventId: $eventId) {
      dj {
        ...EventFileOptionsWebFragment
      }
      __typename
    }
  }
  ${EVENT_FILE_OPTIONS}
`;

export const GET_EVENT_PREVIEW_VIA_LINK = gql`
  query getEvenPreviewViaLink($deepLink: String!) {
    getEvenPreviewViaLink(deepLink: $deepLink) {
      _id
      title
      image {
        squareUrl
        rectUrl
      }
    }
  }
`;

export const GET_EVENT_CONTACTS = gql`
  query eventContacts($eventId: ID!, $filter: EventContactsFilter, $pagination: PaginationInput) {
    eventContacts(eventId: $eventId, filter: $filter, pagination: $pagination) {
      contacts {
        ...EventContact
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
  ${EVENT_CONTACT}
`;

export const GET_AVAILABLE_EVENT_DJS = gql`
  query getAvailableEventDjs($eventId: ID!, $filter: AvailableEventDjFilter) {
    getAvailableEventDjs(eventId: $eventId, filter: $filter) {
      ...AvailableEventDj
    }
  }
  ${AVAILABLE_EVENT_DJ}
`;

export const GET_FAVORITE_SECTION_USER_SONG_IDEAS = gql`
  query getFavoriteSectionUserSongIdeas(
    $favoriteSectionId: ID!
    $filter: SectionSongIdeasFilter
    $pagination: PaginationInput
  ) {
    getFavoriteSectionUserSongIdeas(
      favoriteSectionId: $favoriteSectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_FAVORITE_SECTION_VIBO_SONG_IDEAS = gql`
  query getFavoriteSectionViboSongIdeas(
    $favoriteSectionId: ID!
    $filter: SectionSongIdeasFilter
    $pagination: PaginationInput
  ) {
    getFavoriteSectionViboSongIdeas(
      favoriteSectionId: $favoriteSectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_FAVORITE_SECTION_COMPANY_SONG_IDEAS = gql`
  query getFavoriteSectionCompanySongIdeas(
    $favoriteSectionId: ID!
    $filter: SectionSongIdeasFilter
    $pagination: PaginationInput
  ) {
    getFavoriteSectionCompanySongIdeas(
      favoriteSectionId: $favoriteSectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;

export const GET_FAVORITE_SECTION_SONG_IDEAS = gql`
  query getFavoriteSectionSongIdeas(
    $favoriteSectionId: ID!
    $filter: SectionSongIdeasFilter
    $pagination: PaginationInput
  ) {
    getFavoriteSectionSongIdeas(
      favoriteSectionId: $favoriteSectionId
      pagination: $pagination
      filter: $filter
    ) {
      ...SongIdeasResponseFragment
    }
  }
  ${SONG_IDEAS_RESPONSE}
`;
