import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  viboIcon: {
    '&:not(.disableAutoFontSize)': {
      '&[class*="36 "]': {
        fontSize: '32px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '36px',
        },
      },

      '&[class*="24 "]': {
        fontSize: '20px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '24px',
        },
      },

      '&[class*="16 "]:not(&[class*="160 "])': {
        fontSize: '14px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
        },
      },

      '&[class*="160 "]': {
        fontSize: '156px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '160px',
        },
      },
    },
  },

  viboIconSVG: {
    display: 'inline-block',

    '& > svg': {
      width: '100%',
      height: '100%',
      display: 'block',
    },

    '&:not(.disableAutoFontSize)': {
      width: '20px',

      '@media screen and (min-width: 1441px)': {
        width: '24px',
      },
    },
  },
});

export default useStyles;
