import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import Icon from 'vibo-ui/Icon';
import Menu from 'vibo-ui/Menu';
import Dropdown from 'vibo-ui/Dropdown';
import ClickAwayListener from 'vibo-ui/ClickAwayListener';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import Input, { InputProps } from 'vibo-ui/Input';

import { ReactComponent as GoogleLogoIcon } from 'resources/img/svg/googleLogo.svg';

import { LocationProps } from './interfaces';

import useStyles from './style';

const Location: FC<LocationProps> = ({ placeholder, locationValue, onLocationChange, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [name, setName] = useState<string>(locationValue?.name || '');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSelect = (address: string) => {
    setName(address);

    return geocodeByAddress(address).then(results =>
      getLatLng(results[0]).then(latLng =>
        onLocationChange({
          ...latLng,
          name: address,
        })
      )
    );
  };

  const handleChange = (name: string) => {
    setName(name);

    !name && onLocationChange({ name });
  };

  useEffect(() => {
    setName(locationValue?.name ?? '');
  }, []);

  return (
    <PlacesAutocomplete value={name} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = {
          ...getInputProps({
            ...rest,
            placeholder: placeholder || t('search'),
          }),
        };

        return (
          <>
            <Dropdown
              trigger="click"
              overlay={
                !!suggestions.length ? (
                  <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <Menu
                      activeKey={`${locationValue?.name}`}
                      className={classes.suggestionsMenu}
                      selectable={false}
                    >
                      {suggestions.map(suggestion => {
                        const divProps = getSuggestionItemProps(suggestion, {
                          className: `suggestion ${
                            suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                          }`,
                        });

                        return (
                          <Menu.Item
                            // @ts-ignore
                            onClick={divProps.onClick}
                            className={divProps.className}
                            key={`suggestion-${suggestion.placeId}`}
                          >
                            {suggestion.description}
                          </Menu.Item>
                        );
                      })}
                      {loading ? <LoadingOverlay /> : null}
                      {suggestions.length ? (
                        <div className={classes.poveredBy}>
                          {t('poweredBy')} <Icon icon={GoogleLogoIcon} disableAutoFontSize />
                        </div>
                      ) : null}
                    </Menu>
                  </ClickAwayListener>
                ) : (
                  <></>
                )
              }
              visible={isOpen}
              disableToggleClass
            >
              <Input
                {...((inputProps as unknown) as InputProps)}
                onChange={e => {
                  !!e.target.value && !isOpen && setIsOpen(true);
                  inputProps.onChange(e);
                }}
                onClick={() => setIsOpen(true)}
                onBlur={() => {}}
              />
            </Dropdown>
          </>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default Location;
