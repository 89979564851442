export const settings = {
  mask: 'NN:NN DK',
  formatChars: {
    N: '[ 0123456789]',
    D: '[AP]',
    K: '[M]',
  },
  maskChar: ' ',
};

export const timeRegexAmPmFormat = /^(0[0-9]|10|11|12):([0-5]\d)\s(AM|PM)$/;
