import React, { FC } from 'react';
import classNames from 'classnames';
import Tooltip from 'vibo-ui/Tooltip';

import Icon from 'vibo-ui/Icon';

import { BlockInfoProps } from '../interfaces';

import useStyles from '../style';

const BlockInfo: FC<BlockInfoProps> = ({ className, icon, content, description = '' }) => {
  const classes = useStyles();

  return (
    <Tooltip overlay={description} type="primary">
      <div className={classNames('blockInfo', classes.blockInfo, className)}>
        {icon ? (
          <Icon
            icon={icon}
            className={classNames({
              [classes.iconWithContent]: !!content,
            })}
          />
        ) : null}
        <div className="blockInfoText">{content}</div>
      </div>
    </Tooltip>
  );
};

export default BlockInfo;
