import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  settingsPage: {
    background: theme.palette.background.default,
    height: '100%',
  },

  settingsItem: {
    margin: '0 0 20px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 24px 0',
    },
  },

  content: {
    margin: '16px 0 20px 0',
    display: 'flex',

    '& > .contentArea': {
      width: '100%',

      '&:not(:last-child)': {
        margin: '0 13px 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '0 16px 0 0',
        },
      },
    },
  },

  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.5px',

    '&.withAction': {
      cursor: 'pointer',
    },

    '& .viboIcon': {
      margin: '0 8px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 12px 0 0',
      },
    },

    '& .viboButton': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  container: {
    height: 'calc(100vh - 40px)',
    padding: '16px 15px',
    userSelect: 'none',

    '& .containerContent': {
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',

      '& .contentArea': {
        minHeight: 'fit-content',
        width: '100%',
        padding: '12px',

        '& .settingsItem': {
          '&:last-child': {
            margin: '0',
          },
        },

        '&:not(:last-child)': {
          margin: '0 12px 0 0',

          '@media screen and (min-width: 1441px)': {
            margin: '0 16px 0 0',
          },
        },

        '@media screen and (min-width: 1441px)': {
          padding: '24px',
        },
      },
    },

    '& .viboButton.linkDisplay': {
      padding: 0,
    },

    '@media screen and (min-width: 1441px)': {
      height: 'calc(100vh - 48px)',
    },
  },

  title: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '19.5px',
    margin: '0 0 12px 0',
    color: theme.palette.text.hint,

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 24px 0',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  expiredDate: {
    margin: '0 8px 0 18px',
  },

  successText: {
    color: theme.palette.success.main,
  },

  greyText: {
    color: theme.palette.text.hint,
  },

  boldText: {
    fontWeight: 500,
  },

  role: {
    margin: '0 0 0 12px',
  },

  spotifyIcon: {
    color: theme.palette.spotify,
  },

  generalIcon: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
