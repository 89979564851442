import React, { FC, useContext } from 'react';
import omit from 'lodash/omit';
import classNames from 'classnames';

import WithEventRole from 'components/user/WithEventRole';
import AddTimelineSectionButton from 'components/buttons/AddTimelineSectionButton';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import { ReactComponent as PlusCircleFilledIcon } from 'resources/img/svg/common/plusCircleFilled.svg';

import { EventUserType } from 'types/enums';

import { CREATE_EVENT_SECTION } from 'graphql/mutations/events';
import { CREATE_TEMPLATE_SECTION } from 'graphql/mutations/template';

import { AddSectionInMiddleProps } from './interfaces';

import useStyles from './style';

const AddSectionInMiddle: FC<AddSectionInMiddleProps> = ({ sectionId }) => {
  const classes = useStyles();

  const { event, isTemplate } = useContext(EventContext);
  const sectionsContext = useContext(SectionsFilterableContext);

  const filter = omit(sectionsContext.filter, 'q');

  const createSectionMutation = isTemplate ? CREATE_TEMPLATE_SECTION : CREATE_EVENT_SECTION;
  const variables = isTemplate
    ? { templateId: event?._id, filter }
    : { eventId: event?._id, filter };

  return !!event ? (
    <WithEventRole roles={[EventUserType.dj, EventUserType.host]}>
      <AddTimelineSectionButton
        refetchQueries={[isTemplate ? 'templateSections' : 'sections']}
        createSectionMutation={createSectionMutation}
        variables={variables}
        eventSettings={event.settings as EventSettings}
        insertBeforeSectionId={sectionId}
        className={classNames('addInTheMiddleBtn', classes.addInTheMiddleBtn)}
        prefixIcon={PlusCircleFilledIcon}
        displayType="link"
      />
    </WithEventRole>
  ) : null;
};

export default AddSectionInMiddle;
