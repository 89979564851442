import { HTMLAttributes, FC, SVGProps } from 'react';

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  icon: IconmoonFont | SvgElement;
  className?: string;
  disableAutoFontSize?: boolean;
}

export type SvgElement = FC<SVGProps<SVGSVGElement> & { title?: string }>;

export enum IconFontSize {
  'regular' = 16,
  'large' = 24,
  'x_large' = 36,
}

export enum IconmoonFont {
  'descriptionAnswer-36' = 'descriptionAnswer-36',
  'checkboxAnswer-36' = 'checkboxAnswer-36',
  'radiobuttonAnswer-36' = 'radiobuttonAnswer-36',
  'phoneAnswer-36' = 'phoneAnswer-36',
  'locationAnswer-36' = 'locationAnswer-36',
  'pdfAnswer-36' = 'pdfAnswer-36',
  'imageAnswer-36' = 'imageAnswer-36',
  'textAnswer-36' = 'textAnswer-36',
  'arrowUpDown-16' = 'arrowUpDown-16',
  'dnd-16' = 'dnd-16',
  'add-file-16' = 'add-file-16',
  'empty-file-16' = 'empty-file-16',
  'image-16' = 'image-16',
  'pause-16' = 'pause-16',
  'colorLine-16' = 'colorLine-16',
  'share-arrow-16' = 'share-arrow-16',
  'spotifyColorful-16' = 'spotifyColorful-16',
  'clone-16' = 'clone-16',
  'copy-16' = 'copy-16',
  'musicNoteDoubleOutlined-16' = 'musicNoteDoubleOutlined-16',
  'academy-24' = 'academy-24',
  'youtube-16' = 'youtube-16',
  'notifications-bell-24' = 'notifications-bell-24',
  'helpCenter-24' = 'helpCenter-24',
  'lightBulb-24' = 'lightBulb-24',
  'playFilled-24' = 'playFilled-24',
  'locked-24' = 'locked-24',
  'email-24' = 'email-24',
  'creditCard-24' = 'creditCard-24',
  'site-24' = 'site-24',
  'mobile-16' = 'mobile-16',
  'creditCardChecked-24' = 'creditCardChecked-24',
  'sandClockEnd-24' = 'sandClockEnd-24',
  'sandClockStart-24' = 'sandClockStart-24',
  'djArrivalTime-24' = 'djArrivalTime-24',
  'searchOnScreen-24' = 'searchOnScreen-24',
  'timelineLayout-24' = 'timelineLayout-24',
  'notes-24' = 'notes-24',
  'infoRotatedOutlined-24' = 'infoRotatedOutlined-24',
  'heartOutlined-16' = 'heartOutlined-16',
  'clock-16' = 'clock-16',
  'location-24' = 'location-24',
  'phone-24' = 'phone-24',
  'layoutLandscape-16' = 'layoutLandscape-16',
  'layoutPortrait-16' = 'layoutPortrait-16',
  'editPencil-24' = 'editPencil-24',
  'copyText-16' = 'copyText-16',
  'delete-16' = 'delete-16',
  'userV2-16' = 'userV2-16',
  'arrowLeft-24' = 'arrowLeft-24',
  'camera-24' = 'camera-24',
  'noImage-36' = 'noImage-36',
  'noImage-160' = 'noImage-160',
  'arrowDown-24' = 'arrowDown-24',
  'arrowRight-24' = 'arrowRight-24',
  'arrowUp-24' = 'arrowUp-24',
  'bell-24' = 'bell-24',
  'birthday-24' = 'birthday-24',
  'calendar-24' = 'calendar-24',
  'caretLeft-24' = 'caretLeft-24',
  'caretRight-24' = 'caretRight-24',
  'changePassword-24' = 'changePassword-24',
  'checkCircleOutlined-24' = 'checkCircleOutlined-24',
  'clock-24' = 'clock-24',
  'clone-24' = 'clone-24',
  'copyText-24' = 'copyText-24',
  'corporate-24' = 'corporate-24',
  'crown-24' = 'crown-24',
  'delete-24' = 'delete-24',
  'download-24' = 'download-24',
  'events-24' = 'events-24',
  'guestsInviteGuests-24' = 'guestsInviteGuests-24',
  'headphones-24' = 'headphones-24',
  'headphonesWithMic-24' = 'headphonesWithMic-24',
  'heartFilled-24' = 'heartFilled-24',
  'hostsAddSections-24' = 'hostsAddSections-24',
  'hostsInviteGuests-24' = 'hostsInviteGuests-24',
  'hostsReorderSections-24' = 'hostsReorderSections-24',
  'location-241' = 'location-241',
  'lockedCalendar-24' = 'lockedCalendar-24',
  'logOut-24' = 'logOut-24',
  'm3u-24' = 'm3u-24',
  'mitzvah-24' = 'mitzvah-24',
  'musicNoteDoubleOutlined-24' = 'musicNoteDoubleOutlined-24',
  'musicNoteSingleOutlined-24' = 'musicNoteSingleOutlined-24',
  'notVisible-24' = 'notVisible-24',
  'party-24' = 'party-24',
  'plus-24' = 'plus-24',
  'questionOutlined-24' = 'questionOutlined-24',
  'quinceanera-24' = 'quinceanera-24',
  'refresh-24' = 'refresh-24',
  'scanner-24' = 'scanner-24',
  'schoolDance-24' = 'schoolDance-24',
  'send-24' = 'send-24',
  'settings-24' = 'settings-24',
  'sweet16-24' = 'sweet16-24',
  'templates-24' = 'templates-24',
  'termsConditions-24' = 'termsConditions-24',
  'user-24' = 'user-24',
  'visible-24' = 'visible-24',
  'wedding-24' = 'wedding-24',
  'appleMusic-16' = 'appleMusic-16',
  'arrowDown-16' = 'arrowDown-16',
  'arrowLeft-16' = 'arrowLeft-16',
  'arrowLeftRight-16' = 'arrowLeftRight-16',
  'arrowRight-16' = 'arrowRight-16',
  'arrowUp-16' = 'arrowUp-16',
  'birthday-16' = 'birthday-16',
  'bold-16' = 'bold-16',
  'bulletList-16' = 'bulletList-16',
  'caretDown-16' = 'caretDown-16',
  'caretDownFilled-16' = 'caretDownFilled-16',
  'caretLeft-16' = 'caretLeft-16',
  'caretRight-16' = 'caretRight-16',
  'caretUp-16' = 'caretUp-16',
  'caretUpFilled-16' = 'caretUpFilled-16',
  'checkedCalendar-16' = 'checkedCalendar-16',
  'checkedCircleFilled-16' = 'checkedCircleFilled-16',
  'checkedFilled-16' = 'checkedFilled-16',
  'checkMark-16' = 'checkMark-16',
  'clearFormatting-16' = 'clearFormatting-16',
  'close-16' = 'close-16',
  'closeCircleFilled-16' = 'closeCircleFilled-16',
  'colapse-16' = 'colapse-16',
  'comment-16' = 'comment-16',
  'corporate-16' = 'corporate-16',
  'crown-16' = 'crown-16',
  'dj-16' = 'dj-16',
  'editPencil-16' = 'editPencil-16',
  'email-16' = 'email-16',
  'emptyNotes-16' = 'emptyNotes-16',
  'expand-16' = 'expand-16',
  'flagFilled-16' = 'flagFilled-16',
  'flagOutlined-16' = 'flagOutlined-16',
  'folder-16' = 'folder-16',
  'host-16' = 'host-16',
  'indeterminateFilled-16' = 'indeterminateFilled-16',
  'indeterminateOutlined-16' = 'indeterminateOutlined-16',
  'infoCircleFilled-16' = 'infoCircleFilled-16',
  'infoCircleOutlined-16' = 'infoCircleOutlined-16',
  'infoRotatedOutlined-16' = 'infoRotatedOutlined-16',
  'italic-16' = 'italic-16',
  'link-16' = 'link-16',
  'location-16' = 'location-16',
  'locked-16' = 'locked-16',
  'm3u-16' = 'm3u-16',
  'mitzvah-16' = 'mitzvah-16',
  'notes-16' = 'notes-16',
  'notVisible-16' = 'notVisible-16',
  'numberList-16' = 'numberList-16',
  'party-16' = 'party-16',
  'personalComputer-16' = 'personalComputer-16',
  'phone-16' = 'phone-16',
  'play-16' = 'play-16',
  'plus-16' = 'plus-16',
  'public-16' = 'public-16',
  'questionCircleFilled-16' = 'questionCircleFilled-16',
  'questionCircleOutlined-16' = 'questionCircleOutlined-16',
  'quinceanera-16' = 'quinceanera-16',
  'radioButtonOff-16' = 'radioButtonOff-16',
  'radioButtonOn-16' = 'radioButtonOn-16',
  'redo-16' = 'redo-16',
  'schoolDance-16' = 'schoolDance-16',
  'search-16' = 'search-16',
  'settings-16' = 'settings-16',
  'site-16' = 'site-16',
  'stop-16' = 'stop-16',
  'sweet16-16' = 'sweet16-16',
  'text-16' = 'text-16',
  'threeDots-16' = 'threeDots-16',
  'threeDotsCircle-16' = 'threeDotsCircle-16',
  'uncheckedOutlined-16' = 'uncheckedOutlined-16',
  'underline-16' = 'underline-16',
  'undo-16' = 'undo-16',
  'visible-16' = 'visible-16',
  'wedding-16' = 'wedding-16',
  'spinner' = 'spinner',
}
