import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Tooltip from 'vibo-ui/Tooltip';
import ContentArea from 'components/common/ContentArea';

import { useModal } from 'vibo-ui/Modal';
import { getFullName } from 'services/users/helpers';

import { Modals, ViboUserRole } from 'types/enums';
import { UpdateUserModalProps } from 'components/modals/UpdateUserModal';
import { UserRowProps } from './interfaces';

import useStyles from './style';

const UserRole: FC<UserRowProps> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { openModal, openedModals } = useModal();

  const openUpdateUserModal = useCallback(
    () =>
      openModal<UpdateUserModalProps>({
        key: Modals.updateUser,
        props: {
          user,
        },
      }),
    [
      user?._id,
      user?.email,
      user?.firstName,
      user?.lastName,
      user?.role,
      user?.djSettings,
      openedModals,
    ]
  );

  const isCompanyOrChild = user.role === ViboUserRole.company || user.role === ViboUserRole.child;

  const relatedUsers: ViboPlainUser[] =
    user.role === ViboUserRole.company ? user.children : user.parent;

  const role = t(user.role);

  return (
    <div onClick={e => isCompanyOrChild && e.stopPropagation()}>
      {isCompanyOrChild ? (
        <Tooltip
          overlay={
            <ContentArea>
              <strong>{role}</strong>
              {relatedUsers.length ? (
                relatedUsers.map(user => (
                  <Button onClick={openUpdateUserModal} key={user._id}>
                    {getFullName(user)}
                  </Button>
                ))
              ) : (
                <span>{t('noDjs')}</span>
              )}
            </ContentArea>
          }
          trigger="click"
          placement="bottom"
          overlayClassName={classes.roleOverlay}
          destroyTooltipOnHide
        >
          <Button>{role}</Button>
        </Tooltip>
      ) : (
        role
      )}
    </div>
  );
};

export default UserRole;
