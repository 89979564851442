import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'vibo-ui/Tooltip';
import classNames from 'classnames';

import LastName from './LastName';
import FirstName from './FirstName';
import Image from '../../../image/Image';
import InputLabel from 'components/common/InputLabel';
import ContentArea from '../../../common/ContentArea';
import PageContentHeader from '../../../common/PageContentHeader';
import PhoneNumberWithPolicy from './PhoneNumber/PhoneNumberWithPolicy';
import ImageDefaultPlaceholder from '../../../image/ImageDefaultPlaceholder';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { UserPolicyFields } from '../../../context/PolicyContext';

import { ReactComponent as MenuAccountIcon } from '../../../../resources/img/svg/userRoleLg.svg';

import { useFieldPolicy } from '../../../../graphql/hooks/policy';
import { getFullName } from '../../../../services/users/helpers';

import { InfoProps } from './interfaces';

import useStyles from './style';

const UserInfo: FC<InfoProps> = ({ user, setModalProfileOpen, handleUpdateProfile }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isEditable } = useFieldPolicy(UserPolicyFields.firstName);

  return (
    <>
      <PageContentHeader />
      <div className="content">
        <div className="photos">
          <div
            className={classNames('profilePicture', classes.profilePicture, {
              canEdit: isEditable,
            })}
            onClick={() => isEditable && setModalProfileOpen(true)}
          >
            {!!user ? (
              <Image
                token={user?._id}
                src={`${user?.imageUrl}`}
                alt={getFullName(user)}
                className={classNames('photo', classes.imageCover)}
              >
                <ImageDefaultPlaceholder icon={<MenuAccountIcon />} text={t('addProfilePhoto')} />
              </Image>
            ) : null}
          </div>
        </div>
        <div className="info">
          <ContentArea className={classes.nameBlock} shadowed>
            <div className="row">
              <InputLabel text={t('firstName')} required>
                <FirstName handleUpdateProfile={handleUpdateProfile} user={user} />
              </InputLabel>
            </div>
            <div className="row">
              <InputLabel text={t('lastName')} required>
                <LastName handleUpdateProfile={handleUpdateProfile} user={user} />
              </InputLabel>
            </div>
          </ContentArea>
          <ContentArea shadowed>
            <div className="row">
              <InputLabel
                text={t('phoneNumber')}
                iconPrefix={<Icon icon={IconmoonFont['phone-16']} />}
              />
              <PhoneNumberWithPolicy handleUpdateProfile={handleUpdateProfile} user={user} />
            </div>
            <div className="row">
              <InputLabel text={t('email')} iconPrefix={<Icon icon={IconmoonFont['email-16']} />} />
              <Tooltip overlay={t('needChangeYourEmailContactSupport')} placement="bottomLeft">
                <div className="email">{user?.email}</div>
              </Tooltip>
            </div>
          </ContentArea>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
