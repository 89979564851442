import { gql } from 'graphql.macro';

import { USER_DATA } from './user';

export const AUTH_RESPONSE_DATA = gql`
  fragment AuthResponseData on AuthResponse {
    accessToken
    refreshToken
    user {
      ...UserData
    }
  }
  ${USER_DATA}
`;

export const COMPLETE_SIGNUP_RESPONSE_DATA = gql`
  fragment CompleteSignUpResponseData on CompleteSignUpResponse {
    token
    action
    data {
      email
      firstName
      lastName
      imageUrl
      facebookId
      appleId
      googleId
    }
  }
  ${USER_DATA}
`;
