import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  statusSelect: {
    marginLeft: '-2px',

    '& .prefix': {
      margin: '0 14px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 10px 0 0',
      },
    },
  },

  dot: {
    width: '12px',
    height: '12px',
    borderRadius: '100%',
  },
}));

export default useStyles;
