import { SectionColorsKeys } from './interfaces';

export const colorNames = [
  SectionColorsKeys.blue,
  SectionColorsKeys.violet,
  SectionColorsKeys.rose,
  SectionColorsKeys.indochine,
  SectionColorsKeys.gold,
  SectionColorsKeys.green,
];
