import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  pdfSections: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  pdfSectionsHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.contentArea,
    borderBottom: `1px solid ${isDark ? colors.gray : theme.palette.primary.light}`,
    position: 'sticky',
    top: '32px',
    zIndex: 10,
    borderRadius: '4px 4px 0 0',

    '& .headerMain, & .headerExtra': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& .headerMain': {
      minHeight: '40px',
      alignItems: 'center',

      '& .cellName, & .togleAllCheckbox': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '@media screen and (min-width: 1441px)': {
          height: '36px',
          paddingTop: '4px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        minHeight: '46px',
      },
    },

    '& .headerExtra': {
      margin: '6px 0 0 0',
      paddingRight: '6px',

      '@media screen and (min-width: 1441px)': {
        margin: '8px 0 0 0',
        paddingRight: '8px',
      },
    },
  },

  pdfSectionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&.disabled': {
      '& .sectionName': {
        opacity: '0.7',
      },

      '& .toggleControls': {
        cursor: 'not-allowed',
      },

      '& .pdfSectionToggler, & .songsCount': {
        pointerEvents: 'none',
        opacity: '0.2',
      },
    },

    '& .sectionName': {
      ...theme.mixins.longText,
      maxWidth: 'max-content',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '19.5px',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    '& > .visibilityLabel, & > .headlineLabel': {
      margin: '0 4px 0 4px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 4px 0 8px',
      },
    },
  },

  toggleControls: {
    margin: '0 0 0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',

    '& .cellName': {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  sectionsOptionInfo: {
    margin: '0 0 0 6px',
    fontSize: '13px',
    lineHeight: '19.56px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 0 8px',
      fontSize: '16px',
    },
  },

  togleAllCheckbox: {
    margin: '0 auto 0 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  pdfSectionToggler: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',

    '& .toggle-content': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 0 0 6px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 8px',
      },
    },

    '&.noContent': {
      border: `none`,
      pointerEvents: 'none',
      backgroundColor: 'transparent',
    },

    '&.disabled': {
      pointerEvents: 'none',
      color: theme.palette.text.disabled,
      backgroundColor: 'transparent',
    },

    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > div': {
        margin: '0 0 0 4px',
      },

      '& svg': {
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        lineHeight: '16px',
      },
    },
  },

  songsCount: {
    minWidth: '62px',
    fontSize: '13px',
    lineHeight: '19.5px',
    margin: '0 12px 0 6px',

    '@media screen and (min-width: 1441px)': {
      minWidth: '76px',
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0 16px 0 6px',
    },
  },
}));

export default useStyles;
