import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';

import { getPadding } from 'services/common/scrollHelper';

import { ScrollbarProps } from './interfaces';

import useStyles from './style';

const Scrollbar: FC<ScrollbarProps> = ({
  className,
  id,
  alwaysShowThumb,
  children,
  shiftThumb = false,
}) => {
  const classes = useStyles();

  const [thumbHeight, setThumbHeight] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState<number>(0);
  const [initialScrollTop, setInitialScrollTop] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isThumbVisible, setisThumbVisible] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const scrollTrackRef = useRef<Nullable<HTMLDivElement>>(null);
  const scrollThumbRef = useRef<Nullable<HTMLDivElement>>(null);
  const observer = useRef<Nullable<ResizeObserver>>(null);

  const handleThumbVisibility = useCallback(() => {
    return setisThumbVisible(
      contentRef.current?.clientHeight !== scrollTrackRef.current?.scrollHeight ||
        contentRef.current?.scrollHeight !== scrollTrackRef.current?.scrollHeight
    );
  }, [
    contentRef.current?.scrollHeight,
    contentRef.current?.clientHeight,
    scrollTrackRef.current?.scrollHeight,
  ]);

  function handleResize(ref: HTMLDivElement, trackSize: number) {
    const { clientHeight, scrollHeight } = ref;
    const { getTop, getBottom } = getPadding(ref);

    const paddingY = getTop() + getBottom();

    handleThumbVisibility();
    setThumbHeight(
      Math.max((clientHeight / (scrollHeight - paddingY)) * (trackSize - paddingY), 20)
    );
  }

  const handleTrackClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const { current: trackCurrent } = scrollTrackRef;
      const { current: contentCurrent } = contentRef;
      if (trackCurrent && contentCurrent) {
        const { clientY } = e;
        const target = e.target as HTMLDivElement;
        const rect = target.getBoundingClientRect();
        const trackTop = rect.top;
        const thumbOffset = -(thumbHeight / 2);
        const clickRatio = (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
        const scrollAmount = Math.floor(clickRatio * contentCurrent.scrollHeight);
        contentCurrent.scrollTo({
          top: scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [thumbHeight]
  );

  const handleThumbPosition = useCallback(() => {
    if (!contentRef.current || !scrollTrackRef.current || !scrollThumbRef.current) {
      return;
    }
    const { scrollTop: contentTop, scrollHeight: contentHeight } = contentRef.current;
    const { clientHeight: trackHeight } = scrollTrackRef.current;
    let newTop = (+contentTop / +contentHeight) * trackHeight;
    newTop = Math.min(newTop, trackHeight - thumbHeight);
    const thumb = scrollThumbRef.current;
    thumb.style.top = `${newTop}px`;
  }, []);

  const handleThumbMousedown = useCallback(e => {
    e.stopPropagation();
    setScrollStartPosition(e.clientY);

    if (contentRef.current) {
      setInitialScrollTop(contentRef.current.scrollTop);
    }

    setIsDragging(true);
  }, []);

  const handleThumbMouseup = useCallback(
    e => {
      e.stopPropagation();

      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbMousemove = useCallback(
    e => {
      e.stopPropagation();

      if (isDragging && !!contentRef.current) {
        const {
          scrollHeight: contentScrollHeight,
          offsetHeight: contentOffsetHeight,
        } = contentRef.current;

        const deltaY = (e.clientY - scrollStartPosition) * (contentOffsetHeight / thumbHeight);
        const newScrollTop = Math.min(
          initialScrollTop + deltaY,
          contentScrollHeight - contentOffsetHeight
        );

        contentRef.current.scrollTop = newScrollTop;
      }
    },
    [isDragging, scrollStartPosition, thumbHeight]
  );

  useEffect(() => {
    if (!!contentRef.current && !!scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientHeight: trackSize } = scrollTrackRef.current;

      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });
      observer.current.observe(ref);
      ref.addEventListener('scroll', handleThumbPosition);

      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, [contentRef.current?.scrollHeight]);

  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    document.addEventListener('mouseleave', handleThumbMouseup);

    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
      document.removeEventListener('mouseleave', handleThumbMouseup);
    };
  }, [handleThumbMousemove, handleThumbMouseup]);

  useEffect(() => {
    handleThumbVisibility();
  }, []);

  return (
    <div
      className={classNames('viboScrollbar', classes.viboScrollbar, className, {
        shiftThumb,
      })}
      id={id}
    >
      <div ref={contentRef} className={classNames('scrollView', classes.scrollView)}>
        {children}
      </div>
      <div
        className={classNames('trackVertical', classes.trackVertical, {
          isDragging,
          isThumbVisible,
          alwaysShowThumb,
        })}
      >
        <div className={classNames('yaxis', classes.yaxis)}>
          <div
            ref={scrollTrackRef}
            onClick={handleTrackClick}
            className={classNames('scrollbarsTrack', classes.scrollbarsTrack)}
            style={{ cursor: isDragging ? 'grabbing' : 'none' }}
          />
          <div
            ref={scrollThumbRef}
            onMouseDown={handleThumbMousedown}
            className={classNames('scrollbarsThumb', classes.scrollbarsThumb)}
            style={{
              height: `${thumbHeight}px`,
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Scrollbar;
