import React from 'react';
import { useTranslation } from 'react-i18next';

import BlockInfo from '../../../sections/TimelineSection/SectionBlocksInfo/BlockInfo';

import { ReactComponent as MusicNoteDoubleIcon } from '../../../../resources/img/svg/musicNoteDouble.svg';

interface PrepModeSectionSongsMatchedProps {
  section: PrepModeSection;
}

const PrepModeSectionSongsMatched: React.FC<PrepModeSectionSongsMatchedProps> = ({ section }) => {
  const { t } = useTranslation();

  const songsContent = t('songMatched', {
    count: section.preparedSongsCount,
    songs: section.songsCount,
  });

  return <BlockInfo icon={MusicNoteDoubleIcon} description={songsContent} content={songsContent} />;
};

export default PrepModeSectionSongsMatched;
