import React, { FC, useContext, memo, useCallback } from 'react';

import FormInput from 'components/form/inputs/FormInput';
import SpotifyButton from 'components/buttons/SpotifyButton';
import PageContentHeader from 'components/common/PageContentHeader';
import ClonePlaylistButton from 'components/buttons/ClonePlaylistButton';
import SharePlaylistButton from 'components/buttons/SharePlaylistButton';
import AssignPlaylistButton from 'components/buttons/AssignPlaylistButton';
import { EditableContext } from 'components/common/EditableContext';

import { useModal } from 'vibo-ui/Modal';
import { MAX_TITLE_LENGTH } from 'services/constants';

import { Modals } from 'types/enums';
import { IconmoonFont } from 'vibo-ui/Icon';
import { ExportSongIdeasToSpotifyModalProps } from 'components/modals/ExportSongIdeasToSpotifyModal';
import { SongIdeaPageHeaderProps } from './interfaces';

const SongIdeaPageHeader: FC<SongIdeaPageHeaderProps> = ({ songIdeas }) => {
  const { canEdit } = useContext(EditableContext);

  const { openModal } = useModal();

  const openExportSongIdeasSpotifyModal = useCallback(
    () =>
      openModal<ExportSongIdeasToSpotifyModalProps>({
        key: Modals.exportSongIdeasToSpotify,
        props: {
          songIdeasId: songIdeas._id,
        },
      }),
    [songIdeas._id]
  );

  return (
    <PageContentHeader
      extra={
        <>
          <AssignPlaylistButton />
          {!!songIdeas?.isOwner ? (
            <SharePlaylistButton playlist={songIdeas} />
          ) : (
            <ClonePlaylistButton
              playlist={songIdeas}
              prefixIcon={IconmoonFont['plus-24']}
              displayType="primary"
              shape="round"
            />
          )}
          <SpotifyButton
            onClick={openExportSongIdeasSpotifyModal}
            disabled={!songIdeas?.songsCount}
          />
        </>
      }
    >
      <FormInput
        disabled={!canEdit || !songIdeas.isOwner}
        maxLength={MAX_TITLE_LENGTH}
        name="title"
        className="bold"
        size="xlg"
        withUnderline
        showMaxLength
      />
    </PageContentHeader>
  );
};

export default memo(SongIdeaPageHeader);
