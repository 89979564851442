import { gql } from 'graphql.macro';

export const SET_BEATSOURCE_CODE = gql`
  mutation setBeatsourceCode($code: String!) {
    setBeatsourceCode(code: $code)
  }
`;

export const CREATE_BEATSOURCE_PLAYLIST = gql`
  mutation createBeatsourcePlaylist($name: String!, $trackIds: [String!]!) {
    createBeatsourcePlaylist(name: $name, trackIds: $trackIds) {
      playlistUrl
    }
  }
`;

export const DISCONNECT_BEATSOURCE = gql`
  mutation disconnectBeatsource {
    disconnectBeatsource
  }
`;
