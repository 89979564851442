import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button, { ButtonDecor } from 'vibo-ui/Button';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SpotifyButtonProps } from './interfaces';

import useStyles from './style';

const SpotifyButton: FC<SpotifyButtonProps> = ({
  disabled,
  className,
  onClick,
  text = 'exportToSpotify',
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={classNames('spotifyButton', ButtonDecor.success, classes.spotifyButton, className)}
      prefixIcon={IconmoonFont['spotifyColorful-16']}
      displayType="primary"
      shape="round"
      size="lg"
      shadowed
      {...rest}
    >
      {t(text)}
    </Button>
  );
};

export default SpotifyButton;
