import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Image from '../../image/Image';
import UserLogo from '../../user/images/UserLogo';
import { LogoContext } from '../../context/LogoContext';

import { GET_ME } from '../../../graphql/queries/user';

import useStyles from './style';

const SiderLogo: React.FC<{}> = () => {
  const classes = useStyles();

  const { url, alt, id } = useContext(LogoContext);

  const { data, loading } = useQuery(GET_ME);
  const user: Maybe<User> = data?.me;

  if (loading) {
    return null;
  }

  return url ? (
    <Image token={id || ''} src={url} alt={alt || 'Logo'} className={classes.logoContainer} />
  ) : (
    <UserLogo user={user as UserPreview} className={classes.logoContainer} />
  );
};

export default SiderLogo;
