import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BlockInfo from './BlockInfo';
import { TimelineSectionContext } from '../TimelineSectionContext';

import { getSectionQuestionsInfo } from '../../../../services/sections/helpers';
import { hasQuestions } from '../../../../services/questions/helpers';

import { IconmoonFont } from 'vibo-ui/Icon';
import { SectionBlockName } from '../../../../types/enums';

const QuestionsBlockInfo: FC = () => {
  const { t } = useTranslation();

  const { section } = useContext(TimelineSectionContext);

  const questionsContent = useMemo(() => getSectionQuestionsInfo(section), [section]);

  return (
    <BlockInfo
      className={SectionBlockName.questions}
      icon={
        IconmoonFont[
          !hasQuestions(section) ? 'infoRotatedOutlined-16' : 'questionCircleOutlined-16'
        ]
      }
      description={`${questionsContent} ${t('questions', {
        count: section.questionsCount,
      })}`}
      content={questionsContent}
    />
  );
};

export default QuestionsBlockInfo;
