import React, { FC, useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import loadable from '@loadable/component';

import Icon from 'vibo-ui/Icon';

import { ReactComponent as SquareWithPhtoCameraIcon } from 'resources/img/svg/common/squareWithPhtoCamera.svg';

import { ImageProps, LazyOnlyImageProps } from './interfaces';

import useStyles from './style';

const LazyImage = loadable(() => import('./LazyImage')) as FC<LazyOnlyImageProps>;

const Image: FC<ImageProps> = ({
  children,
  isLazy = true,
  showExtra = false,
  loading = 'eager',
  alt = 'image',
  ...rest
}) => {
  const classes = useStyles();

  const [isLoadError, setIsLoadError] = useState<boolean>(true);

  const defaultImageProps = {
    ...rest,
    loading,
    alt,
  };

  const gtmAttribute = useMemo(() => {
    const gtmId = get(rest, 'data-gtm-target-id', '');

    return !!gtmId
      ? {
          'data-gtm-target-id': gtmId,
        }
      : {};
  }, [rest?.['data-gtm-target-id']]);

  const handleLoadError = () => {
    setIsLoadError(true);
    rest.onError?.();
  };

  useEffect(() => {
    if (!rest.src) {
      handleLoadError();
    } else {
      setIsLoadError(false);
    }
  }, [rest.src]);

  return (
    <div
      className={classNames('viboImage', classes.viboImage, rest.className)}
      onClick={rest.onClick}
      {...gtmAttribute}
    >
      {isLazy ? (
        <LazyImage
          {...defaultImageProps}
          isLoadError={isLoadError}
          setIsLoadError={setIsLoadError}
          handleLoadError={handleLoadError}
        >
          {children}
        </LazyImage>
      ) : isLoadError ? (
        children
      ) : (
        <img {...defaultImageProps} alt={alt} onError={handleLoadError} />
      )}
      <Icon icon={SquareWithPhtoCameraIcon} className="changePhotoIcon" disableAutoFontSize />
      {showExtra && !isLoadError ? children : null}
    </div>
  );
};

export default Image;
