import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  hideTooltip: {
    opacity: 0,
    pointerEvents: 'none',
  },

  moreToolsToggler: {
    minWidth: 'auto',
    boxShadow: 'none!important',
    borderRadius: '2px',
  },

  moreToolsMenu: {
    '& .menuItem': {
      padding: '0!important',

      '& .action': {
        padding: '2.5px 8px',

        //for gtm tracking propper target
        '& *': {
          pointerEvents: 'none',
        },
      },

      '&:hover': {
        '&, & *': {
          color: theme.palette.background.paper,
        },
      },
    },
  },

  optionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  option: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',

    '& .viboIcon': {
      margin: '0 6px 0 0',
    },
  },
}));

export default useStyles;
