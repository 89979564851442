import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sectionTime: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    margin: '0',
    color: 'inherit',

    '& .viboButton': {
      lineHeight: '15px',
      color: 'inherit!important',
      gap: '4px',

      // FOR GTM TRIGGER
      '& > div': {
        pointerEvents: 'none',
      },
    },
  },

  timeIcon: {
    margin: '0 4px 0 0',
  },

  time: {
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    //for gtm tracking propper target
    '& *': {
      pointerEvents: 'none',
    },

    '& > div:not(:last-child)': {
      margin: '0 2px 0 0',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
    },
  },

  timePreview: {
    right: '10px',
  },

  selectSectionTimeDropdown: {
    '& .viboToggleDropdown': {
      padding: '4px 8px',
    },
  },
}));

export default useStyles;
