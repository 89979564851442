import { createUseStyles } from 'react-jss';
import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';
import { GetTooltipColors } from './interfaces';

const getTooltipBase = ({ textColor, fillTextColor, bgColor }: GetTooltipColors) => ({
  '& .rc-tooltip-inner': {
    color: textColor,
    border: `1px solid ${bgColor}`,
  },

  '&.rc-tooltip-placement-bottom, &.rc-tooltip-placement-bottomLeft': {
    '& .rc-tooltip-arrow': {
      borderBottomColor: bgColor,
    },
  },

  '&.rc-tooltip-placement-top .rc-tooltip-arrow': {
    borderTopColor: bgColor,
  },

  '&.rc-tooltip-placement-left .rc-tooltip-arrow': {
    borderLeftColor: bgColor,
  },

  '&.rc-tooltip-placement-right .rc-tooltip-arrow': {
    borderRightColor: bgColor,
  },

  '&.fillOverlay': {
    '& .rc-tooltip-inner': {
      backgroundColor: bgColor,
      ...(!!fillTextColor && {
        color: fillTextColor,
      }),
    },
  },
});

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboTooltipOvelay: {
    opacity: 1,
    zIndex: 1000,

    '&.fillOverlay': {
      '& .rc-tooltip-inner': {
        color: theme.palette.tooltip.infoBgColor,
      },
    },

    '& .withSizeLimit': {
      maxWidth: '250px',
    },

    '& .rc-tooltip-inner': {
      padding: '2px 4px',
      fontSize: '11px',
      lineHeight: '16px',
      minHeight: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,

      '@media screen and (min-width: 1441px)': {
        minHeight: '28px',
        padding: '2px 6px',
        fontSize: '14px',
        lineHeight: '21px',
      },
    },

    '&.rc-tooltip-placement-bottomLeft': {
      '& .rc-tooltip-arrow': {
        top: '-3px',

        '@media screen and (min-width: 1441px)': {
          top: '-4px',
        },
      },
    },

    '&.emptyDisplay': {
      backgroundColor: 'transparent',

      '& .rc-tooltip-inner': {
        backgroundColor: 'transparent',
        border: `none`,
        padding: '0',
        minHeight: 'auto',
      },

      '& .rc-tooltip-arrow': {
        display: 'none',
      },
    },

    '&.defaultDisplay': {
      '& .rc-tooltip-inner': {
        boxShadow: theme.shadows[1],
        border: `1px solid ${theme.palette.background.paper}`,
      },

      '& .rc-tooltip-arrow': {
        display: 'none',
      },
    },

    '&.primaryDisplay': {
      ...getTooltipBase({
        textColor: theme.palette.tooltip.infoColor,
        bgColor: theme.palette.tooltip.infoColor,
      }),
    },

    '&.successDisplay': {
      ...getTooltipBase({
        textColor: theme.palette.success.main,
        bgColor: theme.palette.success.main,
      }),
    },

    '&.dangerDisplay': {
      ...getTooltipBase({
        textColor: theme.palette.error.main,
        bgColor: theme.palette.error.main,
      }),
    },

    '&.warningDisplay': {
      ...getTooltipBase({
        textColor: theme.palette.warning.main,
        bgColor: theme.palette.warning.main,
      }),
    },

    '&.blackDisplay': {
      ...getTooltipBase({
        textColor: colors.black,
        bgColor: colors.black,
      }),
    },

    '&.whiteDisplay': {
      ...getTooltipBase({
        textColor: colors.black,
        bgColor: colors.white,
        fillTextColor: colors.black,
      }),
    },

    '& .rc-tooltip-arrow': {
      borderWidth: '0 5px 5px',
    },

    '&.rc-tooltip-placement-bottom': {
      '& .rc-tooltip-arrow': {
        top: '-3px',

        '@media screen and (min-width: 1441px)': {
          top: '-4px',
        },
      },
    },

    '&.rc-tooltip-placement-top': {
      '& .rc-tooltip-arrow': {
        bottom: '-4px',
        borderWidth: '5px 5px 0',
        borderBottomColor: 'inherit',
      },
    },

    '&.rc-tooltip-placement-left': {
      '& .rc-tooltip-arrow': {
        right: '-8px',
        borderWidth: '5px',
      },
    },

    '&.rc-tooltip-placement-right': {
      '& .rc-tooltip-arrow': {
        left: '-9px',
        borderWidth: '5px',
      },
    },
  },
}));

export default useStyles;
