import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sections: {
    height: 'calc(100% - 20px)',
    width: '100%',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',

    '& .icon': {
      height: '15px',
    },

    '& > button': {
      minWidth: 'unset',
      position: 'absolute',
      left: '39%',
      marginLeft: 'unset',
    },

    '& > .viboScrollbar': {
      height: 'inherit',
      marginRight: '-6px',

      '& > .scrollView': {
        paddingRight: '6px',
      },
    },

    '& .emptyState': {
      marginTop: '80px',
    },
  },

  spaceBewteenSections: {
    '& .timelineSection': {
      '&:not(:last-child)': {
        margin: '0 0 12px 0',
      },

      '&:first-child': {
        marginTop: '12px',
      },
    },

    '& .timelineSectionsHeader > div': {
      '&.searchBarWrapper': {
        minWidth: '100%',
      },
    },
  },

  sectionsTimeline: {
    '& .timelineSection': {
      '& .section': {
        borderRadius: '4px',
      },

      '&:first-child': {
        '& > .addInTheMiddleBtn': {
          margin: '-9px 0 0 0',

          '@media screen and (min-width: 1441px)': {
            margin: '-12px 0 0 0',
          },
        },
      },

      '&:first-child:last-child': {
        '& .section': {
          borderRadius: '4px',
        },
      },
    },
  },

  nonEmptyList: {
    '& > button': {
      bottom: '59px',
    },
  },

  emptyList: {
    '& > button': {
      bottom: '45%',
    },
  },

  emptyListText: {
    position: 'absolute',
    left: '41%',
    bottom: '54%',
    width: '134px',
    fontSize: '24px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  sectionsListHeader: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderRadius: '4px',
    padding: '7px 12px 5px',
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    margin: '0 10px 0 auto',
    width: '433px',
    textAlign: 'center',
  },
}));

export default useStyles;
