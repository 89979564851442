import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useParams } from 'react-router';
import has from 'lodash/has';
import { useQueryParam } from 'use-query-params';
import classNames from 'classnames';

import { TemplateFilterableContext } from '../../../components/context/TemplateContext';
import { EventsSortableContext } from '../../../components/events/common/EventsListContext';
import TemplatesPageHeader from '../../../components/headers/TemplatesPageHeader';
import TemplatesList from '../../../components/template/TemplatesList';
import Container from '../../../components/common/Container';

import {
  GET_VIBO_TEMPLATES,
  GET_MY_TEMPLATES,
  GET_COMPANY_TEMPLATES,
} from '../../../graphql/queries/template';

import { getTemplatesData } from '../../../services/template/helpers';
import { getInitialPagination } from '../../../services/common/paginationHelper';
import { useMe } from '../../../graphql/hooks/user';

import { TemplatesType } from '../../../types/enums';

import useStyles from './style';

const Templates: React.FC = () => {
  const classes = useStyles();
  const location = useLocation<TemplatesLocationState>();

  const [sort, setSort] = useState<Nullable<TSort>>(null);
  const [typeId, setTypeId] = useState<Nullable<string>>(null);

  const [q = '', setQ] = useQueryParam<string | undefined>('q');

  const { type } = useParams<{ type: TemplatesType }>();

  const { user } = useMe();

  const getPagination = (id: string) => {
    return id === location.state?.listId ? getInitialPagination(location.state) : null;
  };

  const { data: viboTemplatesData, fetchMore: fetchMoreVibo, loading: loadingVibo } = useQuery<
    TemplatesResponse,
    GetTemplatesListVariables
  >(GET_VIBO_TEMPLATES, {
    variables: {
      filter: { q, typeId },
      sort,
      pagination: getPagination('vibo-templates'),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: companyTemplatesData,
    fetchMore: fetchMoreCompany,
    loading: loadingCompany,
  } = useQuery<TemplatesResponse, GetTemplatesListVariables>(GET_COMPANY_TEMPLATES, {
    variables: {
      filter: { q, typeId },
      sort,
      pagination: getPagination('company-templates'),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const { data: myTemplatesData, fetchMore: fetchMoreMy, loading: loadingMy } = useQuery<
    TemplatesResponse,
    GetTemplatesListVariables
  >(GET_MY_TEMPLATES, {
    variables: {
      filter: { q, typeId },
      sort,
      pagination: getPagination('my-templates'),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const viboTemplates: TemplatesListData = getTemplatesData(viboTemplatesData);
  const companyTemplates: TemplatesListData = getTemplatesData(companyTemplatesData);
  const myTemplates: TemplatesListData = getTemplatesData(myTemplatesData);

  const isChildDj = user?.djSettings?.type === 'child';

  useEffect(() => {
    if (has(location.state, 'filter.q')) {
      setQ(location.state?.filter?.q || '');
    }

    if (has(location.state, 'filter.typeId')) {
      setTypeId(location.state?.filter?.typeId || '');
    }
  }, [location]);

  useEffect(() => {
    setQ(undefined);

    return () => setQ(undefined);
  }, []);

  return (
    <div className={classNames(classes.templatesPage)}>
      <TemplateFilterableContext.Provider value={{ filter: { typeId, q }, setFilter: setTypeId }}>
        <TemplatesPageHeader />
        <Container className={classes.container}>
          <EventsSortableContext.Provider value={{ sort, setSort }}>
            {isChildDj && type === TemplatesType.company ? (
              <TemplatesList
                loading={loadingCompany}
                fetchMore={fetchMoreCompany}
                templatesData={companyTemplates}
                type={TemplatesType.company}
                id="company-templates"
                queryKey={'getCompanyTemplates'}
                filter={{ q, typeId }}
              />
            ) : null}
            {type === TemplatesType.vibo ? (
              <TemplatesList
                loading={loadingVibo}
                fetchMore={fetchMoreVibo}
                templatesData={viboTemplates}
                type={TemplatesType.vibo}
                id="vibo-templates"
                queryKey={'getViboTemplates'}
                filter={{ q, typeId }}
              />
            ) : null}
            {type === TemplatesType.user ? (
              <TemplatesList
                loading={loadingMy}
                fetchMore={fetchMoreMy}
                templatesData={myTemplates}
                type={TemplatesType.user}
                id="my-templates"
                queryKey={'getMyTemplates'}
                filter={{ q, typeId }}
              />
            ) : null}
          </EventsSortableContext.Provider>
        </Container>
      </TemplateFilterableContext.Provider>
    </div>
  );
};

export default Templates;
