import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router';
import { useSearchParam } from 'react-use';
import { useTranslation } from 'react-i18next';

import JoinByDeepLinkPlaceholder from '../../../components/Placeholders/JoinByDeepLinkPlaceholder';
import HeadingWithImage from '../../../components/common/HeadingWithImage';
import SignInWithLink from '../../../components/auth/SignInWithLink';
import DeepLinkActionButton from '../../../components/auth/SignInWithLink/DeepLinkActionButton';
import { DeepLinkContextProvider } from '../../../components/context/DeepLinkContext';
import { WizardState } from 'vibo-ui/Wizard/WizardStepContext';

import { GET_PLAYLIST_DEEP_LINK_PREVIEW } from '../../../graphql/queries/songIdeas';
import { getRedirectPlaylistLink } from '../../../services/songIdeas/helpers';

const SharedPlaylistPage: FC = () => {
  const { t } = useTranslation();

  const { push } = useHistory();

  const playlistId = useSearchParam('id') || '';

  const { data } = useQuery<GetPlaylistDeepLinkPreview>(GET_PLAYLIST_DEEP_LINK_PREVIEW, {
    variables: {
      songIdeasId: playlistId,
    },
    skip: !playlistId,
  });
  const playlistPreview = data?.getSongIdeasDeepLinkPreview as PlaylistDeepLinkPreview;

  const previewData = {
    title: playlistPreview?.title,
    photo: { url: playlistPreview?.coverPhoto?.url, token: playlistId },
  };

  const handleOpenPlaylist = () => {
    if (!!playlistId) {
      push(getRedirectPlaylistLink(playlistId));
    }
  };

  return (
    <WizardState>
      <DeepLinkContextProvider
        action={!!playlistId ? handleOpenPlaylist : null}
        previewData={previewData}
      >
        <SignInWithLink>
          {!!playlistPreview ? (
            <HeadingWithImage {...previewData} />
          ) : (
            <JoinByDeepLinkPlaceholder />
          )}
          <DeepLinkActionButton loading={!playlistPreview}>
            {t('openPlaylist')}
          </DeepLinkActionButton>
        </SignInWithLink>
      </DeepLinkContextProvider>
    </WizardState>
  );
};

export default SharedPlaylistPage;
