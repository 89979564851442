import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  copy: {
    opacity: '0',
    pointerEvents: 'none',
    margin: '0 0 0 4px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 0 6px',
    },

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },
  },
}));

export default useStyles;
