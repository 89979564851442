import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  droppable: {
    padding: '2px 0',
    margin: '0 0 0 -1px',
  },

  sectionDescription: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    padding: '2px 12px',
    margin: '0 0 16px 0',
    minHeight: '28px',

    '& p': {
      margin: '0',
      fontSize: '13px',
      lineHeight: '18px',

      '&.text': {
        ...theme.mixins.longText,
        width: '100px',
        maxWidth: '100ch',
      },

      '& .viboButton': {
        display: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '22.5px',
      },
    },

    '& .viboButton': {
      margin: '0 0 0 4px',
      padding: '0',
    },

    '&.isShown': {
      alignItems: 'start',

      '& .text': {
        width: 'auto',
        whiteSpace: 'pre-wrap',
        overflow: 'visible',
        wordBreak: 'break-word',

        '& .viboButton': {
          display: 'inline',
        },
      },

      '& > .viboButton': {
        display: 'none',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '2px 16px',
      minHeight: '32px',
    },
  },

  sectionDescSubstrate: {
    position: 'absolute',
    left: '0',
    top: '0',
    opacity: '0',
    color: 'red',
    pointerEvents: 'none',
    whiteSpace: 'nowrap',
  },

  lastBlock: {
    '& > .customContentArea': {
      '& > .timeline-icon': {
        '&.block-collapsed': {
          '& > .colored-line': {
            display: 'none',
          },
        },

        '& > .colored-line': {
          height: 'calc(100% - 34px)',

          '&:before': {
            display: 'block',
          },
        },
      },
    },
  },

  stickyBlock: {
    boxShadow: `${theme.shadows[2]}!important`,
  },
}));

export default useStyles;
