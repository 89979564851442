import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboTimeInput: {
    fontSize: '13px',
    lineHeight: '18.2px',
    width: '80px',
    display: 'inline',
    border: 'none',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    outline: 'none',

    '@media screen and (min-width: 1441px)': {
      width: '85px',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },

  timePickerOverlay: {
    '& .overlayWrapper': {
      width: 'fit-content',
    },
  },

  timePickerToggle: {
    '&.viboCard': {
      boxShadow: 'none',
    },
  },

  optionsScroll: {
    width: 'fit-content',

    '& .scrollView': {
      position: 'relative!important',
      height: 'fit-content',
      maxHeight: '200px',
    },
  },

  invalidTime: {
    color: theme.palette.error.main,
  },

  tooltipContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '3px',

    '& .tooltipIcon': {
      margin: '-3px',
      color: theme.palette.primary.main,
      padding: '3px',
      borderRadius: '50%',

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },

  clearTimeWrapper: {
    position: 'relative',

    '& .viboSpinner, & .clearIcon': {
      color: theme.palette.primary.main,
      position: 'absolute',
      right: '5px',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    '& .clearIcon': {
      zIndex: 2,
      cursor: 'pointer',
      display: 'none',
      color: theme.palette.primary.main,
      right: 0,

      '@media screen and (min-width: 1441px)': {
        right: '-4px',
      },
    },

    '&:hover': {
      '& .clearIcon': {
        display: 'block',
      },
    },

    '&.iconUnderlinePosition': {
      '& .viboSpinner, & .clearIcon': {
        top: '43%',
      },
    },
  },

  timeOptions: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
    height: 'fit-content',
    width: 'fit-content',

    '& li': {
      fontSize: '13px',
      lineHeight: '18.2px',
      cursor: 'pointer',
      padding: '6px 12px 6px 6px',
      whiteSpace: 'nowrap',

      '&.active': {
        backgroundColor: theme.palette.primary.lighty,
      },

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: colors.white,
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
  },
}));

export default useStyles;
