import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import client from './graphql/client';

import './i18n';
import 'react-virtualized/styles.css';
import 'resources/styles/main.css';

const render = (App: React.FC) =>
  ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

render(App);

window.addEventListener('storage', e => {
  if (e.key === 'vibo_token') {
    !e.newValue && client.resetStore();
    client.writeData({ data: { isLoggedIn: !!e.newValue, scannerComputer: null } });
  }
});
