import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import ContentArea from 'components/common/ContentArea';
import WithEventRole from 'components/user/WithEventRole';
import EventTitle from 'components/events/Timeline/EventTitle';
import { EventContext } from 'components/context/EventContext';

import { isDontPlaySection } from 'services/sections/helpers';

import { IconmoonFont } from 'vibo-ui/Icon';
import { EventUserType } from 'types/enums';
import { SectionBlocksHeaderProps } from './interfaces';

import useStyles from './style';

const SectionBlocksHeader: FC<SectionBlocksHeaderProps> = ({ onEditClick, section }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEventDj, isEventHost, canEditSection = false } = useContext(EventContext);
  const isDontPlay = isDontPlaySection(section);

  return (
    <ContentArea
      className={classNames('sectionBlocksHeader', classes.sectionBlocksHeader)}
      shadowed
    >
      <div className={classes.blocksHeaderTitle}>
        <EventTitle className={classes.sectionName} name={section.name} />
        <WithEventRole
          roles={[EventUserType.dj, EventUserType.host]}
          extraCondition={(isEventHost && !isDontPlay) || isEventDj}
        >
          {!!onEditClick && canEditSection ? (
            <Button
              onClick={onEditClick}
              prefixIcon={IconmoonFont['settings-16']}
              displayType="link"
              data-gtm-target-id="timeline-section-details-settings"
            >
              {t('sectionSettings')}
            </Button>
          ) : null}
        </WithEventRole>
      </div>
    </ContentArea>
  );
};

export default SectionBlocksHeader;
