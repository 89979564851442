import { gql } from 'graphql.macro';

import { USER_PREVIEW_DATA } from './user';

export const EVENTS_LIST_ITEM = gql`
  fragment EventItemFragment on EventsListItem {
    _id
    image {
      squareUrl
      rectUrl
      cropped {
        small
        medium
      }
    }
    title
    status
    date
    type
    timezone
    lastUpdated
    usersCount
    playlistSize
    progress
    sectionsWithSongsProgress
    isDeleted
    sectionsWithSongs
    sectionsWithSongsTotal
    questionsCount
    answeredQuestionsCount
    role
    hasGeneralQuestions
    hasSectionQuestions
    isPast
    hostsCount
    guestsCount
    location {
      name
      lat
      lng
    }
    djs {
      ...UserPreviewData
    }
  }
  ${USER_PREVIEW_DATA}
`;

export const EVENT_DETAILS = gql`
  fragment EventDetailsFragment on Event {
    _id
    sectionsWithSongs
    sectionsWithSongsTotal
    sectionsWithSongsProgress
    questionsCount
    answeredQuestionsCount
    title
    timezone
    image {
      squareUrl
      rectUrl
    }
    status
    location {
      name
    }
    type
    lockDate
    progress
    sectionsCount
    specialSongsProgress
    date
    hasGeneralQuestions
    hasSectionQuestions
    lastUpdated
    hostsCount
    guestsCount
    usersCount
    playlistSize
    daysCountBeforeLock
    arrivalTime
    startTime
    endTime
    hostDeepLink
    guestDeepLink
    emailMessage {
      host
      guest
    }
    template {
      _id
      title
    }
    isDeleted
    isLocked
    settings {
      canHostsInviteGuests
      canGuestsInviteGuests
      canHostCreateSections
      canHostReorderSections
      sectionSongsLimit
      sectionMustPlayLimit
    }
    djs {
      ...UserPreviewData
    }
    role
    currentTime
    note
    emailMessage {
      host
      guest
    }
    isPast
  }
  ${USER_PREVIEW_DATA}
`;

export const EVENT_TITLE_AND_NOTES = gql`
  fragment GetTitleAndNotesFromEvent on Event {
    title
    note
  }
`;

export const EVENT_NOTE = gql`
  fragment EventNote on Event {
    note
  }
`;

export const HOST_DEEPLINK = gql`
  fragment HostDeepLinkFragment on Event {
    hostDeepLink
    emailMessage {
      host
    }
  }
`;

export const GUEST_DEEPLINK = gql`
  fragment GuestDeepLinkFragment on Event {
    guestDeepLink
    emailMessage {
      guest
    }
  }
`;

export const EVENT_FILE_OPTIONS = gql`
  fragment EventFileOptionsWebFragment on EventFileOptionsWeb {
    __typename
    withEventDetails
    withDJNotes
    withTimeline
    withSections
    withPlaylists
    pdfScale
    printUnansweredQuestions
    timelinePrintQuestions
    font
    landscape
    isColorful
    timeline {
      _id
      name
      visibility
      selected
      showSongs
      showNotes
      showQuestions
      songsCount
      questionsCount
      notesEnabled
      songsEnabled
      answeredQuestionsCount
      questionsEnabled
      isHeadline
      notesVisibleForHosts
      __typename
    }
    sections {
      _id
      name
      visibility
      selected
      showSongs
      showNotes
      showQuestions
      songsCount
      questionsCount
      answeredQuestionsCount
      songsEnabled
      notesEnabled
      questionsEnabled
      isHeadline
      notesVisibleForHosts
      __typename
    }
    playlists {
      _id
      name
      selected
      visibility
      songsCount
      songsEnabled
      isHeadline
      __typename
    }
  }
`;

export const EVENT_SECTION_NOTES_VISIBLE_HOSTS = gql`
  fragment EventSectionNotesVisibleHosts on Section {
    settings {
      notesVisibleForHosts
      __typename
    }
    __typename
  }
`;

export const EVENT_CONTACT = gql`
  fragment EventContact on EventContact {
    _id
    email
    role
    status
    createdAt
    firstName
    lastName
    phoneCode
    phoneNumber
    photo {
      url
      cropped {
        small
        medium
      }
    }
    __typename
  }
`;

export const AVAILABLE_EVENT_DJ = gql`
  fragment AvailableEventDj on AvailableEventDj {
    _id
    firstName
    lastName
    nick
    showName
    imageUrl
    logoUrl
    isMe
    image {
      cropped {
        small
        medium
      }
    }
    isAssigned
    __typename
  }
`;
