import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon/Icon';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper/StopPropagationWrapper';
import Select, { SimpleOption } from 'vibo-ui/Select';

import { sectionVisibility } from 'services/sections/constants';

import { SectionVisibilityRoles } from 'types/enums';
import { VisibilityDropdownProps, SectionVisibilityOption } from './interfaces';

import useStyles from './style';

const VisibilityDropdown: FC<VisibilityDropdownProps> = ({
  handleChange,
  value,
  isDisabled = false,
  allowedTypes = [],
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectedOptionObj = sectionVisibility.find(
    option => option.id === value
  ) as SectionVisibilityOption;

  const options = useMemo(
    () =>
      sectionVisibility
        .filter(({ id }) => allowedTypes.includes(id))
        .map(option => ({
          label: t(option.name),
          value: option.id,
          className: classNames(classes.visibilityOption, `${option.id}-type`),
          ...option,
        })),
    []
  );

  const handleChangeVisibility = useCallback(option => {
    const { value } = option as SimpleOption;

    handleChange?.(value as SectionVisibilityRoles);
  }, []);

  return (
    <StopPropagationWrapper>
      <Select
        value={selectedOptionObj.id}
        className={classes.visibilityDropdown}
        onChange={handleChangeVisibility}
        components={{
          Option: props => <Select.Option {...props}>{props.label}</Select.Option>,
        }}
        selectedValuePrefix={
          <Icon
            className={classNames(classes.visibilityOption, `${selectedOptionObj.id}-type`)}
            icon={selectedOptionObj.icon}
          />
        }
        menuPortalTarget={document.body}
        options={options}
        isDisabled={isDisabled}
        withCustomValueContainer
        hideDropdownIndicator
        menuShouldBlockScroll
      />
    </StopPropagationWrapper>
  );
};

export default VisibilityDropdown;
