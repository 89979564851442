import React, { FC, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Select from 'vibo-ui/Select';

import { EVENT_STATUSES } from '../../../../services/constants';
import { getEventStatusLabel, getStatusColor } from '../../../../services/events/helpers';

import { StatusSelectProps } from './interfaces';

import useStyles from './style';

const StatusSelect: FC<StatusSelectProps> = ({ event, className, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const eventStatus = getEventStatusLabel(event);

  const isHistory = event.isPast || event.isDeleted;

  const options = useMemo(
    () =>
      isHistory
        ? [
            {
              label: t(eventStatus),
              value: eventStatus,
              color: getStatusColor(eventStatus),
            },
          ]
        : Object.keys(EVENT_STATUSES).map(option => ({
            label: t(option),
            value: option,
            color: getStatusColor(option),
          })),
    [isHistory, eventStatus]
  );

  const handleChange = useCallback(option => {
    onChange(get(EVENT_STATUSES, option.value));
  }, []);

  return (
    <Select
      components={{
        Option: props => {
          const data = get(props, 'data') as { color: string };

          return (
            <Select.Option {...props}>
              <div
                className={classes.dot}
                style={{ margin: '0 6px 0 0', backgroundColor: data.color }}
              />
              {props.label}
            </Select.Option>
          );
        },
      }}
      selectedValuePrefix={
        <div className={classes.dot} style={{ backgroundColor: getStatusColor(eventStatus) }} />
      }
      value={eventStatus}
      options={options}
      onChange={handleChange}
      className={classNames(classes.statusSelect, className)}
      isDisabled={isHistory}
      withCustomValueContainer
    />
  );
};

export default StatusSelect;
