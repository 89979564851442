import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  commentPreview: {
    ...theme.mixins.longText,
    backgroundColor: theme.palette.background.paper,
    padding: '2px 4px',
    width: 'fit-content',
    fontSize: '14px',
    lineHeight: '21.07px',
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    color: theme.palette.text.primary,
    maxWidth: '98px',
    maxHeight: '48px',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '4px',
    flex: 'inherit!important',

    '@media screen and (min-width: 1242px)': {
      whiteSpace: 'pre-wrap',
    },

    '@media screen and (min-width: 1441px)': {
      maxWidth: '150px',
    },

    '@media screen and (min-width: 1537px)': {
      maxWidth: '160px',
    },
  },
}));

export default useStyles;
