import { ChangeEvent } from 'react';

import { setSelection } from 'vibo-ui/utils/helpers/dom';
import { timeRegexAmPmFormat } from './constants';

const MAX_HOUR = 12;
const MAX_MINUTE = 59;
const MINUTES_INTERVAL = 5;

export const checkTimeExist = (time?: string): boolean => {
  if (!!time) {
    return (
      TIME_SUGGESTIONS.some(searchOption => searchOption === time) && checkTimeRegexValid(time)
    );
  }
  return false;
};

export const getTimeSearchValue = (time: string) => time.match(/[1-9:AMP]+/g)?.join('') || '';

export const getTimeSuggestions = (minutesInterval: number): string[] => {
  const suggestions: string[] = [];
  const amPm = ['PM', 'AM'];
  const maxIntervalMinute = MAX_MINUTE - minutesInterval + 1;

  for (const meridiem of amPm) {
    for (let minute = 0; minute <= maxIntervalMinute; minute += minutesInterval) {
      const hours = `0${MAX_HOUR}`.slice(-2);
      const minutes = `0${minute}`.slice(-2);

      suggestions.push(`${hours}:${minutes} ${meridiem}`);
    }

    for (let hour = 1; hour < MAX_HOUR; hour++) {
      for (let minute = 0; minute <= maxIntervalMinute; minute += minutesInterval) {
        const hours = `0${hour}`.slice(-2);
        const minutes = `0${minute}`.slice(-2);

        suggestions.push(`${hours}:${minutes} ${meridiem}`);
      }
    }
  }

  return suggestions;
};

export const getHourSubstring = (value: string): string => value.substring(0, 1);

export const processTimeValue = (e: ChangeEvent<HTMLInputElement>): string => {
  const value = e.currentTarget.value;
  const hour = +getHourSubstring(value);

  if (hour > 1) {
    setSelection(e.currentTarget, 3);

    return `0${hour}${value.substring(2)}`;
  }

  return value;
};

export const TIME_SUGGESTIONS = getTimeSuggestions(MINUTES_INTERVAL);

export const checkTimeRegexValid = (time?: string): boolean => {
  if (!!time) {
    return new RegExp(timeRegexAmPmFormat).test(time);
  }
  return false;
};
