import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import Tooltip from 'vibo-ui/Tooltip';
import UserImage from 'components/user/images/UserImage';

import { useModal } from 'vibo-ui/Modal';
import { getDjName, getSmallerUserImage } from 'services/users/helpers';

import { Modals } from 'types/enums';
import { DjDetailsModalProps } from 'components/modals/DjDetailsModal/interfaces';
import { DjPreviewProps } from './interfaces';

import useStyles from './style';

const DjPreview: FC<DjPreviewProps> = ({
  dj,
  className,
  children,
  showDetails,
  showTooltip = true,
}) => {
  const classes = useStyles();

  const { openedModals, openModal } = useModal();

  const openDjDetailsModal = useCallback(
    () =>
      showDetails &&
      openModal<DjDetailsModalProps>({
        key: Modals.djDetails,
        props: {
          djId: dj._id,
        },
      }),
    [dj._id, showDetails, openedModals]
  );

  const name = getDjName(dj);

  return !!dj ? (
    <Tooltip overlay={showTooltip ? name : ''} type="primary">
      <div
        className={classNames('djPreview', classes.djPreview, className)}
        key={dj._id}
        onClick={openDjDetailsModal}
      >
        <UserImage
          user={dj as Maybe<UserBase>}
          src={getSmallerUserImage(dj)}
          className={classes.djLogo}
        />
        <div className={classNames('content', classes.content)}>
          {children}
          <div className="djName">{name}</div>
        </div>
      </div>
    </Tooltip>
  ) : null;
};

export default DjPreview;
