import React, { FC, useCallback, useContext, useMemo } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import Select from 'vibo-ui/Select';
import { TemplateFilterableContext } from '../../../../../context/TemplateContext';

import { GET_TEMPLATE_TYPES_STATS } from '../../../../../../graphql/queries/template';

import { TemplatesType } from '../../../../../../types/enums';

const TypeColumn: FC = () => {
  const { t } = useTranslation();

  const { filter, setFilter } = useContext(TemplateFilterableContext);

  const { type } = useParams<{ type: TemplatesType }>();

  const { data } = useQuery<TemplateTypesStatsResponse>(GET_TEMPLATE_TYPES_STATS, {
    variables: {
      source: type,
    },
    fetchPolicy: 'cache-and-network',
  });
  const templateTypes: TemplateTypeStat[] = get(data, 'getTemplateTypesStats', []);

  const options = useMemo(
    () =>
      templateTypes.map(option => ({
        selectedLabel: t(option.type),
        label: `${t(option.type)} (${option.count})`,
        value: option._id,
      })),
    [templateTypes]
  );

  const handleChange = useCallback(option => {
    setFilter(option.value);
  }, []);

  return (
    <div className="typeColumn">
      <Select
        value={filter?.typeId}
        onChange={handleChange}
        options={options}
        selectedValuePrefix={t('type')}
        styleModificator="asLink"
        withCustomValueContainer
        hideDropdownIndicator
      />
    </div>
  );
};

export default TypeColumn;
