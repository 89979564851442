import React, { FC, useEffect, useState, Ref } from 'react';
import compact from 'lodash/compact';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Input from 'vibo-ui/Input';

import { toastNotify } from 'graphql/hooks/common';
import { updateSongComment } from 'graphql/cache/songs';
import { useFormikAutoSave } from 'graphql/hooks/form';
import { MAX_SONG_COMMENT_LENGTH } from 'services/constants';

import { UPDATE_SECTION_SONGS } from 'graphql/mutations/songs';

import { CommentInputProps, RouteParams } from './interfaces';

const CommentInput: FC<CommentInputProps> = ({ inputRef, song, onBlur, onFocus, className }) => {
  const { t } = useTranslation();

  const [refetchStats, setRefetchStats] = useState<boolean>(true);

  const { id: eventId, sectionId } = useParams<RouteParams>();

  const [updateSong] = useMutation<UpdateSectionSongsData, UpdateSectionSongsVariables>(
    UPDATE_SECTION_SONGS,
    {
      onCompleted: () => {
        toastNotify({ text: t('songCommentSaved') });
      },
      refetchQueries: compact([refetchStats && 'getSectionSongsStats']),
    }
  );

  const { formik } = useFormikAutoSave<{ comment: string }>(
    {
      initialValues: {
        comment: song.comment ?? '',
      },
      onSubmit: payload => {
        if (payload.comment !== song.comment) {
          updateSongComment(song._id, payload.comment);
          updateSong({
            variables: {
              eventId,
              sectionId,
              payload,
              songIds: [song._id],
            },
          });
        }
      },
      enableReinitialize: true,
    },
    500
  );

  const comment = formik.values.comment;

  useEffect(() => {
    setRefetchStats(!song.comment || !comment);
  }, [song.comment, comment]);

  return (
    <Input
      ref={inputRef as Ref<HTMLInputElement> | undefined}
      onChange={(_, value) => formik.setFieldValue('comment', value)}
      value={comment}
      onBlur={() => {
        onBlur?.(comment);
      }}
      onPressEnter={() => inputRef?.current?.blur()}
      onFocus={onFocus}
      className={className}
      maxLength={MAX_SONG_COMMENT_LENGTH}
      placeholder={t('commentAboutThisSong')}
      withUnderline
    />
  );
};

export default CommentInput;
