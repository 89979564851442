import React, { FC, useContext } from 'react';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';

import Scrollbar from 'vibo-ui/Scrollbar';
import EventContactRow from './EventContactRow';
import NoUsers from 'components/emptyStates/NoUsers';
import EventContactsHeader from './EventContactsHeader';
import LoadingOverlay from 'components/common/LoadingOverlay';
import VirtualizedList from 'components/common/VirtualizedList';
import NoUsersContent from 'pages/events/EventUsers/NoUsersContent';
import { EventContext } from 'components/context/EventContext';

import { GET_EVENT_CONTACTS } from 'graphql/queries/events';

import useStyles from './style';

const EventContacts: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isEventDj } = useContext(EventContext);

  const { id: eventId } = useParams<EventPageRouteParams>();

  const [filter] = useQueryParams({
    status: StringParam,
    role: StringParam,
  });

  const { data: getContactsData, loading, fetchMore } = useQuery<GetEventContacts>(
    GET_EVENT_CONTACTS,
    {
      variables: {
        eventId,
        filter: pickBy(filter),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const contacts = getContactsData?.eventContacts.contacts || [];
  const totalCount = getContactsData?.eventContacts.totalCount || 0;
  const pagination = getContactsData?.eventContacts.next;

  const hasFilter = !!filter?.status || !!filter?.role;
  const contactsCount = contacts?.length || 0;

  const loadMore = () => {
    !loading &&
      !!pagination &&
      fetchMore({
        variables: {
          pagination,
        },
        updateQuery: (prev: GetEventContacts, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            eventContacts: {
              ...prev.eventContacts,
              contacts: [...prev.eventContacts.contacts, ...fetchMoreResult.eventContacts.contacts],
              next: fetchMoreResult.eventContacts.next,
            },
          };
        },
      });
  };

  return !!contactsCount || hasFilter ? (
    <div
      className={classNames(classes.eventContacts, {
        forUser: !isEventDj,
      })}
    >
      <EventContactsHeader />
      {hasFilter && !contactsCount ? (
        <NoUsers>{t('noPeopleFound')}</NoUsers>
      ) : (
        <Scrollbar id="event-contacts" shiftThumb>
          <VirtualizedList<EventContact>
            rowRenderer={contact => (
              <EventContactRow contact={contact} key={`event-contact-${contact._id}`} />
            )}
            scrollId="event-contacts"
            data={contacts}
            loadMore={loadMore}
            totalRowsCount={totalCount}
          />
        </Scrollbar>
      )}
      {loading ? <LoadingOverlay /> : null}
    </div>
  ) : (
    <NoUsersContent />
  );
};

export default EventContacts;
