import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SearchInput from 'vibo-ui/SearchInput';
import { SectionsFilterableContext } from '../../events/Timeline/SectionsFilterContext';

import { TimelineSectionsHeaderProps } from './interfaces';

import useStyles from './style';

const TimelineSectionsHeader: FC<TimelineSectionsHeaderProps> = ({
  numSections,
  filter,
  buttons,
  onSearch,
  loading = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { setFilter } = useContext(SectionsFilterableContext);

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, q: string) => {
    setFilter({ q });
    onSearch(q);
  };

  return (
    <div className={classNames('timelineSectionsHeader', classes.timelineSectionsHeader)}>
      {numSections || !!filter?.q ? (
        <SearchInput
          onChange={handleChange}
          placeholder={t('searchSections', { count: numSections })}
          loading={loading}
          queryName="sectionsQ"
          data-gtm-target-id="timeline-sections-search"
        />
      ) : null}
      {!!buttons ? (
        <div
          className={classNames('buttons', classes.buttons, {
            [classes.noButtons]: !buttons?.length,
          })}
        >
          {buttons}
        </div>
      ) : null}
    </div>
  );
};

export default TimelineSectionsHeader;
