export interface CustomLabelProps {
  type?: CustomLabelTypes;
  className?: string;
}

export enum CustomLabelTypes {
  'success' = 'success',
  'default' = 'default',
  'gray' = 'gray',
}
