import { createContext } from 'react';
import { SectionVisibilityRoles } from '../../../../../types/enums';
import { TUpdateSection } from '../../../../sections/blocks/SectionBlocks/interfaces';

type TTimelineNotesContext = {
  variables: SectionSongsVariables;
  section: TimelineSection;
  sections: TimelineSection[];
  updateSection: TUpdateSection<{ note?: string }>;
};

type TEditingNotesContext = {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  notesText: string;
  setNotesText: (newValue: string) => void;
};

type TVisibilityNotesContext = {
  handleNotesVisiblity: (option: SectionVisibilityRoles) => void;
};

export const TimelineNotesContext = createContext<TTimelineNotesContext>(undefined!);

export const EditingNotesContext = createContext<TEditingNotesContext>(undefined!);

export const VisibilityNotesContext = createContext<TVisibilityNotesContext>(undefined!);
