import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_ME } from '../../../graphql/queries/user';

interface WithDjTypeProps {
  types: DjType[];
}

const WithDjType: React.FC<WithDjTypeProps> = ({ types, children }) => {
  const { data } = useQuery<UserResponse>(GET_ME);
  const user: Maybe<User> = data?.me;
  const djType: Maybe<DjType> = user?.djSettings?.type;

  if (!djType || !types.includes(djType)) {
    return null;
  }

  return <>{children}</>;
};

export default WithDjType;
