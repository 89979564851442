import React, { FC, useCallback, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import DjsPreview from 'components/common/DjsPreview';
import ContentArea from 'components/common/ContentArea';
import EventTypeIcon from 'components/events/common/EventTypeIcon';
import EventStatusIndicator from 'components/events/EventStatusIndicator';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import googleCalendar from 'services/googleCalendar';
import { useModal } from 'vibo-ui/Modal';
import { EVENT_TIME_FIELDS } from 'services/constants';
import { getDateWithTimezone } from 'services/common/dateHelpers';

import { EventTimeType, EventType, Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { EventGeneralViewProps } from '../interfaces';

import useStyles from '../style';

const EventGeneralUserView: FC<EventGeneralViewProps> = ({ event }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { location } = useHistory();
  const routeState = location.state as HistoryRouteState;

  const { openModal } = useModal();

  const openAddNewDjModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('locked'),
          children: t('eventLockedForChanges'),
          submit: { text: t('ok'), onClick: () => {} },
        },
      }),
    []
  );

  const eventDate = getDateWithTimezone(event.date, event.timezone);
  const lockDate = getDateWithTimezone(event.lockDate, event.timezone);

  useEffect(() => {
    if (event.isLocked && routeState?.prevPage !== 'event') {
      openAddNewDjModal();
    }
  }, []);

  return (
    <ContentArea shadowed>
      {!!eventDate ? (
        <div className={classNames('row date')}>
          <Icon className={classes.fieldIcon} icon={IconmoonFont['calendar-24']} />
          <div>{moment(`${eventDate}`).format('ddd, MMM Do YYYY')}</div>
          <Button onClick={() => googleCalendar.create(event)}>{t('addToCalendar')}</Button>
        </div>
      ) : null}
      {EVENT_TIME_FIELDS.filter(({ key }) => key !== EventTimeType.arrivalTime).map(field =>
        !!event[field.key] ? (
          <div className={classes.timeInputWrapper} key={field.key}>
            <Icon className={classes.fieldIcon} icon={field.icon} />
            <p>{t(field.translationKey)}</p>
            <div className="time">{event[field.key]}</div>
          </div>
        ) : null
      )}
      {!!event.location?.name ? (
        <div className={classNames('row')}>
          <Icon icon={IconmoonFont['location-24']} className={classes.fieldIcon} />
          <div>{event.location.name}</div>
        </div>
      ) : null}
      <div className={classNames('row djs')}>
        <Icon className={classes.fieldIcon} icon={IconmoonFont['headphones-24']} />
        <DjsPreview djs={event.djs} showDetails />
      </div>
      <div className="row status">
        {event.status ? <EventStatusIndicator event={event} /> : null}
      </div>
      {!!lockDate && !!event.daysCountBeforeLock ? (
        <div className={classNames(classes.timeInputWrapper, 'row dateLock')}>
          <Icon className={classes.fieldIcon} icon={IconmoonFont['lockedCalendar-24']} />
          <p>{t('lockoutDate')}</p>
          <div>
            {moment(`${lockDate}`).format('ddd, MMM Do YYYY')}
            <span className="days">{`(${t('countDaysBefore', {
              count: event?.daysCountBeforeLock || 0,
            })})`}</span>
          </div>
        </div>
      ) : null}
      <div className={classNames('row eventType', classes.eventType)}>
        <Icon
          className={classes.fieldIcon}
          icon={() => <EventTypeIcon type={event.type as EventType} />}
        />
        <div>{event.type}</div>
      </div>
    </ContentArea>
  );
};

export default EventGeneralUserView;
