import React, { useContext, useEffect, FC } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

import Container from 'components/common/Container';
import EventPageHeader from 'components/headers/EventPageHeader';
import { LogoContext } from 'components/context/LogoContext';
import { EventContextProvider } from 'components/context/EventContext';

import { GET_EVENT } from 'graphql/queries/events';

import { EventLayoutProps } from './interfaces';

import useStyles from './style';

const EventLayout: FC<EventLayoutProps> = ({ className, children, page, isLoading = false }) => {
  const classes = useStyles();

  const { setUrl, setAlt, setId } = useContext(LogoContext);

  const { id: eventId } = useParams<EventPageRouteParams>();

  const { data, error, loading } = useQuery<EventResponse>(GET_EVENT, {
    variables: { eventId },
    fetchPolicy: 'cache-and-network',
    skip: !eventId,
  });

  const event: Maybe<EventItem> = data?.event;

  useEffect(() => {
    setUrl(event?.image?.squareUrl);
    setAlt(event?.title);
    setId(event?._id);

    return () => {
      setUrl(null);
      setAlt(null);
      setId(null);
    };
  }, [event]);

  if (error) {
    return null;
  }

  return (
    <div className={classNames(classes.eventLayout, className)}>
      <EventContextProvider value={{ isLoading: loading || isLoading, event, page }}>
        <EventPageHeader />
        <Container>{children}</Container>
      </EventContextProvider>
    </div>
  );
};

export default EventLayout;
