import { gql } from 'graphql.macro';

export const NOTIFICATION = gql`
  fragment NotificationFragment on Notification {
    _id
    imageUrl
    header
    body
    isRead
    notificationType
    createdAt
    metadata {
      eventId
    }
  }
`;
