import { gql } from 'graphql.macro';

export const SONG_LINKS = gql`
  fragment SongLinks on SongLinks {
    youtube
    spotify
    appleMusic
    tidal
    soundcloud
    amazonStore
    deezer
  }
`;

export const THUMBNAILS = gql`
  fragment Thumbnails on Thumbnails {
    s180x180
    original
    max
    youtube
  }
`;

export const SONG_LIKES_INFO = gql`
  fragment SongLikesInfo on SectionSong {
    likedByMe
    likesCount
  }
`;

export const SONG_COMMENT = gql`
  fragment SongComment on SectionSong {
    comment
  }
`;

export const PREP_MODE_SONG_IS_FLAGGED = gql`
  fragment PrepModeSongIsFlagged on PrepModeSong {
    isFlagged
  }
`;

export const SONG_IS_FLAGGED = gql`
  fragment SongIsFlagged on SectionSong {
    isFlagged
  }
`;

export const SONG_IS_MUST_PLAY = gql`
  fragment SongIsMustPlay on SectionSong {
    isMustPlay
  }
`;

export const SONG_FILE = gql`
  fragment SongFile on SongFile {
    fileName
    revisedFileName
    fileId
    filePath
    directory
    fileSize
    bitrate
    duration
    selectedCount
    seratoPlayCount
    djayProPlayCount
    traktorPlayCount
    virtualDJPlayCount
    rekordboxPlayCount
    enginePrimePlayCount
    score
    lastSelected
    isSelected
    bpm
    title
    artist
    genre
    album
    comment
  }
`;

export const SONG_FILES = gql`
  fragment SongFiles on PrepModeSong {
    files {
      ...SongFile
    }
  }
  ${SONG_FILE}
`;

export const SECTION_SEARCHED_SONG_DATA = gql`
  fragment SearchedSongData on SearchedSong {
    sectionSongId
    isInSection
  }
`;

export const BEATSOURCE_SONGS_STATE = gql`
  fragment MyBeatsourceSongsState on BeatsourceSongsState {
    ids
  }
`;
