import React, { FC, useContext, useEffect, memo, useCallback } from 'react';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Sections from 'components/sections/Sections';
import TimelineSection from 'components/sections/TimelineSection';
import ColorfulSectionWrapper from '../../ColorfulSectionWrapper';
import TimelineSectionsHeader from 'components/sections/TimelineSectionsHeader';
import AddTimelineSectionButton from 'components/buttons/AddTimelineSectionButton';
import { EditableContext } from 'components/common/EditableContext';
import { SectionsFilterableContext } from '../SectionsFilterContext';

import { ReactComponent as PlusIcon } from 'resources/img/svg/common/plus.svg';

import { useModal } from 'vibo-ui/Modal';
import { useColorfulSections } from 'graphql/hooks/sections';
import { hasSectionsFilterValue } from 'services/sections/helpers';
import { compareSearchStrings } from 'services/common/stringHelpers';

import {
  REORDER_TEMPLATE_SECTIONS,
  DELETE_TEMPLATE_SECTION,
  CREATE_TEMPLATE_SECTION,
} from 'components/../graphql/mutations/template';
import { TEMPLATE_SECTIONS } from 'components/../graphql/queries/template';

import { Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { DeleteSectionModalProps } from 'components/modals/DeleteSectionModal/interfaces';
import { TemplateSectionsProps } from './interfaces';

const TemplateSections: FC<TemplateSectionsProps> = ({
  template,
  sections,
  selectedSection,
  refetch,
  updateSection,
  onSectionClick,
  onEditClick,
}) => {
  const { t } = useTranslation();

  const templateId: string = template._id;

  const { filter, setFilter } = useContext(SectionsFilterableContext);

  const { canEdit } = useContext(EditableContext);

  const { sectionColors } = useColorfulSections({ sections });

  const { openModal } = useModal();

  const openDeleteSectonModal = useCallback(
    section =>
      openModal<DeleteSectionModalProps>({
        key: Modals.deleteSection,
        props: {
          delteSectionMutation: DELETE_TEMPLATE_SECTION,
          refetchQueries: ['templateSections'],
          variables: {
            templateId,
            sectionId: section._id,
          },
        },
      }),
    [templateId]
  );
  const openConfirmReorderingModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('clearSearch'),
          children: t('clearSearchReorderSections'),
          submit: {
            text: t('clear'),
            onClick: handleClearReordering,
          },
        },
      }),
    []
  );

  const [reorderSections] = useMutation(REORDER_TEMPLATE_SECTIONS);

  const filteredSections = sections.filter(section =>
    compareSearchStrings(section.name, filter?.q)
  );

  const handleClearReordering = useCallback(() => {
    setFilter(null);
    refetch({ templateId, filter: omit(filter, 'q') });
  }, [templateId, filter?.q]);

  useEffect(() => {
    if (!selectedSection || (!sections.includes(selectedSection) && sections.length)) {
      onSectionClick(sections[0]);
    }
  }, [sections, selectedSection, onSectionClick]);

  return (
    <Sections<TemplateSection, TemplateSectionsVariables>
      sections={filteredSections}
      reorderProps={{
        // TODO handle types issue and remove ts-ignore
        // @ts-ignore
        onReorder: reorderSections,
        variables: { templateId, filter: omit(filter, 'q') },
        listQuery: TEMPLATE_SECTIONS,
        listCacheKey: 'templateSections',
        onBeforeCapture: () => {
          if (hasSectionsFilterValue(filter)) {
            return openConfirmReorderingModal();
          }
        },
      }}
      renderSection={({ section, isDragging }): JSX.Element => (
        <ColorfulSectionWrapper
          color={sectionColors.find(option => option.sectionId === section._id)?.color}
        >
          <TimelineSection
            section={section}
            isSelected={section._id === selectedSection?._id}
            isDragging={isDragging}
            onClick={onSectionClick}
            onEditClick={onEditClick}
            onDeleteClick={section => {
              openDeleteSectonModal(section);
            }}
            onTimeChange={debounce(
              time =>
                updateSection({
                  variables: {
                    templateId,
                    sectionId: section._id,
                    payload: {
                      time,
                    },
                  },
                }),
              500
            )}
            onNameChange={debounce(
              name =>
                updateSection({
                  variables: {
                    templateId,
                    sectionId: section._id,
                    payload: { name },
                  },
                }),
              1000
            )}
          />
        </ColorfulSectionWrapper>
      )}
      renderHeader={() => (
        <TimelineSectionsHeader
          numSections={sections.length}
          filter={filter}
          onSearch={q => {
            setFilter(prev => ({ ...prev, q }));
          }}
          buttons={
            canEdit
              ? [
                  <AddTimelineSectionButton
                    refetchQueries={['templateSections']}
                    createSectionMutation={CREATE_TEMPLATE_SECTION}
                    variables={{ templateId, filter: omit(filter, 'q') }}
                    eventSettings={template.settings}
                    prefixIcon={PlusIcon}
                    key="add_section_btn"
                    displayType="primary"
                    shape="round"
                    size="lg"
                    hideTooltip
                  >
                    {t('addSection')}
                  </AddTimelineSectionButton>,
                ]
              : []
          }
        />
      )}
    />
  );
};

export default memo(TemplateSections);
