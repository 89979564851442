import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';

import Action from '../Action';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import { ReactComponent as DeleteIcon } from '../../../../../../resources/img/svg/common/delete.svg';

import { DELETE_SECTION_SONGS } from '../../../../../../graphql/mutations/songs';
import { onError } from '../../../../../../graphql/helpers';
import { toastNotify } from '../../../../../../graphql/hooks/common';

import { ButtonIconType } from '../../../../../../types/enums';
import { ActionProps } from '../interfaces';

const Delete: FC<ActionProps> = ({ songIds, onCompleted, ...rest }) => {
  const { t } = useTranslation();

  const { songsOptions, refetchSongs } = useContext(TimelineSongsContext);

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const [deleteSong, { loading: loadingDeleteSong }] = useMutation(DELETE_SECTION_SONGS, {
    onCompleted: () => {
      songsOptions.removeSongsById(songIds);
      onCompleted?.();
      toastNotify({
        text: t('songDeleted', { count: songIds.length }),
        iconType: ButtonIconType.delete,
      });
      refetchSongs();
    },
    onError,
    refetchQueries: ['sections', 'getSectionSongsStats'],
  });

  const handleClick = () =>
    loadingDeleteSong
      ? null
      : deleteSong({
          variables: {
            eventId,
            sectionId,
            songIds,
          },
        });

  return (
    <Action
      onClick={handleClick}
      icon={DeleteIcon}
      text={t('delete')}
      songIds={songIds}
      {...rest}
    />
  );
};

export default Delete;
