import React, { FC, useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Textarea from 'vibo-ui/Textarea';
import CopyQuestionAnswer from '../../CopyQuestionAnswer';
import { QuestionContext } from '../../../events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import { MAX_QUESTION_LENGTH } from 'services/constants';
import { usePrevious } from 'services/common/dataProcessingHelpers';

import useStyles from '../../common/style';

const Text: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isTriggered, setIsTriggered] = useState(false);

  const formik = useFormikContext<UpdateQuestionForm>();

  const { canAnswer } = useContext(QuestionContext);

  const id = get(formik.values, '_id');
  const isAnswered = get(formik.values, 'isAnswered');

  const prevIsAnswered = usePrevious(isAnswered);

  useEffect(() => {
    if (!!prevIsAnswered && !formik.values.answer?.text) {
      setIsTriggered(!isTriggered);
    }
  }, [isAnswered, prevIsAnswered, formik.values.answer?.text]);

  return (
    <div className="textAnswer">
      <Textarea
        defaultValue={formik.values.answer?.text}
        onChange={(_, value) => {
          formik.setFieldValue('answer.text', value);
        }}
        placeholder={t('yourAnswer')}
        wrapperClassName={classes.textQuestionInput}
        className="textQuestionInput"
        disabled={!canAnswer}
        maxLength={MAX_QUESTION_LENGTH}
        showMaxLength
        key={`question-answer-${id}-${isTriggered}`}
      />
      {/* <QuillEditor
        value={formik.values.text}
        onChange={e => formik.setFieldValue('text', e)}
        toolbarSchema={[ToolbarButton.link]}
        moduleFormats={['link']}
        placeholder={t('yourAnswer')}
        toolbarId={`toolbar-${question._id}`}
        readOnly={!canAnswer}
      /> */}
      <CopyQuestionAnswer value={formik.values.answer?.text} />
    </div>
  );
};

export default Text;
