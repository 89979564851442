import React, { FC, createContext, useState, useContext, useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useSearchParam } from 'react-use';

import { GA4Context } from '../GA4Context';
import { DeepLinkContext } from '../DeepLinkContext';
import { WizardContext } from 'vibo-ui/Wizard/WizardStepContext';

import { CHECK_EMAIL_EXIST, LOG_USER_IN, SIGN_IN } from 'graphql/mutations/auth';

import { onError } from 'graphql/helpers';

import { SignUpSteps } from 'components/auth/SignIn/interfaces';
import { HandleLogUserIn, SignInContextProps } from './interfaces';

export const SignInContext = createContext<SignInContextProps>(undefined!);

export const SignInContextProvider: FC = ({ children }) => {
  const url = useSearchParam('url');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const wizard = useContext(WizardContext);
  const deepLinkContext = useContext(DeepLinkContext);
  const { initGaLogin } = useContext(GA4Context);

  const [checkEmailExists, { loading: isCheckingEmail }] = useMutation(CHECK_EMAIL_EXIST, {
    onCompleted: res => {
      if (res.checkEmailExists.exists) {
        wizard.wizardRefProps?.goToNamedStep(SignUpSteps.signIn);
      } else {
        wizard.wizardRefProps?.goToNamedStep(SignUpSteps.confirmEmail);
      }
    },
    onError,
  });

  const checkEmailExistInApp = useCallback(
    (emailToCheck: string) => {
      checkEmailExists({
        variables: {
          email: emailToCheck,
          isMobile: false,
          pross: false,
        },
      });
    },
    [wizard]
  );

  const [logUserIn] = useMutation(LOG_USER_IN, {
    onCompleted: () => {
      if (!!url) {
        deepLinkContext?.action?.();
      }
    },
    onError,
  });

  const [signIn] = useMutation(SIGN_IN, {
    onCompleted: data => {
      logUserIn({
        variables: data.signIn,
      });
    },
    onError,
  });

  const handleSignIn = useCallback(() => {
    signIn({
      variables: {
        email,
        password,
      },
    });
  }, [email, password]);

  const handleLogUserIn: HandleLogUserIn = useCallback((options, loginSource) => {
    initGaLogin(loginSource);
    logUserIn(options);
  }, []);

  return (
    <SignInContext.Provider
      value={{
        setEmail,
        setPassword,
        setFirstName,
        setLastName,
        setToken,
        checkEmailExistInApp,
        handleSignIn,
        handleLogUserIn,
        isCheckingEmail,
        data: {
          token,
          email,
          password,
          firstName,
          lastName,
        },
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};
