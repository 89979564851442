import React, { FC, Suspense } from 'react';
import { ApolloProvider } from 'react-apollo';
import { IntercomProvider } from 'react-use-intercom';
import { ToastContainer, Zoom } from 'react-toastify';

import { UserContextProvider } from './components/context/UserContext';
import { ViboThemeProvider } from './components/context/ViboThemeContext';
import { IntercomContextProvider } from './components/context/IntercomContext';
import { ApolloLoadingContextProvider } from './components/context/ApolloLoadingContext';
import Routes from './Routes';

import { INTERCOM_APP_ID } from './services/constants';

import client from './graphql/client';

const App: FC = () => (
  <ApolloProvider client={client}>
    <ApolloLoadingContextProvider>
      <ViboThemeProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <UserContextProvider>
              <IntercomContextProvider>
                <Routes />
              </IntercomContextProvider>
            </UserContextProvider>
          </IntercomProvider>
        </Suspense>
        <ToastContainer limit={1} transition={Zoom} />
      </ViboThemeProvider>
    </ApolloLoadingContextProvider>
  </ApolloProvider>
);

export default App;
