import React, { FC, memo } from 'react';
import classNames from 'classnames';
import isString from 'lodash/isString';

import { SvgElement } from 'vibo-ui/Icon';
import { IconProps } from './interfaces';

import useStyles from './style';

const ViboIcon: FC<IconProps> = ({ icon, className, disableAutoFontSize, ...rest }) => {
  const classes = useStyles();

  const isIconString = isString(icon);

  const SVGIcon = (isIconString ? null : icon) as SvgElement;

  const defaultClassName = classNames('viboIcon', className);

  return isIconString ? (
    <i
      className={classNames(`icon-${icon}`, classes.viboIcon, defaultClassName, {
        disableAutoFontSize,
      })}
      {...rest}
    />
  ) : (
    <i
      className={classNames('viboIconSVG', classes.viboIconSVG, defaultClassName, {
        disableAutoFontSize,
      })}
      {...rest}
    >
      <SVGIcon />
    </i>
  );
};

const shouldUpdate = (prevProps: IconProps, nextProps: IconProps) => {
  return (
    prevProps.disableAutoFontSize === nextProps.disableAutoFontSize &&
    prevProps.icon === nextProps.icon &&
    prevProps.className === nextProps.className
  );
};

export default memo<FC<IconProps>>(ViboIcon, shouldUpdate);
