import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  modal: {
    margin: 0,
    maxHeight: '100%',
    borderRadius: '4px',

    '& .viboTooltipOvelay': {
      zIndex: 1050,
    },

    '& .rc-dialog-content': {
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
    },

    '& .rc-dialog-header': {
      borderRadius: '4px 4px 0 0',
      color: theme.palette.selection.whiteToBalticSeaDark,
      padding: '0 16px',
      height: '36px',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      border: 'none',

      '& .rc-dialog-title': {
        ...theme.mixins.longText,
        maxWidth: '96%',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '22.5px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '18px',
          lineHeight: '27px',
        },
      },
    },

    '& .rc-dialog-body': {
      padding: '0',
      overflowX: 'hidden',
    },

    '& .rc-dialog-close': {
      opacity: 1,
      padding: '0',
      textShadow: 'none',
      color: theme.palette.selection.whiteToBalticSeaDark,
      right: '11px',
      top: '6px',
    },

    '&.smModal, &.mdModal': {
      width: '400px',

      '@media screen and (min-width: 1441px)': {
        width: '560px',
      },
    },

    '&.lgModal, &.xlgModal': {
      width: '680px',

      '@media screen and (min-width: 1441px)': {
        width: '800px',
      },
    },

    '&.smModal': {
      '& .modalBody': {
        height: '170px',

        '&.autoHeight': {
          maxHeight: '170px',

          '@media screen and (min-width: 1441px)': {
            maxHeight: '220px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          height: '220px',
        },
      },
    },

    '&.mdModal': {
      '& .modalBody': {
        height: '370px',

        '&.autoHeight': {
          maxHeight: '370px',

          '@media screen and (min-width: 1441px)': {
            maxHeight: '524px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          height: '524px',
        },
      },
    },

    '&.lgModal': {
      '& .modalBody': {
        height: '310px',

        '&.autoHeight': {
          maxHeight: '310px',

          '@media screen and (min-width: 1441px)': {
            maxHeight: '364px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          height: '364px',
        },
      },
    },

    '&.xlgModal': {
      '& .modalBody': {
        height: '480px',

        '&.autoHeight': {
          maxHeight: '480px',

          '@media screen and (min-width: 1441px)': {
            maxHeight: '564px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          height: '564px',
        },
      },
    },

    '&.xxlgModal': {
      width: '842px',

      '& .modalBody': {
        height: '516px',

        '&.autoHeight': {
          maxHeight: '516px',

          '@media screen and (min-width: 1441px)': {
            maxHeight: '696px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          height: '696px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        width: '1151px',
      },
    },
  },

  viboModalWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalHeader: {
    padding: '6px 16px',
    backgroundColor: theme.palette.background.contentArea,
    boxShadow: theme.shadows[1],
    minHeight: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,

    '& > .clickAwayListener': {
      width: '100%',
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '48px',
    },
  },

  modalBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    zIndex: 1,

    '&.noScroll': {
      padding: '16px',
    },

    '&.autoStaticHeight, &.autoHeight': {
      '& > .viboScrollbar': {
        height: 'fit-content!important',
        maxHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',

        '& > .scrollView': {
          position: 'relative!important',
          maxHeight: '100%',
          height: 'max-content!important',
        },
      },
    },

    '&.autoStaticHeight': {
      height: 'fit-content!important',
    },

    '&.autoHeight': {
      height: 'auto!important',
      overflow: 'hidden',
    },

    '& > .modalBodyScroll.shiftThumb': {
      '& > .scrollView': {
        padding: '20px',
      },

      '& > .trackVertical': {
        right: '5px',
      },
    },
  },

  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px 16px',

    '& .viboButton': {
      width: '50%',
      justifyContent: 'center',

      '&:not(:last-child)': {
        margin: '0 16px 0 0',
      },

      '&:only-child': {
        minWidth: '100%',
      },
    },
  },
}));

export default useStyles;
