import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  textQuestionAnswer: {
    wordBreak: 'break-all',
    fontSize: '13px',
    lineHeight: '16px',
    minWidth: '50px',
    width: 'fit-content',
    maxWidth: '100%',
    color: theme.palette.text.primary,

    '& input': {
      border: '0',
      height: '36px',
      padding: '5px',
      flex: '0 0 91%',
      marginRight: '19px',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
    },
  },

  underlineTextarea: {
    lineHeight: '26px!important',
    border: 'none',
    borderBottom: `1px solid ${theme.palette.primary.light}`,

    '&:focus, &:focus:hover, &:hover': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
  },

  multiplyGroup: {
    '& .isOther': {
      width: '100%',

      '@media screen and (max-width: 1441px)': {
        paddingBottom: '4px',
      },
    },

    '&.checkboxes, & .multiplyOption': {
      '&:not(:first-child)': {
        margin: '12px 0 0 0',

        '@media screen and (min-width: 1441px)': {
          margin: '14px 0 0 0',
        },
      },
    },

    '& .radio': {
      marginBottom: '0!important',
    },
  },

  textQuestionInput: {
    '& .closeWrapper': {
      display: 'flex',
    },
  },

  questionBody: {
    marginTop: '10px',
  },

  answer: {
    width: 'auto',
    marginLeft: '25px',

    '&.openedQuestion': {
      margin: '4px 45px 0 22px',
      width: 'auto',
    },

    '& input': {
      background: theme.palette.background.default,
      border: '0',
      height: '36px',
      padding: '5px',
      flex: '0 0 91%',
      marginRight: '19px',
    },

    '& a': {
      zIndex: '10000',
    },
  },

  optionInput: {
    resize: 'none',
    width: '508px',
  },

  footer: {
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    justifyContent: 'space-between',
    position: 'relative',
    fontSize: '13px',
    lineHeight: '18.2px',

    '@media screen and (min-width: 1441px)': {
      height: '26px',
      fontSize: '16px',
      lineHeight: '24px',
    },

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },
  },

  nav: {
    marginRight: '19px',
  },

  descriptionFooter: {
    height: '40px',

    '& svg': {
      marginTop: '16px',
    },
  },

  btn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },

  customCheckbox: {
    display: 'flex',
    flexDirection: 'column',

    '&.openedQuestion': {
      marginTop: '10px',
    },

    '& span': {
      fontSize: '14px',
    },
  },

  deleteIcon: {
    position: 'relative',
    opacity: 0,
    pointerEvents: 'none',
    top: '8px',

    '&.showDeleteBtn': {
      opacity: '1',
      pointerEvents: 'all',
    },
  },

  wrapItem: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',

    '&:last-child': {
      marginBottom: '0',
    },

    '& label': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '24px',

      '& > span:last-child': {
        width: '100%',
        padding: '0 4px 0 8px',

        '&:hover div.activeName': {
          boxShadow: `inset 0px -1px ${theme.palette.primary.main}`,
        },
      },
    },

    '&.closedQuestion label > span:last-child': {
      padding: '0 8px',

      '& .textQuestionAnswer ': {
        color: theme.palette.text.secondary,
      },
    },
  },

  emptyQuestionBody: {
    height: '28px',
  },

  multiplyOptionContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '24px',
    whiteSpace: 'normal',

    '&.viboCheckbox, &.radio': {
      marginRight: '8px!important',
    },
  },

  multiplyOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    width: '100%',

    '&.withCopyIconSpace': {
      padding: '0 24px 0 0',
    },

    '& .viboImage': {
      ...theme.mixins.getStaticIconSize?.(140),
      margin: '2px 8px 2px 2px',
      borderRadius: '4px',
      cursor: 'pointer',

      '@media screen and (min-width: 1441px)': {
        ...theme.mixins.getStaticIconSize?.(200),
        margin: '5px 12px 5px 4px',
      },
    },

    '& .textQuestionAnswer': {
      '&:not(.canAnswer)': {
        cursor: 'not-allowed',
      },
    },

    '& .textPreview.lg': {
      lineHeight: '28px',

      '&.withAnswer': {
        height: 'auto',
        minHeight: 0,
      },

      '@media screen and (min-width: 1441px)': {
        lineHeight: '34px',
      },
    },
  },

  fileAnswer: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',

    '&.withAnswer': {
      gap: '2px',
      borderRadius: '4px',

      '& .label': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        gap: '4px',

        '& > .viboButton': {
          margin: '0 0 0 auto',
          padding: 0,
          opacity: 0,
        },

        '@media screen and (min-width: 1441px)': {
          gap: '6px',
        },
      },

      '&:hover': {
        '& .label > .viboButton': {
          opacity: 1,
        },
      },

      '&.imageAnswer': {
        padding: '0 8px',

        '& .viboImage': {
          ...theme.mixins.getStaticIconSize?.(300),
          borderRadius: '4px',
          cursor: 'pointer',

          '@media screen and (min-width: 1441px)': {
            ...theme.mixins.getStaticIconSize?.(400),
          },
        },

        '@media screen and (min-width: 1441px)': {
          padding: '0 10px',
        },
      },

      '&.pdfAnswer': {
        '& .filePreview': {
          whiteSpace: 'nowrap',
          padding: 0,
        },
      },
    },
  },

  infoAnswer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '6px',

    '&.phoneAnswer, & .phoneNumber': {
      zIndex: 101,
    },

    '&.locationAnswer': {
      '& .viboToggleDropdown': {
        width: '100%',
      },
    },

    '& > .viboIcon': {
      color: theme.palette.text.hint,
    },

    '@media screen and (min-width: 1441px)': {
      gap: '8px',
    },
  },
}));

export default useStyles;
