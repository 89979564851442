import { ReactElement } from 'react';
import padStart from 'lodash/padStart';

import moment from 'services/moment';

const MAX_HOUR = 12;
const MAX_MINUTE = 59;
const MINUTES_INTERVAL = 5;

export const normalizeTime = (value: number | string) => padStart(value.toString(), 2, '0');

export const getTimeSuggestions = (minutesInterval: number): string[] => {
  const suggestions: string[] = [];
  const amPm = ['PM', 'AM'];
  const maxIntervalMinute = MAX_MINUTE - minutesInterval + 1;

  for (const meridiem of amPm) {
    for (let minute = 0; minute <= maxIntervalMinute; minute += minutesInterval) {
      const hours = normalizeTime(MAX_HOUR);
      const minutes = normalizeTime(minute);

      suggestions.push(`${hours}:${minutes} ${meridiem}`);
    }

    for (let hour = 1; hour < MAX_HOUR; hour++) {
      for (let minute = 0; minute <= maxIntervalMinute; minute += minutesInterval) {
        const hours = normalizeTime(hour);
        const minutes = normalizeTime(minute);

        suggestions.push(`${hours}:${minutes} ${meridiem}`);
      }
    }
  }

  return suggestions;
};

export const filterSuggestions = (inputValue: string, option: ReactElement<any>): boolean => {
  const searchRegExps = inputValue.split(' ').map(value => new RegExp(value, 'i'));

  return searchRegExps.every(
    regex => option.props.children && regex.test(option.props.children?.toString())
  );
};

export const getFutureTimestamp = (minutes: number) => {
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + minutes * 60000);

  return futureDate;
};

export const TIME_SUGGESTIONS = getTimeSuggestions(MINUTES_INTERVAL);

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const secToTime = (sec: number) => moment.utc(sec * 1000).format('mm:ss');
