import React, { FC, useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import classNames from 'classnames';
import Tooltip from 'vibo-ui/Tooltip';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import Icon from 'vibo-ui/Icon';
import SelectedLinkField from './SelectedLinkField';
import { UserPolicyFields } from '../../../../context/PolicyContext';

import { SOCIAL_LINKS_STRUCTURE } from '../../../../../services/constants';
import { useFieldPolicy } from '../../../../../graphql/hooks/policy';

import { LinkData, SocialLinksProps } from './interfaces';

import useStyles from './style';

const SocialLinks: FC<SocialLinksProps> = ({ handleUpdateProfile, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedLinks, setSelectedLinks] = useState<LinkData[]>([]);
  const [error, setError] = useState({
    instagram: false,
    facebook: false,
    youtube: false,
    spotify: false,
    soundcloud: false,
    mixcloud: false,
    tiktok: false,
  });

  const { isEditable } = useFieldPolicy(UserPolicyFields.socialLinks);

  const updateDebounced = useRef(
    debounce((site: keyof SocialLinks, url: string) => {
      handleUpdateProfile({ socialLinks: { [site]: url } })
        .then(() => {
          setError({ ...error, [site]: false });
        })
        .catch(({ graphQLErrors }: any) => {
          const errorTarget = graphQLErrors[0]?.field.split('.')[1];

          if (errorTarget) {
            return setError({ ...error, [errorTarget]: true });
          }
        });
    }, 1000)
  ).current;

  const updateLinks = (site: keyof SocialLinks, url: string) => {
    updateDebounced(site, url);
  };

  const handleSelectLink = (value: LinkData) => {
    setSelectedLinks([...selectedLinks, value]);
  };

  const handleDeselectLink = (value: LinkData) => {
    setSelectedLinks(selectedLinks.filter(link => link.name !== value.name));
    updateLinks(value.name.toLowerCase() as keyof SocialLinks, '');
  };

  const handleChangeLink = (value: LinkData, text: string) => {
    const linkName = value.name.toLowerCase();
    const prevLinkText = get(user?.socialLinks, linkName);

    if (prevLinkText !== text) {
      updateLinks(linkName as keyof SocialLinks, text);
    }
  };

  useEffect(() => {
    setSelectedLinks(
      uniq([
        ...selectedLinks,
        ...SOCIAL_LINKS_STRUCTURE.filter(
          link => !!get(user?.socialLinks ?? {}, link.name.toLowerCase())
        ),
      ])
    );
  }, [user?.socialLinks]);

  return (
    <div className={classes.profilesInner}>
      {selectedLinks.map(link => {
        const value = get(user?.socialLinks, link.name.toLowerCase());

        return (
          <SelectedLinkField
            handleChangeLink={handleChangeLink}
            value={value}
            handleDeselectLink={handleDeselectLink}
            link={link}
            error={error}
            key={link.name}
          />
        );
      })}
      {isEditable ? (
        <>
          <div className={classNames('text', classes.addLinkTitle)}>
            <div>{t('addLink')}</div>
          </div>
          <div className={classes.selectLinks}>
            {SOCIAL_LINKS_STRUCTURE.map((link, idx) => {
              return selectedLinks.some(({ name }) => link.name === name) ? null : (
                <div
                  onClick={() => handleSelectLink(link)}
                  className={classNames('linkToSelect', classes.linkToSelect)}
                  key={idx}
                >
                  <Tooltip overlay={`${t('add')} ${link.name}`} type="primary">
                    <Icon icon={link.icon} />
                  </Tooltip>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SocialLinks;
