import React, { FC, useMemo, useCallback } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';

import UserRole from './UserRole';
import CellName from 'components/common/CellName';
import ContentArea from 'components/common/ContentArea';
import UserImage from 'components/user/images/UserImage';

import { useModal } from 'vibo-ui/Modal';
import { getFullName } from 'services/users/helpers';

import { Modals } from 'types/enums';
import { UpdateUserModalProps } from 'components/modals/UpdateUserModal';
import { UserRowProps } from './interfaces';

import useStyles from './style';

const UserRow: FC<UserRowProps> = ({ user }) => {
  const classes = useStyles();

  const name = useMemo(() => getFullName(user), [user.firstName, user.lastName]);

  const { openModal, openedModals } = useModal();

  const openUpdateUserModal = useCallback(() => {
    openModal<UpdateUserModalProps>({
      key: Modals.updateUser,
      props: {
        user,
      },
    });
  }, [
    user?._id,
    user?.email,
    user?.firstName,
    user?.lastName,
    user?.role,
    user?.djSettings,
    openedModals,
  ]);

  return (
    <ContentArea
      className={classNames('userRow', classes.userRow)}
      onClick={openUpdateUserModal}
      shadowed
    >
      <CellName name="photo">
        <UserImage
          user={user as UserBase}
          src={get(user, 'image.cropped.small', '')}
          loadMs={400}
        />
      </CellName>
      <CellName name="name" title={name}>
        {name}
      </CellName>
      <CellName name="role">
        <UserRole user={user} />
      </CellName>
      <CellName name="email" title={user.email}>
        {user.email}
      </CellName>
      <CellName name="finance-status">{user.djSettings?.financeStatus}</CellName>
    </ContentArea>
  );
};

export default UserRow;
