import React, { FC, memo } from 'react';

import EventsNavigation from './EventsNavigation';
import Header from 'components/common/Header';
import Container from 'components/common/Container';

const EventsPageHeader: FC = () => (
  <Header>
    <Container>
      <EventsNavigation />
    </Container>
  </Header>
);

export default memo(EventsPageHeader);
