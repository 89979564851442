import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SettingsItem from './SettingsItem';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { DjFinanceStatus } from 'types/enums';
import { SettingsItemWithUserProps } from './interfaces';

import useStyles from './style';

const PaymentStatus: FC<SettingsItemWithUserProps> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isActivated = user!.djSettings?.financeStatus === DjFinanceStatus.activated;
  const financeStatus = useMemo(() => user!.djSettings!.financeStatus || '-', [
    user?.djSettings?.financeStatus,
  ]);

  return (
    <SettingsItem>
      <div className={classNames('label', classes.label)}>
        <Icon icon={IconmoonFont['creditCardChecked-24']} className={classes.generalIcon} />
        <div className={classes.greyText}>{t('paymentStatus')}</div>
        <div
          className={classNames(classes.boldText, classes.role, {
            [classes.successText]: isActivated,
          })}
        >
          {t(financeStatus)}
        </div>
      </div>
    </SettingsItem>
  );
};

export default PaymentStatus;
