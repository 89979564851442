import React, { FC, useCallback, useContext } from 'react';
import { useParams } from 'react-router';

import SpotifyButton from '../SpotifyButton';
import { EventContext } from 'components/context/EventContext';

import { useModal } from 'vibo-ui/Modal';

import { Modals } from 'types/enums';
import { ExportToSpotifyModalProps } from 'components/modals/ExportToSpotifyModal';

const ExportToSpotifyButton: FC = () => {
  const { id: eventId, sectionId } = useParams<{ id: string; sectionId: string }>();

  const eventContext = useContext(EventContext);

  const { openModal } = useModal();

  const openExportToSpotifyModal = useCallback(
    () =>
      !!eventContext?.event &&
      openModal<ExportToSpotifyModalProps>({
        key: Modals.exportToSpotify,
        props: {
          eventContext,
          eventId,
          sectionId,
        },
      }),
    [eventContext, eventId, sectionId]
  );

  return <SpotifyButton onClick={openExportToSpotifyModal} displayType="default" />;
};

export default ExportToSpotifyButton;
