import get from 'lodash/get';
import { Dispatch, SetStateAction } from 'react';
import isUndefined from 'lodash/isUndefined';

import { MAX_SONGS_IN_SPECIAL_SECTION } from '../constants';
import { hasQuestions } from '../questions/helpers';
import { sectionVisiblitySettings } from './constants';

import {
  SectionIconType,
  SectionTimelineItemType,
  SectionType,
  SectionBlockName,
  SectionVisibilityRoles,
} from '../../types/enums';
import {
  SECTION_DEFAULTS,
  SECTION_SETTINGS_DEFAULTS,
} from 'components/modals/CreateSectionModal/constants';

export const isTimelineSection = (v: any): v is TimelineSection => {
  return v && '_id' in v && 'name' in v && 'type' in v && 'settings' in v;
};

export const isEventSection = (v: any): v is EventSection => {
  return isTimelineSection(v) && 'hasSongs' in v;
};

export const isTemplateSection = (v: any): v is TemplateSection => {
  return isTimelineSection(v) && !isEventSection(v);
};

export const isFavoriteSection = (v: any): v is FavoriteSectionOption => {
  return !!get(v, 'userId') || !!get(v, 'favoriteSectionId');
};

export const isPrepModeSection = (v: any): v is PrepModeSection => {
  return (
    v &&
    '_id' in v &&
    'name' in v &&
    'songsCount' in v &&
    'preparedSongsCount' in v &&
    'isDone' in v
  );
};

export const getSectionSongsInfo = (section: SectionBase, totalCount?: number): string => {
  if (isEventSection(section)) {
    const songsCount = isUndefined(totalCount) ? section.songsCount : totalCount;

    return `${songsCount}${section.settings.songsLimit ? `/${section.settings.songsLimit}` : ''}`;
  }

  if (!!section?.settings) {
    return section.settings.songsLimit ? `0/${section.settings.songsLimit}` : '';
  }

  return '';
};

export const getSectionQuestionsInfo = (section: TimelineSectionBase): string =>
  section && hasQuestions(section) ? `${section.answeredCount}/${section.questionsCount}` : '';

export const updateSectionIdFromRoute = <T extends SectionBase>(
  selectedSection: Nullable<T>,
  setSelectedSection: Dispatch<SetStateAction<Nullable<T>>>,
  sectionIdFromRoute: Maybe<string>,
  sectionsList: T[]
) => {
  if (sectionIdFromRoute) {
    const sectionToSelect: Maybe<T> = sectionsList.find(
      section => section._id === sectionIdFromRoute
    );

    if (sectionToSelect) {
      return setSelectedSection(sectionToSelect);
    }
  }

  if (!selectedSection) {
    const firstSection = get(sectionsList, '[0]');

    if (firstSection) {
      return setSelectedSection(firstSection);
    }
  }
};

export const getSectionTimelineItemType = <TSection extends SectionBase>(
  section: TSection
): SectionTimelineItemType => {
  if (section.type === SectionType.dontPlay) {
    return SectionTimelineItemType.dontPlay;
  }

  if (!section.settings.songsEnabled) {
    return SectionTimelineItemType.noMusic;
  }

  if (section.settings.songsLimit && section.settings.songsLimit <= MAX_SONGS_IN_SPECIAL_SECTION) {
    return SectionTimelineItemType.specialMoment;
  }

  return SectionTimelineItemType.songList;
};

export const getSectionIconType = <TSection extends TimelineSectionBase>(
  section: TSection
): SectionIconType => {
  if (section.type === SectionType.dontPlay) {
    return SectionIconType.dontPlay;
  }

  if (!section.settings.songsEnabled) {
    return SectionIconType.noMusic;
  }

  if (section.settings.visibleForGuests) {
    return SectionIconType.public;
  }

  if (section.settings.songsLimit === 1) {
    return SectionIconType.specialMoment;
  }

  return SectionIconType.songList;
};

export const getInitialUpdateSectionValues = (
  section: Nullable<TimelineSection>
): UpdateSectionForm => {
  if (isFavoriteSection(section)) {
    return {
      type: section?.type,
      name: section.name,
      description: section.description,
      settings: section.settings,
    };
  }

  if (isEventSection(section)) {
    return {
      type: section.type,
      name: section.name,
      time: section.time || '',
      description: section.description || '',
      settings: section.settings,
    };
  }

  if (isTemplateSection(section)) {
    return {
      type: section.type,
      name: section.name,
      time: section.time || '',
      description: section.description,
      settings: section.settings,
    };
  }

  return {
    ...SECTION_DEFAULTS,
    settings: SECTION_SETTINGS_DEFAULTS,
  };
};

export const hasSectionsFilterValue = (filter: Maybe<Nullable<SectionsFilter>>): boolean => {
  return !!filter && (!!filter.q || typeof filter.songsEnabled !== 'undefined');
};

export const getSectionFromListById = <TSection extends SectionBase>(
  sections: TSection[],
  sectionId: string
): Maybe<TSection> => sections.find(section => section._id === sectionId);

export const isEventSectionVariables = <TVariables extends SectionSongIdeasListVariables>(
  variables: TVariables
): variables is TVariables => {
  return 'eventId' in variables;
};

export const isDontPlaySection = <TSection extends SectionBase>(
  section: Nullable<TSection>
): boolean => section?.type === SectionType.dontPlay;

export const isHeadlineSection = <TSection extends SectionBase>(
  section: Nullable<TSection>
): boolean => section?.type === SectionType.headline;

export const getSuggestedSectionTime = (
  section: TimelineSectionBase,
  sections: TimelineSectionBase[]
): string => {
  const sectionIndex = sections.findIndex(s => s._id === section._id);

  for (let i = sectionIndex - 1; i >= 0; i--) {
    const time = sections[i].time;

    if (time) {
      return time;
    }
  }

  return '';
};

export const hasBlock = <TSection extends { blocks: SectionBlock[] }>(
  section: TSection,
  blockName: SectionBlockName
): boolean => section?.blocks?.some(block => block.name === blockName) || false;

export const getVisibleSections = (sections: EventSection[]) => {
  return sections.filter(section => section.settings.songsEnabled && section.songsCount);
};

export const getSectionVisibility = (settings: {
  visibleForGuests: boolean;
  visibleForHosts: boolean;
}) => {
  const sectionVisibility =
    (Object.entries(sectionVisiblitySettings).find(
      visibilitySsettings =>
        visibilitySsettings[1].visibleForGuests === settings?.visibleForGuests &&
        visibilitySsettings[1].visibleForHosts === settings?.visibleForHosts
    )?.[0] as SectionVisibilityRoles) || SectionVisibilityRoles.dj;

  return sectionVisibility;
};
