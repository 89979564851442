import * as Yup from 'yup';

import i18n from 'i18n';
import { MAX_LIMIT_DIGITS, MAX_TITLE_LENGTH } from 'services/constants';

import { SectionType } from 'types/enums';

export const SECTION_DEFAULTS = {
  type: SectionType.simple,
  name: '',
  description: '',
  time: '',
};

export const SECTION_SETTINGS_DEFAULTS = {
  visibleForGuests: false,
  visibleForHosts: true,
  timeEnabled: true,
  songsEnabled: true,
  notesEnabled: true,
  canHostsOrderSongs: true,
  canHostDeleteSection: true,
  notesVisibleForHosts: true,
  canHostChangeSectionName: true,
  canHostChangeSectionTime: true,
};

export const createSectionValidation = Yup.object().shape({
  name: Yup.string()
    .max(MAX_TITLE_LENGTH, i18n.t('nameMustBeCharactersOrLess', { count: MAX_TITLE_LENGTH }))
    .trim()
    .required(i18n.t('sectionNameRequired')),
  settings: Yup.object().shape({
    songsLimit: Yup.string()
      .min(0)
      .max(MAX_LIMIT_DIGITS, i18n.t('mustBeLessThanNumber', { count: 1000 }))
      .nullable(),
    mustPlayLimit: Yup.string()
      .min(0)
      .max(MAX_LIMIT_DIGITS, i18n.t('mustBeLessThanNumber', { count: 1000 }))
      .nullable(),
  }),
});
