import React, { useContext } from 'react';
import classNames from 'classnames';

import Menu from 'vibo-ui/Menu';
import Delete from '../TimelineSongsHeader/Delete';
import MoveTo from '../TimelineSongsHeader/MoveTo';
import DontPlay from '../TimelineSongsHeader/DontPlay';
import MustPlay from '../TimelineSongsHeader/MustPlay';
import { TimelineSongsContext } from '../TimelineSongsContext';

import { isDontPlaySection } from 'services/sections/helpers';

import { ToolsProps } from './interfaces';

import useStyles from './style';

const Tools = <T extends SectionSong>({ song }: ToolsProps<T>) => {
  const classes = useStyles();

  const { section } = useContext(TimelineSongsContext);

  const defaultOptionProps = {
    className: classNames('option', classes.option),
    songIds: [song?._id ?? ''],
    withConfirm: true,
  };

  return (
    <Menu
      className={classes.moreToolsMenu}
      styleModificator="asDropdown"
      selectable={false}
      activeKey=""
    >
      <Menu.Item>
        <MustPlay
          {...defaultOptionProps}
          data-gtm-target-id="timeline-section-details-songs-song-mustplay"
        />
      </Menu.Item>
      {isDontPlaySection(section) ? null : (
        <Menu.Item>
          <DontPlay
            {...defaultOptionProps}
            data-gtm-target-id="timeline-section-details-songs-song-dontplay"
          />
        </Menu.Item>
      )}
      <Menu.Item>
        <MoveTo
          {...defaultOptionProps}
          withConfirm={false}
          data-gtm-target-id="timeline-section-details-songs-song-moveto"
        />
      </Menu.Item>
      <Menu.Item>
        <Delete
          {...defaultOptionProps}
          data-gtm-target-id="timeline-section-details-songs-song-delete"
        />
      </Menu.Item>
    </Menu>
  );
};

export default Tools;
