import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboScrollbar: {
    display: 'grid',
    height: '100%',
    gridTemplate: 'auto / auto',
    position: 'relative',
    maxHeight: '100%',
    width: '100%',

    '&.withVirtualContent': {
      '& .scrollView': {
        height: '100vh',
      },
    },

    '&.shiftThumb': {
      width: 'calc(100% + 6px)',
      alignSelf: 'center',
      margin: '-4px',

      '& > .scrollView': {
        padding: '4px',
        minWidth: '100%',
      },

      '& > .trackVertical': {
        right: '-16px',
      },
    },

    '&:hover': {
      '& > .trackVertical.isThumbVisible': {
        opacity: 1,
      },
    },
  },

  scrollView: {
    '-ms-overflow-style': 'none',
    height: '100%',
    maxHeight: 'inherit',
    overflow: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  trackVertical: {
    position: 'absolute',
    width: '6px',
    right: 0,
    top: 0,
    bottom: 0,
    transition: 'opacity .3s',

    '&:not(.isDragging):not(.alwaysShowThumb)': {
      opacity: 0,
    },

    '&:hover, &.isDragging': {
      '& .scrollbarsThumb': {
        opacity: 1,
      },
    },
  },

  yaxis: {
    display: 'block',
    height: '100%',
    position: 'relative',
    width: '6px',
  },

  scrollbarsTrack: {
    borderRadius: '3px',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '6px',
  },

  scrollbarsThumb: {
    borderRadius: '3px',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    opacity: '0.5',
  },
}));

export default useStyles;
