import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Tooltip from 'vibo-ui/Tooltip';
import TimePicker from 'vibo-ui/TimePicker';
import DatePicker from 'vibo-ui/DatePicker';
import Location from 'components/common/Location';
import DjsPreview from 'components/common/DjsPreview';
import ContentArea from 'components/common/ContentArea';
import WithEventRole from 'components/user/WithEventRole';
import FormSwitch from 'components/form/inputs/FormSwitch';
import StatusSelect from 'components/events/fields/StatusSelect';
import EventTypeIcon from 'components/events/common/EventTypeIcon';
import FormNumberInput from 'components/form/inputs/FormNumberInput';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';

import { EVENT_TIME_FIELDS, MAX_DAYS_BEFORE_LOCKING, MAX_SONG_LIMIT } from 'services/constants';
import googleCalendar from 'services/googleCalendar';
import { getDateWithTimezone } from 'services/common/dateHelpers';
import { getTimezone } from 'services/common/timeHelper';
import { useModal } from 'vibo-ui/Modal';

import { EventType, EventUserType, Modals } from 'types/enums';
import { ManageDjsModalProps } from 'components/modals/ManageDjsModal/interfaces';
import { ChangeEventTemplateModalProps } from 'components/modals/ChangeEventTemplateModal';
import { EventGeneralViewProps } from '../interfaces';

import useStyles from '../style';

const EventGeneralDjView: FC<EventGeneralViewProps> = ({
  user,
  event,
  isHistory,
  updateEventLoading = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<UpdateEventForm>();

  const { id: eventId } = useParams<EventPageRouteParams>();

  const { openedModals, openModal } = useModal();

  const openManageDjsModal = useCallback(
    () =>
      openModal<ManageDjsModalProps>({
        key: Modals.manageDjs,
        props: {
          eventId,
        },
      }),
    [eventId, openedModals]
  );
  const openChangeEventTemplatModal = () => {
    !!event.template?._id &&
      openModal<ChangeEventTemplateModalProps>({
        key: Modals.changeEventTemplate,
        props: {
          eventId: event._id,
          template: event.template,
        },
      });
  };

  const eventDate = useMemo(() => getDateWithTimezone(values.date, event.timezone), [
    values.date,
    event.timezone,
  ]);

  return (
    <>
      <ContentArea shadowed>
        <div className={classes.date}>
          <DatePicker
            value={eventDate as string}
            onChange={date => {
              setFieldValue('date', date);
              setFieldValue('timezone', getTimezone());
            }}
            disabled={isHistory}
            disablePast
            withUnderline
          />
          <Button onClick={() => googleCalendar.create(event)}>{t('addToCalendar')}</Button>
        </div>
        {EVENT_TIME_FIELDS.map(field => (
          <div className={classes.timeInputWrapper} key={field.key}>
            <Icon className={classes.fieldIcon} icon={field.icon} />
            <p>{t(field.translationKey)}:</p>
            <TimePicker
              value={event[field.key]}
              onChangeTime={time => setFieldValue(field.key, time)}
              disabled={isHistory || updateEventLoading}
              className={classes.timePicker}
              placeholder="hh:mm"
            />
          </div>
        ))}
        <div className={classes.location}>
          <Icon icon={IconmoonFont['location-24']} className={classes.fieldIcon} />
          <Location
            locationValue={event.location}
            onLocationChange={location => setFieldValue('location', location)}
            placeholder={t('eventLocation')}
            disabled={isHistory}
            withUnderline
          />
        </div>
        {user?.djSettings?.type === 'company' ? (
          <div className={classes.selectedDj}>
            <Icon className={classes.fieldIcon} icon={IconmoonFont['headphones-24']} />
            <DjsPreview djs={event.djs} showDetails>
              <Button onClick={openManageDjsModal} shape="round" shadowed>
                {t('manageDjs')}
              </Button>
            </DjsPreview>
          </div>
        ) : null}
        {values.statusId ? (
          <StatusSelect
            event={event}
            className={classes.status}
            onChange={statusId => setFieldValue('statusId', statusId)}
          />
        ) : null}
        <label className={classNames('daysBeforeLock', classes.daysBeforeLock)}>
          <Icon className={classes.fieldIcon} icon={IconmoonFont['lockedCalendar-24']} />
          <span>{t('numberDaysBeforeLockingEvent')}</span>
          <Tooltip overlay={event.date ? null : t('chooseEventDate')}>
            <span>
              <FormNumberInput
                max={MAX_DAYS_BEFORE_LOCKING}
                disabled={!event.date || isHistory}
                name="daysCountBeforeLock"
              />
            </span>
          </Tooltip>
        </label>
        <div className={classNames('eventType', classes.eventType)}>
          <Icon
            className={classes.fieldIcon}
            icon={() => <EventTypeIcon type={event.type as EventType} />}
          />
          <p>{event.type}</p>
        </div>
        {event.template?.title ? (
          <div className={classes.template}>
            <Icon className={classes.fieldIcon} icon={IconmoonFont['templates-24']} />
            <p>{event.template.title}</p>
            <WithEventRole roles={[EventUserType.dj]}>
              <Button onClick={openChangeEventTemplatModal} shape="round" shadowed>
                {t('changeTemplate')}
              </Button>
            </WithEventRole>
          </div>
        ) : null}
      </ContentArea>
      <ContentArea shadowed>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostsInviteGuests-24']} className={classes.fieldIcon} />
          <div>{t('hostsCanInviteGuests')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostsInviteGuests" />
        </div>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['guestsInviteGuests-24']} className={classes.fieldIcon} />
          <div>{t('guestsCanInviteGuests')}</div>
          <FormSwitch disabled={isHistory} name="settings.canGuestsInviteGuests" />
        </div>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostsAddSections-24']} className={classes.fieldIcon} />
          <div>{t('hostsCanAddNewSections')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostCreateSections" />
        </div>
        <div className="settingsRow">
          <Icon icon={IconmoonFont['hostsReorderSections-24']} className={classes.fieldIcon} />
          <div>{t('hostsCanReorderSections')}</div>
          <FormSwitch disabled={isHistory} name="settings.canHostReorderSections" />
        </div>
        <div className="settingsRow withInput">
          <Icon className={classes.fieldIcon} icon={IconmoonFont['musicNoteDoubleOutlined-24']} />
          <div>{t('songLimitForNewSections')}</div>
          <FormNumberInput
            disabled={isHistory}
            max={MAX_SONG_LIMIT}
            name="settings.sectionSongsLimit"
          />
        </div>
        <div className="settingsRow withInput">
          <Icon className={classes.fieldIcon} icon={IconmoonFont['crown-24']} />
          <div>{t('mustPlaysLimitForNewSections')}</div>
          <FormNumberInput
            disabled={isHistory}
            max={MAX_SONG_LIMIT}
            name="settings.sectionMustPlayLimit"
          />
        </div>
      </ContentArea>
    </>
  );
};

export default EventGeneralDjView;
