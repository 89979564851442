import React, { FC } from 'react';
import classNames from 'classnames';

import WithEventRole from 'components/user/WithEventRole';
import AddTimelineQuestionButton from 'components/buttons/AddTimelineQuestionButton';

import { ReactComponent as PlusCircleFilledIcon } from 'resources/img/svg/common/plusCircleFilled.svg';

import { EventUserType } from 'types/enums';

import { AddQuestionInMiddleProps } from './interfaces';

import useTimelineSectionStyles from 'components/sections/TimelineSection/style';

const AddQuestionInMiddle: FC<AddQuestionInMiddleProps> = ({ questionId }) => {
  const timelineSectionClasses = useTimelineSectionStyles();

  return (
    <WithEventRole roles={[EventUserType.dj]}>
      <AddTimelineQuestionButton
        questionIdAfter={questionId}
        className={classNames('addInTheMiddleBtn', timelineSectionClasses.addInTheMiddleBtn)}
        prefixIcon={PlusCircleFilledIcon}
        displayType="link"
      />
    </WithEventRole>
  );
};

export default AddQuestionInMiddle;
