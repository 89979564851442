import React, { FC, memo, useContext, lazy, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import JoinEventButton from '../../buttons/JoinEventButton';
import NoEvents from '../../emptyStates/NoEvents';
import EventsContentHeader from './EventsContentHeader';
import LoadingOverlay from '../../common/LoadingOverlay';
import WithRole from '../../user/WithRole';
import DjFinanceStatusBar from '../../common/DjFinanceStatusBar';
import CreateEventButton from '../../buttons/CreateEventButton';
import { SortContext } from '../../context/SortContext';
import { EventsContext } from '../../context/EventsContext';
import { IntercomContext } from '../../context/IntercomContext';

import { getEventInfoForIntercom } from '../../../services/events/helpers';

import { UserRole } from '../../../types/enums';
import { EventsListProps } from './interfaces';

import useStyles from './style';

const EventsDJView = lazy(() => import('./views/EventsDjView'));
const EventsUserView = lazy(() => import('./views/EventsUserView'));

const EventsList: FC<EventsListProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { firstEventData, setFirstEventData } = useContext(IntercomContext);
  const { sort, setSort } = useContext(SortContext);
  const { eventsData, loading, queryKey, isFilterApplied, isHistory } = useContext(EventsContext);

  const eventsDefaultProps = {
    ...props,
    sort,
    setSort,
  };

  useEffect(() => {
    if (!firstEventData && eventsData.events.length === 1) {
      const eventPreviewData = getEventInfoForIntercom(eventsData.events[0]);

      setFirstEventData(eventPreviewData);
    }
  }, [eventsData.events.length]);

  return (
    <>
      <div className={classNames(classes.eventsList, props.className)}>
        {loading ? <LoadingOverlay className={classes.eventsLoading} /> : null}
        {isFilterApplied || !!eventsData.totalCount ? (
          <>
            <EventsContentHeader eventsCount={eventsData.totalCount} eventsType={t(queryKey)} />
            <>
              <WithRole roles={[UserRole.dj]}>
                <EventsDJView {...eventsDefaultProps} />
              </WithRole>
              <WithRole roles={[UserRole.user]}>
                <EventsUserView {...eventsDefaultProps} />
              </WithRole>
            </>
          </>
        ) : (
          <NoEvents>
            <div>{t(isHistory ? 'eventsHistoryEmptyState' : 'eventsUpcomingEmptyState')}</div>
            {isHistory ? null : (
              <>
                <WithRole roles={[UserRole.dj]}>
                  <CreateEventButton className="centrate" />
                </WithRole>
                <WithRole roles={[UserRole.user]}>
                  <JoinEventButton className="centrate" />
                </WithRole>
              </>
            )}
          </NoEvents>
        )}
      </div>
      <DjFinanceStatusBar />
    </>
  );
};

export default memo(EventsList);
