import React, { FC, PropsWithChildren } from 'react';
import RcTooltip from 'rc-tooltip';
import classNames from 'classnames';

import { TooltipProps } from './interfaces';

import useStyles from './style';

const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  className,
  children,
  overlay,
  overlayClassName,
  isNoWrapEnabled = false,
  fillOverlay = false,
  type = 'default',
  placement = 'bottom',
  trigger = ['hover'],
  ...rest
}) => {
  const classes = useStyles();

  return (
    <RcTooltip
      placement={placement}
      trigger={trigger}
      overlay={overlay}
      mouseLeaveDelay={0}
      align={{
        offset: [2, 6],
      }}
      getTooltipContainer={() => {
        const nodes = document.querySelectorAll('.rc-dialog-content');

        return (nodes[nodes.length - 1] as HTMLElement) || document.body;
      }}
      {...rest}
      overlayClassName={classNames(
        'viboTooltipOvelay',
        classes.viboTooltipOvelay,
        `${type}Display`,
        {
          fillOverlay,
          withSizeLimit: isNoWrapEnabled,
        },
        className
      )}
      overlayStyle={{ display: !!overlay ? '' : 'none', ...rest.overlayStyle }}
    >
      {children}
    </RcTooltip>
  );
};

export default Tooltip;
