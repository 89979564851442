import React, { MouseEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/react-hooks';

import Icon from 'vibo-ui/Icon';

import { ReactComponent as HeartOutlinedIcon } from '../../../../../resources/img/svg/common/heartOutlined.svg';
import { ReactComponent as HeartFilledIcon } from '../../../../../resources/img/svg/common/heartFilled.svg';
import { ReactComponent as HeartFilledMustPlayIcon } from '../../../../../resources/img/svg/heartFilledMustPlay.svg';
import { ReactComponent as HeartOutlinedMustPlayIcon } from '../../../../../resources/img/svg/heartOutlinedMustPlay.svg';

import { TOGGLE_LIKE } from '../../../../../graphql/mutations/songs';
import { onError } from '../../../../../graphql/helpers';

import { MAX_SONG_LIKES_DISPLAY } from '../../../../../services/constants';

import { LikeProps } from './interfaces';

import useStyles from './style';

const Like = <T extends SectionSong>({ song, className, ...rest }: LikeProps<T>) => {
  const classes = useStyles();

  const [isLiked, setIsLiked] = useState(song?.likedByMe || false);
  const [likesCount, setLikesCount] = useState(song?.likesCount || 0);

  const { id: eventId, sectionId } = useParams<EventPageRouteParams>();

  const likedByMeIcon = song?.isMustPlay ? HeartFilledMustPlayIcon : HeartFilledIcon;
  const defaultLikedIcon = song?.isMustPlay ? HeartOutlinedMustPlayIcon : HeartOutlinedIcon;
  const stateIcon = isLiked ? likedByMeIcon : defaultLikedIcon;
  const isLikesLimitExceeded = likesCount > MAX_SONG_LIKES_DISPLAY;

  const [toggleSongLike] = useMutation<ToggleLikeData, ToggleLikeVariables>(TOGGLE_LIKE, {
    onError,
    refetchQueries: ['getSectionSongsStats', 'getSectionSongs'],
  });

  const handleToggleLike: MouseEventHandler<HTMLDivElement> = e => {
    if (song) {
      const liked = !isLiked;
      setIsLiked(liked);

      setLikesCount(likesCount + (isLiked ? -1 : 1));

      toggleSongLike({
        variables: {
          eventId,
          sectionId,
          songId: song._id,
          liked,
        },
      });
    }
  };

  useEffect(() => {
    if (!!song && song.likedByMe !== isLiked) {
      setIsLiked(song.likedByMe);
    }
  }, [song?.likedByMe]);

  useEffect(() => {
    if (!!song && song.likesCount !== likesCount) {
      setLikesCount(song.likesCount);
    }
  }, [song?.likesCount]);

  return (
    <div
      className={classNames(
        'like',
        isLiked ? classes.likedByMe : classes.defaultLiked,
        classes.like,
        className
      )}
      title={isLikesLimitExceeded ? `${likesCount}` : ''}
      onClick={handleToggleLike}
      {...rest}
    >
      <Icon icon={stateIcon} />
      <div className={classes.likesCount}>
        {isLikesLimitExceeded ? `${MAX_SONG_LIKES_DISPLAY}+` : likesCount}
      </div>
    </div>
  );
};

export default Like;
