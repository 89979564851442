import { ViboCustomMixins } from 'types/theme';

export const mixins: ViboCustomMixins = {
  longText: {
    flexShrink: 1,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  highlightedSongPseudo: {
    content: '""',
    left: 0,
    top: 0,
    position: 'absolute',
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    pointerEvents: 'none',
  },

  selectedSectionCaret: {
    content: "''",
    position: 'absolute',
    top: '50%',
    transform: 'translate(100%,-50%)',
    right: '0',
    borderWidth: '6px',
    borderStyle: 'solid',
    borderColor: 'transparent',
  },

  noClickableFilter: {
    filter: 'grayscale(1)',
    pointerEvents: 'none',
  },

  noScroll: {
    scrollbarWidth: 'none',

    // @ts-ignore
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  getStaticIconSize: (size: number) => ({
    minHeight: `${size}px`,
    height: `${size}px`,
    width: `${size}px`,
    minWidth: `${size}px`,
    maxWidth: `${size}px`,
    maxHeight: `${size}px`,
  }),
};
