import { useContext } from 'react';

import { UserContext } from '../../components/context/UserContext';

import { DjType, UserRole } from '../../types/enums';

export const useMe = () => {
  const { user } = useContext(UserContext);

  const isDj = user?.role === UserRole.dj;

  const isAdmin = user?.djSettings?.isAdmin;

  const isChildDj = user?.djSettings?.type === DjType.child;

  const isCompanyDj = user?.djSettings?.type === DjType.company;

  const isJustUser = user?.role === UserRole.user;

  return {
    user,
    isDj,
    isCompanyDj,
    isChildDj,
    isAdmin,
    isJustUser,
  };
};
