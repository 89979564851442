import client from '../client';
import {
  AVAILABLE_EVENT_DJ,
  EVENT_NOTE,
  EVENT_SECTION_NOTES_VISIBLE_HOSTS,
} from '../fragments/events';
import { GET_CREATE_EVENT_FILE_OPTIONS_WEB, GET_EVENT } from '../queries/events';

export const updateEventNote = (eventId: string, note: string): void => {
  client.writeFragment({
    fragment: EVENT_NOTE,
    id: `Event:${eventId}`,
    data: {
      note,
      __typename: 'Event',
    },
  });
};

export const updateEventNotesVisibility = (
  sectionId: string,
  notesVisibleForHosts: boolean
): void => {
  client.writeFragment({
    fragment: EVENT_SECTION_NOTES_VISIBLE_HOSTS,
    id: sectionId,
    data: {
      settings: {
        notesVisibleForHosts,
        __typename: 'SectionSettings',
      },
      __typename: 'Section',
    },
  });
};

export const updateEventPdfSettingsWebCache = ({
  eventId,
  userType,
  data,
}: {
  eventId: string;
  userType: EventFileUserType;
  data: CreateEventFileWebForm;
}): void => {
  const eventFileOptions = client.readQuery<GetCreateEventFileOptionsWeb>({
    query: GET_CREATE_EVENT_FILE_OPTIONS_WEB,
    variables: { eventId },
  });

  if (!!eventFileOptions?.getCreateEventFileOptionsWeb) {
    client.writeQuery<GetCreateEventFileOptionsWeb>({
      query: GET_CREATE_EVENT_FILE_OPTIONS_WEB,
      variables: { eventId },
      data: {
        getCreateEventFileOptionsWeb: {
          ...eventFileOptions.getCreateEventFileOptionsWeb,
          dj: {
            ...data,
            __typename: 'EventFileOptionsWeb',
          },
        } as CreateEventFileWebForm,
      },
    });
  }
};

export const toggleAssignEventDj = (dj: AvailableEventDj, eventId: string, isAssigned: boolean) => {
  client.writeFragment({
    fragment: AVAILABLE_EVENT_DJ,
    id: `AvailableEventDj:${dj._id}`,
    data: {
      isAssigned,
    },
  });

  const cachedEvent = client.readQuery<{ event: EventListItem }>({
    query: GET_EVENT,
    variables: { eventId },
  })?.event;

  if (!!cachedEvent) {
    const updatedDjs = isAssigned
      ? [...(cachedEvent?.djs || []), dj]
      : cachedEvent?.djs.filter(({ _id }) => _id !== dj._id);

    client.writeQuery({
      query: GET_EVENT,
      variables: { eventId },
      data: {
        event: {
          ...cachedEvent,
          djs: updatedDjs,
        },
      },
    });
  }
};
