import React, { FC, useCallback, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import UserRow from './UserRow';
import Spinner from 'vibo-ui/Spinner';
import UsersHeader from './UsersHeader';
import Scrollbar from 'vibo-ui/Scrollbar';
import SearchInput from 'vibo-ui/SearchInput';
import Container from 'components/common/Container';
import VirtualizedList from 'components/common/VirtualizedList';
import PageContentHeader from 'components/common/PageContentHeader';
import AdminUsersPageHeader from 'components/headers/AdminUsersPageHeader';
import { UsersFilterableContext } from './UsersListContext';

import { onError } from 'graphql/helpers';
import { GET_USERS } from 'graphql/queries/admin';

import { DEFAULT_PAGINATION } from 'services/constants';
import { ADMIN_USER_ROW_SM, ADMIN_USER_ROW_LG } from './constants';

import useStyles from './style';

const AdminUsers: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<Nullable<UsersFilter>>(null);

  const { data, fetchMore, loading } = useQuery<GetUsersResponse, GetUsersVariables>(GET_USERS, {
    variables: {
      filter,
      pagination: DEFAULT_PAGINATION,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError,
  });
  const users = data?.getUsers.users || [];
  const totalCount = data?.getUsers.totalCount || 0;

  const handleSearch = useCallback(
    (_, q) => {
      setFilter(prev => ({
        ...prev,
        q,
      }));
    },
    [filter?.q, filter?.financeStatus, filter?.role]
  );

  return (
    <UsersFilterableContext.Provider value={{ filter, setFilter }}>
      <div className={classes.adminUsersPage}>
        <AdminUsersPageHeader usersCount={data?.getUsers.totalCount} />
        <Container>
          <PageContentHeader
            extra={
              <SearchInput
                onChange={handleSearch}
                placeholder={t('searchUsers')}
                loading={loading}
              />
            }
          />
          <UsersHeader />
          {loading && !data ? (
            <Spinner />
          ) : (
            <Scrollbar id="admin-users-scroll" shiftThumb>
              <VirtualizedList<ViboUser>
                rowRenderer={user => <UserRow user={user} key={`user-${user._id}`} />}
                scrollId="admin-users-scroll"
                data={users}
                loadMore={() => {
                  !loading &&
                    data?.getUsers.next &&
                    fetchMore({
                      variables: {
                        pagination: data.getUsers.next,
                      },
                      updateQuery: (prev: GetUsersResponse, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;

                        const newUsers = uniqBy(
                          [...prev.getUsers.users, ...fetchMoreResult.getUsers.users],
                          '_id'
                        );

                        return {
                          getUsers: {
                            ...prev.getUsers,
                            users: newUsers,
                            next: fetchMoreResult.getUsers.next,
                          },
                        };
                      },
                    });
                }}
                totalRowsCount={totalCount}
                rowHeights={[ADMIN_USER_ROW_SM, ADMIN_USER_ROW_LG]}
                gaps={[10, 10]}
              />
            </Scrollbar>
          )}
        </Container>
      </div>
    </UsersFilterableContext.Provider>
  );
};

export default AdminUsers;
