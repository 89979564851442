import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  tools: {
    margin: '0 0 auto 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: 0,

    '& .iconButton': {
      cursor: 'pointer',

      '& svg': {
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
}));

export default useStyles;
