import { IconmoonFont } from 'vibo-ui/Icon';
import {
  SectionVisibilityOption,
  SectionVisibilityTypes,
} from 'components/dropdowns/VisibilityDropdown/interfaces';
import { SectionVisibilityRoles } from 'types/enums';

export const sectionVisibilityTypes: SectionVisibilityTypes = {
  dj: SectionVisibilityRoles.dj,
  host: SectionVisibilityRoles.host,
  public: SectionVisibilityRoles.public,
};

export const sectionVisiblitySettings = {
  [sectionVisibilityTypes.dj]: { visibleForGuests: false, visibleForHosts: false },
  [sectionVisibilityTypes.host]: { visibleForGuests: false, visibleForHosts: true },
  [sectionVisibilityTypes.public]: { visibleForGuests: true, visibleForHosts: true },
};

export const sectionVisibility: SectionVisibilityOption[] = [
  {
    id: sectionVisibilityTypes.dj,
    name: 'DJ only',
    icon: IconmoonFont['dj-16'],
  },
  {
    id: sectionVisibilityTypes.host,
    name: 'DJ & Host',
    shortName: 'Host',
    icon: IconmoonFont['host-16'],
  },
  {
    id: sectionVisibilityTypes.public,
    name: 'Public',
    icon: IconmoonFont['public-16'],
  },
];

export const DefaultSongsSort = {
  field: 'createdAt',
  direction: 'desc',
};
