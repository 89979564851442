import React, { FC, createContext, memo, useState } from 'react';

import ApolloLoadingIndicator from '../../common/ApolloLoadingIndicator';

import { TApolloLoadingContext } from './interfaces';

export const ApolloLoadingContext = createContext<TApolloLoadingContext>(undefined!);

export const ApolloLoadingContextProvider: FC = memo(({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <ApolloLoadingContext.Provider value={{ setIsLoading, isLoading }}>
      <ApolloLoadingIndicator />
      {children}
    </ApolloLoadingContext.Provider>
  );
});
