import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';

import Menu from 'vibo-ui/Menu';
import Button from 'vibo-ui/Button';
import Dropdown from 'vibo-ui/Dropdown';
import SearchInput from 'vibo-ui/SearchInput';

import { IconmoonFont } from 'vibo-ui/Icon';
import { PlaylistHeaderProps } from './interfaces';

import useStyles from './style';

const PlaylistHeader: FC<PlaylistHeaderProps> = ({
  isMySongIdeas,
  addSongsDropdownOptions,
  songsCount = 0,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [playlistSongsQ] = useQueryParam<string | undefined>('playlistSongsQ');

  return (
    <div className={classes.playlistHeader}>
      <div className={classes.songsCont}>
        {`${songsCount} ${t('songs', {
          count: songsCount,
        })}`}
      </div>
      <SearchInput
        placeholder={t('searchThisList')}
        disabled={!songsCount && !playlistSongsQ}
        size="sm"
        queryName="playlistSongsQ"
      />
      {isMySongIdeas ? (
        <Dropdown
          overlay={
            <Menu selectable={false}>
              {addSongsDropdownOptions.map(({ text, ...optionRest }) => (
                <Menu.Item {...optionRest} key={text}>
                  {text}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger="click"
        >
          <Button prefixIcon={IconmoonFont['plus-16']} displayType="primary" shape="round">
            {t('addSongs')}
          </Button>
        </Dropdown>
      ) : null}
    </div>
  );
};

export default PlaylistHeader;
