import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';

import MyAccount from './MyAccount';
import ProfileLayout from 'pages/users/ProfileLayout';
import { UserContext } from 'pages/users/UserContext';

import { GET_ME } from 'graphql/queries/user';

import { Page } from 'types/enums';

const Account: FC = () => {
  const { data } = useQuery(GET_ME, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const user: Maybe<User> = data?.['me'];

  return (
    <UserContext.Provider value={{ user }}>
      <ProfileLayout page={Page.myDjs}>
        <MyAccount />
      </ProfileLayout>
    </UserContext.Provider>
  );
};

export default Account;
