import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useParams } from 'react-router';

import EventLayout from '../EventLayout';
import EventGeneralContent from './EventGeneralContent';
import LoadingOverlay from 'components/common/LoadingOverlay';

import { openErrorNotification } from 'graphql/hooks/common';

import { GET_EVENT } from 'graphql/queries/events';

import { Page } from 'types/enums';

import useStyles from './style';

const EventGeneral: FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { push } = useHistory();
  const { id: eventId } = useParams<EventPageRouteParams>();

  const { data: eventData, loading } = useQuery<EventResponse>(GET_EVENT, {
    variables: { eventId },
    fetchPolicy: 'cache-and-network',
    onError: e => {
      // @ts-ignore
      if (e.graphQLErrors.some(error => error.code === 'EVENT_NOT_FOUND')) {
        openErrorNotification(t('eventNotFoundMessage'));
        push('/not-found');
      }
    },
  });

  const event = eventData?.event;

  return (
    <EventLayout page={Page.home} className={classes.eventGeneralLayout}>
      {!!event ? <EventGeneralContent event={event} /> : loading ? <LoadingOverlay /> : null}
    </EventLayout>
  );
};

export default EventGeneral;
