import { gql } from 'graphql.macro';

import { SONG_LINKS, THUMBNAILS } from 'graphql/fragments/songs';

export const IS_USER_LOGGED_IN_TO_BEATSOURCE = gql`
  query isUserLoggedInToBeatsource {
    isUserLoggedInToBeatsource
  }
`;

export const GET_BEATSOURCE_SECTIONS = gql`
  query getBeatsourceSections($eventId: ID!, $filter: BeatsourceSectionsFilterInput) {
    getBeatsourceSections(eventId: $eventId, filter: $filter) {
      _id
      name
      type
      songsCount
      coverSong {
        artist
        title
        thumbnails {
          ...Thumbnails
        }
      }
    }
    ${THUMBNAILS}
  }
`;

export const GET_BEATSOURCE_SONGS = gql`
  query getBeatsourceSongs(
    $eventId: ID!
    $sectionId: ID!
    $filter: BeatsourceSongsFilter
    $pagination: PaginationInput
    $sort: SongsSortInput
  ) {
    getBeatsourceSongs(
      eventId: $eventId
      sectionId: $sectionId
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      songs {
        _id
        viboSongId
        artist
        title
        thumbnails {
          ...Thumbnails
        }
        isFlagged
        likesCount
        links {
          ...SongLinks
        }
        beatsourceTracks {
          name
          artist
          id
          image {
            id
            uri
            dynamic_uri
          }
          length
          sample_url
          url
          bpm
          key
          is_explicit
          genre
          publish_date
          mix_name
        }
      }
      next {
        limit
        skip
      }
      totalCount
    }        
    ${THUMBNAILS}
    ${SONG_LINKS}
  }
`;
