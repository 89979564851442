import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import Sider from 'components/layouts/Sider';
import CookiesLayout from 'components/common/Cookies';
import MobileDetect from 'components/auth/MobileDetect';
import AuthRequired from 'components/auth/AuthRequired';
import UserSubscription from 'components/user/UserSubscription';
import { LogoContextProvider } from 'components/context/LogoContext';

import useStyles from './style';

const DashboardLayout = ({ component: Component, ...rest }: RouteProps) => {
  const classes = useStyles();

  return (
    <Route
      {...rest}
      render={renderProps => (
        <MobileDetect>
          <AuthRequired>
            <LogoContextProvider>
              <div className={classes.layout}>
                <Sider />
                <div className="content">{Component ? <Component {...renderProps} /> : null}</div>
              </div>
              <CookiesLayout />
              <UserSubscription />
            </LogoContextProvider>
          </AuthRequired>
        </MobileDetect>
      )}
    />
  );
};

export default DashboardLayout;
