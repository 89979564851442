import React, { FC, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Sort from './Sort';
import Flag from './Flag';
import Filter from './Filter';
import Delete from './Delete';
import MoveTo from './MoveTo';
import MustPlay from './MustPlay';
import DontPlay from './DontPlay';
import Button from 'vibo-ui/Button';
import SearchInput from 'vibo-ui/SearchInput';
import StopPropagationWrapper from 'components/common/StopPropagationWrapper';
import SelectCheckbox from 'components/context/SelectListContext/SelectCheckbox';
import { TimelineSongsContext } from '../TimelineSongsContext';
import { SelectListContext } from 'components/context/SelectListContext';

import { isDontPlaySection } from 'services/sections/helpers';

import { IconmoonFont } from 'vibo-ui/Icon';

import useStyles from './style';

const TimelineSongsHeader: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { section, songsOptions } = useContext(TimelineSongsContext);
  const { selectedIds } = useContext(SelectListContext);

  const defaultActionProps = {
    className: classes.action,
    confirmButtonClassName: classes.confirmAction,
    confirmText: t('confirm'),
    songIds: selectedIds,
    withConfirm: true,
  };

  const hasSomeSongs = songsOptions.songsList.length > 1;

  const handleSearch = useCallback(
    (_, q: string) =>
      songsOptions.setSongsFilter({
        ...songsOptions.songsFilter,
        q,
      }),
    [songsOptions.songsFilter]
  );

  return (
    <div className={classNames('timelineSongsHeader', classes.timelineSongsHeader)}>
      <StopPropagationWrapper>
        <div className={classNames('top', classes.top)}>
          <SearchInput
            onChange={handleSearch}
            placeholder={`${t('searchThisList')}...`}
            value={songsOptions.songsFilter.q}
            loading={songsOptions.songsLoading}
            queryName="tSongsQ"
            size="sm"
            data-gtm-target-id="timeline-section-details-songs-search"
            withUnderline
          />
          <div className={classNames('sortFilter', classes.sortFilter)}>
            {!!songsOptions.songsSort || songsOptions.isFilterApplied ? (
              <Button
                onClick={() => {
                  songsOptions.setSongsSort(null);
                  songsOptions.clearFiltering();
                }}
                className={classNames('reorderBtn', classes.reorderBtn)}
                prefixIcon={IconmoonFont['arrowUp-16']}
                displayType="link"
                shape="round"
              >
                {t('reorder')}
              </Button>
            ) : null}
            {hasSomeSongs ? <Sort /> : null}
            <Filter />
          </div>
        </div>
        {hasSomeSongs ? (
          <div
            className={classNames('bottom', classes.bottom, {
              highlighted: !!selectedIds?.length,
            })}
          >
            <SelectCheckbox />
            {selectedIds?.length ? (
              <div className={classes.actions}>
                <div className={classes.selectedSongsCounter}>{selectedIds.length}</div>
                <Flag
                  {...defaultActionProps}
                  data-gtm-target-id="timeline-section-details-songs-chosen-song-flag"
                />
                <MustPlay
                  {...defaultActionProps}
                  data-gtm-target-id="timeline-section-details-songs-chosen-song-mustplay"
                />
                {isDontPlaySection(section) ? null : (
                  <DontPlay
                    {...defaultActionProps}
                    data-gtm-target-id="timeline-section-details-songs-chosen-song-dontplay"
                  />
                )}
                <MoveTo
                  {...defaultActionProps}
                  withConfirm={false}
                  data-gtm-target-id="timeline-section-details-songs-chosen-song-moveto"
                />
                <Delete
                  {...defaultActionProps}
                  data-gtm-target-id="timeline-section-details-songs-chosen-song-delete"
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </StopPropagationWrapper>
    </div>
  );
};

export default TimelineSongsHeader;
