import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import Linkify from 'components/common/Linkify';
import ContentArea from 'components/common/ContentArea';

import { SectionDescriptionProps } from './interfaces';

import useStyles from './style';

const SectionDescription: FC<SectionDescriptionProps> = ({ description }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isShown, setIsShown] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);

  const isSomeDesc = !!description?.trim().length;

  const parentRef = useRef<Nullable<HTMLDivElement>>(null);
  const textRef = useRef<Nullable<HTMLParagraphElement>>(null);

  const toggleShow = useCallback(() => setIsShown(!isShown), [isShown]);

  const toggleButton = showButton ? (
    <Button onClick={toggleShow} displayType="link">
      {t(isShown ? 'showLess' : 'readMore')}
    </Button>
  ) : null;

  useEffect(() => {
    const parentSize = parentRef.current?.getBoundingClientRect()?.width || 0;
    const textSize = textRef.current?.getBoundingClientRect()?.width || 0;

    setShowButton(textSize >= parentSize && isSomeDesc);
  }, [parentRef, textRef, description]);

  return isSomeDesc ? (
    <ContentArea
      className={classNames('sectionDescription', classes.sectionDescription, {
        isShown,
      })}
      title={description}
      ref={parentRef}
      shadowed
    >
      <Linkify>
        <p className={classes.sectionDescSubstrate} ref={textRef}>
          {description}
        </p>
        <p className="text">
          {description}
          {toggleButton}
        </p>
      </Linkify>
      {toggleButton}
    </ContentArea>
  ) : null;
};

export default SectionDescription;
