import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  contentArea: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',

    '&.v2': {
      backgroundColor: theme.palette.background.contentArea,
    },
  },

  shadowed: {
    boxShadow: theme.shadows[3],
  },
}));

export default useStyles;
