import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  '@global': {
    '#root': {
      height: '100vh',
    },

    '*': {
      boxSizing: 'border-box',
    },

    body: {
      fontFamily: "'Roboto', sans-serif",
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      margin: '0',

      '&::selection': {
        color: colors.white,
        backgroundColor: theme.palette.primary.main,
      },
    },

    div: {
      outline: 'none',
    },

    button: {
      outline: 'none',
    },

    'h1, h2, h3, h4': {
      color: theme.palette.text.primary,
    },

    'b, strong': {
      fontWeight: 500,
    },

    p: {
      margin: 0,
    },

    a: {
      textDecoration: 'none',
    },

    '.hoverable-action': {
      cursor: 'pointer',
      color: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.button.active,
      },
    },

    '.activeDnd': {
      zIndex: 999999,
      position: 'relative',
    },

    '.hoverable-paper': {
      cursor: 'pointer',
      background: theme.palette.background.paper,
      borderRadius: '4px',
      boxShadow: theme.shadows[3],

      '&:hover': {
        boxShadow: theme.shadows[8],
      },
    },

    'div > .listItemWrapper': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,

      '&:hover': {
        backgroundColor: theme.palette.selection.hover,
      },

      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.background.default}`,
      },
    },

    '.line-break': {
      whiteSpace: 'pre-line',
    },

    '.wide': {
      width: '100%',
    },

    '.error': {
      textAlign: 'end',
      color: theme.palette.error.main,
      position: 'absolute',
      right: '0',
      fontSize: '12px',
      lineHeight: 'unset',
    },

    '.error__bottom': {
      bottom: '-17px',
    },

    '.clickableIcon': {
      cursor: 'pointer',

      '& svg': {
        color: theme.palette.primary.main,
      },

      '&:hover svg': {
        color: theme.palette.action.hover,
      },
    },

    '.djs > div a': {
      display: 'flex',
      alignItems: 'center',
    },

    '#appleid-signin': {
      position: 'relative',
      width: '20px',
      height: '20px',
      overflow: 'hidden',
      cursor: 'pointer',

      '& img': {
        position: 'absolute',
        top: '-40px',
        left: '-20px',
      },
    },

    '.position-absolute': {
      position: 'absolute',
    },

    '.mainText': {
      fontFamily: 'Roboto',
      fontSize: '18px',
      color: theme.palette.text.primary,
      textAlign: 'center',
    },

    '.blackText': {
      fontFamily: 'Roboto',
      fontSize: '16px',
      color: theme.palette.text.primary,
      textAlign: 'center',
    },

    '.errorMessage': {
      fontSize: '12px',
      color: theme.palette.error.main,
    },

    '.go-back': {
      zIndex: 7,
      top: '14.5px',

      '& svg': {
        marginRight: '15px',
        width: '21px',
        minWidth: '21px',
        color: theme.palette.primary.main,

        '&:hover': {
          color: theme.palette.action.hover,
        },
      },

      '& a': {
        zIndex: 7,
        marginLeft: '20px',
        display: 'flex',
        fontSize: '20px',
        alignItems: 'center',
        color: `${theme.palette.text.primary} !important`,
      },

      '& span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '240px',
      },
    },

    '.header-icon': {
      minWidth: '21px',
      marginRight: '16px',
      cursor: 'pointer',
    },

    '.iconWrap': {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '16px',
        cursor: 'pointer',
        color: theme.palette.primary.main,

        '&:hover': {
          color: theme.palette.action.hover,
        },
      },

      '& .reloadIcon': {
        cursor: 'pointer',
      },
    },

    '.v-header-button': {
      borderRadius: '4px',
      width: '172px',
      minWidth: 'unset',
      height: '28px',
      fontSize: '16px',
      fontWeight: 500,
      boxShadow: theme.shadows[4],

      '& svg': {
        width: '16px',
        height: '16px',
      },

      '&:hover': {
        boxShadow: theme.shadows[8],
      },
    },

    '.v-header-icon': {
      width: '28px',
      minWidth: '28px',
      height: '28px',
      padding: 6,
    },

    '.hidden': {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: 0,
      width: 0,
    },

    '.rdw-editor-toolbar': {
      border: 'none',
      margin: '0',
      padding: '0',

      '& .rdw-history-wrapper': {
        margin: '0',
      },

      '& .rdw-option-wrapper': {
        margin: '0',
        padding: '0',
        border: 'none',
        cursor: 'pointer',

        '&:hover': {
          boxShadow: 'none',
        },
      },
    },

    '.draggableItem': {
      cursor: 'move',

      '&:active': {
        cursor: 'grabbing',
      },
    },

    '.react-code-input': {
      display: 'flex!important',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& input': {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default,

        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
        },

        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
      },

      '&.invalid': {
        '& input': {
          border: `1px solid ${theme.palette.error.light}!important`,
        },
      },
    },

    '.shareButtonOption': {
      padding: '0 10px!important',
    },

    '.viboWizard': {
      '& > div, & > div > div, & > div > div > div': {
        height: '100%',
      },
    },

    '.ReactCrop': {
      '& .ReactCrop__crop-selection': {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: '0 0 0 9999em rgba(255,255,255,.7)',
      },

      '& .ReactCrop__rule-of-thirds-vt, & .ReactCrop__rule-of-thirds-hz': {
        display: 'none',
      },

      '& .ReactCrop__drag-handle': {
        '&:after': {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '50%',
          backgroundColor: theme.palette.background.paper,
        },

        '&.ord-n, &.ord-e, &.ord-s, &.ord-w': {
          display: 'none',
        },
      },
    },

    '.react-tel-input': {
      '& .country-list': {
        overflowY: 'scroll',
        zIndex: 1,
        listStyle: 'none',
        position: 'absolute',
        padding: '0',
        margin: '15px 0 10px -1px',
        boxShadow: theme.shadows[4],
        backgroundColor: theme.palette.background.dropDown,
        borderRadius: '4px',

        '& .search': {
          backgroundColor: theme.palette.background.dropDown,

          '& .search-box': {
            color: 'inherit',
            backgroundColor: theme.palette.background.input,
            border: `1px solid ${theme.palette.primary.main}`,
          },
        },

        '& .no-entries-message': {
          color: theme.palette.text.secondary,
        },

        '& .country': {
          color: theme.palette.text.secondary,

          '& .dial-code': {
            color: theme.palette.text.hint,
          },
        },

        '& .country.highlight': {
          backgroundColor: theme.palette.background.default,
        },

        '& .country:hover': {
          backgroundColor: theme.palette.background.photo,
        },
      },

      '& .form-control': {
        color: 'inherit',

        '&:disabled': {
          cursor: 'not-allowed',
        },

        '&, &:focus': {
          backgroundColor: 'transparent',
        },
      },
    },

    svg: {
      '&.tidal path': {
        fill: theme.palette.text.primary,
      },
    },

    // NEW GLOBAL STYLES
    '.Toastify__toast-container': {
      width: '190px',

      '@media screen and (min-width: 1441px)': {
        width: '230px',
      },
    },

    '.toastNotify': {
      padding: '4px 4px 4px 10px',
      minHeight: '48px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[4],

      '&.Toastify__toast--success': {
        color: theme.palette.success.main,
      },

      '&.Toastify__toast--error': {
        color: theme.palette.error.main,

        '& .toastNotifyProgress': {
          backgroundColor: theme.palette.error.main,
        },
      },

      '& .viboIcon': {
        color: 'inherit',
        fontSize: '14px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
        },

        '&.close': {
          color: theme.palette.text.hint,
          fontSize: '10px',

          '@media screen and (min-width: 1441px)': {
            fontSize: '12px',
          },
        },
      },

      '& .Toastify__toast-icon': {
        width: 'auto',
        marginInlineEnd: '6px',
      },

      '& .toastNotifyProgress': {
        height: '2px',
      },

      '& .toastNotifyBody': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '7px 0',

        '& div': {
          fontWeight: 500,
          fontSize: '11px',
          lineHeight: '15.59px',
          fontFamily: 'Roboto, sans-serif',

          '@media screen and (min-width: 1441px)': {
            fontSize: '14px',
            lineHeight: '19.6px',
          },
        },

        '& svg': {
          width: '14px',
          height: '14px',
          fill: 'none',

          '@media screen and (min-width: 1441px)': {
            width: '16px',
            height: '16px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          padding: '9px 0',
        },
      },
    },

    '.noClickableFilter': {
      ...theme.mixins.noClickableFilter,
    },

    '.rc-dropdown': {
      fontFamily: "'Roboto', sans-serif",
    },

    '& .ql-container, & .ql-editor': {
      fontSize: '13px',
      lineHeight: '24px',

      '& h1, h2, h3, h4, h5, h6': {
        fontWeight: '400',
      },

      '& h1': {
        lineHeight: '32px',
      },

      '& h2': {
        lineHeight: '26px',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },

    '.viboSelect__menu-portal': {
      zIndex: '3!important',
    },
  },
}));

export default useStyles;
