import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  viboButton: {
    fontFamily: `'Roboto', sans-serif`,
    userSelect: 'none',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '19.5px',
    color: theme.palette.primary.main,
    transition: 'color .1s, background-color .1s, border .1s',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    position: 'relative',

    '&.shadowed': {
      boxShadow: '0px 0.5px 3px 0px rgba(0, 0, 0, 0.45)',
    },

    '& > span': {
      width: '100%',
    },

    '&.smSize': {
      height: '24px',
      padding: '0 6px',

      '@media screen and (min-width: 1441px)': {
        height: '28px',
      },
    },

    '&.lgSize': {
      height: '30px',
      padding: '0 10px',

      '@media screen and (min-width: 1441px)': {
        height: '36px',
      },
    },

    '& .prefixIcon, & .sufixIcon': {
      fontSize: '14px',
      padding: '0',
      display: 'inline-block',

      '&.viboIconSVG': {
        width: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
        },
      },

      '& svg': {
        fill: 'none',
      },

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
      },
    },

    '&[disabled]': {
      cursor: 'not-allowed',
    },

    '&.defaultDisplay': {
      border: 'none',
      backgroundColor: theme.palette.background.paper,

      '&[disabled]': {
        color: theme.palette.button.disabled,
      },

      '&:not(:disabled)': {
        '&:hover': {
          backgroundColor: theme.palette.selection.hover,
          color: theme.palette.button.active,
        },
      },

      '&.danger': {
        '&, &:not([disabled]):hover': {
          color: theme.palette.error.main,
        },

        '&.borderedDisplay:not([disabled]):hover': {
          color: theme.palette.error.main,
          border: `1px solid ${theme.palette.error.main}`,
        },

        '&[disabled]': {
          color: theme.palette.error.light,
          border: `1px solid ${theme.palette.error.light}`,
        },
      },

      '&.shadowed': {
        '&:hover': {
          backgroundColor: theme.palette.primary.lighty,
        },
      },
    },

    '&.borderedDisplay': {
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.primary.main}`,

      '&[disabled]': {
        border: `1px solid ${theme.palette.button.disabled}`,
        color: theme.palette.button.disabled,
      },

      '&:not(:disabled)': {
        '&:hover': {
          backgroundColor: theme.palette.selection.hover,
          border: `1px solid ${theme.palette.button.active}`,
          color: theme.palette.button.active,
        },
      },

      '&.danger': {
        '&, &:not([disabled]):hover': {
          color: theme.palette.error.main,
          border: `1px solid ${theme.palette.error.main}`,
        },

        '&[disabled]': {
          color: theme.palette.error.light,
          border: `1px solid ${theme.palette.error.light}`,
        },
      },
    },

    '&.primaryDisplay': {
      color: isDark ? colors.balticSeaDark : colors.white,
      backgroundColor: theme.palette.primary.main,
      border: '1px solid transparent',

      '&[disabled]': {
        backgroundColor: theme.palette.button.disabled,
      },

      '&:not(:disabled)': {
        '&:hover': {
          backgroundColor: theme.palette.button.active,
        },
      },

      '&.success': {
        backgroundColor: colors.mountainMeadow,

        '&[disabled]': {
          backgroundColor: theme.palette.text.hint,
        },

        '&:not([disabled]):hover': {
          backgroundColor: colors.malachite,
        },
      },

      '&.danger': {
        backgroundColor: theme.palette.error.main,

        '&[disabled], &:not([disabled]):hover': {
          backgroundColor: theme.palette.error.light,
        },
      },
    },

    '&.linkDisplay': {
      border: 'none',
      backgroundColor: 'transparent',

      '&[disabled]': {
        color: theme.palette.text.disabled,
      },

      '&:not(:disabled)': {
        '&:hover': {
          color: theme.palette.button.active,
        },
      },

      '&.danger': {
        color: theme.palette.error.main,

        '&[disabled], &:not([disabled]):hover': {
          color: theme.palette.error.light,
        },
      },

      '&.success': {
        color: theme.palette.success.main,

        '&:not([disabled]):hover': {
          color: theme.palette.success.light,
        },
      },
    },

    '&.roundShape': {
      borderRadius: '4px',
    },

    '&.circleShape': {
      display: ' inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',

      '&.smSize': {
        minWidth: '20px',
        maxWidth: '20px',
        minHeight: '20px',
        maxHeight: '20px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '24px',
          maxWidth: '24px',
          minHeight: '24px',
          maxHeight: '24px',
        },
      },

      '&.lgSize': {
        minWidth: '32px',
        maxWidth: '32px',
        minHeight: '32px',
        maxHeight: '32px',

        '@media screen and (min-width: 1441px)': {
          minWidth: '36px',
          maxWidth: '36px',
          minHeight: '36px',
          maxHeight: '36px',
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  withPrefixIcon: {
    '&.centrate': {
      position: 'relative',
      justifyContent: 'center',

      '& .prefixIcon': {
        position: 'absolute',
        left: '8px',
      },
    },
  },

  withSufixIcon: {
    '&.centrate': {
      position: 'relative',
      justifyContent: 'center',

      '& .prefixIcon': {
        position: 'absolute',
        right: '8px',
      },
    },
  },

  alignedContent: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  spinner: {
    height: 'auto',
    width: 'auto',
    maxHeight: '16px',

    '& .container': {
      padding: '0',
    },
  },

  prefixIcon: {
    '&.withChildren': {
      margin: '0 5px 0 0',

      '@media screen and (min-width: 1441px)': {
        margin: '0 6px 0 0',
      },
    },
  },

  sufixIcon: {
    margin: '0 0 0 7px',

    '@media screen and (min-width: 1441px)': {
      margin: '0 0 0 8px',
    },
  },
}));

export default useStyles;
