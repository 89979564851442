export const PLAYLISTS_COLS_COUNT_SM = 5;
export const PLAYLISTS_COLS_COUNT_LG = 6;

export const PLAYLISTS_ROW_HEIGHT_SM = 219;
export const PLAYLISTS_ROW_HEIGHT_LG = 260;

export const PLAYLISTS_COL_WIDTH_SM = 166;
export const PLAYLISTS_COL_WIDTH_LG = 195;

export const PLAYLISTS_GAP_SM = 11;
export const PLAYLISTS_GAP_LG = 15.2;
