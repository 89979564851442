import React, { FC } from 'react';

import Button from 'vibo-ui/Button';

import { MONTHS } from './constants';

import { ChooseMonthsProps } from './interfaces';

import useStyles from './style';

const ChooseMonths: FC<ChooseMonthsProps> = ({ handleChangeMonth }) => {
  const classes = useStyles();

  return (
    <div className={classes.months}>
      {MONTHS.map((month, index) => (
        <Button
          onClick={() => handleChangeMonth(index)}
          shape="round"
          size="lg"
          shadowed
          key={`date-month-choose-${month}`}
        >
          {month}
        </Button>
      ))}
    </div>
  );
};

export default ChooseMonths;
