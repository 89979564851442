import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  like: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transform: 'translateY(-4px)',
    cursor: 'pointer',

    //for gtm tracking propper target
    '& *': {
      pointerEvents: 'none',
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.background.paper,
      },
    },
  },

  likedByMe: {
    color: theme.palette.error.main,
  },

  defaultLiked: {
    color: theme.palette.text.hint,
  },

  likesCount: {
    minWidth: '27px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21.07px',
    transform: 'translateY(3px)',

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
      transform: 'translateY(4px)',
    },
  },
}));

export default useStyles;
