import React, { FC, useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import { useQuery, useMutation } from 'react-apollo';

import Spinner from 'vibo-ui/Spinner';

import { useMusicKit } from 'graphql/hooks/musickit';
import { removeMusicKitToken } from 'services/appleMusic/helpers';
import { GET_APPLE_MUSIC_DEVELOPER_TOKEN, CONNECT_APPLE_MUSIC } from 'graphql/mutations/appleMusic';

import useStyles from './style';

const AppleMusicConnect: FC = () => {
  const classes = useStyles();
  const [token = ''] = useQueryParam<string>('token');
  const [refreshToken = ''] = useQueryParam<string>('refreshToken');
  const [musicToken, setMusicToken] = useState<Nullable<string>>(null);

  const { data } = useQuery<DeveloperTokenResponse>(GET_APPLE_MUSIC_DEVELOPER_TOKEN, {
    context: {
      token,
      refreshToken,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      return window.location.replace(`${process.env.REACT_APP_APPLE_REDIRECT_URL}?success=false`);
    },
  });
  const developerToken: Maybe<string> = data?.getAppleMusicDeveloperToken.developerToken;

  const [connectAppleMusic] = useMutation<ConnectAppleMusicResponse, ConnectAppleMusicVariables>(
    CONNECT_APPLE_MUSIC,
    {
      onCompleted: response => {
        removeMusicKitToken();
        window.location.replace(
          `${process.env.REACT_APP_APPLE_REDIRECT_URL}?success=${response.connectAppleMusic}`
        );
      },
      onError: () => {
        disconnect();
        removeMusicKitToken();
        window.location.replace(`${process.env.REACT_APP_APPLE_REDIRECT_URL}?success=false`);
      },
    }
  );

  const { connect, disconnect, loaded } = useMusicKit({
    developerToken,
    onMusicToken: token => {
      setMusicToken(token);
    },
  });

  useEffect(() => {
    if (developerToken && loaded) {
      connect();
    }
  }, [developerToken, loaded]);

  useEffect(() => {
    if (musicToken) {
      connectAppleMusic({
        variables: {
          appleMusicAuth: {
            developerToken: developerToken!,
            musicUserToken: musicToken,
          },
        },
        context: {
          token,
          refreshToken,
        },
      });
    }
  }, [musicToken]);

  return (
    <div className={classes.appleMusicConnect}>
      <Spinner className="withSpace" />
    </div>
  );
};

export default AppleMusicConnect;
