import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Scrollbar from 'vibo-ui/Scrollbar';
import Header from 'components/common/Header';
import AccountSettings from './AccountSettings';
import GeneralSettings from './GeneralSettings';
import Container from '../../components/common/Container';
import EventTitle from 'components/events/Timeline/EventTitle';
import PageContentHeader from 'components/common/PageContentHeader';

import useStyles from './style';

const SettingsPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.settingsPage}>
      <Header />
      <Container className={classes.container}>
        <Scrollbar shiftThumb>
          <PageContentHeader>
            <EventTitle name={t('settings')} />
          </PageContentHeader>
          <div className={classes.content}>
            <GeneralSettings />
            <AccountSettings />
          </div>
        </Scrollbar>
      </Container>
    </div>
  );
};

export default SettingsPage;
