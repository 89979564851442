import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  sectionBlock: {
    '& .emptyState.noSongsFound, & .emptyState.noQuestions, & .emptyState.noSongs': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '76px',

      '& *': {
        opacity: 1,
        transition: 'opacity .3s',
      },

      '& .description': {
        color: theme.palette.text.hint,
        fontWeight: 500,
        fontSize: '17px',
        lineHeight: '24.09px',
        margin: '0 6px',

        '@media screen and (min-width: 1441px)': {
          fontSize: '20px',
          lineHeight: '30px',
          margin: '0 15px',
        },
      },

      '& .stateIcon svg': {
        width: '64px',
        height: '64px',
        margin: '0 0 0 6px',

        '@media screen and (min-width: 1441px)': {
          width: '76px',
          height: '76px',
          margin: '0 0 0 8px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        height: '82px',
      },
    },

    '&.blankEmptyState': {
      '& .emptyState': {
        '& *': {
          opacity: 0,
        },
      },

      '& .timelineSongsHeader': {
        ...theme.mixins.noClickableFilter,
      },
    },

    '&.isEventGuest': {
      '& .caretButton': {
        display: 'none',
      },
    },

    '&.collapsed': {
      '& .sectionBlockHeader': {
        borderRadius: '4px',
      },
    },

    '& .sectionBlockHeader': {
      zIndex: 2,
    },

    '& .sectionBlockContent': {
      zIndex: 1,
    },
  },

  sectionBlockHeader: {
    position: 'sticky',
    top: '0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'padding .2s',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
    padding: '4px 0 0 12px',

    '&:before': {
      zIndex: 0,
      position: 'absolute',
      backgroundColor: theme.palette.background.default,
      content: '""',
      width: 'calc(100% + 8px)',
      height: 'calc(100% + 8px)',
      left: '-4px',
      top: '-8px',

      '@media screen and (min-width: 1441px)': {
        height: 'calc(100% + 10px)',
      },
    },

    '& > *': {
      zIndex: 5,
    },

    '& .addSongsWrapper, & .viboButton:not(:first-child), & .stopPropagationWrapper:not(:first-child)': {
      margin: '0 0 0 4px',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 8px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '4px 0 0 16px',
    },
  },

  notShownContent: {
    '& .timelineSongsHeader': {
      top: 0,
      position: 'relative',
    },

    '& .questionTitle': {
      zIndex: 1,
    },

    '& .viboList': {
      position: 'absolute',
      width: '100%',
      top: '0',
    },

    '&:hover': {
      '&.defaultSize': {
        '&:after': {
          zIndex: 2,
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: 'calc(100%)',
          backgroundColor: theme.palette.overlay.accent,
        },

        '& .sectionBlockFooter': {
          '&:before': {
            opacity: 0,
          },

          '& .viboButton': {
            zIndex: 2,
          },
        },
      },
    },
  },

  sectionBlockContent: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 3,
    margin: '6px 0 0 0',
    display: 'flex',
    flexDirection: 'column',

    '& .emptyState': {
      minHeight: '114px',
    },

    '@media screen and (min-width: 1441px)': {
      margin: '8px 0 0 0',
    },
  },

  sectionBlockFooter: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0 0 4px 4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 2,

    '& .viboButton': {
      width: '100%',
      fontSize: '13px',
      lineHeight: '19.56px',
      fontWeight: 400,
      borderRadius: '0 0 4px 4px',
      transition: 'none',

      '&.collapse': {
        '& .spinner': {
          display: 'none',
        },
      },

      '& > .viboIcon': {
        margin: '0 0 0 6px',
      },

      '&:not(:disabled):hover': {
        color: theme.palette.action.hover,
        backgroundColor: `${theme.palette.primary.light}`,
      },

      '@media screen and (min-width: 1500px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  defaultSize: {
    height: '256px',

    '& .viboTable, & .viboList': {
      pointerEvents: 'none',
    },

    '& .sectionBlockFooter': {
      pointerEvents: 'all',
      position: 'absolute',
      bottom: '0',
      left: '0',

      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '40px',
        transform: 'translateY(-100%)',
      },
    },

    '@media  screen and (max-height: 780px)': {
      maxHeight: '148px',
      minHeight: '148px',
      height: '148px',
    },
  },

  blockIcon: {
    display: 'flex',
    color: theme.palette.primary.main,
    padding: '5px',
    width: 'fit-content',
    cursor: 'pointer',
    margin: '0 6px 0 0',

    '@media screen and (min-width: 1500px)': {
      padding: '6px',
      margin: '0 8px 0 0',
    },

    '& svg': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1500px)': {
        width: '16px',
        height: '16px',
      },
    },
  },

  sectionBlockInfo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    lineHeight: '21px',
    margin: '0 auto 0 0',
    fontWeight: 500,

    '@media screen and (min-width: 1500px)': {
      fontSize: '18px',
      lineHeight: '25.2px',
    },
  },

  title: {
    width: '100%',
  },

  extraInfo: {
    marginLeft: '4px',
  },

  caret: {
    margin: '0 0 0 4px!important',
    padding: '5px',

    //for gtm tracking propper target
    '& > .viboIcon': {
      pointerEvents: 'none',
    },

    '@media screen and (min-width: 1500px)': {
      margin: '0 0 0 6px!important',
      padding: '6px',
    },
  },
}));

export default useStyles;
