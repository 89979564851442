import React, { FC, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';

import Menu from 'vibo-ui/Menu';
import Tooltip from 'vibo-ui/Tooltip';
import Dropdown from 'vibo-ui/Dropdown';
import WithEventRole from 'components/user/WithEventRole';
import Button, { ButtonProps } from 'vibo-ui/Button';
import { EventContext } from 'components/context/EventContext';
import { SectionsFilterableContext } from 'components/events/Timeline/SectionsFilterContext';

import { useModal } from 'vibo-ui/Modal';

import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { CreateSectionModalProps } from 'components/modals/CreateSectionModal';
import { EventUserType, Modals } from 'types/enums';
import { AddTimelineSectionButtonProps } from './interfaces';
import { FavoriteSectionsModalProps } from 'components/modals/FavoriteSectionsModal';

import useStyles from './style';

const AddTimelineSectionButton: FC<AddTimelineSectionButtonProps> = ({
  createSectionMutation,
  refetchQueries,
  variables,
  eventSettings,
  insertBeforeSectionId,
  children,
  hideTooltip,
  onClick,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const eventContext = useContext(EventContext);
  const { filter, setFilter } = useContext(SectionsFilterableContext);

  const showToggleWithoutOptions = useMemo(
    () =>
      (eventContext.isEventHost && eventContext.event?.settings?.canHostCreateSections) ||
      !!eventContext?.isFavoriteSections,
    [
      eventContext?.event?.settings?.canHostCreateSections,
      eventContext?.isFavoriteSections,
      eventContext?.isEventHost,
    ]
  );

  const { openModal } = useModal();

  const openFavoriteSectionsModal = useCallback(
    () =>
      openModal<FavoriteSectionsModalProps>({
        key: Modals.favoriteSections,
        props: {
          insertBeforeSectionId,
          eventContext,
        },
      }),
    [insertBeforeSectionId]
  );
  const openCreateSectionModal = useCallback(
    () =>
      openModal<CreateSectionModalProps>({
        key: Modals.createSection,
        props: {
          eventContext,
          createSectionMutation,
          refetchQueries,
          variables,
          eventSettings,
          insertBeforeSectionId,
        },
      }),
    []
  );
  const openConfirmClearSearchModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('clearSearch'),
          children: t('clearSearchCreateNewSection'),
          submit: {
            text: t('clear'),
            onClick: clearSearch,
          },
        },
      }),
    []
  );

  const [, setSectionsQ] = useQueryParam('sectionsQ');

  const gtmSuffix = useMemo(() => (!!insertBeforeSectionId ? 'middle' : 'top'), [
    insertBeforeSectionId,
  ]);

  const handleCheckSearch = useCallback(
    (action: Function) => {
      if (!!filter?.q) {
        openConfirmClearSearchModal();
      } else {
        action();
      }
    },
    [filter?.q]
  );

  const addNewSection = useCallback(() => handleCheckSearch(() => openCreateSectionModal()), [
    handleCheckSearch,
  ]);

  const addFavoriteSection = useCallback(() => handleCheckSearch(openFavoriteSectionsModal), [
    handleCheckSearch,
  ]);

  const clearSearch = useCallback(() => {
    setFilter(null);
    setSectionsQ(undefined);
  }, []);

  const menuContent = (
    <Menu>
      <Menu.Item
        onClick={addNewSection}
        key="new-section"
        data-gtm-target-id={`timeline-add-new-section-${gtmSuffix}`}
      >
        {t('newSection')}
      </Menu.Item>
      {eventContext.isFavoriteSections ? null : (
        <Menu.Item
          onClick={addFavoriteSection}
          key="favorite-section"
          data-gtm-target-id={`timeline-add-section-from-favorites-${gtmSuffix}`}
        >
          {t('fromFavorites')}
        </Menu.Item>
      )}
    </Menu>
  );

  const Toggler = (toggleProps: ButtonProps) => (
    <Tooltip
      overlay={hideTooltip ? null : t('addSectionOrHeadline')}
      className={classes.addSectionMiddleTooltip}
      align={{
        offset: [-6, 0],
      }}
      placement="left"
      type="primary"
    >
      <Button {...{ ...rest, ...toggleProps }}>{children}</Button>
    </Tooltip>
  );

  return (
    <>
      <WithEventRole roles={[EventUserType.dj]} extraCondition={!showToggleWithoutOptions}>
        <Dropdown
          trigger="click"
          overlay={menuContent}
          overlayWrapperClassName="blue"
          overlayClassName={classes.menus}
          disableToggleClass
        >
          <Toggler />
        </Dropdown>
      </WithEventRole>
      {showToggleWithoutOptions ? <Toggler onClick={addNewSection} /> : null}
    </>
  );
};

export default AddTimelineSectionButton;
