import React, { useEffect, forwardRef, useState, KeyboardEvent, useCallback } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import RcSelect, { ActionMeta } from 'react-select';
import { Key } from 'ts-key-enum';
import { useEnsuredForwardedRef } from 'react-use';

import Input from './Input';
import Option from './Option';
import Scrollbar from 'vibo-ui/Scrollbar';
import ClearIndicator from './ClearIndicator';
import MultiValueLabel from './MultiValueLabel';
import MultiValueRemove from './MultiValueRemove';
import DropdownIndicator from './DropdownIndicator';
import CustomValueContainer from './CustomValueContainer';
import ClickAwayListener from 'vibo-ui/ClickAwayListener';

import {
  SelectProps,
  CurrentOption,
  SimpleOption,
  SelectRefMutable,
  SelectRef,
  CustomValueContainerProps,
} from './interfaces';

import useStyles from './style';

const Select = forwardRef<RcSelect, SelectProps>(
  (
    {
      className,
      renderSelectedOption,
      components,
      hideMenu,
      hideDropdownIndicator,
      menuIsOpen,
      options,
      selectedValuePrefix,
      validation,
      onKeyDown,
      styleModificator = 'default',
      withCustomValueContainer = false,
      blurInputOnSelect = true,
      alwaysShowThumb = false,
      isSearchable = false,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const [currentOptions, setCurrentOptions] = useState<CurrentOption>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [isInputValid, setisInputValid] = useState(true);

    const selectRef = useEnsuredForwardedRef((ref as unknown) as SelectRefMutable);

    const handleValidateInput: (value: string) => void = value => {
      if (!!validation) {
        const valid = validation?.(value) ?? false;
        setisInputValid(!!value ? valid : true);
      }
    };

    const handleInputChange: (value: string) => void = value => {
      setInputValue(value);
      handleValidateInput(value);
    };

    const handleChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void = useCallback(
      (newValue, actionMeta) => {
        rest.onChange?.(newValue, actionMeta);
      },
      []
    );

    const handleValidateOption = () => {
      if (isInputValid && rest.isMulti && !!inputValue) {
        handleAddOption({
          value: inputValue,
          label: inputValue,
        });

        selectRef.current?.focus();
      }
    };

    const handleKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void = e => {
      switch (e.key) {
        case Key.Enter:
          handleValidateOption();
          break;
        default:
          break;
      }
      onKeyDown?.(e);
    };

    const handleAddOption: (option: SimpleOption) => void = option => {
      setCurrentOptions([...currentOptions, option]);
      selectRef.current?.selectOption(option);
    };

    useEffect(() => {
      handleInputChange(inputValue);
    }, []);

    useEffect(() => {
      if (!!options) {
        setCurrentOptions(options);
      }
    }, [options]);

    const foundValue = currentOptions.find(option => get(option, 'value') === rest.value);

    return (
      <RcSelect
        menuIsOpen={hideMenu ? false : menuIsOpen}
        options={currentOptions}
        components={{
          ...(withCustomValueContainer && {
            ValueContainer: (props: CustomValueContainerProps) => (
              <CustomValueContainer {...props}>{selectedValuePrefix}</CustomValueContainer>
            ),
          }),
          DropdownIndicator,
          ClearIndicator,
          MultiValueLabel,
          MultiValueRemove,
          Input,
          MenuList: props => (
            <ClickAwayListener
              onClickAway={() => {
                props.selectProps.onMenuClose();
              }}
            >
              <Scrollbar
                className={classNames(classes.menuListScrollbar, {
                  alwaysShowThumb,
                })}
                alwaysShowThumb={alwaysShowThumb}
              >
                {props.children}
              </Scrollbar>
            </ClickAwayListener>
          ),
          Option: props => (
            <Option key={props.label} renderSelectedOption={renderSelectedOption} {...props} />
          ),
          ...components,
        }}
        blurInputOnSelect={blurInputOnSelect}
        classNamePrefix={'viboSelect'}
        onKeyDown={handleKeyDown}
        onBlur={handleValidateOption}
        ref={(selectRef as unknown) as SelectRef}
        {...rest}
        value={foundValue}
        isSearchable={isSearchable}
        classNames={{
          ...rest.classNames,
          menu: props => classNames(classes.menu, rest.classNames?.menu?.(props)),
        }}
        className={classNames(
          {
            [classes.invalidInput]: !isInputValid,
            [classes.hideDropdownIndicator]: hideMenu || hideDropdownIndicator,
            [classes.loading]: rest.isLoading,
          },
          'viboSelect',
          classes.viboSelect,
          styleModificator,
          className
        )}
        onInputChange={handleInputChange}
        onChange={handleChange}
      />
    );
  }
);

export default Select;
