import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { EditableContext } from '../../common/EditableContext';
import Image from '../../image/Image';
import ImageDefaultPlaceholder from '../../image/ImageDefaultPlaceholder';
import EventTypeIcon from '../common/EventTypeIcon';
import { EventContext } from '../../context/EventContext';

import { ChangeEventImageStep, EventType } from '../../../types/enums';

import useStyles from './style';

interface EventImagesProps {
  id: string;
  title: string;
  type?: EventType;
  squareUrl?: string;
  onClick?: (initialStep: ChangeEventImageStep.cropSquare) => void;
}

const EventImages: React.FC<EventImagesProps> = ({ id, title, type, squareUrl = '', onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const eventContext = useContext(EventContext);
  const editableContext = useContext(EditableContext);

  return (
    <div className={classes.eventImages}>
      <Image
        token={id}
        src={squareUrl}
        alt={title}
        className={classNames(classes.imgSquare, {
          photo:
            !eventContext?.isEventGuest && !eventContext?.isHistory && !!editableContext?.canEdit,
        })}
        onClick={() => onClick?.(ChangeEventImageStep.cropSquare)}
      >
        <ImageDefaultPlaceholder
          icon={type ? <EventTypeIcon type={type} /> : <span />}
          text={t('eventPhoto')}
          className={classes.placeholder}
        />
      </Image>
    </div>
  );
};

export default EventImages;
