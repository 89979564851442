import { useContext } from 'react';

import { ApolloLoadingContext } from '../../components/context/ApolloLoadingContext';

export const useApolloLoadingIndicator = () => {
  const { isLoading, setIsLoading } = useContext(ApolloLoadingContext);

  return {
    setIsLoading,
    isLoading,
  };
};
