import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Tools from './Tools';
import Dropdown from 'vibo-ui/Dropdown';
import IconButton from 'components/buttons/IconButton';

import { ButtonIconType } from 'types/enums';
import { MoreToolsPopoverProps } from './interfaces';

import useIconButtonDecStyles from 'components/buttons/IconButton/decorStyle';
import useSongStyles from '../style';

const MoreToolsPopover = <T extends SectionSong>({ song, className }: MoreToolsPopoverProps<T>) => {
  const iconButtonDecClasses = useIconButtonDecStyles();
  const songClasses = useSongStyles();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={classNames('songMoreTools', className)}>
      <Dropdown
        overlay={<Tools song={song} />}
        onVisibleChange={setIsOpen}
        visible={isOpen}
        trigger="click"
        disableToggleClass
      >
        <IconButton
          placement="bottomLeft"
          title={t('moreOptions')}
          type={ButtonIconType.threeDots}
          className={classNames(songClasses.iconButton, iconButtonDecClasses.highlighted)}
          data-gtm-target-id="timeline-section-details-songs-song-more-options"
        />
      </Dropdown>
    </div>
  );
};

export default MoreToolsPopover;
