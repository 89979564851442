import { gql } from 'graphql.macro';
import { EVENT_CONTACT } from '../fragments/events';

export const JOIN_EVENT = gql`
  mutation joinEvent($eventId: ID!, $type: EventUserType!) {
    joinEvent(eventId: $eventId, type: $type) {
      _id
    }
  }
`;

export const LEAVE_EVENT = gql`
  mutation leaveEvent($eventId: ID!) {
    leaveEvent(eventId: $eventId)
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($eventId: ID!, $payload: UpdateEventInput!) {
    updateEvent(eventId: $eventId, payload: $payload) {
      title
      status
      date
      daysCountBeforeLock
      location {
        name
      }
      image {
        squareUrl
        rectUrl
      }
      arrivalTime
      startTime
      endTime
      settings {
        canHostsInviteGuests
        canGuestsInviteGuests
        sectionSongsLimit
        sectionMustPlayLimit
        canHostCreateSections
        canHostReorderSections
      }
      isDeleted
      djs {
        _id
        firstName
        lastName
        imageUrl
        logoUrl
      }
      note
    }
  }
`;

export const CHANGE_USER_TYPE_IN_EVENT = gql`
  mutation changeUserTypeInEvent($eventId: ID!, $userId: ID!, $type: EventUserType!) {
    changeUserTypeInEvent(eventId: $eventId, userId: $userId, type: $type)
  }
`;

export const SAVE_EVENT_AS_TEMPLATE = gql`
  mutation saveEventAsTemplate($eventId: ID!, $payload: CloneTemplatePayload!) {
    saveEventAsTemplate(eventId: $eventId, payload: $payload) {
      _id
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: ID!) {
    deleteEvent(eventId: $eventId)
  }
`;

export const RESTORE_EVENT = gql`
  mutation restoreEvent($eventId: ID!) {
    restoreEvent(eventId: $eventId) {
      _id
    }
  }
`;

export const DELETE_USER_WITH_EVENT = gql`
  mutation removeUserFromEvent($eventId: ID!, $userId: ID!) {
    removeUserFromEvent(eventId: $eventId, userId: $userId)
  }
`;

export const INVITE_USER = gql`
  mutation inviteUserViaEmail(
    $eventId: ID!
    $emails: [String!]!
    $type: EventUserType!
    $text: String!
  ) {
    inviteUserViaEmail(eventId: $eventId, emails: $emails, type: $type, text: $text)
  }
`;

export const CREATE_EVENT_SECTION = gql`
  mutation createSection($eventId: ID!, $payload: CreateSectionInput!) {
    createSection(eventId: $eventId, payload: $payload) {
      _id
      settings {
        songsEnabled
      }
    }
  }
`;

export const DELETE_EVENT_SECTION = gql`
  mutation removeSection($eventId: ID!, $sectionId: ID!) {
    removeSection(eventId: $eventId, sectionId: $sectionId)
  }
`;

export const UPDATE_EVENT_SECTION = gql`
  mutation updateSection($eventId: ID!, $sectionId: ID!, $payload: UpdateSectionInput!) {
    updateSection(eventId: $eventId, sectionId: $sectionId, payload: $payload) {
      _id
      note
      name
    }
  }
`;

export const REORDER_EVENT_SECTIONS = gql`
  mutation reorderSections($eventId: ID!, $sourceSectionId: ID!, $targetSectionId: ID) {
    reorderSections(
      eventId: $eventId
      sourceSectionId: $sourceSectionId
      targetSectionId: $targetSectionId
    )
  }
`;

export const ADD_SONG_IDEAS_TO_EVENT_SECTION = gql`
  mutation addSongIdeasToEventSection(
    $eventId: ID!
    $sectionId: ID!
    $payload: AddSongIdeasToSectionInput!
  ) {
    addSongIdeasToEventSection(eventId: $eventId, sectionId: $sectionId, payload: $payload) {
      addedCount
      totalCount
    }
  }
`;

export const DELETE_SONG_IDEAS_FROM_EVENT_SECTION = gql`
  mutation deleteSongIdeasFromEventSection(
    $eventId: ID!
    $sectionId: ID!
    $payload: DeleteSongIdeasFromSectionInput!
  ) {
    deleteSongIdeasFromEventSection(eventId: $eventId, sectionId: $sectionId, payload: $payload)
  }
`;

export const REORDER_EVENT_SECTION_SONG_IDEAS = gql`
  mutation reorderEventSectionSongIdeas(
    $eventId: ID!
    $sectionId: ID!
    $sourceSongIdeasId: ID!
    $targetSongIdeasId: ID
  ) {
    reorderEventSectionSongIdeas(
      eventId: $eventId
      sectionId: $sectionId
      sourceSongIdeasId: $sourceSongIdeasId
      targetSongIdeasId: $targetSongIdeasId
    )
  }
`;

export const CREATE_EVENT_FILE_WEB = gql`
  mutation createEventFileWeb(
    $eventId: ID!
    $fileType: EventFileTypeEnum!
    $params: CreateEventFileInputWeb!
  ) {
    createEventFileWeb(eventId: $eventId, fileType: $fileType, params: $params) {
      url
    }
  }
`;

export const EXPORT_EVENT_TO_SPOTIFY = gql`
  mutation exportEventToSpotify(
    $eventId: ID!
    $sectionIds: [ID!]!
    $sort: SongsSortInput
    $filter: ExportEventFilter
    $title: String
  ) {
    exportEventToSpotify(
      eventId: $eventId
      sectionIds: $sectionIds
      sort: $sort
      filter: $filter
      title: $title
    ) {
      playlistUrl
      exportedCount
      failedToExport {
        text
        viboSongId
        sectionId
      }
      title
    }
  }
`;

export const FLAG_FAILED_TO_EXPORT = gql`
  mutation flagFailedToExport($eventId: ID!, $songsToFlag: [SectionAndViboSongId!]!) {
    flagFailedToExport(eventId: $eventId, songsToFlag: $songsToFlag)
  }
`;

export const UPDATE_SECTION_BLOCKS = gql`
  mutation updateSectionBlocks($eventId: ID!, $sectionId: ID!, $blocks: [SectionBlockInput!]!) {
    updateSectionBlocks(eventId: $eventId, sectionId: $sectionId, blocks: $blocks)
  }
`;

export const UPDATE_EVENT_PDF_SETTINGS_WEB = gql`
  mutation updateEventPdfSettingsWeb(
    $eventId: ID!
    $userType: String!
    $payload: EventPdfSettingsPayloadWeb!
  ) {
    updateEventPdfSettingsWeb(eventId: $eventId, userType: $userType, payload: $payload)
  }
`;

export const INVITE_DJ = gql`
  mutation inviteDj($djName: String!, $email: String!) {
    inviteDj(djName: $djName, email: $email)
  }
`;

export const JOIN_EVENT_VIA_DEEP_LINK = gql`
  mutation joinEventViaDeepLink($deepLink: String!) {
    joinEventViaDeepLink(deepLink: $deepLink) {
      _id
    }
  }
`;

export const CREATE_EVENT_CONTACT = gql`
  mutation createEventContact($eventId: ID!, $payload: CreateContactInput!) {
    createEventContact(eventId: $eventId, payload: $payload) {
      ...EventContact
    }
  }
  ${EVENT_CONTACT}
`;

export const UPDATE_EVENT_CONTACT = gql`
  mutation updateEventContact($contactId: ID!, $payload: UpdateContactInput!) {
    updateEventContact(contactId: $contactId, payload: $payload) {
      ...EventContact
    }
  }
  ${EVENT_CONTACT}
`;

export const REMOVE_EVENT_CONTACT = gql`
  mutation removeEventContact($contactId: ID!) {
    removeEventContact(contactId: $contactId)
  }
`;

export const TOGGLE_ASSIGN_DJ_TO_EVENT = gql`
  mutation toggleAssignDjToEvent($eventId: ID!, $djId: ID!) {
    toggleAssignDjToEvent(eventId: $eventId, djId: $djId) {
      isAssigned
    }
  }
`;

export const CHANGE_EVENT_TEMPLATE = gql`
  mutation changeEventTemplate($eventId: ID!, $templateId: ID!, $image: UpdateEventImageInput) {
    changeEventTemplate(eventId: $eventId, templateId: $templateId, image: $image) {
      _id
    }
  }
`;
