import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Button from 'vibo-ui/Button';
import AuthLayout from '../AuthLayout';
import GoBack from 'components/buttons/GoBack';
import ViboLogo from 'components/common/ViboLogo';
import EmailInput from 'components/form/inputs/EmailInput';

import { openErrorNotification } from 'graphql/hooks/common';
import { emailValidationSchema } from './constants';

import { SEND_RESET_PASSWORD_LINK } from 'graphql/mutations/auth';

const ForgotPassword: FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');

  const history = useHistory();

  const [sendResetPasswordLink, { loading }] = useMutation(SEND_RESET_PASSWORD_LINK, {
    onCompleted: () => history.push('/forgot-password-success', { email }),
    onError: () => openErrorNotification(t('emailNotFound')),
  });

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={emailValidationSchema}
      onSubmit={values => {
        setEmail(values.email);

        return sendResetPasswordLink({
          variables: {
            email: values.email,
          },
        });
      }}
    >
      {({ errors, handleSubmit }) => (
        <AuthLayout className="forgot-password">
          <div className="content">
            <ViboLogo />
            <GoBack to="/login" />
            <h3>{t('forgotPassword')}</h3>
            <span>{t('enterEmailAddressAssociatedWithAcc')}</span>
            <form onSubmit={handleSubmit} noValidate>
              <EmailInput />
              <Button
                loading={loading}
                disabled={!!errors.email}
                type="submit"
                displayType="primary"
                shape="round"
                size="lg"
              >
                {t('send')}
              </Button>
            </form>
          </div>
        </AuthLayout>
      )}
    </Formik>
  );
};

export default ForgotPassword;
