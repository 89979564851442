import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  visibilityDropdown: {
    boxShadow: theme.shadows[3],
    borderRadius: '4px',
    backgroundColor: theme.palette.background.paper,

    '& .viboSelect__control': {
      padding: '0 8px 0 4px!important',

      '& .highlighted': {
        padding: '0!important',
      },
    },
  },

  visibilityOption: {
    '&.viboIconSVG, & > .viboIconSVG': {
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },

    '& > .viboIconSVG': {
      margin: '0 6px 0 0',
    },

    '&.dj-type:not(.viboSelect__option--is-focused)': {
      '&, & > .viboIconSVG': {
        color: `${theme.palette.text.secondary}!important`,
      },
    },

    '&.host-type:not(.viboSelect__option--is-focused)': {
      '&, & > .viboIconSVG': {
        color: `${theme.palette.primary.main}!important`,
      },
    },

    '&.public-type:not(.viboSelect__option--is-focused )': {
      '&, & > .viboIconSVG': {
        color: `${theme.palette.success.main}!important`,
      },
    },
  },

  short: {
    width: 'fit-content',
    minWidth: 'max-content',
    height: '20px',
  },

  menu: {
    padding: '0',
    overflow: 'hidden',
  },

  checkMarkWrapper: {
    width: '16px',
    marginLeft: '8px',
  },
}));

export default useStyles;
