import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import ChildDjs from '../../ChildDjs';
import Checkbox from 'vibo-ui/Checkbox';
import Scrollbar from 'vibo-ui/Scrollbar';
import WithDjType from '../../WithDjType';
import ContentArea from 'components/common/ContentArea';

import client from 'graphql/client';

import { GET_ME } from 'graphql/queries/user';
import { UPDATE_ALLOW_UPDATE_PROFILE_FOR_CHILD } from 'graphql/mutations/user';

import useStyles from './style';

const MyAccount: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const { data } = useQuery<UserResponse>(GET_ME);
  const user: Maybe<User> = data?.me;

  const [updateUser] = useMutation(UPDATE_ALLOW_UPDATE_PROFILE_FOR_CHILD);

  const handleAllowUpdateProfileForChild = (allowUpdateProfileForChild: boolean): Promise<any> => {
    client.writeQuery({
      query: GET_ME,
      data: {
        me: {
          ...user,
          djSettings: {
            ...(user?.djSettings || {}),
            allowUpdateProfileForChild,
          },
        },
      },
    });

    return updateUser({
      variables: {
        allowUpdateProfileForChild,
      },
    });
  };

  return (
    <div className={classes.myAccount}>
      <WithDjType types={['company', 'single']}>
        <ContentArea className={classes.changeInfo} shadowed>
          <Checkbox
            checked={user?.djSettings!.allowUpdateProfileForChild}
            onChange={e => handleAllowUpdateProfileForChild(e.target.checked)}
            className={classes.checkbox}
          />
          <div>{t('allowMyDjsChangeTheirInfo')}</div>
        </ContentArea>
      </WithDjType>
      <WithDjType types={['company', 'single']}>
        <Scrollbar className="djs-scrollbar" shiftThumb>
          <ChildDjs onDjClick={dj => history.push(`/profile/${dj._id}`)} />
        </Scrollbar>
      </WithDjType>
    </div>
  );
};

export default MyAccount;
