import { createUseStyles } from 'react-jss';

import { StyleProps } from './interfaces';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  colorfulSectionWrapper: {
    '& .section': {
      '&.headline .sectionTop': {
        '& .textPreview , & .viboInput': {
          color: ({ color }: StyleProps) => theme.palette.headline[color],
        },
      },

      '&:not(.selected):not(.headline)': {
        overflow: 'hidden',

        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: '2px',
        },
      },

      '&:not(.selected):not(.headline):not(.dontPlay)': {
        '&:before': {
          backgroundColor: ({ color }: StyleProps) => theme.palette.headline[color],
        },
      },
    },
  },
}));

export default useStyles;
