import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory } from 'react-router';
import { useSearchParam } from 'react-use';
import { useTranslation } from 'react-i18next';

import JoinByDeepLinkPlaceholder from '../../../components/Placeholders/JoinByDeepLinkPlaceholder';
import DeepLinkActionButton from '../../../components/auth/SignInWithLink/DeepLinkActionButton';
import SignInWithLink from '../../../components/auth/SignInWithLink';
import HeadingWithImage from '../../../components/common/HeadingWithImage';
import { DeepLinkContextProvider } from '../../../components/context/DeepLinkContext';
import { WizardState } from 'vibo-ui/Wizard/WizardStepContext';

import { GET_EVENT_PREVIEW_VIA_LINK } from '../../../graphql/queries/events';
import { JOIN_EVENT_VIA_DEEP_LINK } from '../../../graphql/mutations/events';

const JoinEventPage: FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const deepLink = useSearchParam('url') || '';

  const { data } = useQuery<GetEvenPreviewViaLink>(GET_EVENT_PREVIEW_VIA_LINK, {
    variables: {
      deepLink,
    },
    skip: !deepLink,
  });
  const event = data?.getEvenPreviewViaLink as EventDeepLinkPreview;

  const previewData = {
    title: event?.title,
    photo: { url: event?.image.squareUrl, token: event?._id },
  };

  const [joinEventViaDeepLink, { loading }] = useMutation(JOIN_EVENT_VIA_DEEP_LINK, {
    onCompleted: () => push(`/event/${event?._id}/general`),
  });

  const handleStartPlanning = () => {
    if (!!event?._id && !!deepLink) {
      joinEventViaDeepLink({
        variables: {
          deepLink,
        },
      });
    }
  };

  return (
    <WizardState>
      <DeepLinkContextProvider
        action={!!deepLink ? handleStartPlanning : null}
        previewData={previewData}
      >
        <SignInWithLink>
          {!!event ? <HeadingWithImage {...previewData} /> : <JoinByDeepLinkPlaceholder />}
          <DeepLinkActionButton loading={!event || loading}>
            {t('startPlanning')}
          </DeepLinkActionButton>
        </SignInWithLink>
      </DeepLinkContextProvider>
    </WizardState>
  );
};

export default JoinEventPage;
