import React from 'react';
import Tooltip from 'vibo-ui/Tooltip';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SongArtistNamesProps } from './interfaces';

import useStyles from './style';

const SongArtistNames = <T extends SongBase>({
  song,
  className,
  divideWithHyphen,
}: SongArtistNamesProps<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip
      overlay={
        <div className={classes.extraInfoTooltipContent}>
          <div className="label">
            {`${t('song')}:`}
            <span className="songName">{song?.title || '-'}</span>
          </div>
          <div className="label">
            {`${t('artist')}:`}
            <span className="songName">{song?.artist || '-'}</span>
          </div>
        </div>
      }
      type="primary"
      placement="bottomLeft"
      className={classes.extraInfoTooltip}
    >
      <div className={classNames('songArtistNames', classes.songArtistNames, className)}>
        {divideWithHyphen ? (
          <div className={classNames('songName artistName')}>{`${song?.title} ${
            !!song?.artist ? `- ${song?.artist}` : ''
          }`}</div>
        ) : (
          <>
            <div className={classNames('songName', classes.songName)}>{song?.title}</div>
            <div className={classNames('artistName', classes.artistName)}>{song?.artist}</div>
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default SongArtistNames;
