import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SectionBlockInfo from 'components/sections/blocks/SectionBlock/SectionBlockInfo';
import AddTimelineQuestionButton from 'components/buttons/AddTimelineQuestionButton';
import { TimelineQuestionsContext } from '../TimelineQuestionsContext';

import { ReactComponent as PlusIcon } from 'resources/img/svg/common/plus.svg';

import { getSectionQuestionsInfo } from 'services/sections/helpers';
import { hasQuestions } from 'services/questions/helpers';

import useStyles from './style';

const QuestionsBlockHeader: FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const timelineQuestionsContext = useContext(TimelineQuestionsContext);
  const { section } = timelineQuestionsContext;

  return (
    <div className={classNames('questionsBlockHeader', classes.questionsBlockHeader)}>
      <SectionBlockInfo
        title={t('questions')}
        info={!hasQuestions(section) ? '' : getSectionQuestionsInfo(section)}
      />

      <AddTimelineQuestionButton
        prefixIcon={PlusIcon}
        displayType="primary"
        shape="round"
        hideTooltip
        shadowed
      >
        {t('addQuestion')}
      </AddTimelineQuestionButton>

      {children}
    </div>
  );
};

export default QuestionsBlockHeader;
