import ApolloClient from 'apollo-client';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink, from, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { loader } from 'graphql.macro';
import { persistCache } from 'apollo-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import omitDeep from 'omit-deep';
import get from 'lodash/get';

import { interceptZapierLogin } from 'services/common/loginHelper';
import { FORBIDDEN_RESPONSE_OPERATION_NAMES } from 'services/constants';
import { gaSendGqlError } from 'services/googleAnalytics';
import { clearViboTokens } from 'services/users/helpers';

const typeDefs = loader('./schema.graphql');

const resolvers = {
  Mutation: {
    logUserIn: (_: any, { accessToken, refreshToken, user }: any, { cache }: any) => {
      if (interceptZapierLogin(user._id)) {
        return null;
      }

      localStorage.setItem('vibo_token', accessToken);
      localStorage.setItem('vibo_refreshToken', refreshToken);

      cache.writeData({
        data: {
          isLoggedIn: true,
        },
      });

      return null;
    },
    logUserOut: (_: any, __: any, { cache }: any) => {
      clearViboTokens();

      cache.writeData({
        data: {
          user: null,
          isLoggedIn: false,
          scannerComputer: null,
        },
      });

      return null;
    },
  },
};

const forbiddenLink = onError(({ graphQLErrors, operation }) => {
  const { operationName } = operation;
  const code = get(graphQLErrors, '[0].code');
  const goForbiddenPage = () => window?.location?.assign('/forbidden');

  gaSendGqlError(graphQLErrors?.[0]);

  if (code === 'FORBIDDEN' && FORBIDDEN_RESPONSE_OPERATION_NAMES.includes(operationName)) {
    return goForbiddenPage();
  }
});

const errorLink = onError(({ graphQLErrors, response }) => {
  const code = get(graphQLErrors, '[0].code');
  const pathname = window.location.pathname;
  const goNotFoundPage = () => window?.location?.assign('/not-found');

  gaSendGqlError(graphQLErrors?.[0]);

  if (code === 'UNAUTHORIZED' && pathname !== '/connect-applemusic') {
    if (response) {
      response.errors = undefined;
      response.data = {};
    }

    clearViboTokens();

    client.writeData({ data: { user: null, isLoggedIn: false } });
  }

  if (code === 'CANNOT_GET_EVENT') {
    return goNotFoundPage();
  }

  client.writeData({ data: { loading: false } });
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URI,
});

const middlewareLink = setContext((_, prevContext) => {
  const headers: Record<string, any> = {};

  const prevToken = prevContext.token;
  const vibo_token = localStorage.getItem('vibo_token');

  if (prevToken || vibo_token) {
    headers['x-token'] = prevToken || vibo_token;
  }

  if (prevContext.refreshToken) {
    headers['x-refresh-token'] = prevContext.refreshToken;
  } else if (localStorage.getItem('vibo_refreshToken')) {
    headers['x-refresh-token'] = localStorage.getItem('vibo_refreshToken');
  }

  return { headers };
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const {
      response: { headers },
    } = operation.getContext();

    if (headers) {
      const token = headers.get('x-token');
      const refreshToken = headers.get('x-refresh-token');

      if (token) {
        localStorage.setItem('vibo_token', token);
      }

      if (refreshToken) {
        localStorage.setItem('vibo_refreshToken', refreshToken);
      }
    }

    return response;
  })
);

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map(data => {
    return data;
  });
});

export const networkStatusLink = new ApolloLink((operation, forward) => {
  client.writeData({ data: { loading: true } });

  return forward(operation).map(data => {
    client.writeData({ data: { loading: false } });

    return data;
  });
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_URI!,
  options: {
    reconnect: true,
    connectionParams: () => ({
      accessToken: localStorage.getItem('vibo_token'),
      refreshToken: localStorage.getItem('vibo_refreshToken'),
    }),
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  from([
    networkStatusLink,
    cleanTypeName,
    errorLink,
    forbiddenLink,
    middlewareLink,
    afterwareLink,
    uploadLink,
  ])
);

export const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case 'SectionSong':
      case 'PrepModeSong':
      case 'Section':
      case 'TemplateSection': {
        return object._id;
      }
      case 'PrepModeSection': {
        return `PrepModeSection:${object._id}`;
      }
      case 'SearchedSong': {
        return object.viboSongId
          ? `SearchedSong:${object.viboSongId}`
          : `SearchedSong:${object.songUrl}`;
      }
      case 'SearchedSongForSongIdeas': {
        return object.viboSongId
          ? `SearchedSongForSongIdeas:${object.viboSongId}`
          : `SearchedSongForSongIdeas:${object.songUrl}`;
      }
      case 'PlaylistSongsResponse':
      case 'EventFileTimelineOptions':
      case 'EventFileSectionsOptionsWeb':
      case 'EventFilePlaylistsOptionsWeb':
      case 'PrintSectionOptions': {
        return null;
      }
      default:
        return defaultDataIdFromObject(object);
    }
  },
  addTypename: true,
});

persistCache({
  cache,
  storage: window.localStorage as any,
});

const client = new ApolloClient({
  link,
  cache,
  resolvers,
  typeDefs,
});

window.addEventListener('load', () =>
  client.writeData({
    data: {
      isOnline: navigator.onLine,
    },
  })
);

window.addEventListener('online', () =>
  client.writeData({
    data: {
      isOnline: true,
    },
  })
);

window.addEventListener('offline', () =>
  client.writeData({
    data: {
      isOnline: false,
    },
  })
);

export default client;
