import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboMenu: {
    padding: '0',
    margin: '0',
    boxShadow: 'none',
    border: 'none',
    fontFamily: 'Roboto, sans-serif',

    '&[class*="rc-menu"]': {
      padding: '0',
      margin: '0',
      boxShadow: 'none',
      border: 'none',
    },

    '&.rc-menu-horizontal': {
      backgroundColor: 'transparent',
    },

    '&.asTabs, &.asTabsLg': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .menuItem': {
        color: theme.palette.text.hint,
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '19.5px',
        padding: '4px 13px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.text.hint}`,
        paddingTop: 0,
        paddingBottom: 0,

        '&:hover:not(.rc-menu-item-selected)': {
          backgroundColor: 'transparent',
        },

        '&.rc-menu-item-selected': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.background.default,
          border: `1px solid ${theme.palette.primary.main}`,
        },

        '& .suffix': {
          textTransform: 'lowercase',
          marginLeft: '4px',
        },

        '&:first-child': {
          borderRadius: '4px 0 0 4px',
        },

        '&:last-child': {
          borderRadius: '0 4px 4px 0',
        },

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },

    '&.asTabsLg': {
      height: '30px',

      '& .menuItem': {
        height: '100%',
      },

      '@media screen and (min-width: 1441px)': {
        height: '36px',
      },
    },

    '&.asDropdown': {
      '& .menuItem': {
        color: theme.palette.text.secondary,
        padding: '4px 6px',

        '&:hover': {
          backgroundColor: theme.palette.primary.lighty,
          color: theme.palette.text.primary,
        },

        '&:first-child': {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        },

        '&:last-child': {
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        },

        '& .menuItemContent': {
          '& .viboIcon': {
            color: theme.palette.primary.main,
            margin: '0 4px 0 0',

            '@media screen and (min-width: 1441px)': {
              margin: '0 6px 0 0',
            },
          },
        },

        '@media screen and (min-width: 1441px)': {
          padding: '4px 8px',
        },
      },
    },

    '&.asButtons': {
      '&.rc-menu': {
        padding: '4px',

        '& .menuItem': {
          padding: 0,

          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },

      '& .menuItem': {
        color: theme.palette.tooltip.infoBgColor,
        height: '24px',
        borderRadius: '4px',
        overflow: 'hidden',
        backgroundColor: theme.palette.primary.main,
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '19.5px',

        '& .menuItemContent': {
          width: '100%',
          justifyContent: 'center',
        },

        '&:not(:last-child)': {
          margin: '0 0 4px 0',
        },

        '@media screen and (min-width: 1441px)': {
          height: '28px',
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },

    '&.siderTop': {
      height: '100%',
      borderRadius: 0,

      '& .menuItem': {
        fontSize: '15px',
        height: '100%',
        color: theme.palette.text.primary,
        padding: 0,
        border: 'none',

        '&.rc-menu-item-selected': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',

          '&:after': {
            content: '""',
            width: '100%',
            height: '2px',
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
            left: 0,
            bottom: 0,
          },
        },

        '& .menuItemContent': {
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '&:not(:last-child)': {
          margin: '0 36px 0 0',
        },

        '&:hover': {
          backgroundColor: 'transparent',
        },

        '@media screen and (min-width: 1441px)': {
          fontSize: '18px',
        },
      },
    },

    '&.siderLeft': {
      borderRadius: 0,

      '& .menuItem': {
        fontSize: '13px',
        color: theme.palette.text.secondary,
        padding: '12px',
        width: '100%',

        '&.rc-menu-item-selected': {
          borderRight: `2px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
        },

        '& .menuItemContent > .viboIcon': {
          marginRight: '8px',

          '@media screen and (min-width: 1441px)': {
            marginRight: '12px',
          },
        },

        '&, &:last-of-type': {
          borderBottom: `1px solid ${theme.palette.background.default}`,
        },

        '&:hover': {
          '&:not(.rc-menu-item-selected)': {
            background: theme.palette.background.default,
          },
        },

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          padding: '12px 16px',
        },
      },
    },
  },

  menuItem: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color .2s',

    '&.rc-menu-item-disabled': {
      cursor: 'not-allowed',
    },

    '&.rc-menu-item': {
      padding: '12px',
    },

    '& a': {
      display: 'block',
      width: '100%',
      height: '100%',
      padding: '12px 8px 12px 12px',
      textDecoration: 'none',

      '&:active': {
        color: theme.palette.text.secondary,
      },
    },
  },

  menuItemContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default useStyles;
