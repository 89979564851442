import React, { FC, useContext, memo } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useMutation } from '@apollo/react-hooks';

import TimelineSong from '../../TimelineSong';
import VirtualizedListDnd from 'components/common/VirtualizedList/VirtualizedListDnd';
import { EventContext } from 'components/context/EventContext';
import { TimelineSongsContext } from '../../TimelineSongsContext';

import client from 'graphql/client';
import { onError } from 'graphql/helpers';
import { INITIAL_SONGS_LIMIT } from 'services/constants';
import { hasSectionSongsFilterValue } from 'services/songs/helpers';
import { TIMELINE_SONG_ROW_LG, TIMELINE_SONG_ROW_SM } from '../constants';

import { GET_EVENT_SECTION_SONGS } from 'graphql/queries/songs';
import { REORDER_SONGS } from 'graphql/mutations/songs';

import { SongsViewProps } from '../interfaces';
import { DragResponse } from 'vibo-ui/List';

import useTimelineSongStyles from '../../TimelineSong/style';

const SongsDjView: FC<SongsViewProps> = ({ className, highlightedSong, handleHighlightSong }) => {
  const timelineSongClasses = useTimelineSongStyles();

  const { songsOptions, variables, section, refetchSongs } = useContext(TimelineSongsContext);
  const { isEventHost, isEventDj } = useContext(EventContext);

  const [reorderSongs] = useMutation(REORDER_SONGS, {
    onCompleted: () => {
      refetchSongs();
    },
    onError,
  });

  const isReorderingEnabled =
    !songsOptions.songsSort &&
    (isEventDj || (isEventHost && section.settings.canHostsOrderSongs)) &&
    !songsOptions.isFilterApplied;

  const handleDragChange = (
    reorderedSongs: SectionSong[],
    { removedIndex, addedIndex }: DragResponse
  ) => {
    if (hasSectionSongsFilterValue(songsOptions.songsFilter) || songsOptions.songsSort !== null) {
      songsOptions.setSongsList([...songsOptions.songsList]);
      return;
    }

    if (!isEqual(reorderedSongs, songsOptions.songsList)) {
      const targetIndex = addedIndex - (addedIndex < removedIndex ? 1 : 0);

      songsOptions.setSongsList(reorderedSongs);

      reorderSongs({
        variables: {
          ...variables,
          sourceSongId: songsOptions.songsList[removedIndex]?._id,
          targetSongId: targetIndex < 0 ? null : songsOptions.songsList[targetIndex]?._id,
        },
      });
      client.writeQuery<SectionSongsResponse>({
        query: GET_EVENT_SECTION_SONGS,
        variables: {
          ...variables,
          filter: songsOptions.songsFilter,
          sort: songsOptions.songsSort,
          pagination: {
            skip: 0,
            limit: INITIAL_SONGS_LIMIT,
          },
        },
        data: {
          getSectionSongs: {
            ...songsOptions.sectionSongsData,
            songs: reorderedSongs,
          },
        },
      });
    }
  };

  return (
    <VirtualizedListDnd<SectionSong>
      rowRenderer={(song, index) => {
        return (
          <TimelineSong
            key={`songId_${song._id}`}
            song={song}
            index={index}
            className={classNames('timelineSong', highlightedSong, song.viboSongId, {
              [timelineSongClasses.isMustPlay]: song.isMustPlay,
              highlighted: highlightedSong === song.viboSongId,
            })}
            onClick={({ viboSongId }) => handleHighlightSong(viboSongId)}
            data-gtm-target-id="timeline-section-details-songs-full-song"
          />
        );
      }}
      className={classNames('songsList', className)}
      data={songsOptions.songsList}
      rowHeights={[TIMELINE_SONG_ROW_SM, TIMELINE_SONG_ROW_LG]}
      gaps={[0, 0]}
      onDropCallback={handleDragChange}
      scrollId="timeline-blocks"
      droppableId="timeline-songs"
      isDragDisabled={!isReorderingEnabled}
      key={`virtualized-timeline-songs-${highlightedSong}`}
      destroyOnUnmount
    />
  );
};

export default memo(SongsDjView, () => true);
