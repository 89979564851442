import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import SignIn from '../SignIn';
import { ModalWindowContextProvider } from 'vibo-ui/Modal/ModalWindowContext';

import { isLoggedIn } from 'graphql/queries/auth';

export const AuthRequiredInline = (props: any) => {
  const { loading, data } = useQuery(isLoggedIn);

  if (loading) return <div>loading...</div>;

  return (
    <ModalWindowContextProvider>
      {data && data.isLoggedIn ? props.children : <SignIn />}
    </ModalWindowContextProvider>
  );
};
