import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles(({ isDark, ...theme }: ViboTheme) => ({
  topBar: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 0 auto',

    '& .viboToggleDropdown .viboButton, & .menuItem': {
      padding: 0,

      '& .viboIcon': {
        color: theme.palette.primary.main,
        margin: '0',

        '& svg': {
          width: '20px',
          height: '20px',

          '@media screen and (min-width: 1441px)': {
            width: '24px',
            height: '24px',
          },
        },
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    '& .tooltipContent, & .viboMenu': {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& .menuItem, .viboToggleDropdown': {
      margin: '0 0 0 12px!important',

      '@media screen and (min-width: 1441px)': {
        margin: '0 0 0 16px!important',
      },
    },
  },

  profileImage: {
    borderRadius: '50%',
    width: '20px',
    height: '20px',

    '@media screen and (min-width: 1441px)': {
      width: '24px',
      height: '24px',
    },
  },

  themeButton: {
    margin: '0 0 0 16px',

    '&.smSize': {
      padding: 0,
    },

    '&.defaultDisplay': {
      '&, &:hover': {
        backgroundColor: `${isDark ? colors.white : colors.emperor}!important`,
        color: `${isDark ? colors.emperor : colors.white}!important`,
      },
    },

    '&.circleShape': {
      width: '20px',
      height: '20px',

      '@media screen and (min-width: 1441px)': {
        width: '24px',
        height: '24px',
      },
    },

    '& svg': {
      fill: 'none',
    },
  },

  toggleShowBtn: {
    boxShadow: 'none',
    display: 'flex',

    '& .viboButton': {
      // FOR GTM TRIGERS
      '& > *': {
        pointerEvents: 'none',
      },

      '& > .prefixIcon': {
        fontSize: '20px!important',

        '@media screen and (min-width: 1441px)': {
          fontSize: '24px!important',
        },
      },
    },
  },

  notificationsMenu: {
    left: 'auto!important',
    right: '20px!important',
    top: '40px!important',
    width: '464px',
    height: 'fit-content',
    zIndex: 999,

    '@media screen and (min-width: 1441px)': {
      top: '48px!important',
      width: '536px',
    },
  },

  notificationsCount: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 500,
    padding: '0 2px',
    backgroundColor: theme.palette.error.light,
    color: colors.white,
    borderRadius: '8px',
    position: 'absolute',
    right: '0',
    height: '11px',
    minWidth: '11px',
    textAlign: 'center',
    width: 'auto!important',
  },

  notificationsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '13px',
    lineHeight: '19.5px',
    fontWeight: 500,
    padding: '7px 14px',
    borderBottom: `1px solid ${theme.palette.selection.iconsHover}`,
    color: theme.palette.text.primary,

    '& .viboButton': {
      padding: '0',
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
      padding: '10px 16px',
    },
  },

  notificationsArea: {
    backgroundColor: isDark ? colors.shipGray : colors.white,
    userSelect: 'none',

    '& .emptyState': {
      color: isDark ? colors.silver : colors.dustyGray,
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '24px 0',

      '& .viboIcon, & .description': {
        color: 'inherit',
      },

      '& .viboIcon': {
        marginRight: '12px',

        '@media screen and (min-width: 1441px)': {
          marginRight: '16px',
        },
      },

      '@media screen and (min-width: 1441px)': {
        padding: '28px 0',
      },
    },

    '& .notification:not(:last-child)': {
      borderBottom: `1px solid ${isDark ? colors.balticSeaDark : theme.palette.primary.lighty}`,
    },
  },

  notificationsFooter: {
    '& .viboButton.smSize': {
      height: '36px',
      width: '100%',

      '&:hover': {
        backgroundColor: isDark ? colors.balticSea : theme.palette.primary.lighty,
        color: theme.palette.button.active,
      },

      '@media screen and (min-width: 1441px)': {
        height: '48px',
      },
    },
  },

  scrollbar: {
    height: '100%!important',
    padding: '0 0 10 0',

    '&.withContent': {
      maxHeight: '75vh!important',

      '@media screen and (min-width: 1441px)': {
        maxHeight: '80vh!important',
      },
    },
  },

  notification: {
    display: 'flex',
    flexDirection: 'row',
    padding: '14px',
    overflow: 'hidden',

    '&:not(.isNew)': {
      opacity: '0.7',
    },

    '&:hover': {
      backgroundColor: isDark ? colors.scarpaFlow : theme.palette.primary.lighty,

      '& .time': {
        color: colors.dustyGray,
      },
    },

    '@media screen and (min-width: 1441px)': {
      padding: '16px',
    },
  },

  notificationImage: {
    minWidth: '60px',
    width: '60px',
    height: '60px',
    position: 'relative',

    '& .viboImage': {
      borderRadius: '50%',

      '& .gradient': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        '& .viboIcon': {
          color: theme.palette.tooltip.infoBgColor,

          '& svg': {
            width: '32px',
            height: '32px',

            '@media screen and (min-width: 1441px)': {
              width: '36px',
              height: '36px',
            },
          },
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      minWidth: '72px',
      width: '72px',
      height: '72px',
    },
  },

  notificationDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 12px',
    color: theme.palette.text.primary,
    fontSize: '15px',
    lineHeight: '22.5px',
    width: 'calc(100% - 90px)',
    userSelect: 'none',

    '& .eventName': {
      fontWeight: 500,
    },

    '& .eventName, & .message': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },

    '@media screen and (min-width: 1441px)': {
      margin: '0 16px',
      fontSize: '18px',
      lineHeight: '27px',
      width: 'calc(100% - 110px)',
    },
  },

  indicators: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.hint,

    '& .newCircle': {
      width: '14px',
      height: '14px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },

    '& .time': {
      margin: 'auto 0 0 0',
      fontSize: '13px',
      lineHeight: '19.5px',
      whiteSpace: 'nowrap',

      '@media screen and (min-width: 1441px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },

  notificationIcon: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    backgroundColor: isDark ? colors.shipGray : colors.white,
    right: '0',
    bottom: '0',
    borderRadius: '50%',

    '&.viboIcon': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&.hostGuestJoinedEvent, &.accountStatusChangedToActivated': {
      color: theme.palette.success.main,
    },

    '&.hostGuestLeftEvent, &.eventLocked, &.accountStatusChangedToChargeFailed': {
      color: theme.palette.error.main,
    },

    '&.lockoutDate': {
      color: theme.palette.primary.main,
    },

    '&.accountStatusChangedToLimited': {
      color: theme.palette.warning.main,
    },

    '& svg': {
      margin: 0,
      width: '14px',
      height: '14px',

      '@media screen and (min-width: 1441px)': {
        width: '16px',
        height: '16px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      width: '28px',
      height: '28px',
    },
  },
}));

export default useStyles;
