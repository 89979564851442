import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  songPlayerIframe: {
    margin: 'auto 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '346px',

    '& iframe': {
      border: '0',
      borderRadius: '4px',
      maxWidth: '100%',
      maxHeight: '340px',

      '@media screen and (min-width: 1441px)': {
        maxHeight: '390px',
      },
    },

    '@media screen and (min-width: 1441px)': {
      minHeight: '396px',
    },
  },

  switchSongIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },

  disabledIcon: {
    cursor: 'default',

    '& svg': {
      '& path': {
        stroke: theme.palette.text.disabled,
      },
    },
  },

  links: {
    fontSize: '16px',
    display: 'flex',
    height: 'auto',
    alignItems: 'center',
    margin: '6px auto 0 auto',

    '& > div': {
      display: 'flex',

      '& svg': {
        height: '20px',
        width: '20px',

        '&:hover': {
          cursor: 'pointer',
        },

        '@media screen and (min-width: 1441px)': {
          height: '24px',
          width: '24px',
        },
      },
    },
  },

  thumbnail: {
    '&.songImage': {
      height: '340px',
      width: '340px',

      '@media screen and (min-width: 1441px)': {
        height: '390px',
        width: '390px',
      },

      '& img, & .intrinsic-item': {
        borderRadius: '4px',
      },
    },
  },
}));

export default useStyles;
