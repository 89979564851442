import { gql } from 'graphql.macro';

import { NOTIFICATION } from 'graphql/fragments/notifications';

export const GET_NOTIFICATIONS = gql`
  query getNotifications($pagination: PaginationInput) {
    getNotifications(pagination: $pagination) {
      notifications {
        ...NotificationFragment
      }
      next {
        skip
        limit
      }
      totalCount
    }
  }
  ${NOTIFICATION}
`;

export const GET_NOTIFICATIONS_COUNT = gql`
  query getNotificationsCount {
    getNotificationsCount {
      total
      __typename
    }
  }
`;
