import { createUseStyles } from 'react-jss';

import { colors } from 'resources/styles/colors';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  viboSelect: {
    fontSize: '13px',
    lineHeight: '19.56px',
    width: 'fit-content',

    '& .viboSelect__menu-list': {
      padding: '0',
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
    },

    '& .viboSelect__input-container': {
      color: theme.palette.text.secondary,
    },

    '& .viboSelect__indicator-separator': {
      display: 'none',
    },

    '& .viboSelect__single-value': {
      color: theme.palette.text.primary,
      margin: 0,
    },

    '& .viboSelect__placeholder': {
      color: theme.palette.text.hint,
    },

    '& .viboSelect__multi-value': {
      borderRadius: '4px',
    },

    '& .viboSelect__indicators': {
      '& > .viboSelect__indicator': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
      },
    },

    //Excluded classes - modificators, to prevent recovering these styles
    '&:not(.asBorderedInput):not(.asDropdown)': {
      '& .viboSelect__control': {
        fontSize: '13px',
        height: '24px',
        width: 'fit-content',
        minHeight: 'auto',
        border: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: 'none',

        '&.viboSelect__control--menu-is-open': {
          '& .viboSelect__indicators': {
            '& > .viboSelect__indicator': {
              transform: 'rotate(180deg)',
            },
          },
        },

        '& .viboSelect__indicators': {
          '& > .viboSelect__indicator': {
            transition: 'transform .2s',
            padding: '0 6px 0 4px',

            '@media screen and (min-width: 1441px)': {
              padding: '0 8px 0 6px',
            },
          },
        },

        '& .viboSelect__value-container': {
          padding: 0,
        },

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          height: '28px',
        },
      },
    },

    '&.default': {
      '& .viboSelect__control': {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,

        '&.viboSelect__control--is-disabled': {
          backgroundColor: 'transparent',
          pointerEvents: 'all',

          '&, & *': {
            cursor: 'not-allowed',
          },
        },

        '&, & .highlighted': {
          padding: '0 0 0 6px',

          '@media screen and (min-width: 1441px)': {
            padding: '0 0 0 8px',
          },
        },

        '@media screen and (min-width: 1441px)': {
          fontSize: '16px',
          height: '28px',
        },
      },

      '&:hover': {
        '& .viboSelect__control': {
          backgroundColor: theme.palette.selection.hover,
        },
      },
    },

    '&.asBorderedInput': {
      '& .viboSelect__control': {
        border: '1px solid transparent',
        borderRadius: '4px',
        padding: '0 11px',
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',

        '&:hover, &:focus, &:active': {
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },

      '& .viboSelect__value-container': {
        padding: '0',
      },
    },

    '&.asDropdown': {
      boxShadow: theme.shadows[4],
      borderRadius: '4px',
      border: 'none',

      '& .viboSelect__control': {
        cursor: 'pointer',

        '&:hover, &:focus, &:active': {
          backgroundColor: theme.palette.primary.lighty,
        },
      },

      '& .customValueContainer ': {
        padding: '0 0 0 8px',

        '& .prefix': {
          color: theme.palette.text.primary,
        },

        '& .highlighted': {
          color: theme.palette.primary.main,
        },
      },
    },

    '&.asLink': {
      '& .viboSelect__control': {
        backgroundColor: 'transparent',
        padding: 0,
        color: theme.palette.primary.main,

        '& .viboSelect__single-value': {
          color: theme.palette.primary.main,
        },
      },
    },

    '@media screen and (min-width: 1441px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  hideDropdownIndicator: {
    '& .viboSelect__dropdown-indicator': {
      display: 'none',
    },
  },

  invalidInput: {
    '& .viboSelect__control': {
      '&:hover, &:focus, &:focus-within': {
        border: `1px solid ${theme.palette.error.main}`,
        boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
      },
    },
  },

  loading: {
    pointerEvents: 'none',

    '& .viboSelect__dropdown-indicator': {
      display: 'none',
    },

    '& .viboSelect__menu': {
      pointerEvents: 'none',
      color: theme.palette.text.hint,
    },
  },

  multiValueLabel: {
    borderRadius: '4px 0 0 4px',
    backgroundColor: theme.palette.primary.main,

    '& > div': {
      color: colors.white,
    },
  },

  multiValueRemove: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0 4px 4px 0',
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    padding: '0 3px',
    color: colors.white,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  menuListScrollbar: {
    display: 'block',

    '&.alwaysShowThumb': {
      padding: '0 6px 0 0',
    },

    '& > .scrollView': {
      maxHeight: '192px',

      '@media screen and (min-width: 1441px)': {
        maxHeight: '180px',
      },
    },

    '& .trackVertical': {
      '& .thumbVertical': {
        opacity: '0.25',
      },
    },
  },

  menu: {
    '&.viboSelect__menu': {
      fontSize: '11px',
      lineHeight: '16.5px',
      color: theme.palette.text.secondary,
      overflow: 'hidden',
      zIndex: 2,
      backgroundColor: theme.palette.background.dropDown,
      boxShadow: theme.shadows[3],
      width: 'fit-content',
      margin: '4px 0 0 0',

      '@media screen and (min-width: 1441px)': {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },

    '& .viboSelect__option': {
      padding: '2px 6px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .checkIcon': {
        margin: '0 0 0 4px',
      },

      '& .viboIcon:not(.checkIcon)': {
        margin: '0 6px 0 0',

        '&.viboIconSVG': {
          width: '14px',
          height: '14px',

          '@media screen and (min-width: 1441px)': {
            width: '16px',
            height: '16px',
          },
        },
      },

      '&:not(.viboSelect__option--is-disabled)': {
        '&.viboSelect__option--is-selected:not(.viboSelect__option--is-focused)': {
          backgroundColor: theme.palette.selection.menuItemSelectedBg,
          color: theme.palette.text.primary,

          '& .checkIcon': {
            color: theme.palette.primary.main,
          },
        },

        '&.viboSelect__option--is-focused': {
          backgroundColor: theme.palette.selection.menuItemHoverBg,
          color: theme.palette.selection.whiteToBalticSeaDark,

          '& .checkIcon': {
            color: theme.palette.selection.whiteToBalticSeaDark,
          },
        },

        '&:hover': {
          cursor: 'pointer',
        },
      },

      '&.viboSelect__option--is-disabled': {
        color: theme.palette.text.hint,
        backgroundColor: 'transparent',
        pointerEvents: 'none',

        '&.asTitle': {
          paddingLeft: '14px',
          paddingRight: '14px',
          fontSize: '9px',

          '@media screen and (min-width: 1441px)': {
            fontSize: '12px',
          },
        },
      },

      '@media screen and (min-width: 1441px)': {
        padding: '4px 8px',
      },
    },
  },

  optionContent: {
    '&': {
      ...theme.mixins.longText,
    },

    maxWidth: '250px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '@media screen and (min-width: 1441px)': {
      maxWidth: '280px',
    },
  },

  customValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& .prefix': {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .viboIconSVG': {
        color: theme.palette.primary.main,
        margin: '0 4px 0 0',
        width: '14px',
        height: '14px',

        '@media screen and (min-width: 1441px)': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
}));

export default useStyles;
