import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  addSectionMiddleTooltip: {
    '& .rc-tooltip-inner': {
      maxWidth: '12ch',
      lineHeight: '20px',
    },
  },

  menus: {
    '& > .viboCard': {
      width: 'fit-content',
      margin: '0 auto',
    },

    '& .menuItemContent': {
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
