import { gql } from 'graphql.macro';

export const TOGGLE_SELECT_SONG_FILE = gql`
  mutation toggleSelectSongFile(
    $eventId: ID!
    $sectionId: ID!
    $songId: ID!
    $computerId: ID!
    $fileId: String!
  ) {
    toggleSelectSongFile(
      eventId: $eventId
      sectionId: $sectionId
      songId: $songId
      computerId: $computerId
      fileId: $fileId
    ) {
      selected
    }
  }
`;

export const REMOVE_SONG_FILE = gql`
  mutation removeSongFile(
    $eventId: ID!
    $sectionId: ID!
    $songId: ID!
    $fileId: String!
    $computerId: String!
  ) {
    removeSongFile(
      eventId: $eventId
      sectionId: $sectionId
      songId: $songId
      fileId: $fileId
      computerId: $computerId
    )
  }
`;
