import React, { FC, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import PhoneNumber from 'components/user/Profile/MyInfo/PhoneNumber';
import CopyQuestionAnswer from 'components/questions/CopyQuestionAnswer';
import Icon, { IconmoonFont } from 'vibo-ui/Icon';
import { QuestionContext } from 'components/events/Timeline/TimelineQuestionsBlock/TimelineQuestionsContext';

import useCommonStyles from '../../common/style';
import useStyles from './style';

const PhoneAnswer: FC = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const formik = useFormikContext<UpdateQuestionForm>();

  const { question, canAnswer } = useContext(QuestionContext);

  const phoneNumber = useMemo(() => formik.values.answer?.phoneNumber, [
    formik.values.answer?.phoneNumber,
  ]);
  const number = useMemo(() => phoneNumber?.phoneNumber, [phoneNumber?.phoneNumber]);
  const code = useMemo(() => phoneNumber?.phoneCode, [phoneNumber?.phoneCode]);
  const value = useMemo(() => (!!code && !!number ? `+${code}${number}` : ''), [code, number]);

  return (
    <div className={classNames('phoneAnswer', commonClasses.infoAnswer)}>
      <Icon icon={IconmoonFont['phone-16']} />
      <PhoneNumber
        onChange={(phoneNumber, { dialCode, countryCode }) => {
          formik.setFieldValue(
            'answer.phoneNumber.phoneNumber',
            phoneNumber.replace(new RegExp(dialCode, 'i'), '')
          );
          formik.setFieldValue('answer.phoneNumber.phoneCode', !!phoneNumber ? dialCode : null);
          formik.setFieldValue('answer.phoneNumber.country', countryCode);
        }}
        value={value}
        disabled={!canAnswer}
        containerClass={classes.phonePreviewInput}
        disableBaseClasses
        key={`phone-answer-${question._id}-${value}`}
      />
      {!!number ? <CopyQuestionAnswer value={value} /> : null}
    </div>
  );
};

export default PhoneAnswer;
