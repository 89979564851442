import { gql } from 'graphql.macro';

export const GET_SCANNER_INFO = gql`
  query getScannerInfo {
    getScannerInfo {
      winFileSize
      winVersion
      macVersion
      macFileSize
    }
  }
`;
