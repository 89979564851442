import { HTMLAttributes } from 'react';
import { IconmoonFont, SvgElement } from 'vibo-ui/Icon';
export interface LinkProps extends BaseButtonProps {
  href?: string;
  to?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export interface BaseButtonProps extends HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  disabled?: boolean;
  className?: string;
}

export interface ButtonProps extends BaseButtonProps, LinkProps {
  displayType?: DisplayType;
  type?: ButtonType;
  shape?: ButtonShape;
  loading?: boolean;
  prefixIcon?: IconmoonFont | SvgElement;
  sufixIcon?: IconmoonFont | SvgElement;
  size?: ButtonSize;
  shadowed?: boolean;
}

export enum ButtonDecor {
  'danger' = 'danger',
  'success' = 'success',
  'centrate' = 'centrate',
}

export type ButtonShape = 'default' | 'circle' | 'round';

export type ButtonType = 'submit' | 'button' | 'reset';

export type ButtonSize = 'sm' | 'lg';

export type DisplayType = 'default' | 'primary' | 'link' | 'bordered';
