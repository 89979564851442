import React, { FC, useContext, useCallback, MouseEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'vibo-ui/Button';
import CellName from 'components/common/CellName';
import IconButton from 'components/buttons/IconButton';
import CloneTemplateButton from '../CloneTemplateButton';
import { TemplatesContext } from 'components/context/TemplatesContext';

import { useModal } from 'vibo-ui/Modal';

import { IconmoonFont } from 'vibo-ui/Icon';
import { ButtonIconType, Modals, TemplatesType } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { ControlsProps } from './interfaces';

import useDecorStyles from 'components/buttons/IconButton/decorStyle';
import useStyles from './style';

const Controls: FC<ControlsProps> = ({ template }) => {
  const decorClasses = useDecorStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const { type, deleteTemplate } = useContext(TemplatesContext);

  const { openModal, closeModal } = useModal();

  const closeConfirmModal = useCallback(() => closeModal(Modals.confirmAction), []);

  const openConfirmModal = useCallback(
    () =>
      openModal<ConfirmActionModalProps>({
        key: Modals.confirmAction,
        props: {
          title: t('deleteTemplate'),
          children: t('sureYouWantDeleteTemplte', {
            template: template?.title,
          }),
          submit: {
            text: t('delete'),
            onClick: handleDeleteTemplate,
          },
        },
      }),
    []
  );

  const handleDeleteTemplate = useCallback(() => {
    deleteTemplate?.(template._id);
    closeConfirmModal();
  }, [template._id]);

  const isTemplatesPublic = type === TemplatesType.vibo;

  return (
    <CellName name="controls" className={classes.controls}>
      {isTemplatesPublic ? (
        <CloneTemplateButton
          template={template}
          renderButton={onClick => (
            <Button
              onClick={onClick}
              prefixIcon={IconmoonFont['clone-16']}
              className={decorClasses.highlighted}
              shape="round"
            >
              {t('addToMyTemplates')}
            </Button>
          )}
        />
      ) : (
        <CloneTemplateButton
          template={template}
          renderButton={onClick => (
            <IconButton
              onClick={onClick as (event: MouseEvent) => void}
              title={t(isTemplatesPublic ? 'addToMyTemplates' : 'cloneTemplate')}
              className={decorClasses.highlighted}
              type={ButtonIconType.clone}
            />
          )}
        />
      )}
      {type === TemplatesType.vibo || !template.canEdit ? null : (
        <IconButton
          type={ButtonIconType.delete}
          className={classNames('hoverable-action', decorClasses.highlighted)}
          title={t('deleteTemplate')}
          onClick={e => {
            e.stopPropagation();
            openConfirmModal();
          }}
        />
      )}
    </CellName>
  );
};

export default Controls;
