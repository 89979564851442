import { createUseStyles } from 'react-jss';

import { ViboTheme } from 'types/theme';

const useStyles = createUseStyles((theme: ViboTheme) => ({
  spotifyButton: {
    height: '100%',
    width: 'auto!important',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 10px',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '219.5px',
    overflow: 'hidden',

    '&.viboButton': {
      '&:disabled': {
        backgroundColor: 'transparent',
        opacity: '0.3',
      },

      '&.primaryDisplay.success': {
        '& .prefixIcon:before': {
          color: theme.palette.tooltip.infoBgColor,
        },
      },
    },

    '@media screen and (min-width: 1500px)': {
      padding: '6px 10px',
      fontSize: '16px',
      lineHeight: '20.85px',
    },
  },
}));

export default useStyles;
