import React, { FC } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import Button, { ButtonDecor } from 'vibo-ui/Button';

import { useModal } from 'vibo-ui/Modal';
import { toastNotify } from 'graphql/hooks/common';

import { DELETE_TEMPLATE } from 'graphql/mutations/template';

import { IconmoonFont } from 'vibo-ui/Icon';
import { ButtonIconType, Modals } from 'types/enums';
import { ConfirmActionModalProps } from 'components/modals/ConfirmActionModal';
import { DeleteEventButtonProps } from './interfaces';

const DeleteTemplateButton: FC<DeleteEventButtonProps> = ({ template, className }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { openModal } = useModal();

  const openConfirmModal = () =>
    openModal<ConfirmActionModalProps>({
      key: Modals.confirmAction,
      props: {
        title: t('deleteTemplate'),
        children: t('sureYouWantDeleteTemplte', {
          template: template.title,
        }),
        submit: {
          text: t('delete'),
          onClick: () => deleteTemplate(),
        },
      },
    });

  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    variables: {
      templateId: template._id,
    },
    onCompleted: () => {
      toastNotify({ text: t('templateDeletedSuccessfully'), iconType: ButtonIconType.delete });
      history.push(`/templates/user`);
    },
  });

  return (
    <Button
      onClick={openConfirmModal}
      prefixIcon={IconmoonFont['delete-16']}
      className={classNames(ButtonDecor.danger, ButtonDecor.centrate, className)}
      displayType="link"
      shape="round"
      size="lg"
      shadowed
    >
      {t('deleteTemplate')}
    </Button>
  );
};

export default DeleteTemplateButton;
